import React from 'react'
import { Link } from 'react-router-dom'

function PatientCard(props) {

    return (
        <div className="row">
            <div className="col-3 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.patient}</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.created_at}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.treatment}</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.phase}</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.status}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}><Link to={`/od/documentacion/cargar/${props.id}`} className="btn btn-outline-primary w-100 rounded-pill px-5" >Ver</Link></p>
            </div>
        </div>
      )
}

export default PatientCard