import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";
import { ChevronDown, ChevronUp } from "react-feather";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// COMPONENTS
import Modals from "./components/modals";
import PaymentsCard from "./components/payments_card";
import PaymentsForm from "./components/payments_form";
import PaymentStatusCard from "./components/payment_status_card";
import PaymentStatusSubcard from './components/payment_status_subcard'
import PlacasRepeticion from "./PlacasRepeticion";
import InitialDetail from "./components/detail_initial";
import NextDetail from "./components/next_detail";

class index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table: {},
            cards: [],
            loading: true,
            isopened: false,
            card_opened: null,
            step: "all",
            filter: 'all',
            modal_opened: false,
            card_show: null,
            currentaccount_modal: false,
            modal_data: {
                patient: null,
                created_at: null,
                treatment: null,
                maxilla: null,
                discount: null,
                cost: null,
                payment_status: null,
                status: null,
                id: null,
            },
            estado_pagos_data: [],
            currentaccount_modal_data: [],
            total_current: 0,
            total_future: 0,
            total_overdue: 0,
            count_pending: 0
        };

        this.handleButtonType = this.handleButtonType.bind(this);
        this.handleCardShow = this.handleCardShow.bind(this);
        this.handleCard = this.handleCard.bind(this);
        this.handleStep = this.handleStep.bind(this);
    }

    componentDidMount() {
        apiClient
            .get(`/api/payments/od`)
            .then((res) => {
                console.log(res.data);
                this.setState({
                    table: res.data.table,
                    cards: res.data.card,
                    loading: false,
                });
                console.log(this.state);
            })
            .catch((err) => console.log(err));

        apiClient.get('/api/paymentStatuses')
            .then(res => {
                console.log(res.data)
                //let keys = Object.keys(res.data.data)
                let values = Object.values(res.data.data)
                let cards = []
                let cp = []
                let critical_payments = values.filter(item => item.critical_payment)

                // let critical_payments = res.data.filter(item => item.critical_payment)
                Object.keys(res.data.data).map((key, i) => cards.push([key, values[i]]))
                Object.keys(res.data.data).map((key, i) => cp.push([key, critical_payments[i]]))
                console.log(critical_payments)
                this.setState({
                    estado_pagos_data: cards,
                    total_current: res.data.total_current,
                    total_overdue: res.data.total_overdue,
                    total_future: res.data.total_future,
                    critical_payments: cp,
                    loading: false
                })
            })

        this.handleAccountModal = this.handleAccountModal.bind(this)
    }

    handleStep(step) {
        this.setState({
            step: step
        })
    }

    handleButtonType({ button, id }) {}

    handleCardShow(id) {
        this.setState({card_show: id})
    }

    handleCard(i){
        this.setState({card_opened: i})
    }

    handleAccountModal(data){
        if(data.id){
            if(data.id !== this.state.currentaccount_modal_data.id) {
                console.log("datadfgdfhgfh")
                console.log(this.state.currentaccount_modal_data)
                console.log(data)
                this.setState({currentaccount_modal: !this.state.currentaccount_modal, currentaccount_modal_data: data})
            }
        }

        this.setState({currentaccount_modal: !this.state.currentaccount_modal})
    }

    render() {
        if (this.state.loading) {
            return null;
        } else {
            if (this.state.step === "all") {
                return (
                    <div className="container-fluid px-3 mt-2">
                        <div className="row">
                            <div className="col-12">
                                <h1 style={{ fontWeight: 700 }} className="text-primary">
                                    Estado de Pagos
                                </h1>
                            </div>
                            <div className="row mt-4">
                                <div className="col-4 px-3 d-flex flex-column">
                                    <div className="shadow">
                                        <div className="w-100 py-2 bg-danger">
                                            <h4 className="text-center text-white fw-bold">Pagos Vencidos</h4>
                                        </div>
                                        <div className="w-100 py-4">
                                            <h2 className="fw-bold text-center text-danger">${this.state.total_overdue}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 px-3 d-flex flex-column">
                                    <div className="shadow">
                                        <div className="w-100 py-2 bg-dark">
                                            <h4 className="text-center text-white fw-bold">Pagos Vigentes</h4>
                                        </div>
                                        <div className="w-100">
                                            <h2 className="fw-bold text-center text-dark py-4">${this.state.total_current}</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 px-3 d-flex flex-column">
                                    <div className="shadow">
                                        <div className="w-100 py-2 bg-warning">
                                            <h4 className="text-center text-white fw-bold">Pagos Futuros</h4>
                                        </div>
                                        <div className="w-100 py-4">
                                            <h2 className="fw-bold text-center text-warning">${this.state.total_future}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <input
                                    type="text"
                                    className="w-100 border-0 p-3"
                                    placeholder="Escribe al menos 3 caracteres..."
                                    onChange={this.handleInputChange}
                                />
                            </div>
                            <div className="col-12">
                                <ul
                                    className="d-flex flex-row justify-content-start p-0"
                                    style={{ listStyleType: "none" }}
                                >
                                    <li style={{ marginRight: "15px" }}>
                                        <a
                                            className="text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                                            href="#"
                                        >
                                            Todos
                                        </a>
                                    </li>
                                    <li style={{ marginRight: "15px" }}>
                                        <a
                                            className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                                            href="#"
                                            onClick={() => this.setState({ step: 'placas_repeticion' })}
                                        >
                                            Placas de Repetición
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col d-flex flex-row justify-content-center">
                                <p className="text-primary fw-bold m-0 text-center">Paciente</p>
                            </div>
                            <div className="col d-flex flex-row justify-content-center">
                                <p className="text-primary fw-bold m-0 text-center">
                                    Costo
                                </p>
                            </div>
                            <div className="col d-flex flex-row justify-content-center">
                                <p className="text-primary fw-bold m-0 text-center">Descuento</p>
                            </div>
                            <div className="col d-flex flex-row justify-content-center">
                                <p className="text-primary fw-bold m-0 text-center">Costo Final</p>
                            </div>
                            <div className="col d-flex flex-row justify-content-center">
                                <p className="text-primary fw-bold m-0 text-center">
                                    Detalle
                                </p>
                            </div>
                        </div>
                        {this.state.table.body.map((item, i) => {
                            let asd = Object.entries(item);
                            let actions = asd.pop();
                            let id = item["id"];
                            let payment_status = parseFloat(item.payment_status).toFixed(2);
                            console.log(item);

                            return (
                                <div className="grow_hover row w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
                                    <button
                                        className="clinic-history-button type-1"
                                        style={{maxHeight: '70px'}}
                                        onClick={() =>
                                            this.setState({ modal_show: `modal_hist_clinica-${id}` })
                                        }
                                    />
                                    <PaymentsCard
                                        id={id}
                                        index={i}
                                        patient={item["patient"]}
                                        payment_status={payment_status}
                                        discount_value={item['discount']}
                                        cost_value={item['cost']}
                                        finalcost_value={item['final_cost']}
                                    />
                                    <Modals
                                        handleModal={this.handleModal}
                                        patient={item["patient"]}
                                        doctor={item["dentist"]}
                                        registro={item["registro"]}
                                        id={id}
                                        opened={this.state.modal_show}
                                    />
                                    <button
                                        className={item.status > 0 ? "btn bg-none" : 'd-none'}
                                        onClick={() =>
                                            this.setState({
                                                card_opened: this.state.card_opened !== id ? id : null,
                                            })
                                        }
                                        style={{
                                            position: "absolute",
                                            top: "20px",
                                            maxWidth: "20px",
                                            right: '15px',
                                        }}
                                    >
                                        {this.state.card_opened === id ? (
                                            <ChevronUp color="#444" size="24" />
                                        ) : (
                                            <ChevronDown color="#444" size="24" />
                                        )}
                                    </button>
                                    {this.state.card_opened === id && (
                                        <div
                                            className="container px-5 pb-4"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                backgroundColor: "#fefefe",
                                            }}
                                        >
                                            <hr />
                                            <div className="row">
                                                {
                                                    item.details.length === 0 ? <InitialDetail payment={item}/> :
                                                    item.details.map((payment) => (
                                                    <PaymentsForm next_detail={item['next_payment_detail']} updateDetail={this.updateDetail} payment_data={payment} paymentId={item["id"]} index={i} />
                                                     ))
                                                }

                                                {
                                                    item['next_payment_detail'] && <NextDetail next_detail={item['next_payment_detail']}/>
                                                }

                                                <div className="col-2 text-center">
                                                    <p className="fw-bold mt-3 mb-3">Estado del pago</p>
                                                    <div
                                                        className="d-flex flex-row"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <div>
                                                            <p style={{fontSize: 15}} className="fw-bold mt-2 mb-2">Saldo pendiente</p>
                                                            <p style={{fontSize: 30}}>450</p>
                                                            {/*<p style={{fontSize: 30}}>${item.pending_amount}</p>*/}
                                                        </div>

                                                        <CircularProgressbar
                                                            value={75}
                                                            text={`${75}%`}
                                                            strokeWidth={15}
                                                            styles={buildStyles({
                                                                pathColor:
                                                                    75 < 50
                                                                        ? "#e74c3c"
                                                                        : 75 >= 50 &&
                                                                        75 < 100
                                                                        ? "#f1c40f"
                                                                        : "#2ecc71",
                                                                textColor:
                                                                    75 < 50
                                                                        ? "#e74c3c"
                                                                        : 75 >= 50 &&
                                                                        75 < 100
                                                                        ? "#f1c40f"
                                                                        : "#2ecc71",
                                                                textSize: 20,
                                                            })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            } else if (this.state.step === "placas_repeticion") {
                return <PlacasRepeticion
                    total_current={this.state.total_current}
                    total_overdue={this.state.total_overdue}
                    total_future={this.state.total_future}
                    handleStep={this.handleStep}
                />
            }
        }
    }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
