import React, { Component } from "react";
import { connect } from "react-redux";

// COMPONENTS
import smile from "../../assets/images/ks-smile.svg";
import { BlackCard, GoldCard, PlatinumCard } from "./components/card";
import SCGold from "../../assets/images/sc-gold.png";
import SCPlatinum from "../../assets/images/sc-platinum.png";
import SCBlack from "../../assets/images/sc-black.png";
import { ChevronRight } from "@styled-icons/boxicons-regular";
import { Link } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleLogo = this.handleLogo.bind(this);
  }

  handleLogo(id) {
    let components = {
      Silver: SCPlatinum,
      Gold: SCGold,
      Platinum: SCPlatinum,
      Black: SCBlack,
    };

    return components[id];
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container-fluid px-5 position-relative">
        <div className="home-bg" style={{ height: "220px" }} />
        <div className="row pt-4 px-4" style={{ zIndex: 2 }}>
          <div className="col-12 px-4" style={{ zIndex: 2 }}>
            <img src={smile} style={{ maxWidth: "75px", margin: 0 }} alt="" />
            <h2 className="text-white fw-bold">Smile Creator</h2>
            <h4 className="text-white">
              Recibí beneficios a partir de la cantidad de tratamientos que
              realices
            </h4>
          </div>
          <div
            className="col-12 px-5 mt-3 py-4 shadow d-flex flex-row justify-content-between"
            style={{ zIndex: 2, borderRadius: "20px" }}
          >
            <div className="d-flex flex-row">
              <img
                src={this.handleLogo(this.props.state.userReducer.smilecreator)}
                style={{ maxHeight: "80px" }}
                alt=""
              />
              <h4 className="pt-4 mx-3">
                Actualmente estás en categoría{" "}
                <span className="fw-bold">
                  {this.props.state.userReducer.smilecreator}
                </span>
              </h4>
            </div>
            <Link to="/od/smileCreator/detalle" className="my-auto" href="">
              Ver detalle de puntos <ChevronRight size="18" />
            </Link>
          </div>
        </div>
        <div className="row mt-4 px-4">
          <h3 className="text-primary fw-bold">¿Cómo funciona?</h3>
          <ul className="px-4 ul-primary">
            <li className="mt-3 fs-6">
              El sistema Smile Creator de KeepSmiling consiste en recibir
              beneficios a partir de la cantidad de tratamientos que realices.
            </li>
            <li className="mt-3 fs-6">
              Solo se cuentan los tratamientos ingresados con una fecha menor a
              los últimos dos años, y se agrupan por trimestre (Enero, Febrero,
              Marzo - Abril, Mayo, Junio - Julio, Agosto, Septiembre - Octubre,
              Noviembre, Diciembre)
            </li>
            <li className="mt-3 fs-6">
              Se cuentan como validos los tratamientos de primera etapa FULL y
              FAST, indistintamente si son una o ambas arcadas.
            </li>
          </ul>
        </div>
        <div className="row mt-4 px-4">
          <h3 className="text-primary fw-bold">Beneficios</h3>
          <div className="d-flex flex-row mt-3 justify-content-around">
            <GoldCard active />
            <PlatinumCard />
            <BlackCard />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Home);
