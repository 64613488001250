import React from "react";
import { Check } from "react-feather";

export default function StepCounter({
  data_completed,
  fotos_completed,
  estudios_completed,
  plan_completed,
  registry_completed,
}) {
  console.log(fotos_completed);
  return (
    <div
      className="col-12 mt-5 mb-5 mx-auto"
      style={{ position: "relative", maxWidth: "70vw" }}
      id="timeline"
    >
      <ul
        className="w-100"
        style={{
          background: "#cecece",
          height: "4px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          listStyleType: "none",
          position: "relative",
        }}
      >
        <li
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            background: data_completed
              ? "#2ecc71"
              : !data_completed
              ? "#f1c40f"
              : "#cecece",
            position: "absolute",
            top: "-22px",
            zIndex: 10,
            left: "0",
          }}
        >
          {data_completed ? (
            <Check
              style={{
                color: "#fff",
                position: "absolute",
                top: "10px",
                left: "25%",
                strokeWidth: "4px",
              }}
            />
          ) : !data_completed ? (
            <span
              style={{
                color: "#fff",
                fontSize: "2em",
                position: "absolute",
                top: "5px",
                left: "40%",
                fontWeight: "bolder",
              }}
            >
              !
            </span>
          ) : null}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".9rem",
            }}
          >
            Datos del paciente
          </p>
        </li>
        <li
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            background: fotos_completed
              ? "#2ecc71"
              : !fotos_completed
              ? "#f1c40f"
              : "#cecece",
            position: "absolute",
            top: "-22px",
            zIndex: 10,
            left: "25%",
          }}
        >
          {fotos_completed ? (
            <Check
              style={{
                color: "#fff",
                position: "absolute",
                top: "10px",
                left: "25%",
                strokeWidth: "4px",
              }}
            />
          ) : !fotos_completed ? (
            <span
              style={{
                color: "#fff",
                fontSize: "2em",
                position: "absolute",
                top: "5px",
                left: "40%",
                fontWeight: "bolder",
              }}
            >
              !
            </span>
          ) : null}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".9rem",
            }}
          >
            Adjuntar fotos
          </p>
        </li>
        <li
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            background: estudios_completed
              ? "#2ecc71"
              : !estudios_completed
              ? "#f1c40f"
              : "#cecece",
            position: "absolute",
            top: "-22px",
            zIndex: 10,
            left: "50%",
          }}
        >
          {estudios_completed ? (
            <Check
              style={{
                color: "#fff",
                position: "absolute",
                top: "10px",
                left: "25%",
                strokeWidth: "4px",
              }}
            />
          ) : !estudios_completed ? (
            <span
              style={{
                color: "#fff",
                fontSize: "2em",
                position: "absolute",
                top: "5px",
                left: "40%",
                fontWeight: "bolder",
              }}
            >
              !
            </span>
          ) : null}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".9rem",
            }}
          >
            Adjuntar Estudios
          </p>
        </li>
        <li
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            background: plan_completed
              ? "#2ecc71"
              : !plan_completed
              ? "#f1c40f"
              : "#cecece",
            position: "absolute",
            top: "-22px",
            zIndex: 10,
            left: "75%",
          }}
        >
          {plan_completed ? (
            <Check
              style={{
                color: "#fff",
                position: "absolute",
                top: "10px",
                left: "25%",
                strokeWidth: "4px",
              }}
            />
          ) : !plan_completed ? (
            <span
              style={{
                color: "#fff",
                fontSize: "2em",
                position: "absolute",
                top: "5px",
                left: "40%",
                fontWeight: "bolder",
              }}
            >
              !
            </span>
          ) : null}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".9rem",
            }}
          >
            Adjuntar Plan de Tratamiento
          </p>
        </li>
        <li
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            background: registry_completed
              ? "#2ecc71"
              : !registry_completed
              ? "#f1c40f"
              : "#cecece",
            position: "absolute",
            top: "-22px",
            zIndex: 10,
            left: "100%",
          }}
        >
          {registry_completed ? (
            <Check
              style={{
                color: "#fff",
                position: "absolute",
                top: "10px",
                left: "25%",
                strokeWidth: "4px",
              }}
            />
          ) : !registry_completed ? (
            <span
              style={{
                color: "#fff",
                fontSize: "2em",
                position: "absolute",
                top: "5px",
                left: "40%",
                fontWeight: "bolder",
              }}
            >
              !
            </span>
          ) : null}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".9rem",
            }}
          >
            Envio de Registro
          </p>
        </li>
      </ul>
      <ul
        className="w-0"
        style={{
          background: "#2ecc71",
          height: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          listStyleType: "none",
          position: "absolute",
          top: "0",
          zIndex: 9,
        }}
      ></ul>
    </div>
  );
}
