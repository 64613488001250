import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import apiClient from "../../../utils/backend";
import moment from "moment";

import { connect } from "react-redux";
import PaymentsForm from "./payments_form";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

class AssignCurrentMoneyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      payment_method: null,
      payment_amount: null,
      bill: null,
      bill_amount: null,
      payment_date: null,
      bill_date: null,
    };
  }

  componentDidMount() {
    console.log(this.props);
  }

  updateDetail({
    payment_date,
    payment_amount,
    payment_method,
    bill,
    bill_amount,
    bill_date,
    id,
    payment_id,
  }) {
    apiClient
      .put(`/api/payments/${payment_id}/details/${id}`, {
        payment_amount: payment_amount,
        bill: bill,
        bill_amount: bill_amount,
        payment_method: payment_method,
        payment_date: payment_date,
        bill_date: bill_date,
        current_account: true,
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { opened, className, handleModal } = this.props;

    return (
      <div>
        <Modal
          size="xl"
          isOpen={opened}
          toggle={handleModal}
          className={className}
        >
          <ModalBody>
            <div className="container">
              <h3 className="mb-3 mt-3 fw-bold text-primary">
                Asignar Dinero de Cuenta a Paciente
              </h3>
              <div className="row border-bottom border-primary border-1 py-2">
                <p
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "30px", marginBottom: 0 }}
                >
                  Paciente
                </p>
                <p
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "30px", marginBottom: 0 }}
                >
                  Fecha ingreso
                </p>
                <p
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "30px", marginBottom: 0 }}
                >
                  Tratamiento
                </p>
                <p
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "30px", marginBottom: 0 }}
                >
                  Maxilar
                </p>
                <p
                  className="col-2 text-primary text-center m-0"
                  style={{ lineHeight: "30px", marginBottom: 0 }}
                >
                  Descuento
                </p>
                <p
                  className="col-2 text-primary text-center m-0"
                  style={{ lineHeight: "30px", marginBottom: 0 }}
                >
                  Costo
                </p>
                <p
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "30px", marginBottom: 0 }}
                ></p>
              </div>
              <div className="row border-bottom border-primary border-1 py-2 mb-3">
                <div
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "50px" }}
                >
                  {this.props.data.patient}
                </div>
                <div
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "50px" }}
                >
                  {moment(this.props.data.created_at).format("YYYY-MM-DD")}
                </div>
                <div
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "50px" }}
                >
                  {this.props.data.treatment}
                </div>
                <div
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "50px" }}
                >
                  {this.props.data.maxilla}
                </div>
                <div
                  className="col-2 text-primary text-center m-0"
                  style={{ lineHeight: "50px" }}
                >
                  <input
                    type="number"
                    name="discount"
                    id="discount"
                    className="form-control"
                    placeholder="%"
                    disabled
                    value={
                      this.props.data.discount !== null
                        ? this.props.data.discount
                        : null
                    }
                  />
                </div>
                <div
                  className="col-2 text-primary text-center m-0"
                  style={{ lineHeight: "50px" }}
                >
                  <input
                    type="number"
                    name="cost"
                    id="cost"
                    className="form-control"
                    placeholder="$"
                    disabled
                    value={
                      this.props.data.cost !== null
                        ? this.props.data.cost
                        : null
                    }
                  />
                </div>
                <div
                  className="col-2 text-primary text-center"
                  style={{ lineHeight: "50px" }}
                ></div>
              </div>
              <div className="row">
                {this.props.data.details !== null &&
                this.props.data.details !== undefined
                  ? this.props.data.details.map((payment, i) => (
                      <PaymentsForm
                        updateDetail={this.updateDetail}
                        payment_data={payment}
                        id={payment.id}
                        paymentId={this.props.data.id}
                      />
                    ))
                  : null}
                <div className="col mx-auto">
                  <button
                    className="btn btn-outline-primary rounded-pill"
                    onClick={() =>
                      this.props.handleAddPayment(this.props.data.id)
                    }
                  >
                    Agregar pago
                  </button>
                </div>
                <div className="col-2 text-center">
                  <p className="fw-bold mt-3 mb-3">Estado del pago</p>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgressbar
                      value={this.props.data.payment_status}
                      text={`${this.props.data.payment_status}%`}
                      strokeWidth={15}
                      styles={buildStyles({
                        pathColor:
                          this.props.data.payment_status < 50
                            ? "#e74c3c"
                            : this.props.data.payment_status >= 50 &&
                              this.props.data.payment_status < 100
                            ? "#f1c40f"
                            : "#2ecc71",
                        textColor:
                          this.props.data.payment_status < 50
                            ? "#e74c3c"
                            : this.props.data.payment_status >= 50 &&
                              this.props.data.payment_status < 100
                            ? "#f1c40f"
                            : "#2ecc71",
                        textSize: 20,
                      })}
                    />
                    <p className="fw-bold mt-2 mb-2">
                      Saldo pendiente: {this.props.data.pending_amount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {!this.state.loading && (
              <div>
                <button
                  className="btn btn-outline-primary text-primary mx-2"
                  onClick={this.props.handleModal}
                >
                  Volver
                </button>
              </div>
            )}

            {this.state.loading && (
              <button class="btn btn-success" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(AssignCurrentMoneyModal);
