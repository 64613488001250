import React from 'react'
import ModalReclamo from "./Modal";

function BodyTable({claim}) {
    return (
        <div className="row mt-3 text-primary fw-bold" style={{fontSize: 11, whiteSpace: 'nowrap'}}>
            <div className="col-1 d-flex flex-row justify-content-center">
                <p className="mb-0" style={{lineHeight: '38px'}}>{claim.id}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
                <p className="mb-0" style={{lineHeight: '38px'}}>{claim.patient}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
                <p className="mb-0" style={{lineHeight: '38px'}}>{claim.dentist}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
                <p className="mb-0" style={{lineHeight: '38px'}}>{claim.type}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
                <p className="mb-0" style={{lineHeight: '38px'}}>{claim.stage}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
                <p className="mb-0" style={{lineHeight: '38px'}}>{claim.created_at}</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
                <p className="mb-0 text-danger" style={{lineHeight: '38px'}}>{claim.required_action}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
                <ModalReclamo claim={claim}/>
            </div>
        </div>
    )
}

export default BodyTable