import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import apiClient from "../../../utils/backend";
import { useHistory } from "react-router";
import moment from 'moment';
import { toast } from "react-toastify";
import { filterPath } from "./filterPaths";
import { getDataFiltered } from "../../../redux/actions/odTreatments";
import { useDispatch } from "react-redux";

const FilterModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [opened, setOpened] = useState(false);
  const [patients, setPatients] = useState();
  const history = useHistory();
  const [filter, setFilter] = useState({
    init_date: null,
    finish_date: null,
    dentist_id: null,
    patient_id: null,
    process_id: null,
    filterByDentist: false,
    filter: false,
  });
  const [odList, setOdList] = useState([]);
  const dispatch = useDispatch()

  useEffect(async () => {
    apiClient
      .get("/api/helper/getDentists")
      .then((res) => {
        let formatted = [];
        for (var i in res.data)
          formatted.push({ value: Number(i), label: res.data[i] });
        setOdList(formatted);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleEditFilter = (e) => {
    let f = filter;
    if (!f.filter) {
      f.filter = true;
    }
    if (e.target.id === "dentist_id") {
      f.dentist_id = Number(e.target.value);
    } else {
      f[e.target.id] = e.target.value;
    }
    setFilter(f);
  };

  const handleFilter = () => {
    const data = { 'dentists.id':filter.dentist_id,'patient.id':filter.patient_id,created_at: filter.init_date,finished_at: filter.finish_date}
    const path = filterPath(data)
    dispatch( getDataFiltered(path) );
  };

  const handleDentistData = () => {
    setFilter({
      ...filter,
      filterByDentist: !filter.filterByDentist,
    });
    if(filter.filterByDentist === false){
      setOdList([]);
      setPatients([]);
    }
    apiClient
      .get("/api/dentists")
      .then((res) => {
        let formatted = [];
        for (var i in res.data.table.body)
          formatted.push({
            value: Number(i),
            label: res.data.table.body[i].user.name,
          });
        setOdList(formatted);
        setLoading(false);
        console.log(formatted);
      })
      .catch((err) => console.log(err));
    apiClient
      .get("api/patients/")
      .then((res) => setPatients(res.data.table.body))
      .catch((err) => console.log(err));
  };

  const handleChangeStartDate = (e) => {
    const date = moment(e.target.value).format('Y-M-D')
    setFilter({...filter,init_date:date})
  }
  const handleChangeEndDate = (e) => {
    const date = moment(e.target.value).format('Y-M-D')
    setFilter({...filter,finish_date:date})
  }

  if (loading) {
    return null;
  } else {
    return (
      <>
        <button
          className="btn btn-primary rounded-pill px-5 mt-2 w-100"
          onClick={() => setOpened(!opened)}
        >
          Busqueda avanzada
        </button>
        <Modal isOpen={opened} toggle={() => setOpened(!opened)}>
          <ModalBody>
            <div className="container">
              <p className="mb-5 text-primary fs-3 fw-bold">
                Buscar Tratamientos
              </p>
              <div className="row px-4">
                <div className="col-12 d-flex flex-row w-100">
                  <div className="d-flex flex-column w-75">
                    <label htmlFor="fecha">Fecha de ingreso</label>
                    <div className="d-flex flex-row mt-1">
                      <div>
                        <span>Desde</span>
                        <input
                          className="form-control w-75 mt-1"
                          onChange={(e) => handleChangeStartDate(e)}
                          type="datetime-local"
                          name="date"
                          id="date"
                        />
                      </div>
                      <div>
                        <span>Hasta</span>
                        <input
                          className="form-control w-75 mt-1"
                          onChange={(e) => handleChangeEndDate(e)}
                          type="datetime-local"
                          name="date"
                          id="date"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 d-flex flex-row w-100 my-3">
                  <div>
                    <input
                      className="checkbox_input mt-3 rounded fs-6"
                      type="checkbox"
                      name="doctor"
                      id="doctor"
                      onClick={() => handleDentistData()}
                    />
                    <label className="ms-1 fs-6 fw-bold" htmlFor="doctor">
                      Filtrar por doctor
                    </label>
                  </div>
                </div>

                {filter.filterByDentist && (
                  <>
                    <div className="col-12">
                      <label htmlFor="dentist_id">Doctor</label>
                      <select
                        className="form-control"
                        onChange={(e) => setFilter({...filter,dentist_id:e.target.value})}
                        id="dentist_id"
                        name="dentist_id"
                      >
                        <option value="0" selected={true} disabled>Selecciona un dentista</option>
                        {odList.map((od) => (
                          <option value={od.value}>{od.label}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12">
                      <label htmlFor="patient">Paciente</label>
                      <select
                        className="form-control"
                        onChange={(e) => setFilter({...filter,patient_id:e.target.value})}
                        name="patient"
                        id="patient"
                      >
                        <option value="0" selected={true} disabled>Selecciona un paciente</option>
                      {patients?.map((patient) => (
                        <option value={patient.id}>{`${patient.name} ${patient.surname}`}</option>
                      ))}
                      </select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-center">
            {!loading && (
              <div className="w-100 d-flex justify-content-center">
                <button
                  className="btn btn-success btn-lg text-white"
                  style={{ width: "50%", borderRadius: "50px" }}
                  onClick={handleFilter}
                >
                  Buscar
                </button>
              </div>
            )}

            {loading && (
              <button class="btn btn-success rounded-3" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </ModalFooter>
        </Modal>
      </>
    );
  }
};

export default FilterModal;
