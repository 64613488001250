import React, {Fragment} from 'react'

function HeaderPlacasRepeticion() {
    return (
        <Fragment>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">ID</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Paciente</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Doctor</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Fecha Pedido</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Fecha Salida</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Placas</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Maxilar</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Cant.</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Motivo</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Costo</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Estado de pago</p>
            </div>
        </Fragment>
    )
}

export default HeaderPlacasRepeticion