import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";

// COMPONENTS

import "./style.css";
import bg from "../../assets/images/home_bg.svg";
import PatientCard from "./components/patient_card";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      todos: [],
      acciones_pendientes: [],
      sao: [],
      step: "todos",
    };
  }

  componentDidMount() {
    apiClient
      .get("/api/incomes/od")
      .then((res) => {
        console.log("res");
        console.log(res);
        let todos = res.data.todos.map((item) => item);
        let acciones_pendientes = res.data.acciones_pendientes.map(
          (item) => item
        );

        this.setState({ todos, acciones_pendientes, loading: false });
      })
      .catch((err) => console.log(err));
  }

  handleTreatmentColor(type) {
    const colors = {
      Full: 1,
      Fast: 2,
      Medium: 3,
      Kids: 4,
      "Kids Fast": 5,
      Teens: 6,
    };

    return colors[type];
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid px-5 position-relative overflow-hidden">
          <img src={bg} className="home-bg1" />
          <div className="row pt-4" style={{ zIndex: 2 }}>
            <div className="col-8 px-4" style={{ zIndex: 2 }}>
              <div
                className="w-100 h-100 shadow p-4 px-5"
                style={{ borderRadius: "20px" }}
              >
                <h4 className="text-primary">Bienvenido</h4>
                <h2 className="text-primary fw-bold">Dr. nombre1, apellido1</h2>
              </div>
            </div>
            <div
              className="col-4 px-2 d-flex justify-content-center"
              style={{ zIndex: 2 }}
            >
              <button
                className="btn btn-light text-primary fw-bold fs-4 p-4 px-5 shadow mt-5 "
                onClick={() => this.props.history.push("/od/solicitud")}
                style={{ borderRadius: "20px" }}
              >
                Nueva solicitud
              </button>
            </div>
          </div>
          <div className="row pt-5 px-3">
            <div className="col-8 d-flex align-items-center mb-2">
              <h3 className="fw-bold text-primary m-0 mx-1">Mis pacientes</h3>
            </div>
            <div className="col-4 mt-2 d-flex justify-content-end">
              <input
                type="text"
                className="search-input w-100 border-0 px-3 py-2 rounded-pill border border-1 mx-2"
                placeholder="Buscar..."
              />
            </div>
            <hr className="mt-2" />
            <div className="col-12">
              <ul
                className="d-flex flex-row justify-content-start p-0"
                style={{ listStyleType: "none" }}
              >
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "todos" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "todos" })}
                  >
                    Todos{" "}
                    <span className="text-muted fw-bold">
                      ({this.state.todos.length})
                    </span>
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "sao" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "sao" })}
                  >
                    SAO{" "}
                    <span className="text-muted fw-bold">
                      ({this.state.sao.length})
                    </span>
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === "acciones_pendientes" && "disabled"
                    }`}
                    href="#"
                    onClick={() =>
                      this.setState({ step: "acciones_pendientes" })
                    }
                  >
                    Acciones pendientes{" "}
                    <span className="text-muted fw-bold">
                      ({this.state.acciones_pendientes.length})
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-3 px-4 ">
            <div className="col-3 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">Paciente</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">
                Tratamiento
              </p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">Etapa</p>
            </div>
            <div className="col-7"></div>
          </div>
          <div className="row mt-3 px-3">
            <div className="col-12">
              {this.state[this.state.step].map((item, i) => {
                return (
                  <div>
                    <div
                      className={`grow_hover w-100 shadow border border-1 rounded container-fluid py-2 text-start mt-2 mb-2 position-relative`}
                    >
                      <button
                        className={`patientcard-bar type-${this.handleTreatmentColor(
                          item.type.name
                        )}`}
                      />
                      <PatientCard
                        id={item.id}
                        patient_id={item.patient_id}
                        external_id={item.external_id}
                        patient={item.patient}
                        created_at={item.created_at}
                        treatment_type={item.type.id}
                        treatment={item.type.name}
                        phase={item.order}
                        pendings={item.pendings}
                        handleModal={this.handleModal}
                        filter="rejected"
                        type="intraoral"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Home);
