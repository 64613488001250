import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import apiClient from '../../../utils/backend'
import { toast } from 'react-toastify'
import { Edit, X } from 'react-feather'

import direction from '../../../assets/images/direcciones.png'
import saologo from '../../../assets/images/sao_logo.png'
import saologoinactive from '../../../assets/images/sao_logo_inactive.png'
import ubaLogo from '../../../assets/images/UBA.png'
import ubaLogoInactive from '../../../assets/images/UBA_inactive.png'
import './index.css'

const EditPatient = ({ props,principalDentist }) => {
  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(false)
  const [assign, setAssign] = useState(false)
  const [data, setData] = useState({
    name: null,
    surname: null,
    email: null,
    profile: null,
    odProfile: null,
  })

  useEffect(() => {
    if(principalDentist === null){
      principalDentist = props.treatments[0]?.dentist[0]
    }
    setData({
      name: props.name,
      surname: props.surname,
      email: props.email,
      profile:
        props.groups[0]?.id === 1 ? 1 : props.groups[0]?.id === 3 ? 3 : [],
      odProfile: principalDentist?.user.groups,
    })
  }, [])

  function handleChange(e) {
    let state = { ...data }
    state[e.target.id] = e.target.value

    setData(state)
    setAssign(false)
  }

  function handleRequest(e) {
    e.preventDefault()
    const headers = {
      'Content-Type': 'application/json',
    }
    apiClient
      .put(`/api/patients/${props.id}`, data, headers)
      .then((res) => {
        toast.success('😁 Paciente editado correctamente.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      })
      .catch((err) => {
        toast.error(
          '🤔 Hubo un error al editar el paciente, por favor, vuelva a intentar.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      })
  }
  return (
    <div>
      <button onClick={() => setOpened(true)} className="btn bg-none">
        <Edit color="#444" size={24} />
      </button>
      <Modal isOpen={opened} size="lg" toggle={() => setOpened(!opened)}>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h4 className="mb-3 fw-bold text-primary">
                  Editar datos paciente
                </h4>
              </div>
              <div className="col-6 d-flex justify-content-end">
                <X className="close_button" onClick={() => setOpened(false)} />
              </div>
            </div>
            <div className="row px-3">
              <div className="col-4 d-flex flex-column justify-content-center py-2">
                <img style={{ maxWidth: '200px' }} src={direction} alt="" />
                <p className="text-center mt-2 fw-light">
                  Id Paciente {props.id}
                </p>
              </div>
              <div className="col-8 px-3">
                <form className="row mb-4 mt-2">
                  <h5 className="fw-bold mb-2">Datos Personales</h5>
                  <div className="col-6">
                    <label htmlFor="name">Nombre</label>
                    <input
                      className="form-control"
                      type="text"
                      id="name"
                      onChange={(e) => handleChange(e)}
                      value={data.name}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="surname">Apellido</label>
                    <input
                      className="form-control"
                      type="text"
                      id="surname"
                      onChange={(e) => handleChange(e)}
                      value={data.surname}
                    />
                  </div>
                  <div className="col-12">
                    <label htmlFor="email">E-mail</label>
                    <input
                      className="form-control"
                      type="text"
                      id="email"
                      onChange={(e) => handleChange(e)}
                      value={data.email}
                    />
                  </div>
                </form>
              </div>
              {data.odProfile?.length !== 0 && (
                <>
                  <hr />
                  <div className="col-12 px-3 mt-3">
                    <form className="row mb-4 mt-2">
                      <div className="col-5">
                        <h5 className="text-primary fw-bold">
                          Perfil del paciente
                        </h5>
                        <div className="col-2 d-flex flex-row">
                          <div
                            onClick={() => setData({ ...data, profile: 1 })}
                            className={`btn border rounded ${
                              data.profile === 1 && 'shadow'
                            } me-2`}
                          >
                            <img
                              src={
                                data.profile === 1 ? saologo : saologoinactive
                              }
                              style={{ maxWidth: '60px' }}
                              alt="SAO"
                            />
                          </div>
                          <div className="col-2 d-flex flex-row">
                            <div
                              onClick={() => setData({ ...data, profile: 3 })}
                              className={`btn border rounded ${
                                data.profile === 3 && 'shadow'
                              } me-2`}
                            >
                              <img
                                src={
                                  data.profile === 3 ? ubaLogo : ubaLogoInactive
                                }
                                style={{ maxWidth: '60px' }}
                                alt="UBA"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-end">
            {!loading && (
              <div>
                <button
                  onClick={(e) => handleRequest(e)}
                  className="btn btn-success px-5 rounded-pill text-white"
                >
                  Guardar
                </button>
              </div>
            )}

            {loading && (
              <button class="btn btn-danger" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditPatient
