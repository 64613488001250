import React, {Fragment} from 'react'
import moment from "moment";

function InitialDetail(props) {
    return (
        <Fragment>
            <div className="col-4 px-3 mb-5">
                <div className="d-flex flex-column justify-content-between">
                    <div
                        className="rounded-pill border-primary px-4 py-2 w-50 bg-success"
                        style={{opacity: 0.5 }}
                    >
                        <span className="text-white">1° Pago</span>
                    </div>

                    <small className="mt-2" style={{ fontSize: ".7rem", marginLeft: "5px" }}>
                        Se espera el 50% del costo final.
                    </small>

                    <div className="d-flex flex-row mt-3">
                        <div className="col-6">
                            <small className="text-primary fs-6">Monto</small>
                            <p className="fw-bold text-primary mt-2 fs-5">{props.payment['final_cost'] / 2}</p>
                        </div>
                        <div className="col-6">
                            <small className="text-primary fs-6">Fecha vencimiento</small>
                            <p className="text-primary mt-2 fs-5">{moment(props.payment.created_at).add(2, 'days').format('DD/MM/YYYY')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default InitialDetail