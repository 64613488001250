import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import apiClient from '../../../utils/backend'
import Input from '../../../components/input'
import { MapPin, MoreVertical, User } from 'react-feather'

// IMAGES
import saologo from '../../../assets/images/sao_logo.png'
import sglogo from '../../../assets/images/study_group_logo.png'
import direction from '../../../assets/images/direcciones.png'
import moment from 'moment'

import './index.css'
import DirectionModal from './components/direction_modal'
import { toast } from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
export const NewDentist = () => {
  useEffect(() => {
    apiClient
      .get('api/getGroups')
      .then((res) => {
        setGroupData(res.data.data)
      })
      .catch((err) => console.log(err))
  }, [])

  const [groupData, setGroupData] = useState([])
  const [id, setID] = useState(
    !isNaN(window.location.pathname.split('/')[2])
      ? window.location.pathname.split('/')[2]
      : ''
  )
  const history = useHistory()
  const [path, setPath] = useState(window.location.pathname.split('/')[2])
  const [avatar, setAvatar] = useState(null)
  const [officesSended, setOfficesSended] = useState(false)
  const [avatarSended, setAvatarSended] = useState(false)
  const [state, setState] = useState({
    telefono: null,
    prefijo: null,
    sufijo: null,
    codigo_area: null,
    cuit: null,
    fiscal_condition: null,
    national_enrollment: null,
    provintial_enrollment: null,
    direcciones: [],
    fecha_curso: null,
    mostrar_od_web: null,

    name: null,
    apellido: null,
    gender: null,
    email: null,
    password: null,
    birthdate: null,
    profile: null,
    study_group: false,
    sao: false,
    sg_type: null,
    type: null,
    group_id: null,

    facebook: null,
    instagram: null,
    twitter: null,
    web: null,
    prefix: '+54',
    sufix: 15,
    area_code: 11,
    phone_number: null,

    profile_photo: null,

    error: { error: false, message: '' },

    errors: [],
  })

  const [hasDirecction, setHasDirecction] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [direccionError, setDireccionError] = useState(false)
  const [errorField, setErrorField] = useState({
    user: { name: '', surname: '', gender: '', birthdate: '', email: '' },
    dentist: {
      phone_number: '',
      cuit: '',
      fiscal_condition: '',
      national_enrollment: '',
      provintial_enrollment: '',
      facebook: '',
      twitter: '',
      instagram: '',
      web: '',
      accreditation_course: '',
    },
    profile: {
      sg_type: '',
    },
  })

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      borderBottom: '2px solid #0027a8!important',
    }),
  }

  const genderos = [
    { value: 'm', label: 'Masculino' },
    { value: 'f', label: 'Femenino' },
    {
      value: 'o',
      label: 'Otro',
    },
  ]

  const cf = [
    { value: 1, label: 'Monotributista' },
    { value: 2, label: 'Responsable inscripto' },
    {
      value: 3,
      label: 'Excento',
    },
    { value: 4, label: 'No Alcanzado' },
  ]

  const gender = genderos.find((item) => item.value === state.gender)
  const fiscal_condition = cf.find(
    (item) => item.value === state.fiscal_condition
  )

  useEffect(() => {
    let url =
      path === 'perfil'
        ? '/api/dentists/' +
          localStorage.getItem('user_id') +
          '?include=user,offices,studyGroupRelationship'
        : '/api/dentists/' +
          path +
          '?include=user,offices,studyGroupRelationship'

    if (path !== 'nuevo') {
      if (path === 'perfil') {
        setID(localStorage.getItem('user_id'))
      }

      apiClient.get(url).then((res) => {
        console.log(res)
        let birthdate_polyfill = res.data.data.user.birthdate
          .split('/')
          .reverse()
          .join('-')
        setState({
          id: res.data.data.id,
          profile_photo: res.data.data.user.avatar,
          name: res.data.data.user.name,
          apellido: res.data.data.user.surname,
          gender: res.data.data.user.gender,
          email: res.data.data.user.email,
          phone_number: res.data.data.phone_number,
          birthdate: birthdate_polyfill,
          cuit: res.data.data.cuit,
          provintial_enrollment: res.data.data.provintial_enrollment,
          national_enrollment: res.data.data.national_enrollment,
          fiscal_condition: res.data.data.fiscal_condition,
          facebook: res.data.data.facebook,
          instagram: res.data.data.instagram,
          twitter: res.data.data.twitter,
          web: res.data.data.web,
          fecha_curso: moment(res.data.data.accreditation_course).format(
            'YYYY-MM-DD'
          ),
          direcciones: res.data.data.offices,
          sao: res.data.data.sao,
          study_group: res.data.data.study_group,
          sg_type: res.data.data.study_group_relationship?.rol
            ? Number(res.data.data.study_group_relationship?.rol)
            : 0,
          sg_level: res.data.data.study_group_relationship?.type
            ? Number(res.data.data.study_group_relationship?.type)
            : null,
          sg_team:
            res.data.data.study_group_relationship?.group_id !== null
              ? Number(res.data.data.study_group_relationship?.group_id)
              : null,
        })
      })
    }
  }, [])
  useEffect(() => {
    if (avatarSended && officesSended) {
      if (path === 'perfil') {
        setTimeout(() => {
          window.location.href = '/od/home'
        }, 1500)
      } else {
        setTimeout(() => {
          window.location.href = '/odontologos'
        }, 1500)
      }
    }
  }, [avatarSended, officesSended, path])

  useEffect(() => {
    if (!state.study_group) {
      delete state['sg_type']
      delete state['sg_level']
      delete state['sg_team']
    }
  }, [state.study_group])

  const handleRequest = async () => {
    let user = {
      name: state.name,
      surname: state.apellido,
      email: state.email,
      password: state.password,
      gender: state.gender,
      birthdate: state.birthdate,
    }

    let profile = {
      study_group: state.study_group,
      sao: state.sao,
      sg_type: state.sg_type,
      sg_team: state.sg_team,
      sg_level: state.sg_level,
    }

    let dentist = {
      cuit: state.cuit,
      fiscal_condition: state.fiscal_condition,
      national_enrollment: state.national_enrollment,
      provintial_enrollment: state.provintial_enrollment,
      phone_number: state.telefono,
      // prefix: "0",
      sufix: '11',
      area_code: '+54',
      facebook: state.facebook,
      instagram: state.instagram,
      web: state.web,
      twitter: state.twitter,
      prefix: '+54',
      sufix: 15,
      area_code: 11,
      phone_number: state.phone_number,
      accreditation_course: state.fecha_curso,
    }

    const headers = {
      'Content-Type': 'application/json',
    }

    let addresses = state.direcciones

    if (path === 'perfil') {
      const promise = apiClient
        .put(
          '/api/dentists/' + id,
          {
            user,
            profile,
            dentist,
          },
          headers
        )
        .then((res) => {
          if (res.status === 200) {
            setID(id)
            if (avatar !== null) {
              handleSendAvatar(id)
            } else {
              setAvatarSended(true)
            }

            if (addresses.length > 0) {
              handleSendAddress({ id, addresses })
            } else {
              setOfficesSended(true)
            }
          } else {
            handleSetErrors(res.data.errors)
          }
        })
        .catch((err) => {
          console.log(err.response)
          handleSetErrors(err.response.data.errors)
        })
    }

    if (path !== 'nuevo' && path !== 'perfil') {
      apiClient
        .put(
          '/api/dentists/' + id,
          {
            user,
            profile,
            dentist,
          },
          headers
        )
        .then((res) => {
          if (res.status === 200) {
            setID(id)
            toast.success('Dentista actualizado correctamente!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            handleSetErrors({})

            if (avatar !== null) {
              handleSendAvatar(id)
            } else {
              setAvatarSended(true)
            }
          } else {
            toast.error('Hubo un problema al cargar los datos', {
              icon: '🤔',
              style: {
                border: '1px solid #ff0000',
                padding: '8px',
              },
            })
            handleSetErrors(res.data.errors)
          }
          setTimeout(() => {
            history.push('/odontologos')
          }, 2000)
        })
        .catch((err) => {
          toast.error('Hubo un problema al cargar los datos', {
            icon: '🤔',
            style: {
              border: '1px solid #ff0000',
              padding: '8px',
            },
          })
          console.log(err.response)
          handleSetErrors(err.response.data.errors)
        })
    } else {
      if (direccionError) {
        if (hasDirecction) {
          const odId = localStorage.getItem('new_od_id')
          setID(odId)
          handleSendAvatar(odId)
          try {
            state.direcciones.map((odDirection) => {
              return apiClient
                .post(`/api/dentists/${odId}/offices`, odDirection)
                .then((response) => {
                  console.log(response)
                })
                .catch((err) => {
                  toast.error('Hubo un problema al crear la direccion', {
                    icon: '🤔',
                    style: {
                      border: '1px solid #ff0000',
                      padding: '8px',
                    },
                  })
                  console.log(err)
                  setModalOpened(true)
                  return setDireccionError(true)
                })
            })
            setDireccionError(false)
          } catch (error) {
            toast.error('Hubo un problema al crear la direccion', {
              icon: '🤔',
              style: {
                border: '1px solid #ff0000',
                padding: '8px',
              },
            })
            console.log(error)
          }
        }
      } else {
        if (path === 'nuevo') {
          const inputs = { user, profile, dentist, addresses }
          apiClient.post(`api/dentists/`, inputs, headers).then((res) => {
            if (res.status === 200) {
              if (res.data.success && res.data.data?.id) {
                localStorage.setItem('new_od_id', res.data.data.id)
                handleSendAvatar(res.data.data.id)
                setID(res.data.data.id)
              }

              if (!res.data.errors) {
                setErrorField({
                  user: {
                    name: '',
                    surname: '',
                    gender: '',
                    birthdate: '',
                    email: '',
                  },
                  dentist: {
                    phone_number: '',
                    cuit: '',
                    fiscal_condition: '',
                    national_enrollment: '',
                    provintial_enrollment: '',
                    facebook: '',
                    twitter: '',
                    instagram: '',
                    web: '',
                  },
                })
                if (hasDirecction) {
                  const idDentist = res.data.data?.id
                  try {
                    state.direcciones.map((odDirection) => {
                      localStorage.setItem('new_od_id', res.data.data?.id)
                      return apiClient
                        .post(`/api/dentists/${idDentist}/offices`, odDirection)
                        .then((response) => {
                          setOfficesSended(true)
                        })
                        .catch((err) => {
                          toast.error(
                            'Hubo un problema al crear la direccion',
                            {
                              icon: '🤔',
                              style: {
                                border: '1px solid #ff0000',
                                padding: '8px',
                              },
                            }
                          )
                          setModalOpened(true)
                          return setDireccionError(true)
                        })
                    })
                  } catch (error) {
                    console.log(error)
                  }
                } else {
                  setOfficesSended(true)
                }
                toast('🧑‍⚕️ Creando odontólogo...')
              } else {
                toast.error('Campos inválidos o vacíos, revisar.', {
                  icon: '🤔',
                  style: {
                    border: '1px solid #ff0000',
                    padding: '8px',
                  },
                })
                handleSetErrors(res.data.errors)
              }
            } else {
              handleSetErrors(res.data.errors)
            }
          })
        }
      }
    }
  }

  const handleSetErrors = (err) => {
    setErrorField({
      ...errorField,
      user: {
        name: (err['user.name'] !== undefined && err['user.name']) || '',
        surname:
          (err['user.surname'] !== undefined && err['user.surname']) || '',
        gender: (err['user.gender'] !== undefined && err['user.gender']) || '',
        birthdate:
          (err['dentist.birthdate'] !== undefined &&
            err['dentist.birthdate']) ||
          '',
        email: (err['user.email'] !== undefined && err['user.email']) || '',
      },
      dentist: {
        phone_number:
          (err['dentist.phone_number'] !== undefined &&
            err['dentist.phone_number']) ||
          '',
        cuit: (err['dentist.cuit'] !== undefined && err['dentist.cuit']) || '',
        fiscal_condition:
          (err['dentist.fiscal_condition'] !== undefined &&
            err['dentist.fiscal_condition']) ||
          '',
        national_enrollment:
          (err['dentist.national_enrollment'] !== undefined &&
            err['dentist.national_enrollment']) ||
          '',
        provintial_enrollment:
          (err['dentist.provintial_enrollment'] !== undefined &&
            err['dentist.provintial_enrollment']) ||
          '',
        facebook:
          (err['dentist.facebook'] !== undefined && err['dentist.facebook']) ||
          '',
        twitter:
          (err['dentist.twitter'] !== undefined && err['dentist.twitter']) ||
          '',
        instagram:
          (err['dentist.instagram'] !== undefined &&
            err['dentist.instagram']) ||
          '',
        web: (err['dentist.web'] !== undefined && err['dentist.web']) || '',
        accreditation_course:
          (err['dentist.accreditation_course'] !== undefined &&
            err['dentist.accreditation_course']) ||
          '',
      },
      profile: {
        sg_type:
          (err['profile.sg_type'] !== undefined && err['profile.sg_type']) ||
          '',
      },
    })
  }

  const handleSendAddress = async ({ id, addresses }) => {
    try {
      addresses.map((odDirection) => {
        if (odDirection.is_edit.value) {
          return apiClient
            .put(`/api/dentists/${id}/offices`, odDirection)
            .then((response) => {
              console.log(response)
              if (response.errors) {
                let errors = response.errors
                let keys = errors.keys()
                let values = errors.values()

                let errorsField = {}

                if (keys.length > 0) {
                  keys.map((key, index) => {
                    errorsField[key] = values[index]
                  })

                  setErrorField({ ...errorField, ...errorsField })
                  console.log(errorsField)
                }
              } else {
                setOfficesSended(true)
              }
            })
            .catch((err) => {
              toast.error('🤔 Hubo un problema al crear la direccion', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              setModalOpened(true)
              return setDireccionError(true)
            })
        } else {
          return apiClient
            .post(`/api/dentists/${id}/offices`, odDirection)
            .then((response) => {
              console.log(response)
              if (response.errors) {
                let errors = response.errors
                let keys = errors.keys()
                let values = errors.values()

                let errorsField = {}

                if (keys.length > 0) {
                  keys.map((key, index) => {
                    errorsField[key] = values[index]
                  })

                  setErrorField({ ...errorField, ...errorsField })
                  console.log(errorsField)
                }
              } else {
                setOfficesSended(true)
              }
            })
            .catch((err) => {
              toast.error('🤔 Hubo un problema al crear la direccion', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              })
              setModalOpened(true)
              return setDireccionError(true)
            })
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleSendAvatar = (odid) => {
    if (avatar !== null && avatar !== undefined) {
      const data = new FormData()
      data.append('avatar', avatar)
      data.append('_method', 'PUT')

      const headers = {
        'Content-Type': 'multipart/form-data',
      }

      apiClient
        .post(`/api/dentists/${odid ? odid : id}/avatar`, data, headers)
        .then((res) => {
          if (res.status === 200) {
            setAvatarSended(true)
          }
        })
    } else {
      setAvatarSended(true)
    }
  }

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    })
  }

  const handleAddDirection = (data) => {
    let directions = [...state.direcciones]
    directions.push(data)
    setState({
      ...state,
      direcciones: directions,
      dir_index: state.dir_index + 1,
    })
    setHasDirecction(true)
  }

  const handleEditDirection = (data, index, id) => {
    let directions = [...state.direcciones]
    directions[index] = data
    setState({
      ...state,
      direcciones: directions,
    })
    apiClient
      .patch(`/api/offices/${id}`, data)
      .then((res) => console.log(res))
      .catch((err) => console.log(err))
  }

  const handleRemoveDirection = (i) => {
    setState({
      ...state,
      direcciones: state.direcciones.filter(function (dir, index) {
        return index !== i
      }),
    })
  }

  const handleSetDirection = (index, dir) => {
    let directions = [...state.direcciones]
    if (index === -1 && directions.length < 1) {
      directions[0] = dir
    } else {
      directions[index] = dir
    }
    setState({
      ...state,
      direcciones: directions,
    })
  }

  const getBase64 = (file, cb) => {
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  return (
    <>
      <div className="container mt-4 px-5">
        <div className="row">
          <div className="col-10 d-flex flex-row justify-content-between">
            <h1 style={{ fontWeight: 700 }} className="text-primary">
              {typeof path !== 'number'
                ? 'Editar Odontologo'
                : 'Nuevo Odontologo'}
            </h1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-8">
            <div className="row">
              <div className="col-4">
                <input
                  type="file"
                  name=""
                  onChange={(e) => {
                    setAvatar(e.target.files[0])
                    getBase64(e.target.files[0], (res) =>
                      setState({ ...state, profile_photo: res })
                    )
                  }}
                  disabled={path === 'perfil'}
                  id="od_profile_pic_input"
                  className=""
                />
                <label for="od_profile_pic_input">
                  {state.profile_photo !== null ? (
                    <img
                      style={{
                        maxWidth: '200px',
                        maxHeight: '200px',
                        objectFit: 'cover',
                      }}
                      src={state.profile_photo}
                      alt="Foto de perfil"
                    />
                  ) : (
                    <div className="flex">
                      <User size={124} color="#ccc" />
                      <p className="text-primary text-center">Avatar</p>
                    </div>
                  )}
                </label>
              </div>

              <div className="col-8 px-2">
                <h5 className="fw-bold">Datos personales</h5>
                <div className="row mb-4 mt-2">
                  <div className="col-6">
                    <Input
                      label="Nombre"
                      type="text"
                      id="name"
                      onChange={handleChange}
                      value={state.name}
                      disabled={path !== 'nuevo'}
                    />
                    {errorField.user.name && (
                      <p className="text-danger fw-light">
                        {errorField.user?.name}
                      </p>
                    )}
                  </div>
                  <div className="col-6">
                    <Input
                      label="Apellido"
                      type="text"
                      id="apellido"
                      onChange={handleChange}
                      value={state.apellido}
                      disabled={path !== 'nuevo'}
                    />
                    {errorField.user.surname && (
                      <p className="text-danger fw-light">
                        {errorField.user?.surname}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-6">
                    <label htmlFor="gender">Genero</label>
                    <Select
                      styles={selectStyle}
                      options={genderos}
                      placeholder="Seleccione el genero..."
                      isSearchable
                      onChange={(e) => setState({ ...state, gender: e.value })}
                      value={gender || state.gender}
                    />
                    {errorField.user.gender && (
                      <p className="text-danger fw-light mt-3">
                        {errorField.user.gender}
                      </p>
                    )}
                  </div>
                  <div className="col-6">
                    <Input
                      label="Fecha de nacimiento"
                      type="date"
                      id="birthdate"
                      value={state.birthdate}
                      onChange={handleChange}
                    />
                    {errorField.user.birthdate && (
                      <p className="text-danger fw-light">
                        {errorField.user.birthdate}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4 mt-4">
              <div className="col-6">
                <Input
                  label="Email"
                  type="email"
                  id="email"
                  onChange={handleChange}
                  value={state.email}
                />
                {errorField.user.email && (
                  <p className="text-danger fw-light">
                    {errorField.user.email}
                  </p>
                )}
              </div>
              <div className="col-6">
                <Input
                  label="Telefono"
                  type="number"
                  id="phone_number"
                  onChange={handleChange}
                  value={state.phone_number}
                />
                {errorField.dentist.phone_number && (
                  <p className="text-danger fw-light">
                    {errorField.dentist.phone_number}
                  </p>
                )}
              </div>
            </div>
            <hr />
            <div className="row mb-4">
              <div className="col-6">
                <Input
                  label="CUIT"
                  type="text"
                  id="cuit"
                  onChange={handleChange}
                  value={state.cuit}
                />
                {errorField.dentist.cuit && (
                  <p className="text-danger fw-light">
                    {errorField.dentist.cuit}
                  </p>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="fiscal_condition">Condicion Fiscal</label>
                <Select
                  styles={selectStyle}
                  placeholder="Seleccione la condicion fiscal..."
                  options={cf}
                  onChange={(e) =>
                    setState({ ...state, fiscal_condition: e.value })
                  }
                  value={fiscal_condition || state.fiscal_condition}
                />
                {errorField.dentist?.fiscal_condition && (
                  <p className="text-danger fw-light">
                    {errorField.dentist?.fiscal_condition}
                  </p>
                )}
                <span style={{ color: 'red' }}></span>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-6">
                <label htmlFor="national_enrollment">Matricula Nacional</label>
                <input
                  type="national_enrollment"
                  className="form-control"
                  id="national_enrollment"
                  onChange={(e) =>
                    setState({ ...state, [e.target.id]: e.target.value })
                  }
                  value={state.national_enrollment}
                />
                {errorField.dentist?.national_enrollment && (
                  <p className="text-danger fw-light">
                    {errorField.dentist?.national_enrollment}
                  </p>
                )}
              </div>
              <div className="col-6">
                <label htmlFor="provintial_enrollment">
                  Matricula Provincial
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="provintial_enrollment"
                  onChange={(e) =>
                    setState({ ...state, [e.target.id]: e.target.value })
                  }
                  value={state.provintial_enrollment}
                />
              </div>
              {errorField.dentist?.provintial_enrollment && (
                <p className="text-danger fw-light">
                  {errorField.dentist?.provintial_enrollment}
                </p>
              )}
            </div>
            <hr />
            <h5 className="fw-bold">Direccion</h5>
            <p className="mt-2">
              Estas direcciones son las que se utilizaran para coordinar retiro
              de impresiones y entrega de placas. Puede ingresar varias
              direcciones. Al momento de coordinar retiro el odontólogo usted
              podrá elegir la dirección en la cual quiere que se retiren
              impresiones o entregar el tratamiento.
            </p>

            <div className="d-flex flex-row flex-wrap mt-3">
              {state.direcciones?.length > 0 &&
                state.direcciones.map((dir, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        width: '200px',
                        height: '250px',
                        marginRight: '20px',
                        marginBottom: '20px',
                        borderRadius: '20px',
                      }}
                      className="position-relative border border-1 shadow d-flex flex-column justify-content-center"
                    >
                      <div class="btn-group dropend">
                        <button
                          type="button"
                          class="btn bg-none dropdown p-0 position-absolute"
                          style={{ top: '15px', right: '5px' }}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <MoreVertical size={24} color="#444" />
                        </button>
                        <ul class="dropdown-menu">
                          <li>
                            <button
                              onClick={() => handleRemoveDirection(i)}
                              className="btn bg-none text-muted d-flex flex-column justify-content-center text-center align-items-middle"
                            >
                              Eliminar
                            </button>
                            <DirectionModal
                              edit={true}
                              direction={state.direcciones[i]}
                              handleAddDir={handleAddDirection}
                              handleEditDir={handleEditDirection}
                              errorField={setErrorField}
                              setErrorField={setErrorField}
                              index={i}
                            />
                          </li>
                        </ul>
                      </div>
                      <p className="text-center text-truncate fw-bold mt-3 fs-6 px-4 mx-1">
                        Consultorio {dir.city?.value}
                      </p>
                      <img
                        src={direction}
                        alt=""
                        className="w-50 mx-auto px-2 mt-3"
                      />
                      <div
                        className="d-flex flex-column mt-auto"
                        style={{ marginLeft: '30px' }}
                      >
                        <p
                          style={{ fontSize: '.8rem' }}
                          className="text-truncate"
                        >
                          <MapPin color="#002E50" size={14} className="mb-1" />
                          {dir.street_address} {dir.street_number}
                        </p>
                        <p
                          style={{
                            fontSize: '.8rem',
                            marginBottom: '10px',
                            marginLeft: '15px',
                          }}
                          className="text-truncate"
                        >
                          {dir.floor} Piso, CP: {dir.zip_code}
                        </p>
                        <p
                          style={{
                            fontSize: '.8rem',
                            marginBottom: '10px',
                            marginLeft: '15px',
                          }}
                          className="text-truncate"
                        >
                          {dir.city}, {dir.province}
                        </p>
                      </div>
                    </div>
                  )
                })}
              <div
                style={{
                  width: '200px',
                  height: '250px',
                  marginRight: '20px',
                  borderRadius: '20px',
                }}
                className="position-relative border border-1 shadow d-flex flex-column justify-content-center"
              >
                <DirectionModal handleAddDir={handleAddDirection} />
              </div>
            </div>

            <hr />
            <h5 className="fw-bold">Curso de acreditacion</h5>
            <div className="row mb-4 mt-3">
              <div className="col-6">
                <label htmlFor="fecha_curso">
                  Fecha en la que se realizo el curso
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="fecha_curso"
                  onChange={(e) =>
                    setState({ ...state, [e.target.id]: e.target.value })
                  }
                  disabled={path === 'perfil'}
                  value={state.fecha_curso}
                />
                {errorField.dentist?.accreditation_course && (
                  <p className="text-danger fw-light">
                    {errorField.dentist?.accreditation_course}
                  </p>
                )}
              </div>
            </div>
            <hr />
            <h5 className="fw-bold">Redes sociales</h5>
            <div className="row mb-4 mt-3">
              <div className="col-12">
                <label htmlFor="facebook">Facebook</label>
                <input
                  type="text"
                  className="form-control"
                  id="facebook"
                  onChange={(e) =>
                    setState({ ...state, [e.target.id]: e.target.value })
                  }
                  value={state.facebook}
                />
                {errorField.dentist?.facebook && (
                  <p className="text-danger fw-light">
                    {errorField.dentist?.facebook}
                  </p>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="instagram|">Instagram</label>
                <input
                  type="text"
                  className="form-control"
                  id="instagram"
                  onChange={(e) =>
                    setState({ ...state, [e.target.id]: e.target.value })
                  }
                  value={state.instagram}
                />
                {errorField.dentist?.instagram && (
                  <p className="text-danger fw-light">
                    {errorField.dentist?.instagram}
                  </p>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="twitter">Twitter</label>
                <input
                  type="text"
                  className="form-control"
                  id="twitter"
                  onChange={(e) =>
                    setState({ ...state, [e.target.id]: e.target.value })
                  }
                  value={state.twitter}
                />
                {errorField.dentist?.twitter && (
                  <p className="text-danger fw-light">
                    {errorField.dentist?.twitter}
                  </p>
                )}
              </div>
              <div className="col-12">
                <label htmlFor="web">Web personal</label>
                <input
                  type="text"
                  className="form-control"
                  id="web"
                  onChange={(e) =>
                    setState({ ...state, [e.target.id]: e.target.value })
                  }
                  value={state.web}
                />
                {errorField.dentist?.web && (
                  <p className="text-danger fw-light">
                    {errorField.dentist?.web}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            className="col-4 px-5"
            style={{ marginLeft: 'auto', marginRight: 'auto' }}
          >
            <h5 className="fw-bold">Perfil</h5>
            <div className="row mb-4 mt-3">
              <div className="col-6 align-items-center">
                <input
                  type="checkbox"
                  name=""
                  id="study_group"
                  onChange={(e) =>
                    setState({
                      ...state,
                      study_group: e.target.checked,
                    })
                  }
                  className="mx-2 rounded"
                  style={{ visibility: 'visible' }}
                  checked={state.study_group}
                  disabled={path === 'perfil'}
                />
                <label htmlFor="study_group" className="fs-4">
                  <img
                    src={sglogo}
                    style={{
                      width: '60px',
                      height: 'auto',
                    }}
                    alt="Study Group"
                  />
                </label>
              </div>

              <div className="col-6 align-items-center">
                <input
                  type="checkbox"
                  name=""
                  id="sao"
                  onChange={(e) =>
                    setState({
                      ...state,
                      sao: e.target.checked,
                    })
                  }
                  className="mx-2 rounded"
                  checked={state.sao}
                  style={{ visibility: 'visible' }}
                  disabled={path === 'perfil'}
                />
                <label htmlFor="sao" className="fs-4">
                  <img
                    src={saologo}
                    style={{ width: '60px', height: 'auto' }}
                    alt="SAO"
                  />
                </label>
              </div>
            </div>
            <div className="row mb-4"></div>
            <div className={state.study_group ? 'row mb-4' : 'd-none'}>
              <div className="col-6 align-items-center">
                {state.path === 'perfil' && state.sg_type === 1 && (
                  <div>
                    <p className="text-primary">Speaker</p>
                    <p className="text-primary mt-2 mb-0">Nombre del grupo:</p>
                    <p className="text-primary fw-bold fs-5">{state.sg_team}</p>
                    <div className="mt-4">
                      <p className="text-primary mb-0">Nivel:</p>
                      <p className="text-primary fw-bold fs-5">
                        Revisar (Back)
                      </p>
                    </div>
                  </div>
                )}
                {state.path !== 'perfil' && (
                  <button
                    disabled={path === 'perfil'}
                    onClick={(e) => {
                      e.preventDefault()
                      delete state['sg_team']
                      setState({ ...state, sg_type: 1 })
                    }}
                    className={`fs-6 text-center w-100 border border-1 border-primary rounded-pill px-4 py-2 btn ${
                      state.sg_type === 1
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    }`}
                  >
                    Orador
                  </button>
                )}
              </div>
              <div className="col-6 align-items-center">
                {state.path === 'perfil' && state.sg_type === 2 && (
                  <button
                    disabled={path === 'perfil'}
                    onClick={(e) => {
                      e.preventDefault()
                      setState({
                        ...state,
                        sg_type: 2,
                        sg_team: groupData[0].id,
                      })
                    }}
                    className={
                      state.sg_type === 2
                        ? 'fs-6 text-center w-100 border border-1 border-primary rounded-pill px-4 py-2 btn btn-primary'
                        : 'fs-6 text-center w-100 border border-1 border-primary rounded-pill px-4 py-2 btn btn-outline-primary'
                    }
                  >
                    Participante
                  </button>
                )}
                {state.path !== 'perfil' && (
                  <button
                    disabled={path === 'perfil'}
                    onClick={(e) => {
                      e.preventDefault()
                      setState({
                        ...state,
                        sg_type: 2,
                        sg_team: groupData[0].id,
                      })
                    }}
                    className={`fs-6 text-center w-100 border border-1 border-primary rounded-pill px-4 py-2 btn ${
                      state.sg_type === 2
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    }`}
                  >
                    Participante
                  </button>
                )}
              </div>
            </div>
            {state.study_group && state.sg_type === 1 && (
              <div className="row mb-4 mt-2">
                <div className="col-12 d-flex flex-row">
                  <div className="mx-1">
                    <input
                      checked={state.sg_level === 1}
                      onClick={() =>
                        setState({
                          ...state,
                          sg_level: state.sg_type === 1 && 1,
                        })
                      }
                      type="radio"
                      name="principiante"
                      id="principiante"
                    />
                    <label htmlFor="principiante">Principiante</label>
                  </div>
                  <div className="mx-1">
                    <input
                      checked={state.sg_level === 2}
                      onClick={() =>
                        setState({
                          ...state,
                          sg_level: state.sg_type === 1 && 2,
                        })
                      }
                      type="radio"
                      name="avanzado"
                      id="avanzado"
                    />
                    <label htmlFor="avanzado">Avanzado</label>
                  </div>
                </div>
              </div>
            )}
            {state.study_group && state.sg_type === 2 && (
              <div className="row mb-4 mt-2">
                <div className="col-12 d-flex flex-row">
                  <div className="mx-1">
                    <input
                      checked={state.sg_level === 1}
                      disabled={path === 'perfil'}
                      onClick={() =>
                        setState({
                          ...state,
                          sg_level: state.sg_type === 2 && 1,
                        })
                      }
                      type="radio"
                      name="principiante"
                      id="principiante"
                    />
                    <label htmlFor="principiante">Principiante</label>
                  </div>
                  <div className="mx-1">
                    <input
                      checked={state.sg_level === 2}
                      disabled={path === 'perfil'}
                      onClick={() =>
                        setState({
                          ...state,
                          sg_level: state.sg_type === 2 && 2,
                        })
                      }
                      type="radio"
                      name="avanzado"
                      id="avanzado"
                    />
                    <label htmlFor="avanzado">Avanzado</label>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="sg_team">Participa en el grupo de:</label>
                  <select
                    onChange={(e) => {
                      console.log(e)
                      setState({ ...state, sg_team: e.target.value })
                    }}
                    className="form-control"
                    value={state.sg_team}
                  >
                    <option defaultValue="0" value="0">
                      Selecciona una opción
                    </option>
                    {groupData.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.dentist.user.full_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <div>
              {errorField.profile?.sg_type && (
                <p className="text-danger fw-light">
                  {errorField.profile?.sg_type}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <hr />
          <div className="col-12 d-flex flex-row justify-content-end">
            <button className="btn btn-primary" onClick={handleRequest}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
