import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import apiClient from "../../../utils/backend";
import Select from "react-select";

import SCIcon from "./smile_creator_icons";
import { connect } from "react-redux";

const AssignMoneyModal = ({ opened, className, handleModal, id }) => {
  const [odList, setOdList] = useState();
  const [od, setOd] = useState({ odontologo: null });
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState();

  useEffect(() => {
    apiClient
      .get("/api/helper/getDentists")
      .then((res) => {
        if (res.status === 200) {
          let formatted = [];

          for (var i in res.data)
            formatted.push({ value: Number(i), label: res.data[i] });

          setOdList(formatted);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleRegistry() {
    const newAmount = Number(od.total_amount) + Number(amount);
    apiClient
      .post("/api/currentAccounts/setMoney", {
        dentist_id: od.dentist_id,
        amount: newAmount,
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          console.log(res);
          handleModal();
        }
      })
      .catch((err) => {
        console.log(err);
        handleModal();
      });
  }

  function selectOd({ odontologo, dentist_id }) {
    setLoading(true);
    apiClient
      .get(`/api/helper/getDentistDetails/${dentist_id}`)
      .then((res) => {
        setOd({
          odontologo: res.data.full_name,
          dentist_id: res.data.id,
          total_amount: res.data.current_account_amount,
          sc_type: res.data.smile_creator_type,
        });
        setLoading(false);
      })
      .then((res) => console.log(res))
      .catch((err) => {
        console.log(err);
        handleModal();
      });
  }

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      borderBottom: "2px solid #0027a8!important",
    }),
  };

  return (
    <div>
      <Modal
        size="lg"
        isOpen={opened}
        toggle={handleModal}
        className={className}
      >
        <ModalBody>
          <div className="container">
            <h3 className="mb-3 mt-3 fw-bold text-primary">Asignar dinero</h3>
            <div className="row">
              <div className="col-6 d-flex flex-column px-3">
                <div>
                  <label
                    style={{ fontSize: ".8rem", paddingBottom: "5px" }}
                    htmlFor="od"
                  >
                    Odontologo:
                  </label>
                  <Select
                    styles={selectStyle}
                    options={odList}
                    isSearchable
                    placeholder="Seleccionar odontologo"
                    onChange={(e) => {
                      selectOd({
                        odontologo: e.label,
                        dentist_id: e.value,
                      });
                      console.log(odList);
                    }}
                  />
                </div>
                <div className="mt-4">
                  <label
                    style={{ fontSize: ".8rem", paddingBottom: "5px" }}
                    htmlFor="amount"
                  >
                    Monto:
                  </label>
                  <input
                    type="number"
                    name="amount"
                    id="amount"
                    className="form-control mx-1"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6 d-flex flex-column shadow p-2 rounded-3 bg-light">
                <strong className="text-primary mb-2">Resumen:</strong>
                <div className="d-flex flex-row justify-content-start">
                  <div className="d-flex flex-column mx-2">
                    <small className="mb-1">Odontologo:</small>
                    <h6 className="fw-bold text-primary">
                      {od.odontologo !== null ? od.odontologo : "Sin asignar"}
                    </h6>
                  </div>
                  <div className="d-flex flex-column">
                    <small className="mb-1">Categoria:</small>
                    {od.odontologo !== null ? (
                      <SCIcon type={od.sc_type} />
                    ) : (
                      <h6 className="fw-bold text-primary">Sin asignar</h6>
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-start mt-3">
                  <div className="d-flex flex-column mx-2">
                    <small className="mb-1">Dinero agregado:</small>
                    <h5 className="fw-bold text-primary">
                      {amount}
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: ".8rem",
                          paddingBottom: "20px",
                        }}
                      >
                        AR$
                      </span>
                    </h5>
                  </div>
                  <div className="d-flex flex-column">
                    <small className="mb-1">Monto total:</small>
                    {od.odontologo !== null ? (
                      <h5 className="fw-bold text-primary">
                        {Number(amount) >= 0
                          ? Number(od.total_amount) + Number(amount)
                          : od.total_amount}
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: ".8rem",
                            paddingBottom: "20px",
                          }}
                        >
                          AR$
                        </span>
                      </h5>
                    ) : (
                      <h5 className="fw-bold text-primary">
                        0
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: ".8rem",
                            paddingBottom: "20px",
                          }}
                        >
                          AR$
                        </span>
                      </h5>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {!loading && (
            <div>
              <button
                className="btn btn-outline-light text-dark mx-2"
                onClick={handleModal}
              >
                Volver
              </button>
              <button
                className="btn btn-success text-white"
                onClick={handleRegistry}
              >
                Guardar
              </button>
            </div>
          )}

          {loading && (
            <button class="btn btn-success" type="button" disabled>
              <span
                class="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(AssignMoneyModal);
