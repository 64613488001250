import React, { Component } from "react";
import apiClient from "../../../utils/backend";
import moment from 'moment'
import Select from 'react-select'
import { Check, X } from 'react-feather'

export default class PaymentsForm extends Component {

  constructor(props){
    super(props)

    this.state ={
      id: null,
      payment_date: null,
      payment_amount: null,
      bill: null,
      bill_date: null,
      bill_amount: null,
      payment_method: null,
      payment_done: null
    }

    this.handleSendData = this.handleSendData.bind(this)
  }

  componentDidMount(){
    console.log("propsssssssssssss")
    console.log(this.props)
    const {id, bill, bill_amount, bill_date, payment_amount, payment_date, payment_method, order, expected, expired_date, payment_done} = this.props.payment_data
    this.setState({
        id,
        payment_date,
        payment_amount:parseFloat(''+payment_amount).toFixed(2),
        bill,
        bill_date,
        bill_amount,
        payment_method: payment_method || 'Seleccione un método de pago',
        order,
        expected,
        expired_date,
        payment_done
    })
  }
  componentWillReceiveProps(nextProps){
    if(this.state.payment_done!==nextProps.payment_data.payment_done){
      console.log("componentWillReceiveProps->")
      this.setState({...this.state,
        payment_done:nextProps.payment_data.payment_done
      })
    }
  }

  handleInputChange({id, e}){
    let state = this.state
    state[id] = e.target.value
  }

  handleSendData(){
      console.log("mandando")
      console.log(this.props.paymentId)
    this.props.updateDetail({payment_id: this.props.paymentId, id: this.state.id, payment_amount: this.state.payment_amount, bill: this.state.bill, bill_amount: this.state.bill_amount, payment_method: this.state.payment_method, payment_date: this.state.payment_date, bill_date: this.state.bill_date, index: this.props.index, payment_done:true})
  }

  render() {

    const payment_types = [{label: 'Seleccione un método de pago', value: ''},{label: 'Paypal', value: 'Paypal'}, {label: 'MercadoPago', value: 'MercadoPago'}, {label: 'Transferencia bancaria', value: 'Transferencia bancaria'}, {label: 'Efectivo', value: 'Efectivo'}, ]
    const selectStyle = {
      control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        borderBottom: "2px solid #0027a8!important",
      }),
    };

    if(this.props.payment_data){
      
      return (
        <React.Fragment>
          <div className="col-4 px-3 mb-5">
          <div className="d-flex flex-row justify-content-between">
            <p
              className="border border-2 rounded-pill border-primary px-3 py-2 w-50"
              style={{ fontSize: "1rem", textAlign: "center" }}
            >
              {this.state.order}° Pago
            </p>
            <small style={{ fontSize: ".7rem", marginLeft: "5px" }}>
              Se espera el {this.state.expected}% del costo final.
            </small>
          </div>
          <form className="row mt-2">
            <div className="col-6">
              <small className="text-primary fs-6">Monto</small>
              <p className="fw-bold text-primary mt-2 fs-5">${this.state.payment_amount}</p>
            </div>
            <div className="col-6">
              <small className="text-primary fs-6">Fecha vencimiento</small>
              <p className="text-primary mt-2 fs-5">{moment(this.state.expired_date).format('DD/MM/YYYY')}</p>
            </div>
          </form>
          <form className="row mt-3">
            <div className="col-6">
              <label htmlFor="bill_amount">Monto Factura</label>
              <input type="number" name="bill_amount" id="bill_amount" className="form-control" value={this.state.bill_amount} onChange={e => this.setState({...this.state, bill_amount: e.target.value})} />
            </div>
            <div className="col-6">
              <label htmlFor="date">Fecha Factura</label>
              <input type="date" name="bill_date" id="bill_date" className="form-control" value={this.state.bill_date} onChange={e => this.setState({...this.state, bill_date: e.target.value})} />
            </div>
          </form>
          <form className="row mt-2">
            <div className="col-12">
              <label htmlFor="bill">N° Factura</label>
              <input type="text" name="bill" id="bill" className="form-control" value={this.state.bill} onChange={e => this.setState({...this.state,bill: e.target.value})} />
            </div>
          </form>
          <form className="row">
            <div className="col-12">
              <label htmlFor="payment_method">Metodo de pago</label>
              {/* <input type="text" name="payment_method" id="payment_method" className="form-control" value={this.state.payment_method} onChange={e => this.setState({payment_method: e.target.value})} /> */}
              <Select
              styles={selectStyle}
                        options={payment_types}
                        isSearchable
                        placeholder="Método de pago"
                        value={{label : this.state.payment_method}}
                        onChange={(e) => {
                          this.setState({...this.state,
                            payment_method: e.value
                          });
                        }}
               />
            </div>
          </form>
          {!this.state.payment_done &&
            // <button className="btn btn-primary mt-3" 
            // disabled={
            //   !this.state.payment_method
            //   || !this.state.bill 
            //   || !this.state.bill_date
            //   || !this.state.bill_amount
            // }
            // onClick={() => this.handleSendData()}>Enviar</button>
            <div className="w-100 d-flex justify-content-end">
            <button title="Enviar pago..." className="btn btn-light mt-3" 
            style={{marginRight:'10px', backgroundColor:'#d7d7d7'}}
            disabled={
              this.state.payment_method==='Seleccione un método de pago'
              || !this.state.payment_method
              || !this.state.bill 
              || !this.state.bill_date
              || !this.state.bill_amount
            }
            onClick={() => this.handleSendData()}><Check /></button>
            <div className="w-5"></div>
            <button title="Eliminar pago..." className="btn btn-light mt-3" 
            style={{marginRight:'10px', backgroundColor:'#d7d7d7'}}
            disabled={
              this.state.payment_method==='Seleccione un método de pago'
              || !this.state.payment_method
              || !this.state.bill 
              || !this.state.bill_date
              || !this.state.bill_amount
            }
            onClick={null}><X /></button>
            </div>
          }
        </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="col-3 px-3">
          <div className="d-flex flex-row justify-content-between">
            <p
              className="border border-2 rounded-pill border-primary px-3 py-2 w-50"
              style={{ fontSize: "1rem", textAlign: "center" }}
            >
              1er Pago
            </p>
            <small style={{ fontSize: ".7rem", marginLeft: "5px" }}>
              Se espera el 50% del costo final.
            </small>
          </div>
          <form className="row mt-3">
            <div className="col-6">
              <label htmlFor="date">Fecha 1er pago</label>
              <input type="date" name="date" id="date" className="form-control" value={this.state.date} onChange={e => this.setState({...this.state, date: e.target.value})} />
            </div>
            <div className="col-6">
              <label htmlFor="amount">Monto</label>
              <input type="number" name="amount" id="amount" className="form-control" value={this.state.amount} onChange={e => this.setState({...this.state, amount: e.target.value})} />
            </div>
          </form>
          <form className="row">
            <div className="col-6">
              <label htmlFor="bill_amount">Monto Factura</label>
              <input type="number" name="bill_amount" id="bill_amount" className="form-control" value={this.state.bill_amount} onChange={e => this.setState({...this.state, bill_amount: e.target.value})} />
            </div>
            <div className="col-6">
              <label htmlFor="date">Fecha Factura</label>
              <input type="date" name="bill_date" id="bill_date" className="form-control" 
              value={this.state.bill_date} onChange={e => this.setState({...this.state, bill_date: e.target.value})} />
            </div>
          </form>
          <form className="row">
            <div className="col-12">
              <label htmlFor="bill">N° Factura</label>
              <input type="text" name="bill" id="bill" className="form-control" value={this.state.bill} onChange={e => this.setState({...this.state, bill: e.target.value})} />
            </div>
          </form>
          <form className="row">
            <div className="col-12">
              <label htmlFor="payment_method">Metodo de pago</label>
              {/* <input type="text" name="payment_method" id="payment_method" className="form-control" value={this.state.payment_method} onChange={e => this.setState({payment_method: e.target.value})} /> */}
              <Select
              styles={selectStyle}
                        options={payment_types}
                        isSearchable
                        placeholder="Método de pago"
                        value={{label : this.state.payment_method}}
                        onChange={(e) => {
                          this.setState({...this.state,
                            payment_method: e.label
                          });
                        }}
               />
            </div>
          </form>
          {!this.state.payment_done &&
            <div className="w-100 d-flex justify-content-around">
            <button title="Enviar pago..." className="btn btn-primary mt-3" 
            disabled={
              this.state.payment_method==='Seleccione un método de pago'
              || !this.state.payment_method
              || !this.state.bill 
              || !this.state.bill_date
              || !this.state.bill_amount
            }
            onClick={() => this.handleSendData()}><Check /></button>
            <button title="Eliminar pago..." className="btn btn-danger mt-3" 
            disabled={
              this.state.payment_method==='Seleccione un método de pago'
              || !this.state.payment_method
              || !this.state.bill 
              || !this.state.bill_date
              || !this.state.bill_amount
            }
            onClick={null}><X /></button>
            </div>
          }
        </div>
        </React.Fragment>
      );
    }
  }
}
