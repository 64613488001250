import React, { useEffect, useState } from "react";
import { Layout, Menu } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Searchbar from "../../components/searchbar";
import {
  getProfilesPositions,
  startGetUserList,
} from "../../redux/actions/users";
import NewUserModal from "./components/new_user_modal";
import UserCard from "./components/user_card_item";
import UserList from "./components/user_list_item";

const UserPage = () => {
  const [state, setState] = useState({
    cards_type: "list",
    step: 0,
  });
  const {countryId} = useSelector(state => state.userReducer)
  useEffect(() => {
    dispatch(startGetUserList());
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProfilesPositions());
  }, [])
  const { loading, usersList } = useSelector((state) => state.usersReducer);
  /*
  COD ---- PAIS
    1: ARG,
    2: URU,
    3: PAR,
    4: COL,
    5: CHI,
    6: PER,
  */
  return (
    <>
      {loading ? (
        <h2>Cargando...</h2>
        ) : (
          <div className="container-fluid px-5 position-relative">
        

          <div className="row pt-4" style={{ zIndex: 2 }}>
            <div className="col-9">
              <h1 className="text-primary fw-bold">Usuarios</h1>
            </div>
            <div className="col-3 d-flex flex-column px-4">
              <NewUserModal countryId={countryId} />
              <Searchbar />
            </div>
          </div>
          <div className="row mt-3 align-items-between mb-3">
            <div className="col-8 d-flex flex-row">
              <ul
                className="d-flex flex-row justify-content-start align-items-center p-0 mb-0"
                style={{ listStyleType: "none" }}
                >
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      state.step === 0 && "disabled"
                    }`}
                    href="#"
                    onClick={() => setState({ ...state, step: 0 })}
                    >
                    Todos
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      state.step === 1 && "disabled"
                    }`}
                    href="#"
                    onClick={() => setState({ ...state, step: 1 })}
                    >
                    Argentina
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      state.step === 2 && "disabled"
                    }`}
                    href="#"
                    onClick={() => setState({ ...state, step: 2 })}
                    >
                    Uruguay
                  </a>
                </li>
                <li style={{ marginLeft: "auto" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      state.step === 3 && "disabled"
                    }`}
                    href="#"
                    onClick={() => setState({ ...state, step: 3 })}
                    >
                    Paraguay
                  </a>
                </li>
                <li style={{ marginLeft: "auto" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      state.step === 4 && "disabled"
                    }`}
                    href="#"
                    onClick={() => setState({ ...state, step: 4 })}
                    >
                    Colombia
                  </a>
                </li>
                <li style={{ marginLeft: "auto" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      state.step === 5 && "disabled"
                    }`}
                    href="#"
                    onClick={() => setState({ ...state, step: 5 })}
                  >
                    Chile
                  </a>
                </li>
                <li style={{ marginLeft: "auto" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      state.step === 6 && "disabled"
                    }`}
                    href="#"
                    onClick={() => setState({ ...state, step: 6 })}
                  >
                    Peru
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-3 ms-5 px-3 d-flex flex-row justify-content-end">
              <button
                className="btn bg-none p-1"
                onClick={() => setState({ cards_type: "cards", step: 0 })}
              >
                <Layout size={24} color="#00155C" />
              </button>
              <button
                className="btn bg-none p-1"
                onClick={() => setState({ cards_type: "list", step: 0 })}
              >
                <Menu size={24} color="#00155C" />
              </button>
            </div>
            <hr className="mt-2" />
          </div>
          {state.cards_type === "cards" && (
            <div className="row mt-3 px-4">
              {state.step > 0 &&
                usersList
                  .filter((item) => state.step === item.country_id)
                  .map((user) => {
                    return (
                      <UserCard
                        id={user.id}
                        name={user.name}
                        surname={user.surname}
                        sector={user.profile.title}
                        position={user.position.title}
                        key={user.id}
                      />
                    );
                  })}

              {state.step === 0 &&
                usersList.map((user) => {
                  return (
                    <UserCard
                      id={user.id}
                      name={user.name}
                      surname={user.surname}
                      sector={user.profile.title}
                      position={user.position.title}
                      key={user.id}
                    />
                  );
                })}
            </div>
          )}

          {state.cards_type === "list" && (
            <div className="row mt-3 px-4">
              <div className="col-2 px-3">
                <p className="text-primary text-center fw-bold m-0 mt-2">
                  Usuario
                </p>
              </div>
              <div className="col-3 px-3">
                <p className="text-primary text-center fw-bold m-0 mt-2">
                  Email
                </p>
              </div>
              <div className="col-2 px-3">
                <p className="text-primary text-center fw-bold m-0 mt-2">
                  Sector
                </p>
              </div>
              <div className="col-1 px-3">
                <p className="text-primary text-center fw-bold m-0 mt-2">
                  Puesto
                </p>
              </div>
              <div className="col-2 px-3 text-center">
                <p className="text-primary text-center fw-bold m-0 mt-2">
                  Pais
                </p>
              </div>
              {state.step > 0 &&
                usersList
                  .filter((item) => state.step === item.country_id)
                  .map((user) => {
                    return (
                      <UserList
                        id={user.id}
                        name={user.name}
                        email={user.email}
                        surname={user.surname}
                        sector={user.profile}
                        position={user.position}
                        country={user.country_id}
                        key={user.id}
                      />
                    );
                  })}

              {state.step === 0 &&
                usersList.map((user) => {
                  return (
                    <UserList
                      id={user.id}
                      avatar={user.avatar}
                      email={user.email}
                      name={user.name}
                      surname={user.surname}
                      sector={user.profile}
                      position={user.position}
                      country={user.country_id}
                      key={user.id}
                    />
                  );
                })}
            </div>
          )}
          
        </div>
      )}
    </>
  );
};

export default UserPage;
