import React, { useState } from 'react'
import ConfirmationModal from '../../../components/confirmation_modal'
import EditTreatment from './edit_treatment'

import './index.css'

export const Card = ({ data, i, handleModal }) => {
  const [opened, setOpened] = useState(false)
  function handleTreatmentColor() {}

  let principalDentist;
    if(data.principal_dentist === null){
      principalDentist = data.dentist[0]
    }else{
      principalDentist = data.dentists.find(
        (dentist) => dentist.id === data.principal_dentist
      )
    }
  
  return (
    <div className={(opened && 'opened') + ' treatments_card_container'}>
      <div
        key={`${i}`}
        className="grow_hover w-100 row d-flex flex-row bg-white shadow rounded m-0 py-2 my-2 align-item-middle tc-container"
        style={{ position: 'relative' }}
      >
        <button
          className={`clinic-history-button type-${handleTreatmentColor(
            data.type
          )}`}
          onClick={() => handleModal(data.id)}
        />
        <div className="col-1 text-center" style={{ maxWidth: '75px' }}>
          <p
            style={{
              fontSize: '.8rem',
              lineHeight: '25px',
              paddingLeft: '20px',
            }}
            className="text-primary fw-bold m-0 d-flex flex-row"
          >
            {data.id}
          </p>
        </div>
        <div className="col-2 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 d-flex flex-row "
          >
            <div
              style={{
                minWidth: '25px',
                minHeight: '25px',
                border: '1px solid #ccc',
                borderRadius: '50%',
                marginRight: '10px',
              }}
            ></div>
            <p title={data.patient.full_name} className="text-truncate p-0 m-0">
              {data.patient.full_name}
            </p>
          </p>
        </div>

        <div className="col-2 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 text-center text-truncate"
            title={principalDentist?.user?.name}
          >
            {principalDentist?.user?.name}
          </p>
        </div>
        <div className="col-1 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 text-center text-truncate"
          >
            {data.patient.user?.groups?.length === 0
              ? 'No hay perfil'
              : data.patient.user?.groups?.map((group) =>
                  group.id === 1
                    ? 'SAO'
                    : group.id === 2
                    ? 'Study Group'
                    : 'SAO y Study Group'
                )}
          </p>
        </div>
        <div className="col-1 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 text-center"
            title={data.treatment}
          >
            {data.treatment}
          </p>
        </div>
        <div className="col-1 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 text-center"
          >
            {data.stage}
          </p>
        </div>
        <div className="col-1 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 text-center"
          >
            {data.maxilla}
          </p>
        </div>
        <div className="col-1 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 text-center"
          >
            {data.movements}
          </p>
        </div>
        <div className="col-1 text-center">
          <p
            style={{ fontSize: '.8rem', lineHeight: '25px' }}
            className="text-primary fw-bold m-0 text-center text-truncate"
            title={data.state}
          >
            {data.state}
          </p>
        </div>
        <div className="col-1 p-0 text-center">
          <span className="text-primary fw-bold m-0 p-0 text-center">
            <span
              style={{ fontSize: '.8rem', lineHeight: '25px' }}
              className="trash"
            >
              {<ConfirmationModal type="treatment-delete" data={data} principalDentist={principalDentist} />}
            </span>
          </span>
          {
            <span className="text-primary fw-bold m-0 p-0 text-center">
              <span
                style={{ fontSize: '.8rem', lineHeight: '25px' }}
                className="edit ms-1"
              >
                {<EditTreatment data={data} principalDentist={principalDentist} />}
              </span>
            </span>
          }
        </div>
      </div>
    </div>
  )
}
