import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import returnStoreAndPersistor from './redux/store'
import 'lightgallery.js/dist/css/lightgallery.css'
import { LightgalleryProvider } from 'react-lightgallery'

import './assets/css/keepdesigning.min.css'

import Navbar from './components/navbar'
import Sidebar from './components/sidebar'

// import Index from './pages/Dashboard'
import NotFound from './pages/NotFound'
import NewLogin from './pages/Login/NewLogin'
import Ingresos from './pages/Ingresos'
import Viabilidad from './pages/Viabilidad'
import CargarTratamiento from './pages/CargarTratamiento'
import CargarViabilidad from './pages/CargarViabilidad'
import CargarConsulta from './pages/CargarConsulta'
import Odontologos from './pages/Odontologos'
/* import CargarOdontologo from './pages/Odontologos/Nuevo' */
import Aprobacion from './pages/Aprobacion'
import Escaneo from './pages/Escaneo'
import Registro from './pages/Registro'
import Procesos from './pages/Test'
import Impresion from './pages/Impresion'
import PlacasRepeticion from './pages/PlacasRepeticion'
import CargarPlacasRepeticion from './pages/PlacasRepeticion/Nuevo'
import Pacientes from './pages/Pacientes'
import CargarPaciente from './pages/Pacientes/Nuevo'
import Pagos from './pages/Pagos'
import ListadoPrecios from './pages/Pagos/Listado'
import DatosBancarios from './pages/Pagos/DatosBancarios'
import PagosOd from './pages/PagosOd'
import PlacasRepeticionOd from './pages/PlacasRepeticionOd'

// OD PAGES
import Home from './pages/Home'
import Notificaciones from './pages/Notificaciones'
import Solicitud from './pages/Solicitud'
import Solicitudes from './pages/Solicitudes'
import CargarTratamientoOdontologo from './pages/Solicitud/Tratamiento'
import CargarEtapaOdontologo from './pages/Solicitud/Etapa'
import CargarViabilidadOdontologo from './pages/Solicitud/Viabilidad'

//HELP PAGES
import Ayuda from './pages/Ayuda'
import Casos from './pages/CentroAyuda/PresentaCaso'
import CasosHow from './pages/CentroAyuda/PresentaCaso/ComoFunciona'
import CargarCaso from './pages/CentroAyuda/PresentaCaso/EnviarCaso'
import CentroDeAyuda from './pages/CentroAyuda/dashboard'
import AdminCentroDeAyuda from './pages/CentroAyuda/Admin'
import PacientesOd from './pages/PacientesOd'
import GaleriaCasos from './pages/CentroAyuda/GaleriaCasos'
import DetalleCaso from './pages/CentroAyuda/GaleriaCasos/DetalleCaso'
import SmileCreator from './pages/SmileCreator'
import SmileCreatorPuntos from './pages/SmileCreator/DetallePuntos'
import HistoriaClinica from './pages/HistoriaClinica'
import Documentacion from './pages/HistoriaClinica/Documentacion'
import DocumentacionIncompleta from './pages/DocumentacionIncompleta'
import Reclamos from './pages/Reclamos'
import Consultas from './pages/Consultas'
import SolicitudTratamiento from './pages/FormularioOnline'
import SolicitudTratamientoAdmin from './pages/FormularioOnline/admin'
import Comunicaciones from './pages/Comunicaciones'
import HomeAdmin from './pages/HomeAdmin'
import UsuariosAdmin from './pages/Usuarios/UserPage'
import { PatientTreatment } from './pages/Pacientes/Tratamientos/PatientTreatment'
import { Treatment } from './pages/Pacientes/Tratamientos/Treatment'
import { NewPassword } from './pages/Login/NewPassword'
import { ResetPassword } from './pages/Login/ResetPassword'
import { NewTreatment } from './pages/Tratamientos/NewTreatment'
import { NewDentist } from './pages/Odontologos/Nuevo/NewNuevoOdontologo'
import Login from './pages/Login'
import { PasswordUpdate } from './pages/Login/PasswordUpdate'
import { ToastContainer } from 'react-toastify'
import { Toaster } from 'react-hot-toast'
import LoadTreatmentAdmin from './pages/Solicitud/Admin'

const PrivateRoute = ({ ...props }) => {
  const logged_in = localStorage.getItem('lgt')
  if (logged_in !== null && logged_in !== undefined && logged_in !== '') {
    return <Route {...props} />
  } else {
    return <Redirect to="/login" />
  }
}

function App() {
  const { store, persistor } = returnStoreAndPersistor()
  return (
    <LightgalleryProvider galleryClassName="my_custom_classname">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <div style={{ height: '100%', overflowX: 'hidden' }}>
              {window.location.href.toUpperCase().search('LOGIN') > -1 ||
              window.location.href.toUpperCase().search('PASSWORDRESET') > -1 ||
              window.location.href.toUpperCase().search('CHANGEPASSWORD') >
                -1 ? null : (
                <Sidebar />
              )}
              <div
                id={
                  window.location.href.toUpperCase().search('LOGIN') > -1 ||
                  window.location.href.toUpperCase().search('PASSWORDRESET') >
                    -1 ||
                  window.location.href.toUpperCase().search('CHANGEPASSWORD') >
                    -1
                    ? 'content-full'
                    : 'content'
                }
                className={
                  window.location.href.toUpperCase().search('LOGIN') > -1 ||
                  window.location.href.toUpperCase().search('PASSWORDRESET') >
                    -1 ||
                  window.location.href.toUpperCase().search('CHANGEPASSWORD') >
                    -1
                    ? 'container-fluid'
                    : 'container'
                }
              >
                {window.location.href.toUpperCase().search('LOGIN') > -1 ||
                window.location.href.toUpperCase().search('PASSWORDRESET') >
                  -1 ||
                window.location.href.toUpperCase().search('CHANGEPASSWORD') >
                  -1 ? null : (
                  <Navbar />
                )}
                {/* <ToastContainer
                  limit={1}
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                /> */}
                <Toaster position="top-right" reverseOrder={false} />
                <Switch>
                  <Route exact path="/login" component={NewLogin} />
                  <Route
                    exact
                    path="/passwordReset/:token"
                    component={NewPassword}
                  />
                  <Route
                    exact
                    path="/changePassword"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/passwordUpdate/:token"
                    component={NewLogin}
                  />

                  <PrivateRoute exact path="/centrodeayuda" component={Ayuda} />
                  <PrivateRoute
                    exact
                    path="/centrodeayuda/caso"
                    component={Casos}
                  />
                  <PrivateRoute
                    exact
                    path="/centrodeayuda/caso/comofunciona"
                    component={CasosHow}
                  />
                  <PrivateRoute
                    exact
                    path="/centrodeayuda/caso/cargar"
                    component={CargarCaso}
                  />
                  <PrivateRoute
                    exact
                    path="/centrodeayuda/galeriacasos/"
                    component={GaleriaCasos}
                  />
                  <PrivateRoute
                    exact
                    path="/centrodeayuda/galeriacasos/:id"
                    component={DetalleCaso}
                  />
                  <PrivateRoute
                    exact
                    path="/centrodeayuda/admin"
                    component={AdminCentroDeAyuda}
                  />
                  <PrivateRoute
                    exact
                    path="/centrodeayuda"
                    component={CentroDeAyuda}
                  />

                  <PrivateRoute exact path="/ingresos/" component={Ingresos} />
                  <PrivateRoute
                    exact
                    path="/ingresos/nuevo"
                    component={LoadTreatmentAdmin}
                  />
                  <PrivateRoute
                    exact
                    path="/tratamientos"
                    component={NewTreatment}
                  />

                  <PrivateRoute
                    exact
                    path="/viabilidad/"
                    component={Viabilidad}
                  />
                  <PrivateRoute
                    exact
                    path="/viabilidad/nuevo"
                    component={CargarViabilidad}
                  />

                  <PrivateRoute
                    exact
                    path="/odontologos"
                    component={Odontologos}
                  />
                  <PrivateRoute
                    exact
                    path="/odontologos/nuevo"
                    component={NewDentist}
                  />
                  <PrivateRoute
                    exact
                    path="/odontologos/:id"
                    component={NewDentist}
                  />

                  <PrivateRoute exact path="/pacientes" component={Pacientes} />

                  <PrivateRoute
                    exact
                    path="/pacientes/nuevo"
                    component={CargarPaciente}
                  />

                  <PrivateRoute exact path="/od/home" component={Home} />
                  <PrivateRoute
                    exact
                    path="/od/notificaciones"
                    component={Notificaciones}
                  />
                  <PrivateRoute
                    exact
                    path="/od/perfil"
                    component={NewDentist}
                  />
                  <PrivateRoute
                    exact
                    path="/od/pacientes"
                    component={PacientesOd}
                  />
                  <PrivateRoute
                    exact
                    path="/od/solicitud"
                    component={Solicitud}
                  />
                  <PrivateRoute
                    exact
                    path="/od/solicitudes"
                    component={Solicitudes}
                  />
                  <PrivateRoute
                    exact
                    path="/od/documentacion/cargar/:id"
                    component={DocumentacionIncompleta}
                  />
                  <PrivateRoute
                    exact
                    path="/od/ingresos/nuevo"
                    component={CargarTratamientoOdontologo}
                  />
                  <PrivateRoute
                    exact
                    path="/od/etapa/nuevo"
                    component={CargarEtapaOdontologo}
                  />
                  <PrivateRoute
                    exact
                    path="/od/viabilidad/nuevo"
                    component={CargarViabilidadOdontologo}
                  />
                  <PrivateRoute
                    exact
                    path="/od/pacientes/:id/historiaclinica"
                    component={HistoriaClinica}
                  />
                  <PrivateRoute
                    exact
                    path="/od/pacientes/:id/historiaclinica/:id/documentacion"
                    component={Documentacion}
                  />
                  <PrivateRoute
                    exact
                    path="/mi-tratamiento/"
                    component={PatientTreatment}
                  />
                  <PrivateRoute
                    exact
                    path="/mi-tratamiento/:id"
                    component={Treatment}
                  />
                  <PrivateRoute
                    exact
                    path="/od/pagos/estado"
                    component={PagosOd}
                  />
                  <PrivateRoute
                    exact
                    path="/od/smilecreator"
                    component={SmileCreator}
                  />
                  <PrivateRoute
                    exact
                    path="/od/smilecreator/detalle"
                    component={SmileCreatorPuntos}
                  />
                  <PrivateRoute
                    exact
                    path="/od/placasRepeticion/nuevo"
                    component={PlacasRepeticionOd}
                  />
                  <PrivateRoute
                    exact
                    path="/od/pagos/listado"
                    component={ListadoPrecios}
                  />
                  <PrivateRoute
                    exact
                    path="/od/pagos/datos"
                    component={DatosBancarios}
                  />
                  <PrivateRoute
                    exact
                    path="/od/consultas"
                    component={Consultas}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/consultas"
                    component={Consultas}
                  />
                  <PrivateRoute
                    exact
                    path="/ayuda/consultas"
                    component={CargarConsulta}
                  />

                  <PrivateRoute exact path="/reclamos" component={Reclamos} />
                  <PrivateRoute exact path="/ayuda" component={Ayuda} />

                  <PrivateRoute
                    exact
                    path="/admin/home"
                    component={HomeAdmin}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/usuarios"
                    component={UsuariosAdmin}
                  />
                  <PrivateRoute
                    exact
                    path="/procesos/impresion"
                    component={Impresion}
                  />
                  <PrivateRoute
                    exact
                    path="/procesos/placasRepeticion"
                    component={PlacasRepeticion}
                  />
                  <PrivateRoute
                    exact
                    path="/procesos/placasRepeticion/nuevo"
                    component={CargarPlacasRepeticion}
                  />
                  <PrivateRoute exact path="/procesos" component={Aprobacion} />
                  <PrivateRoute exact path="/escaneo" component={Escaneo} />
                  <PrivateRoute exact path="/pagos" component={Pagos} />
                  <PrivateRoute
                    exact
                    path="/comunicaciones"
                    component={Comunicaciones}
                  />

                  <PrivateRoute exact path="/registro" component={Registro} />

                  <PrivateRoute
                    exact
                    path="/procesos/:id"
                    component={Procesos}
                  />
                  <PrivateRoute
                    exact
                    path="/tratamiento/:id"
                    component={SolicitudTratamiento}
                  />
                  <PrivateRoute
                    exact
                    path="/tratamiento-view"
                    component={SolicitudTratamientoAdmin}
                  />

                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    </LightgalleryProvider>
  )
}

export default App
