import React from 'react'
import { connect } from 'react-redux'
function ToothsSelector(props){
    const { updateTooths, tooths_state, step, field, enabled }= props;

    const updateState=(payload)=>{
        updateTooths(step, field, payload)
    }
    const getValueChecked=(payload)=>{
        return tooths_state[payload.key][payload.index]
    }
    return (
        <div className="w-100 d-flex justify-content-around">
            <div className="col-5 d-flex flex-column">
                <div className="d-flex justify-content-center">
                    <small>
                        Odontograma Permanentes
                    </small>
                </div>
                <div className="checkbox-container d-flex">
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/18_Mesa de trabajo.png" alt="18" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:0})} onChange={(e)=>updateState({index:0,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})} type="checkbox" name="SecondcheckboxInputOverrideUp1" id="SecondcheckboxInputOverrideUp1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp1">18</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/17_Mesa de trabajo 1.png" alt="17" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:1})} onChange={(e)=>updateState({index:1,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp2" id="SecondcheckboxInputOverrideUp2" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp2">17</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/16_Mesa de trabajo 1.png" alt="16" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:2})} onChange={(e)=>updateState({index:2,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp3" id="SecondcheckboxInputOverrideUp3" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp3">16</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/15_Mesa de trabajo 1.png" alt="15" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:3})} onChange={(e)=>updateState({index:3,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp4" id="SecondcheckboxInputOverrideUp4" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp4">15</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/14_Mesa de trabajo 1.png" alt="14" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:4})} onChange={(e)=>updateState({index:4,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp5" id="SecondcheckboxInputOverrideUp5" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp5">14</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/13_Mesa de trabajo 1.png" alt="13" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:5})} onChange={(e)=>updateState({index:5,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp6" id="SecondcheckboxInputOverrideUp6" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp6">13</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/12_Mesa de trabajo 1.png" alt="12" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:6})} onChange={(e)=>updateState({index:6,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp7" id="SecondcheckboxInputOverrideUp7" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp7">12</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/11_Mesa de trabajo 1.png" alt="11" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:7})} onChange={(e)=>updateState({index:7,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp8" id="SecondcheckboxInputOverrideUp8" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp8">11</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/21_Mesa de trabajo 1.png" alt="21" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:8})} onChange={(e)=>updateState({index:8,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp9" id="SecondcheckboxInputOverrideUp9" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp9">21</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/22_Mesa de trabajo 1.png" alt="22" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:9})} onChange={(e)=>updateState({index:9,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp10" id="SecondcheckboxInputOverrideUp10" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp10">22</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/23_Mesa de trabajo 1.png" alt="23" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:10})} onChange={(e)=>updateState({index:10,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp11" id="SecondcheckboxInputOverrideUp11" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp11">23</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/24_Mesa de trabajo 1.png" alt="24" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:11})} onChange={(e)=>updateState({index:11,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp12" id="SecondcheckboxInputOverrideUp12" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp12">24</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/25_Mesa de trabajo 1.png" alt="25" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:12})} onChange={(e)=>updateState({index:12,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp13" id="SecondcheckboxInputOverrideUp13" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp13">25</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/26_Mesa de trabajo 1.png" alt="26" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:13})} onChange={(e)=>updateState({index:13,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp14" id="SecondcheckboxInputOverrideUp14" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp14">26</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/27_Mesa de trabajo 1.png" alt="27" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:14})} onChange={(e)=>updateState({index:14,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp15" id="SecondcheckboxInputOverrideUp15" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp15">27</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/28_Mesa de trabajo 1.png" alt="28" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsUp',index:15})} onChange={(e)=>updateState({index:15,key:'permanentOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp16" id="SecondcheckboxInputOverrideUp16" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp16">28</label>
                    </div>
                </div>
                <div className="checkbox-container d-flex">
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/48_Mesa de trabajo 1.png" alt="48" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:0})} onChange={(e)=>updateState({index:0,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride1" id="SecondcheckboxInputOverride1" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride1">48</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/47_Mesa de trabajo 1.png" alt="47" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:1})} onChange={(e)=>updateState({index:1,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride2" id="SecondcheckboxInputOverride2" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride2">47</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/46_Mesa de trabajo 1.png" alt="56" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:2})} onChange={(e)=>updateState({index:2,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride3" id="SecondcheckboxInputOverride3" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride3">46</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/45_Mesa de trabajo 1.png" alt="45" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:3})} onChange={(e)=>updateState({index:3,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride4" id="SecondcheckboxInputOverride4" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride4">45</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/44_Mesa de trabajo 1.png" alt="44" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:4})} onChange={(e)=>updateState({index:4,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride5" id="SecondcheckboxInputOverride5" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride5">44</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/43_Mesa de trabajo 1.png" alt="43" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:5})} onChange={(e)=>updateState({index:5,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride6" id="SecondcheckboxInputOverride6" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride6">43</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/42_Mesa de trabajo 1.png" alt="42" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:6})} onChange={(e)=>updateState({index:6,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride7" id="SecondcheckboxInputOverride7" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride7">42</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/41_Mesa de trabajo 1.png" alt="41" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:7})} onChange={(e)=>updateState({index:7,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride8" id="SecondcheckboxInputOverride8" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride8">41</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/31_Mesa de trabajo 1.png" alt="31" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:8})} onChange={(e)=>updateState({index:8,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride9" id="SecondcheckboxInputOverride9" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride9">31</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/32_Mesa de trabajo 1.png" alt="32" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:9})} onChange={(e)=>updateState({index:9,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride10" id="SecondcheckboxInputOverride10" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride10">32</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/33_Mesa de trabajo 1.png" alt="33" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:10})} onChange={(e)=>updateState({index:10,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride11" id="SecondcheckboxInputOverride11" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride11">33</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/34_Mesa de trabajo 1.png" alt="34" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:11})} onChange={(e)=>updateState({index:11,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride12" id="SecondcheckboxInputOverride12" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride12">34</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/35_Mesa de trabajo 1.png" alt="35" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:12})} onChange={(e)=>updateState({index:12,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride13" id="SecondcheckboxInputOverride13" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride13">35</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/36_Mesa de trabajo 1.png" alt="36" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:13})} onChange={(e)=>updateState({index:13,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride14" id="SecondcheckboxInputOverride14" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride14">36</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/37_Mesa de trabajo 1.png" alt="37" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:14})} onChange={(e)=>updateState({index:14,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride15" id="SecondcheckboxInputOverride15" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride15">37</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/38_Mesa de trabajo 1.png" alt="38" />
                        <input disabled={!enabled} checked={getValueChecked({key:'permanentOdontogramsDown',index:15})} onChange={(e)=>updateState({index:15,key:'permanentOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride16" id="SecondcheckboxInputOverride16" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride16">38</label>
                    </div>
                </div>
            </div>
            <div className="col-4 d-flex flex-column">
                <div className="d-flex justify-content-center">
                    <small>
                        Odontograma Temporales
                    </small>
                </div>
                <div className="checkbox-container d-flex justify-content-center">
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/17_Mesa de trabajo 1.png" alt="17" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:0})} onChange={(e)=>updateState({index:0,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp1Kids" id="SecondcheckboxInputOverrideUp1Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp1Kids">55</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/16_Mesa de trabajo 1.png" alt="16" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:1})} onChange={(e)=>updateState({index:1,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp2Kids" id="SecondcheckboxInputOverrideUp2Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp2Kids">54</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/13_Mesa de trabajo 1.png" alt="13" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:2})} onChange={(e)=>updateState({index:2,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp3Kids" id="SecondcheckboxInputOverrideUp3Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp3Kids">53</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/12_Mesa de trabajo 1.png" alt="12" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:3})} onChange={(e)=>updateState({index:3,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp4Kids" id="SecondcheckboxInputOverrideUp4Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp4Kids">52</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/11_Mesa de trabajo 1.png" alt="11" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:4})} onChange={(e)=>updateState({index:4,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp5Kids" id="SecondcheckboxInputOverrideUp5Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp5Kids">51</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/21_Mesa de trabajo 1.png" alt="21" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:5})} onChange={(e)=>updateState({index:5,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp6Kids" id="SecondcheckboxInputOverrideUp6Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp6Kids">61</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/22_Mesa de trabajo 1.png" alt="22" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:6})} onChange={(e)=>updateState({index:6,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp7Kids" id="SecondcheckboxInputOverrideUp7Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp7Kids">62</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/23_Mesa de trabajo 1.png" alt="23" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:7})} onChange={(e)=>updateState({index:7,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp8Kids" id="SecondcheckboxInputOverrideUp8Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp8Kids">63</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/26_Mesa de trabajo 1.png" alt="26" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:8})} onChange={(e)=>updateState({index:8,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp9Kids" id="SecondcheckboxInputOverrideUp9Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp9Kids">64</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/27_Mesa de trabajo 1.png" alt="27" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsUp',index:9})} onChange={(e)=>updateState({index:9,key:'temporalOdontogramsUp',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverrideUp10Kids" id="SecondcheckboxInputOverrideUp10Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverrideUp10Kids">65</label>
                    </div>
                </div>
                <div className="checkbox-container d-flex justify-content-center">
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/47_Mesa de trabajo 1.png" alt="47" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:0})} onChange={(e)=>updateState({index:0,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride1Kids" id="SecondcheckboxInputOverride1Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride1Kids">85</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/46_Mesa de trabajo 1.png" alt="46" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:1})} onChange={(e)=>updateState({index:1,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride2Kids" id="SecondcheckboxInputOverride2Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride2Kids">84</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/43_Mesa de trabajo 1.png" alt="43" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:2})} onChange={(e)=>updateState({index:2,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride3Kids" id="SecondcheckboxInputOverride3Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride3Kids">83</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/42_Mesa de trabajo 1.png" alt="42" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:3})} onChange={(e)=>updateState({index:3,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride4Kids" id="SecondcheckboxInputOverride4Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride4Kids">82</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/41_Mesa de trabajo 1.png" alt="41" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:4})} onChange={(e)=>updateState({index:4,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride5Kids" id="SecondcheckboxInputOverride5Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride5Kids">81</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/31_Mesa de trabajo 1.png" alt="31" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:5})} onChange={(e)=>updateState({index:5,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride6Kids" id="SecondcheckboxInputOverride6Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride6Kids">71</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/32_Mesa de trabajo 1.png" alt="32" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:6})} onChange={(e)=>updateState({index:6,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride7Kids" id="SecondcheckboxInputOverride7Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride7Kids">72</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/33_Mesa de trabajo 1.png" alt="33" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:7})} onChange={(e)=>updateState({index:7,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride8Kids" id="SecondcheckboxInputOverride8Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride8Kids">73</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/36_Mesa de trabajo 1.png" alt="36" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:8})} onChange={(e)=>updateState({index:8,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride9Kids" id="SecondcheckboxInputOverride9Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride9Kids">74</label>
                    </div>
                    <div className="checkboxOverride">
                        <img className="img-dientes" src="/images/dientes/37_Mesa de trabajo 1.png" alt="37" />
                        <input disabled={!enabled} checked={getValueChecked({key:'temporalOdontogramsDown',index:9})} onChange={(e)=>updateState({index:9,key:'temporalOdontogramsDown',value:(e.target.checked?1:0)})}  type="checkbox" name="SecondcheckboxInputOverride10Kids" id="SecondcheckboxInputOverride10Kids" value="1" className="ls" />
                        <label style={{cursor:!enabled?"not-allowed":null}} className='text-center' htmlFor="SecondcheckboxInputOverride10Kids">75</label>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateTooths: (step, field, payload) => {
            dispatch({type:step, field, payload})
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ToothsSelector)