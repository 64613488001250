import React, { Component } from "react";
import apiClient from "../../utils/backend";

import OdCard from "./components/od_card";
import Searchbar from "../../components/searchbar";
import Paginator from "../../components/Paginator";
import  OdDesactivatedCard  from "./components/odDesactivated_card";

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      all: [],
      sao: [],
      study_group: [],
      type: "all",
      modal_show: false,
      modal_data: {
        patients: null,
        active_treatments: null,
        name: null,
        profile: null,
        id: null,
      },
      paginator: { current: null, next: null, previous: null },
      current_page: null,
      cant_per_page: 15,
      loading: true,
      isDesactivated:false
    };

    this.handleModal = this.handleModal.bind(this);
    this.handleChangePaginator = this.handleChangePaginator.bind(this);
    this.handleChangePaginatorDesactivated = this.handleChangePaginatorDesactivated.bind(this)
    this.filterDentists = this.filterDentists.bind(this);
  }

  componentDidMount() {
    apiClient
      .get("/api/dentists?include=treatmentsCount,patientsCount")
      .then((res) => {
        let od = res.data.table.body;
        let sao = od.filter((item) => item.sao === true);
        let sg_group = od.filter((item) => item.study_group === true);
        this.setState({
          all: od,
          sao: sao,
          study_group: sg_group,
          paginator: res.data.paginator,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  }

  handleModal(id) {
    let modal_data = this.state.od_list.find((od) => od.id === id);
    this.setState({ modal_show: !this.state.modal_show, modal_data });
  }

  handleChangePaginator(p){
    let path;
    if(this.state.type === "all"){
      path = `/api/dentists/?page=${p}&cant_pages=15&include=treatmentsCount,patientsCount,user.groups`
    }else{
      path = `/api/dentists/?page=${p}&cant_pages=15&include=treatmentsCount,patientsCount&filter[user.groups.id]=${this.state.type === "sao" ? "1" : "2"}`
    }
    apiClient.get(path)
    .then( res => {
      let od = res.data.table.body;
      let sao = od.filter((item) => item.sao === true);
      let des = res.data.table.body;
      let sg_group = od.filter((item) => item.study_group === true);
      this.setState({
        ...this.state,
        all: od,
        sao: sao,
        desactivated:des,
        study_group: sg_group,
        paginator: res.data.paginator,
        loading: false,
        current_page: p,
      });
    } )
    .catch( err => console.log(err))
  }

  handleChangePaginatorDesactivated(p){
    let path;
      path = `/api/dentists/?page=${p}&cant_pages=15&include=treatments,user.groups&filter[deactivated]=true`;
    apiClient.get(path)
    .then( res => {
      let od = res.data.table.body;
      let sao = od.filter((item) => item.sao === true);
      let des = res.data.table.body;
      let sg_group = od.filter((item) => item.study_group === false);
      this.setState({
        ...this.state,
        all: od,
        sao: sao,
        desactivated:des,
        study_group: sg_group,
        paginator: res.data.paginator,
        loading: false,
        current_page: p,
      });
    } )
    .catch( err => console.log(err))
  }

  filterDentists(type){
    let path;
    if(type === "all"){
      path = `/api/dentists/?page=1&cant_pages=15&include=treatmentsCount,patientsCount`
    }else if(type === "desactivated"){
      path = `/api/dentists/?page=1&cant_pages=15&include=treatments,treatmentsCount,patientsCount,user.groups&filter[deactivated]=true`
    }
    else{
      path = `/api/dentists/?page=1&cant_pages=15&include=treatmentsCount,patientsCount&filter[user.groups.id]=${type === "sao" ? "1" : "2"}`
    }
    apiClient.get(path)
    .then( res => {
      console.log(res.data)
      let od = res.data.table.body;
      let des = res.data.table.body;
      let sao = od.filter((item) => item.sao === true );
      let sg_group = od.filter((item) => item.study_group === true);
      this.setState({
        ...this.state,
        all: od,
        sao: sao,
        study_group: sg_group,
        desactivated:des,
        paginator: res.data.paginator,
        loading: false,
        type:type,
      });
    } )
    .catch( err => console.log(err))
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container mt-4">
          <div className="row">
            <div className="col-9 d-flex flex-row justify-content-between mb-3">
              <h1 style={{ fontWeight: 700 }} className="text-primary">
                Odontologos
              </h1>
            </div>
            <div className="col-3 d-flex flex-column px-3">
              <Searchbar />
              <button
                className="btn btn-primary px-4 rounded-pill mt-2"
                onClick={() => this.props.history.push("/odontologos/nuevo")}
              >
                Nuevo odontologo
              </button>
            </div>
            <div className="col-12 mt-3">
              <ul
                className="d-flex flex-row p-0 mb-0"
                style={{ listStyleType: "none" }}
              >
                <li style={{ marginRight: "30px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.type === "all" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.filterDentists("all")}
                  >
                    Todos
                  </a>
                </li>
                <li style={{ marginRight: "30px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.type === "sao" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.filterDentists("sao")}
                  >
                    SAO
                  </a>
                </li>
                <li style={{ marginRight: "30px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.type === "study_group" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.filterDentists("study_group")}
                  >
                    Study Group
                  </a>
                </li>
                <li style={{ marginRight: "30px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.type === "desactivated" && "disabled"
                    }`}
                    href="#"
                    onClick={() => {this.filterDentists("desactivated");this.setState({...this.state,isDesactivated:true})}}
                  >
                    Desactivados
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="row mt-4 mb-0 ">
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center text-truncate">
                Nombre y apellido
              </p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center text-truncate">
                Perfil
              </p>
            </div>
            <div className="col-2 d-flex flex-row">
              <p className="text-primary fw-bold m-0 text-start text-truncate">
                Smile Creator
              </p>
            </div>
            <div className="col-1 d-flex flex-row">
              <p className="text-primary fw-bold m-0 text-start text-truncate">
                Email
              </p>
            </div>
            {
              this.state.type !== 'desactivated' && (
                <>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center text-truncate">
                Telefono
              </p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center text-truncate">
                Pacientes
              </p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center text-truncate">
                Tratamientos activos
              </p>
            </div>
                </>
              )
            }
            <div className="col-1 d-flex flex-row justify-content-center"></div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              {this.state[this.state.type]?.map((item, i) => {
                return (
                  <div>
                    <div
                      className={`grow_hover w-100 shadow border border-1 rounded container-fluid py-2 text-start mt-2 mb-2 position-relative`}
                    >{
                      this.state.type !== 'desactivated' ? ( <OdCard data={item} />) : ( <OdDesactivatedCard data={item} /> )
                    }
                      
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {
              this.state.type !== 'desactivated' ? (<Paginator paginator={this.state.paginator}  getPaginator={this.handleChangePaginator}/>) : (<Paginator paginator={this.state.paginator}  getPaginator={this.handleChangePaginatorDesactivated}/>)
            }
          </div>
        </div>
      );
    }
  }
}
