import React, { Component } from "react";
import { connect } from "react-redux";

// COMPONENTS
import smile from "../../../assets/images/ks-smile.svg";
import SCGold from "../../../assets/images/sc-gold.png";
import SCPlatinum from "../../../assets/images/sc-platinum.png";
import SCBlack from "../../../assets/images/sc-black.png";
import { Droplet } from "@styled-icons/boxicons-regular";

import "./index.css";

class Detalle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      smilecreator_points: this.props.state.userReducer.smilecreator_points,
    };

    this.handleLogo = this.handleLogo.bind(this);
  }

  componentDidMount() {
    console.log(this.props);
  }

  handleLogo(id) {
    let components = {
      Silver: SCPlatinum,
      Gold: SCGold,
      Platinum: SCPlatinum,
      Black: SCBlack,
    };

    return components[id];
  }

  render() {
    return (
      <div className="container-fluid px-5 position-relative">
        <div className="home-bg" style={{ height: "220px" }} />
        <div className="row pt-4 px-4" style={{ zIndex: 2 }}>
          <div className="col-12 px-4" style={{ zIndex: 2 }}>
            <img src={smile} style={{ maxWidth: "75px", margin: 0 }} alt="" />
            <h2 className="text-white fw-bold">Smile Creator</h2>
            <h4 className="text-white">
              Recibí beneficios a partir de la cantidad de tratamientos que
              realices
            </h4>
          </div>
          <div
            className="col-12 px-5 mt-3 py-4 shadow d-flex flex-row justify-content-between"
            style={{ zIndex: 2, borderRadius: "20px" }}
          >
            <div className="d-flex flex-row">
              <img
                src={this.handleLogo(this.props.state.userReducer.smilecreator)}
                style={{ maxHeight: "80px" }}
                alt=""
              />
              <h4 className="pt-4 mx-3">
                Actualmente estás en categoría{" "}
                <span className="fw-bold">
                  {this.props.state.userReducer.smilecreator}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <h3 className="text-primary fw-bold px-4">Detalle de Puntos</h3>
          <ul className="ul-sc">
            <li className="li-gold">
              <Droplet
                size="24"
                color="#000"
                style={{
                  transform: "rotate(180deg)",
                  position: "absolute",
                  top: "-30px",
                  left: (33 / 14) * this.state.smilecreator_points - 5 + "%",
                  display:
                    this.props.state.userReducer.smilecreator_points < 5 &&
                    "none",
                }}
              />
            </li>
            <li className="li-platinum"></li>
            <li className="li-black"></li>
          </ul>
        </div>
        <div className="row mt-5 px-4">
          <h3 className="text-primary fw-bold">Últimos puntos sumados</h3>
          <p className="fs-5">
            Estos son tus últimos pacientes de etapa 1 en tratamiento:
          </p>
          <div className="d-flex flex-row mt-3 justify-content-around">
            <table className="sc-table">
              <thead>
                <th>Nombre y Apellido del paciente</th>
                <th>Puntos sumados</th>
              </thead>
              <tbody>
                <tr>
                  <td>Martina Rodriguez</td>
                  <td>+1</td>
                </tr>
                <tr>
                  <td>Martina Rodriguez</td>
                  <td>+1</td>
                </tr>
                <tr>
                  <td>Martina Rodriguez</td>
                  <td>+1</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Detalle);
