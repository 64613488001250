import React from 'react'
import { Edit } from 'react-feather'
import DeleteOdModal from './delete_od_modal'
import { User } from 'react-feather'

import sglogo from '../../../assets/images/study_group_logo.png'
import saologo from '../../../assets/images/sao_logo.png'

import gold from '../../../assets/images/sc-gold.png'
import platinum from '../../../assets/images/sc-platinum.png'
import black from '../../../assets/images/sc-black.png'

import './index.css'

function OdCard({ data }) {
  function handleSCImage() {
    let components = {
      Silver: platinum,
      Gold: gold,
      Platinum: platinum,
      Black: black,
      Beginner: platinum,
    }

    return components[data.smile_creator_type]
  }

  return (
    <div className="row mx-auto">
      <div className="col-2 d-flex flex-row justify-content-left">
        <div
          style={{
            width: '30px',
            height: '30px',
            border: '1px solid #ccc',
            borderRadius: '20px',
            marginTop: '5px',
            marginRight: '5px',
          }}
        >
          {' '}
          {<User size={25} style={{ marginLeft: '1px' }} />}
        </div>
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {data.user?.full_name}
        </p>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        <div className="mb-0 d-flex flex-row justify-content-center" style={{ lineHeight: '38px' }}>
          {data.user.groups.length !== 0
            ? data.user.groups.map((group) => (

                <img
                  src={group.id === 1 ? saologo : sglogo}
                  alt={group.name}
                  className="me-1"
                  style={{ width: '45px' }}
                />
              ))
            : 'Sin perfil'}
        </div>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        <img
          src={handleSCImage()}
          style={{ width: '30px', height: '30px', marginTop: '5px' }}
          alt="Smile Creator"
        />
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="mb-0 text-center text-truncate" style={{ lineHeight: '38px' }}>
          {data.user?.email}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {data.phone_number}
        </p>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {data.patients_count}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {data.treatments_count}
        </p>
      </div>
      <div className="col-1 d-flex flex-row justify-content-end">
        <a href={`/odontologos/${data.id}`} className="btn btn-light px-3 mx-1">
          <Edit color="#444" size={16} />
        </a>
          <DeleteOdModal
            id={data.id}
            groups={data.user.groups}
            patients={data.patients_count}
            active_treatments={data.treatments_count}
            name={data.user?.full_name}
            sao={data.sao}
          />
      </div>
    </div>
  )
}

export default OdCard
