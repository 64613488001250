import React, { useState } from "react";
import { Edit, Plus, RefreshCcw, X, Trash } from "react-feather";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, ModalBody } from "reactstrap";
import ConfirmationModal from "../../../components/confirmation_modal";
import apiClient from "../../../utils/backend";
import "./index.css";

const EditTreatment = (props) => {

  const { data,principalDentist } = props;
  const dentistFiltered = data.dentists.slice(1, data.dentists.length);
  const [opened, setOpened] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState(false);
  const [principalDentistId, setPrincipalDentist] = useState(principalDentist?.id);
  const [inputs, setInputs] = useState([]);
  const { dentistList } = useSelector((state) => state.odTreatments);
  const [typeId, setTypeId] = useState(1)

  const [userData, setUserData] = useState({
    dentistToDelete: data.dentists,
    dentist: dentistFiltered,
    id: data.id,
    stage: data.stage,
    treatment: data.treatment,
    odList: dentistList,
  });
  const treatmentSelect = (treatment) => {
    setTypeId(treatment)
    const components = {
      1: "Full",
      2: "Fast",
      3: "Medium",
      4: "Kids",
      5: "Kids Fast",
    };
    setUserData({ ...userData, treatment: components[treatment] });
  };

  const handleDeleteInput = (id) => {
    const data = inputs.filter((item) => item.inputId !== id);
    setInputs(data);
  };

  const handleReset = () => {
    setDefaultSelected(true);
    setTimeout(() => {
      setDefaultSelected(false);
    }, 500);
  };

  const addDentist = (index, id) => {
    let input = inputs[index];
    input.dentist_id = Number(id);
  };

  const deleteDentist = (id) => {
    if (data?.dentists[0].id === id) {
      return toast.error("🤔 No se puede borrar el dentista principal", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    let newDentistList;
    apiClient
      .delete(`/api/deleteDentistInTreatment`, {
        dentists: [{ treatment_id: data.id, dentist_id: id }],
      })
      .then((res) => {
        newDentistList = userData.dentistToDelete.filter(
          (dentist) => dentist.id !== id
        );
        setUserData({ ...userData, dentistToDelete: newDentistList });
      })
      .catch((err) => console.log(err));
  };

  const handleSave = () => {
    const dentistsToAdd = inputs;
    dentistsToAdd.forEach((dentist) => {
      dentist["treatment_id"] = dentist["inputId"];
      delete dentist["inputId"];
      dentist.treatment_id = data.id;
    });

    apiClient
      .put(`/api/editTreatmentType`, {
        id: Number(data.id),
        type_id: Number(typeId),
      })
      .then((res) => console.log(res)
      )
      .catch((err) => console.log(err));
    apiClient
      .put(`/api/editDentistInTreatment`, {
        dentist_id: Number(principalDentistId),
        treatment_id: data.id,
      })
      .then((res) => console.log(res)
      )
      .catch((err) => console.log(err));
    apiClient
      .post(`/api/addDentistInTreatment`, {
        dentists: dentistsToAdd,
      })
      .then((res) =>
        toast.success("Tratamiento guardado correctamente", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      )
      .catch((err) => console.log(err));

      setTimeout(() => {
        window.location.reload();
      }, 2000);
  };

  return (
    <>
      <Edit onClick={() => setOpened(!opened)} />
      <Modal
        style={{ marginTop: "150px" }}
        size="lg"
        isOpen={opened}
        toggle={() => setOpened(!opened)}
      >

        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex ">
                <p className="mt-2 col-11 text-black text-start fs-2 fw-bold">
                  Editar Tratamiento
                </p>
                <X
                  onClick={() => setOpened(false)}
                  className="col-1 x_button m-0"
                />
              </div>
              <div className="col-12 mt-4 d-flex justify-content-space-between">
                <p className="col-2 text-black text-start fs-5 fw-bold mt-2">
                  Tratamiento
                </p>
                <button
                  onClick={() => treatmentSelect(1)}
                  style={{ backgroundColor: "#001B70" }}
                  className={`${
                    userData.treatment === "Full" && "treatment_button_active"
                  } text-white fw-light btn col-1 treatment_button ms-2`}
                >
                  FULL
                </button>
                <button
                  onClick={() => treatmentSelect(2)}
                  style={{ backgroundColor: "#288B7E" }}
                  className={`${
                    userData.treatment === "Fast" && "treatment_button_active"
                  } text-white fw-light btn col-1 treatment_button ms-2`}
                >
                  FAST
                </button>
                <button
                  onClick={() => treatmentSelect(3)}
                  style={{ backgroundColor: "#5A468E" }}
                  className={`${
                    userData.treatment === "Medium" && "treatment_button_active"
                  } text-white fw-light btn col-1 treatment_button ms-2`}
                >
                  MEDIUM
                </button>
                <button
                  onClick={() => treatmentSelect(4)}
                  style={{ backgroundColor: "#E47662" }}
                  className={`${
                    userData.treatment === "Kids" &&
                    "treatment_button_active"
                  } text-white fw-light btn col-1 treatment_button ms-2`}
                >
                  FULL Kids
                </button>
                <button
                  onClick={() => treatmentSelect(5)}
                  style={{ backgroundColor: "#E8793A" }}
                  className={`${
                    userData.treatment === "Kids Fast" &&
                    "treatment_button_active"
                  } text-white fw-light btn col-1 treatment_button ms-2`}
                >
                  FAST Kids
                </button>
              </div>
              {(userData.treatment === "Full" ||
                userData.treatment === "Full Kids") && (
                <div className="col-12 mt-4 d-flex justify-content-space-between">
                  <button
                    onClick={() => setUserData({ ...userData, stage: 1 })}
                    className={`${
                      userData.stage == 1 ? "step_active" : "step_inactive"
                    } col-2 btn-sm`}
                  >
                    ETAPA 1
                  </button>
                  <button
                    onClick={() => setUserData({ ...userData, stage: 2 })}
                    className={`${
                      userData.stage == 2 ? "step_active" : "step_inactive"
                    } col-2 ms-2 btn-sm`}
                  >
                    ETAPA 2
                  </button>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                <div className="mt-5">
                  <p>Doctor:</p>
                </div>
                <div className="row d-flex">
                  <div className="col-10">
                    <select
                      className="form-control"
                      onChange={(e) => setPrincipalDentist(e.target.value)}
                    >
                      <option
                        value={principalDentist?.id}
                        selected={defaultSelected}
                      >
                        {principalDentist?.user.name}{" "}
                        {principalDentist?.user.surname}
                      </option>
                      {userData.dentist.map((option) => (
                        <option value={option.id}>
                          {option.user?.name} {option.user?.surname}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="d-flex col-2 mt-2">
                    <RefreshCcw
                      className="input_button"
                      onClick={() => handleReset()}
                    />
                    <Plus
                      className="input_button ms-2"
                      onClick={() =>
                        setInputs([
                          ...inputs,
                          {
                            inputId:
                              inputs.length === 0
                                ? 0
                                : inputs[inputs.length - 1].inputId + 1,
                            dentist_id: principalDentist?.id,
                          },
                        ])
                      }
                    />
                  </div>
                </div>
                <div className="col-12 d-flex flex-column">
                  {inputs.map((dentist, index) => (
                    <div className="row d-flex">
                      <div className="col-10">
                        <select
                          className="form-control"
                          onChange={(e) => addDentist(index, e.target.value)}
                        >
                          {userData.odList.map((option) => (
                            <option value={option.id}>
                              {option.user?.name} {option.user?.surname}
                            </option>
                          ))}
                        </select>
                      </div>{" "}
                      <div className="col-2 mt-1">
                        <Trash
                          size={27}
                          className="input_button"
                          onClick={() => handleDeleteInput(dentist.inputId)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-12 d-flex flex-row flex-wrap justify-content-center">
                  {userData.dentistToDelete.map((dentist) => (
                    <button className="d-flex align-items-center btn btn-light btn-sm m-2 p-1">
                      <p className="text-truncate mt-2">
                        {dentist.user?.name} {dentist.user?.surname}
                      </p>{" "}
                      <ConfirmationModal
                        type="dentist-delete"
                        data={dentist}
                        deleteDentist={deleteDentist}
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 d-flex justify-content-end">
              <button
                onClick={handleSave}
                className="btn btn-success btn-lg text-white"
              >
                Guardar
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditTreatment;
