import React from "react";

export default function PaymentStatusSubcard({
  patient,
  first_payment,
  second_payment,
  third_payment,
  current_payments,
  overdue_payments,

}) {

  return (
    <div className="row border-bottom border-primary border-1 py-2 px-4">
      <div
        className="col-2 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        {patient}
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        -
      </div>

      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        -
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        -
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        -
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        -
      </div>
      <div
        className="col-1 text-muted text-center m-0"
        style={{ lineHeight: "50px" }}
      >
        ${current_payments !== null ? current_payments : 0}
      </div>
      <div
        className="col-1 text-danger text-center m-0"
        style={{ lineHeight: "50px" }}
      >
        ${overdue_payments !== null ? overdue_payments : 0}
      </div>
      <div
        className="col-1 text-black text-center"
        style={{ lineHeight: "50px" }}
      >
          ${current_payments + overdue_payments}
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        <button className="btn btn-outline-primary rounded-pill">
          Reclamar
        </button>
      </div>
    </div>
  );
}
