import React, {Component} from "react";
import smile from '../../assets/images/ks-smile.svg'

import './index.css'

class Listado extends Component {
    componentDidMount() {

    }

    handleTreatmentColor(type){
        const colors = {
            "KeepSmiling Full": '#10456D',
            "KeepSmiling Fast":'#288B7E',
            "KeepSmiling Medium":'#5A468E',
            "KeepSmiling Kids":'#E0614A',
            "KeepSmiling Kids Fast":'#E8793A',
            "KeepSmiling Teens":'#F7C05B'
        }

        return colors[type]
    }

    render() {
        return (
            <div className="container-fluid overflow-hidden">
                <div className="row">
                    <div className="col-12 position-relative">
                        <div className="pagos-bg" style={{height: '200px'}} />
                    </div>
                </div>

                <div className="row pt-2 mt-5 px-5" style={{zIndex: 1, height: '200px'}}>
                    <div className="col-12 px-4" style={{zIndex: 1}}>
                    <img src={smile} style={{maxWidth: '75px', margin: 0}} alt="" />
                        <h2 className="text-white fw-bold">Pagos</h2>
                        <h4 className="text-white">Listado de precios actualizados y datos bancarios</h4>
                    </div>
                </div>

                <div className="row mt-3 px-4">
                    <div className="col-12 px-4 mb-3">
                        <h2 className="text-primary fw-bold">Listado de precios</h2>
                    </div>
                    <div className="col-2 mx-auto" style={{width: 160, height: 25, padding: 5, backgroundColor: 'gray', textAlign: 'center'}}>
                        <p style={{color:'white', fontWeight: 'bold'}}>Tipo de Tratamiento</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160, height: 25, padding: 5, backgroundColor: 'gray', textAlign: 'center'}}>
                        <p style={{color:'white', fontWeight: 'bold'}}>Maxilar</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160, height: 25, padding: 5, backgroundColor: 'gray', textAlign: 'center'}}>
                        <p style={{color:'white', fontWeight: 'bold'}}>Precios ARG</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160, height: 25, padding: 5, backgroundColor: 'gray', textAlign: 'center'}}>
                        <p style={{color:'white', fontWeight: 'bold', fontSize: 13}}>Cantidad de Alineadores</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160, height: 25, padding: 5, backgroundColor: 'gray', textAlign: 'center'}}>
                        <p style={{color:'white', fontWeight: 'bold', fontSize: 13}}>Reposicion Alineador</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160, height: 25, padding: 5, backgroundColor: 'gray', textAlign: 'center'}}>
                        <p style={{color:'white', fontWeight: 'bold'}}>Etapa Adicional</p>
                    </div>
                </div>

                <div className="row mt-3 px-4">
                    <div className="col-2 mx-auto" style={{display: 'flex', width: 160, height: 60, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), alignItems: 'center', justifyContent: 'center'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>KeepSmiling FULL</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>1 Maxilar</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>2 Maxilares</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Full'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 px-4">
                    <div className="col-2 mx-auto" style={{display: 'flex', width: 160, height: 60, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), alignItems: 'center', justifyContent: 'center'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>KeepSmiling FAST</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>1 Maxilar</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>2 Maxilares</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 px-4">
                    <div className="col-2 mx-auto" style={{display: 'flex', width: 160, height: 60, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), alignItems: 'center', justifyContent: 'center'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>KeepSmiling MEDIUM</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>1 Maxilar</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>2 Maxilares</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Medium'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 px-4">
                    <div className="col-2 mx-auto" style={{display: 'flex', width: 160, height: 60, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), alignItems: 'center', justifyContent: 'center'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>KeepSmiling KIDS</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>1 Maxilar</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>2 Maxilares</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-3 px-4">
                    <div className="col-2 mx-auto" style={{display: 'flex', width: 160, height: 60, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), alignItems: 'center', justifyContent: 'center'}}>
                        <p style={{color: 'white', fontWeight: 'bold'}}>KeepSmiling KIDS FAST</p>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>1 Maxilar</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>2 Maxilares</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>$ 124.100</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Limitado</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluido</p>
                        </div>
                    </div>

                    <div className="col-2 mx-auto" style={{width: 160}}>
                        <div className="row" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                        <div className="row mt-2" style={{width: 160, height: 25, padding: 5, backgroundColor: this.handleTreatmentColor('KeepSmiling Kids Fast'), textAlign: 'center', opacity: 0.5}}>
                            <p style={{color:'white', fontWeight: 'bold'}}>Incluida</p>
                        </div>
                    </div>
                </div>

                <div className="row mt-5 mx-4 px-4">
                    <div className="col-6">
                        <p>TODOS LOS PRECIOS INCLUYEN IVA</p>
                        <p>- 50% DEL PAGO AL INICIO DEL TRATAMIENTO</p>
                        <p>- 25% DEL PAGO LUEGO DE HABER RECIBIDO LOS ALINEADORES</p>
                        <p>- 25% DEL PAGO A LOS 30 DIAS DEL SEGUNDO PAGO</p>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="flex-column d-flex justify-content-center p-3 text-white" style={{width:450, height:80, borderRadius: 15, backgroundColor: this.handleTreatmentColor('KeepSmiling Full')}}>
                            <p>PRECIOS VÁLIDOS HASTA EL 31 DE AGOSTO DE 2021</p>
                            <p className="mt-1">DOLAR DE REFERENCIA 85USD</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Listado
