const Step={
    ParentStep:1,
    ChildStep:1
}
export const CurrentStep ={
    Step
}
const General = {
    description: ''
}
export const AestheticPriority = {
    General
};
const Movements = {
    movement: '',
    permanentOdontogramsUp: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    permanentOdontogramsDown: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsUp: [0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsDown: [0,0,0,0,0,0,0,0,0,0],
    description: ''
}
const Extractions = {
    extraction: '',
    permanentOdontogramsUp: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    permanentOdontogramsDown: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsUp: [0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsDown: [0,0,0,0,0,0,0,0,0,0],
    description: ''
}
const VirtualTooths = {
    virtualTooth: '',
    description: ''
}
const Attachments = {
    attachment: '',
    permanentOdontogramsUp: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    permanentOdontogramsDown: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsUp: [0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsDown: [0,0,0,0,0,0,0,0,0,0],
    description: ''
}
export const PretratmentDiagnosisValues = {
    Movements,
    Extractions,
    VirtualTooths,
    Attachments
};
const MiddleLine = {
    middleLineHigh: '',
    amountMMMoveHihg:0,
    middleLineLow: '',
    amountMMMoveLow:0,
}
const OverjetOverbite = {
    overjet:'',
    overjetUp:'',
    overjetUpOthers:'',
    overjetDown:'',
    overjetDownOthers:'',
    overbite:'',
    overbiteUp:'',
    overbiteUpOthers:'',
    overbiteDown:'',
    overbiteDownOthers:'',
}
const CanineRelationship={
    right:'',
    rightDescription:'',
    left:'',
    leftDescription:''
}
const MolarRelationship={
    right:'',
    rightDescription:'',
    left:'',
    leftDescription:''
}
const CrossBite={
    crossBite:'',
    improveCrossBite:'',
    improveCrossBiteOthers:''
}
const Stripping={
    stripping: '',
    virtualRemodel:'',
    permanentOdontogramsUp: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    permanentOdontogramsDown: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsUp: [0,0,0,0,0,0,0,0,0,0],
    temporalOdontogramsDown: [0,0,0,0,0,0,0,0,0,0],
    description: '',
    withInclination:'',
    withoutInclination:'',
    othersInclination:'',
    withGain:'',
    withoutGain:'',
    othersGain:''
}
const Diastems={
    diastems:'',
    mesiDiastems:'',
    retroDiastems:'',
    description:''
}
const Spaces={
    spaces:'',
    amountOpen:0,
    closeSpaces:'',
    closeSpacesDescription:'',
}
const SequentialDistalation={
    sequentialDistalation:'',
    upRight:0,
    downRight:0,
    upLeft:0,
    downLeft:0
}
const AuxiliarResources={
    auxiliarResources:'',
    auxiliarResourcesSelected:[]
}
const GeneralInstructions={
    instructions:'',
    attachmentFile:[],
}
export const InstructionsTratment = {
    MiddleLine,
    OverjetOverbite,
    CanineRelationship,
    MolarRelationship,
    CrossBite,
    Stripping,
    Diastems,
    Spaces,
    SequentialDistalation,
    AuxiliarResources,
    GeneralInstructions
};
export const ErrorsList=[{
    message:'',
    order: 0,
    icon:''
}]
const MainInformation={
    firstName:'',
    lastName:'',
    type:1,
    maxilar:'',
    age:0,
    photo:{
        name:"",
        extension:"",
        base64:""
    }
}
export const Header={
    MainInformation
}
