import React, { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { ChevronDown, ChevronUp } from "react-feather";
import PaymentsForm from "./payments_form";

function BodyPlacasRepeticion({ item }) {
  const [cardOpened, setCardOpened] = useState(null);

  return (
    <div className="row grow_hover w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
      <button className="clinic-history-button type-1"></button>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.id}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.patient}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.dentist}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.created_at}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.fecha_salida}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.plates}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.maxilla}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.quantity}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.reason}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center text-center">
        <p
          className="m-0 text-center justify-items-middle text-wrap"
          style={{ fontSize: ".8rem" }}
        >
          {item.cost}
        </p>
      </div>
      <div className="col d-flex flex-row justify-content-center">
        <CircularProgressbar
          value={item.payment_status}
          text={`${item.payment_status}%`}
          strokeWidth={15}
          styles={buildStyles({
            pathColor:
              item.payment_status < 50
                ? "#e74c3c"
                : item.payment_status >= 50 && item.payment_status < 100
                ? "#f1c40f"
                : "#2ecc71",
            textColor:
              item.payment_status < 50
                ? "#e74c3c"
                : item.payment_status >= 50 && item.payment_status < 100
                ? "#f1c40f"
                : "#2ecc71",
            textSize: 20,
          })}
        />
      </div>
      <button
        className={item.payment.status > 0 ? "btn bg-none" : "d-none"}
        onClick={() => setCardOpened(cardOpened !== item.id ? item.id : null)}
        style={{
          position: "absolute",
          top: "20px",
          maxWidth: "20px",
          right: "15px",
        }}
      >
        {cardOpened === item.id ? (
          <ChevronUp color="#444" size="24" />
        ) : (
          <ChevronDown color="#444" size="24" />
        )}
      </button>
      {cardOpened === item.id && (
        <div
          className="container px-5 pb-4"
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#fefefe",
          }}
        >
          <hr />
          <div className="row">
            {item.payment.details.map((payment) => (
              <PaymentsForm payment_data={payment} paymentId={item["id"]} />
            ))}
            <div className="col-2 text-center">
              <p className="fw-bold mt-3 mb-3">Estado del pago</p>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                }}
              >
                <CircularProgressbar
                  value={item.payment_status}
                  text={`${item.payment_status}%`}
                  strokeWidth={15}
                  styles={buildStyles({
                    pathColor:
                      item.payment_status < 50
                        ? "#e74c3c"
                        : item.payment_status >= 50 && item.payment_status < 100
                        ? "#f1c40f"
                        : "#2ecc71",
                    textColor:
                      item.payment_status < 50
                        ? "#e74c3c"
                        : item.payment_status >= 50 && item.payment_status < 100
                        ? "#f1c40f"
                        : "#2ecc71",
                    textSize: 20,
                  })}
                />
                <p className="fw-bold mt-2 mb-2">
                  Saldo pendiente: {item.pending_amount}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BodyPlacasRepeticion;
