import axios from "axios";

const apiClient = axios.create({
    baseURL: (window.location.href.search('localhost')) ? ('https://api.test.keepsmiling.app') :  (window.location.href),
    headers: {
        Authorization: `Bearer ${localStorage.getItem('lgt')}`
    }
});

apiClient.defaults.headers.post["Content-Type"] = "application/json";

export default apiClient;
