import React from 'react'
import { Link } from 'react-router-dom'
import kssmile from '../../assets/images/ks-smile.png'
import './index.css'

const Solicitud = () => {
  return (
    <>
      <div className="col-12 mb-4 text-start title_container ps-5 py-5">
        <img src={kssmile} alt={'ksmile'} className="smile_image" />
        <h2 className="text-white fw-bold">Crear una Nueva Solicitud</h2>
        <p className="text-white fs-4">
          A continuación puede elegir el tipo de solicitud que desea crear.
        </p>
      </div>
      <div className="container-fluid px-5 position-relative">
        <div className="row mt-5 w-100 h-100 text-center">
          <div className="col-4 px-5 p-3">
            <div
              className="border border-3 shadow d-flex flex-column px-4 py-5"
              style={{ borderRadius: '20px', height: '250px' }}
            >
              <h4 className="text-primary fw-bold">Nuevo Tratamiento</h4>
              <p className="text-primary mt-2">
                Solicitud de tratamiento para un nuevo paciente que comienza un
                tratamiento de KeepSmiling.
              </p>
              <Link
                to="/od/ingresos/nuevo"
                className="btn btn-primary rounded-pill mt-auto"
              >
                Crear una solicitud
              </Link>
            </div>
          </div>
          <div className="col-4 px-5 p-3">
            <div
              className="border border-3 shadow d-flex flex-column px-4 py-5"
              style={{ borderRadius: '20px', height: '250px' }}
            >
              <h4 className="text-primary fw-bold">Nueva Etapa</h4>
              <p className="text-primary mt-2">
                Solicitud de Nueva Etapa para un paciente existente que continúa
                con su tratamiento.
              </p>
              <Link
                to="/od/etapa/nuevo"
                className="btn btn-primary rounded-pill mt-auto"
              >
                Crear una solicitud
              </Link>
            </div>
          </div>
          <div className="col-4 px-5 p-3">
            <div
              className="border border-3 shadow d-flex flex-column px-4 py-5"
              style={{ borderRadius: '20px', height: '250px' }}
            >
              <h4 className="text-primary fw-bold">Viabilidad</h4>
              <p className="text-primary mt-2">
                Solicitud de consulta de Viabilidad de un tratamiento.
              </p>
              <Link
                to="/od/viabilidad/nuevo"
                className="btn btn-primary rounded-pill mt-auto"
              >
                Crear una solicitud
              </Link>
            </div>
          </div>
          {/* <div className="col-3 p-3">
                    <div className="border border-1 shadow d-flex flex-column px-4 py-5" style={{borderRadius: '20px', height: '250px'}}>
                        <h4 className="text-primary fw-bold">Pedido de placa</h4>
                        <p className="text-primary mt-2">Pedido de placa de repetición, contención, reducción, matriz de botones, o Saca placas o cheewis.</p>
                        <Link className="btn btn-primary rounded-pill mt-auto">Crear una solicitud</Link>
                    </div>
                </div> */}
          <div className="col-12 text-center">
            <p className="text-center text-primary mx-auto mt-4 w-50">
              Todas las solicitudes enviadas serán revisadas por el equipo de
              KeepSmiling y usted recibirá un seguimiento por parte del equipo.
              Una solicitud no es un paciente ingresado aún.
            </p>
            <p className="text-center text-primary mx-auto mt-4 w-50">
              Para cualquier consulta, puede comunicarse por ... al xxxxxxxxx.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Solicitud
