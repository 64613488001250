import React, { useEffect, useRef } from 'react'
import ImageImput from '../components/ImageInput'
import '../../index.css'

export const TreatmentAdminStepFour = ({ state, setState }) => {
  const plantratamiento = useRef()

  const handleImageSelect = async (name, img, type) => {
    setState({
      ...state,
      [name]: img,
      cropper_img: img,
      [type]: {
        ...state[type],
        [name]: img,
      },
    })
  }
  useEffect(() => console.log(state), [state])
  return (
    <div style={{ height: '100%', overflowX: 'hidden', minHeight: '300px' }}>
      <div className="container mt-4">
        <ImageImput
          name="plantratamiento"
          id="Plan de Tratamiento"
          loadImage={state.plantratamiento}
          ref={plantratamiento}
          handleImageSelect={handleImageSelect}
        />
      </div>
    </div>
  )
}
