import React, { useState, useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import CheckBoxList from '../Utilities/CheckBoxList'
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps, type_dispatch } from '../../propsStateConnect';
import { auxiliary_resources_options, auxiliary_resources_options_2, yes_no_options } from '../Utilities/OptionsRadios';
import { useErrorList } from '../Utilities/UseErrorsList';

function AuxiliarResources(props){
    type_dispatch.step='InstructionsTratment'
    type_dispatch.field='AuxiliarResources'
    const { colors, updateState, updateErrors }= props;
    const middle_state= props.formOnlineReducer[type_dispatch.step][type_dispatch.field];

    const auxiliaryResources=middle_state.auxiliarResources
    const close=middle_state.auxiliarResourcesSelected
    const errors_list=props.formOnlineReducer.ErrorsList
    const error1=errors_list.find(err=>err.order===1)
    useErrorList(errors_list, updateErrors, [auxiliaryResources])

    const updateResources=(val, chk)=>{
        let updatedArray=[]
        if(chk){
            updatedArray=[...middle_state.auxiliarResourcesSelected, val];
        }else{
            updatedArray=middle_state.auxiliarResourcesSelected.filter(itm=>itm!==val)
        }
        updateState({key:'auxiliarResourcesSelected', value:updatedArray })
    }

    useEffect(()=>{
        if(auxiliaryResources===2){
            updateState({key:'auxiliarResourcesSelected', value:[] })
        }
    },[auxiliaryResources, updateState])
    return (
        <div>
            <div className="w-100 fw-bolder pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                Recursos Auxiliares
            </div>
            <label className="small mt-2">¿Quiere/piensa utilizar recursos auxiliares?</label>
            {error1 && 
                <div className="alert-danger alert-link p-2 mt-1">
                    {error1.message}
                </div>
            }
            <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={yes_no_options}
            setter={(value)=>updateState({key:'auxiliarResources', value})}
            stateField={auxiliaryResources} name='auxiliaryResources' />
            {auxiliaryResources===1 &&
            <div className='ms-4 mt-2'>
                <div className="fw-bolder small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                    Indique los recursos auxiliares que desea utilizar:
                </div>
                <div className="d-flex ms-3 mt-3 align-items-start">
                    <div className="col-6">
                        <CheckBoxList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={auxiliary_resources_options}
                        setter={(value, checked)=> updateResources(value, checked)}
                        stateField={close} name='close' />
                    </div>
                    <div className="col-6">
                        <CheckBoxList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={auxiliary_resources_options_2}
                        setter={(value, checked)=> updateResources(value, checked)}
                        stateField={close} name='close2' />
                    </div>
                </div>
            </div>
            }
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(AuxiliarResources)