import React from "react";

export default function StepCounter({ step }) {
  function handleBarWidth() {
    let sizes = {
      1: 0,
      2: 25,
      3: 50,
      4: 75,
      5: 100,
      6: 100,
    };

    return sizes[step];
  }

  return (
    <div className="px-5 mt-5" style={{ position: "relative" }} id="timeline">
      <ul
        className="w-100"
        style={{
          background: "#cecece",
          height: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          listStyleType: "none",
          position: "relative",
        }}
      >
        <li
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            background: step >= 1 ? "#2ecc71" : "#ECEEF1",
            position: "absolute",
            top: "-12px",
            zIndex: 10,
            left: "-5px",
          }}
        >
          {/* {data_completed ? <Check size="18" style={{color: '#fff', position: 'absolute', top: '10px', left: '25%', strokeWidth: '4px'}} /> : !data_completed ? <span style={{color: '#fff', fontSize: '1.5em', position: 'absolute', top: '5px', left: '35%', fontWeight: 'bolder'}}>!</span> : null} */}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".6rem",
            }}
          >
            Alta de paciente
          </p>
        </li>
        <li
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            background:
              step >= 3 ? "#2ecc71" : step === 2 ? "#D8BF6E" : "#cecece",
            position: "absolute",
            top: "-12px",
            zIndex: 10,
            left: "23%",
          }}
        >
          {/* {fotos_completed ? <Check size="18" style={{color: '#fff', position: 'absolute', top: '10px', left: '25%', strokeWidth: '4px'}} /> : !fotos_completed ? <span style={{color: '#fff', fontSize: '1.5em', position: 'absolute', top: '5px', left: '35%', fontWeight: 'bolder'}}>!</span> : null} */}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".6rem",
            }}
          >
            Documentacion completa
          </p>
        </li>
        <li
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            background: step >= 4 ? "#2ecc71" : "#ECEEF1",
            position: "absolute",
            top: "-12px",
            zIndex: 10,
            left: "49%",
          }}
        >
          {/* {estudios_completed ? <Check size="18" style={{color: '#fff', position: 'absolute', top: '10px', left: '25%', strokeWidth: '4px'}} /> : !estudios_completed ? <span style={{color: '#fff', fontSize: '1.5em', position: 'absolute', top: '5px', left: '35%', fontWeight: 'bolder'}}>!</span> : null} */}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".6rem",
            }}
          >
            Propuesta de tratamiento
          </p>
        </li>
        <li
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            background: step >= 5 ? "#2ecc71" : "#ECEEF1",
            position: "absolute",
            top: "-12px",
            zIndex: 10,
            left: "73%",
          }}
        >
          {/* {plan_completed ? <Check size="18" style={{color: '#fff', position: 'absolute', top: '10px', left: '25%', strokeWidth: '4px'}} /> : !plan_completed ? <span style={{color: '#fff', fontSize: '1.5em', position: 'absolute', top: '5px', left: '35%', fontWeight: 'bolder'}}>!</span> : null} */}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".6rem",
            }}
          >
            En producción de alineadores
          </p>
        </li>
        <li
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            background: step >= 6 ? "#2ecc71" : "#ECEEF1",
            position: "absolute",
            top: "-12px",
            zIndex: 10,
            left: "95%",
          }}
        >
          {/* {registry_completed ? <Check size="18" style={{color: '#fff', position: 'absolute', top: '10px', left: '25%', strokeWidth: '4px'}} /> : !registry_completed ? <span style={{color: '#fff', fontSize: '1.5em', position: 'absolute', top: '5px', left: '35%', fontWeight: 'bolder'}}>!</span> : null} */}
          <p
            style={{
              position: "absolute",
              top: "50px",
              width: "100px",
              textAlign: "center",
              left: "-30px",
              fontWeight: "500",
              fontSize: ".6rem",
            }}
          >
            Entrega de tratamiento
          </p>
        </li>
      </ul>
      <ul
        className={`w-${handleBarWidth()}`}
        style={{
          background: "#2ecc71",
          height: "10px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          listStyleType: "none",
          position: "absolute",
          top: "0",
          zIndex: 9,
        }}
      ></ul>
    </div>
  );
}
