import React from "react";
import moment from 'moment'
import {PlayCircle} from 'react-feather'

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function CurrentAccountCard({
  patient,
  created_at,
  treatment,
  maxilla,
  discount,
  cost,
  payment_status,
  handleModal,
  status,
  id,
  details,
    pending_amount
}) {

  const modal_item = {
      id,
      patient,
      created_at,
      treatment,
      maxilla,
      discount,
      cost,
      details,
      payment_status,
      pending_amount
  }

  console.log('MODAL ITEM ', modal_item)

  const handleRequest = () => {

  }

  return (
    <div className="row border-bottom border-primary border-1 py-2">
      <div
        className="col-2 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        {patient}
      </div>
      <div
        className="col-2 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        {moment(created_at).format('YYYY-MM-DD')}
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        {treatment}
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        {maxilla}
      </div>
      <div
        className="col-1 text-primary text-center m-0"
        style={{ lineHeight: "50px" }}
      >
        <input
          type="number"
          name="discount"
          id="discount"
          className="form-control"
          placeholder="%"
          disabled
          value={discount !== null ? discount : null}
        />
      </div>
      <div
        className="col-1 text-primary text-center m-0"
        style={{ lineHeight: "50px" }}
      >
        <input
          type="number"
          name="cost"
          id="cost"
          className="form-control"
          placeholder="$"
          disabled
          value={cost !== null ? cost : null}
        />
      </div>
      <div
        className="col-1 text-primary text-center d-flex flex-row justify-content-center"
      >
        {status > 0 ? <div style={{width: '50px', height: '50px'}}>
                <CircularProgressbar value={payment_status} text={`${payment_status}%`} strokeWidth={15} 
                styles={buildStyles({
                    pathColor: payment_status < 50 ? '#e74c3c' : payment_status >= 50 && payment_status < 100 ? '#f1c40f' : '#2ecc71',
                    textColor: payment_status < 50 ? '#e74c3c' : payment_status >= 50 && payment_status < 100 ? '#f1c40f' : '#2ecc71',
                    textSize: 26,
                     })}  />
            </div>
            : <button onClick={() => handleRequest()} className={discount === null || discount === 0 || cost === 0 || cost === null ? "btn p-0 disabled" : "btn p-0"}><PlayCircle size="50" color="#d6d6d6" strokeWidth={2} /></button>}
      </div>
      <div
        className="col-1 text-primary text-center"
        style={{ lineHeight: "50px" }}
      ></div>
      <div
        className="col-2 text-primary text-center"
        style={{ lineHeight: "50px" }}
      >
        <button className="btn btn-outline-primary rounded-pill" onClick={() => handleModal(modal_item)} >
          Asignar dinero
        </button>
      </div>
    </div>
  );
}
