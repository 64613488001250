import React, { Component } from 'react'
import apiClient from '../../utils/backend'
//import moment from 'moment'
import {connect} from 'react-redux'
import {ArrowUp, CheckCircle, XCircle} from 'react-feather'
import ReactTooltip from 'react-tooltip';


// COMPONENTS
import ClinicHistoryModal from '../../components/modal_hist_clinica'
import {Tomar, Borrar, OK, Cargar, Finalizar} from './components/buttons'
import Modals from './components/modals';

class index extends Component {
constructor(props){
    console.log('ENTRA a props!', props)
    super(props)

    this.state = {
      table: {},
      title: "",
      cards: [],
      loading: true,
      country: '',
      country_filter: false,
      isopened: false,
      modal_hist_clinica: false,
      high_priority: [],
      low_priority: [],
      origin:''
    }


    this.handleModal = this.handleModal.bind(this)
    this.handleButtonType = this.handleButtonType.bind(this)
    this.handleHistClinicaModal = this.handleHistClinicaModal.bind(this)
  }

  componentDidMount(){
    this.get_data()
  }

  componentDidUpdate(prevProps, prevState){
    console.log({prev:prevProps.location.pathname});
    if(this.props.location.pathname!==prevProps.location.pathname)
    {
      this.setState({...this.state,
        modal_show: false, modal_id: null, high_priority: [], low_priority: [], origin:''
      })
      this.get_data()
    }
  }
  components(type) {
  // return  {
  //   Tomar: Tomar,
  //   Finalizar: Finalizar,
  //   Borrar: Borrar,
  //   Cargar: Cargar,
  //   OK: OK}
  switch(type){
    default:
      return XCircle
    case 'Tomar':
      return Tomar
    case 'Finalizar':
        return Finalizar
    case 'Borrar':
      return Borrar
    case 'Cargar':
      return Cargar
    case 'OK':
        return OK
    case 'CheckCircle':
      return CheckCircle
    case 'X':
        return XCircle
    }
   }

  componentsApr(type) {
    switch(type){
      default:
        return XCircle
      case 'CheckCircle':
        return CheckCircle
      case 'X':
          return XCircle
    }
    // return {
    //   CheckCircle: CheckCircle,
    //   X: XCircle
    // }
  }
  get_data (){
    const path = window.location.pathname.split("/")[2]
    console.log(path)
    console.log(this.props)
    this.setState({...this.state,loading:true})
    apiClient.get(`/api/processes/${path}`)
    .then(res => {
      let high = res.data.table.body[res.data.user_country].filter(item => item.high)
      let low = res.data.table.body[res.data.user_country].filter(item => !item.high)
      console.log(high)
      this.setState({...this.state, table: res.data.table,
        title: res.data.title,
        country_filter: res.data.country_filter,
        country: res.data.user_country,
        cards: res.data.card,
        high_priority: high,
        low_priority: low,
        loading: false,
        origin: path
      })
      console.log(this.state)

      
    })
    .catch(err => console.log(err))
    //this.setState({loading:false})
  }

  handleButtonIcon(icon, color, value){
    const path = window.location.pathname.split("/")[2]
    console.log(icon, color, value)

    if(path === 'aprobacion'){

      // let components = {
      //   CheckCircle: CheckCircle,
      //   X: XCircle,
      // }

      // let IconComponent = components[icon]

      return this.componentsApr(icon) //<IconComponent color={color} size={30} />
    } else {

      // let components = {
      //   Tomar: Tomar,
      //   Finalizar: Finalizar,
      //   Borrar: Borrar,
      //   Cargar: Cargar,
      //   OK: OK
      // }

      // let IconComponent = components[icon]

      return this.components(icon) //<IconComponent color={color} value={value ? value : null} />
    }
  }

  handleModal(id){
    console.log('handling clinic history modal from processes, ', id)
    this.setState({modal_show: !this.state.modal_show, modal_id: id})
  }

  handleHistClinicaModal(){
    this.setState({modal_hist_clinica: !this.state.modal_hist_clinica})
  }

  handleButtonType({button, id}) {

    const path = window.location.pathname.split("/")[2]

    console.log('modal->', button.type);
    if(button.type === 'modal'){
      this.handleModal(button.modal_id + '-' + id)

      const path = window.location.pathname.split("/")[2]

      // if(path === 'estampado' || path === 'movimiento' || path === 'revision') {
      //   apiClient.post('/api/status/nextStatus', {treatment_id: id, process: path})
      //   .then(res => res.status === 200 ? window.location.reload() : console.log(res))
      //   .catch(err => console.log(err))
      // }

      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
      // HACER QUE EL MODAL PASE DE ESTADO FORZADO EN BOTONES, STRIPPING y ESTAMPADO
    } else {
      console.log(button.path, id)
      apiClient.post(`${button.path}`, {treatment_id: id, process: path})
      .then(res => res.status === 200 ? window.location.reload() : console.log(res))
      .catch(err => console.log(err))
    }
  }

  handleTreatmentColor(type){
    const colors = {
      "KeepSmiling Full": 1,
      "KeepSmiling Fast":2,
      "KeepSmiling Medium":3,
      "KeepSmiling Kids":4,
      "KeepSmiling Kids Fast":5,
      "KeepSmiling Teens":6
    }

    return colors[type]
  }

  render() {
    if(this.state.loading){
      return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{height:'100px'}}>
          <button class="btn btn-success" type="button" disabled>
            <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
          </button>
          <p>Cargando...</p>
        </div>)
    } else {
      return (
        <div className="container-fluid px-3 mt-2">
        <ReactTooltip />
        <ClinicHistoryModal id={this.state.modal_hist_clinica_id} opened={this.state.modal_hist_clinica} handleModal={this.handleHistClinicaModal} switchStage={this.switchStage} />
        <div className="row">
                <div className="col-12">
                  <h1 style={{fontWeight: 700}} className="text-primary">{this.state.title}</h1>
                </div>
                {this.state.cards.map((card,index) => {
                  return <div className="col-3 mt-4 d-flex flex-row justify-content-between" key={`card_${index}`}>
                  <div className="d-flex flex-row">
                    <div className="row shadow py-2 mx-2 rounded">
                      <div className="col-4"><h2 className="justify-content-center align-middle text-center m-0">{card.value}</h2></div>
                      <div className="col-8"><p className="justify-content-center align-middle m-0">{card.title}</p></div>
                    </div>
                  </div>
                </div>
                })}
              </div>
              <div className="row mt-5">
              {this.state.country_filter && <div className="col-12">
                <ul className="d-flex flex-row justify-content-end p-0" style={{listStyleType: 'none'}}>
                  <li style={{marginRight: '15px'}}><a className="text-primary cursor-pointer fs-6 btn disabled border-0 m-0" href="#">Argentina</a></li>
                  <li style={{marginRight: '15px'}}><a className="text-primary cursor-pointer fs-6 btn border-0 m-0" href="#" onClick={() => this.setState({type: 2})}>Chile</a></li>
                  <li style={{marginRight: '15px'}}><a className="text-primary cursor-pointer fs-6 btn border-0 m-0" href="#" onClick={() => this.setState({type: 2})}>Uruguay</a></li>
                  <li style={{marginRight: '15px'}}><a className="text-primary cursor-pointer fs-6 btn border-0 m-0" href="#" onClick={() => this.setState({type: 2})}>Paraguay</a></li>
                  <li style={{marginRight: '15px'}}><a className="text-primary cursor-pointer fs-6 btn border-0 m-0" href="#" onClick={() => this.setState({type: 2})}>Peru</a></li>
                  <li style={{marginRight: '15px'}}><a className="text-primary cursor-pointer fs-6 btn border-0 m-0" href="#" onClick={() => this.setState({type: 2})}>Colombia</a></li>
                </ul>
              </div>}
              <div className="col-12">
                <input type="text" className="w-100 border-0 p-3" placeholder="Escribe al menos 3 caracteres..." onChange={this.handleInputChange}/>
              </div>
            </div>
            {/* <!-- <div className="d-flex justify-content-center">
            <div className="d-flex flex-col flex-column" style={{width:'99%'}}> --> */}
            <div className="row mt-3" >
                {this.state.table.head.map((header, index) => <div key={`head_${index}`} className={`${header==='ID'?'col-half':(header==='Paciente' || header==='Doctor'?'col-2':'col')} d-flex flex-row justify-content-center`}><p className="text-primary fw-bold">{header}</p></div>)}
            </div>
            {this.state.high_priority.map((item, i) => {
                let asd = Object.entries(item)
                let actions = asd.pop()
                let id = item['id']
                let type = this.handleTreatmentColor(item['type'])
                console.log("item high_priority",item)

                return (
                <div key={`high_${i}`} className="row w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container" style={{position: 'relative'}}>
                {item.high && <div style={{position: 'absolute', left: '20px', top: '25px'}}><ArrowUp size={18} color="#e74c3c" /></div>}
                  
                  <button className={`clinic-history-button type-${type}`} onClick={() => this.setState({modal_hist_clinica: !this.state.modal_hist_clinica, modal_hist_clinica_id: id})} ></button>
                  {
                    asd.filter(without=> without[0]!=='high').map((subitem, index) => {
                      return (
                          subitem[0] !== "history" && 
                          <div key={`body_hig_${index}`} 
                          className={`${subitem[0]==='id'?'col-half justify-content-end':(subitem[0]==='patient' || subitem[0]==='dentist'?'col-2 force-inline-text justify-content-center':'col justify-content-center')} d-flex flex-row text-center`}>
                            <p title={subitem[0] === 'status' ? (item.history.user||'') + '&#13;' + (item.history.date||'') : null} className="m-0 text-center justify-items-middle" style={{fontSize: '.8rem', lineHeight: '35px'}}>{subitem[1]}</p></div>
                      )
                    })}
                  <div className="col d-flex flex-row justify-content-center">{Object.entries(actions[1]).map((button, index) => {
                    const CustomButton = this.components(button[1].name)
                    const path = window.location.pathname.split("/")[2]
                    return (
                      <div>
                    {path === 'aprobacion' &&
                      <button key={`btn_high_${index}`} className={`btn p-0`} style={{marginLeft: '5px'}} onClick={() => this.handleButtonType({button: button[1], id: item['id']})}><CustomButton color={button[1].color} size={30} /></button>
                    }
                    {path !== 'aprobacion' &&
                      <div key={`btn_high_${index}`} className={`btn p-0`} style={{marginLeft: '5px'}} onClick={() => {this.handleButtonType({button: button[1], id: item['id']}); console.log(button[1], ' button')}}><CustomButton color={button[1].color} value={button[1].name ? button[1].name : null} /></div>
                    }
                    </div>
                    )
                    // return <button key={`btn_hig_${index}`} className={`btn p-0`} style={{marginLeft: '5px'}} onClick={() => this.handleButtonType({button: button[1], id: item['id']})}>{this.handleButtonIcon(button[1].name, button[1].color, button[1].name)}</button>
                  })}</div>
                  <Modals key={i} handleModal={this.handleModal} patient={item['patient']} doctor={item['dentist']} maxilla={item['maxilla_string']} registro={item['intraoral']} id={id} opened={this.state.modal_id}/>
                </div>
              )})}
              {this.state.low_priority.map((item, i) => {
                let asd = Object.entries(item)
                let actions = asd.pop()
                let id = item['id']
                let type = this.handleTreatmentColor(item['type'])
                console.log("item low_priority",item)

                return (
                <div key={`low_${i}`} className="grow_hover w-100 row d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container" style={{position: 'relative'}}>
                {item.high && <div style={{position: 'absolute', left: '20px', top: '25px'}}><ArrowUp size={18} color="#e74c3c" /></div>}
                  
                  <button className={`clinic-history-button type-${type}`} onClick={() => this.setState({modal_hist_clinica: !this.state.modal_hist_clinica, modal_hist_clinica_id: id})} ></button>
                  {
                    asd.filter(without=> without[0]!=='high').map((subitem,index) => {
                      return (
                          subitem[0] !== "history" && <div key={`body_low_${index}`} 
                          className={`${subitem[0]==='id'?'col-half justify-content-end':(subitem[0]==='patient' || subitem[0]==='dentist'?'col-2 force-inline-text justify-content-center':'col justify-content-center')} d-flex flex-row text-center`}>
                          <p data-tip={subitem[0] === 'status' ? (item.history.user || '') + ' ' + (item.history.date || '') : null} className="m-0 text-center justify-items-middle" style={{fontSize: '.8rem', lineHeight: '35px'}}>
                            {subitem[1]}</p>
                              </div>
                      )
                    })}
                  <div className="col d-flex flex-row justify-content-center">{Object.entries(actions[1]).map((button,index) => {
                    const CustomButton = this.components(button[1].name)
                    const path = window.location.pathname.split("/")[2]
                    return (
                      <div>
                    {path === 'aprobacion' &&
                      <button key={`btn_low_${index}`} className={`btn p-0`} style={{marginLeft: '5px'}} onClick={() => this.handleButtonType({button: button[1], id: item['id']})}><CustomButton color={button[1].color} size={30} /></button>
                    }
                    {path !== 'aprobacion' &&
                      <div key={`btn_low_${index}`} className={`btn p-0`} style={{marginLeft: '5px'}} onClick={() => {this.handleButtonType({button: button[1], id: item['id']}); console.log(item['id'])}}><CustomButton color={button[1].color} value={button[1].name ? button[1].name : null} /></div>
                    }
                    </div>
                    )
                    // if(this.state.origin === 'aprobacion')
                    //   return <button key={`btn_low_${index}`} className={`btn p-0`} style={{marginLeft: '5px'}} onClick={() => this.handleButtonType({button: button[1], id: item['id']})}>{this.handleButtonIcon(button[1].name, button[1].color, button[1].name)}</button>
                    // else
                    //   return <div key={`btn_low_${index}`} className={`btn p-0`} style={{marginLeft: '5px'}} onClick={() => this.handleButtonType({button: button[1], id: item['id']})}>{this.handleButtonIcon(button[1].name, button[1].color, button[1].name)}</div>
                  })}</div>
                  <Modals key={i} handleModal={this.handleModal} patient={item['patient']} doctor={item['dentist']} maxilla={item['maxilla_string']} registro={item['intraoral']} id={id} opened={this.state.modal_id}/>
                </div>
              )})}
            </div>
        //     </div>
        // </div>
  )
      }

  }
}

const mapStateToProps = (state) => ({state})

export default connect(mapStateToProps)(index)
