import React from "react";
import apiClient from "../utils/backend";
import { connect } from "react-redux";
import {
  LogIn,
  Home,
  BookOpen,
  Folder,
  PieChart,
  CreditCard,
  Smile,
  Users,
  Cpu,
  FilePlus,
  Archive,
  HelpCircle,
  Clipboard,
} from "react-feather";

import logo from "../assets/images/logo-ks.svg";
import smile from "../assets/images/ks-smile.svg";
import { withRouter } from "react-router-dom";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: this.props.collapsed,
      items: [],
      item_selected: {},
      dropdown: false,
    };
  }

  componentDidMount() {
    apiClient
      .get("api/helper/getSidebarMenu")
      .then((res) => {
        this.setState({ items: res.data });
      })
      .catch((err) => console.error(err));
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.item_selected) {
      if (
        (this.state.item_selected.level === 1 &&
          this.state.item_selected.ord >= 100 &&
          this.state.item_selected.name === "Centro de Ayuda") ||
        this.state.item_selected.level > 1 ||
        (this.state.item_selected.level === 1 &&
          this.state.item_selected.ord < 100)
      ) {
        if (
          this.state.item_selected.route.indexOf("centrodeayuda") > -1 &&
          this.state.item_selected.level > 1
        ) {
          this.props.history.push({
            pathname: this.state.item_selected.route,
            state: {
              route: this.state.item_selected.route.split("/")[2],
              idParent: 1,
              parent: { name: this.state.item_selected.name, body: null },
            },
          });
        } else {
          this.props.history.push({
            pathname: this.state.item_selected.route,
          });
        }
        this.setState({ ...this.state, item_selected: null });
      }
    }
  }

  handleSidebarIcon(name) {
    const Components = {
      Ingresos: LogIn,
      Pagos: CreditCard,
      Odontologos: Smile,
      Pacientes: Users,
      Procesos: Cpu,
      Reclamos: BookOpen,
      Usuarios: Users,
      'Mis Pacientes': Users,
      'Nueva Solicitud':FilePlus,
      Consultas:Archive,
      'Centro de Ayuda':HelpCircle,
      Home: Home,
      Tratamientos:Clipboard
    };

    if (Components[name]) {
      const Component = Components[name];

      return (
        <Component
          strokeWidth="1.5px"
          size="22"
          style={{ marginBottom: "5px", marginRight: "10px" }}
        />
      );
    } else {
      return (
        <PieChart
          strokeWidth="1.5px"
          size="22"
          style={{ marginBottom: "5px", marginRight: "10px" }}
        />
      );
    }
  }

  render() {
    let path = window.location.href;

    return (
      <div
        id="sidebar"
        className={this.props.collapsed === true ? "collapsed" : ""}
        style={{ overflowX: "hidden" }}
      >
        <div className="w-100 d-flex flex-row justify-content-center">
          {this.props.collapsed ? (
            <img
              src={smile}
              alt="KeepSmiling logo"
              style={{ padding: "5px", marginTop: "30px", maxWidth: "60px" }}
            />
          ) : (
            <img
              src={logo}
              alt="KeepSmiling logo"
              style={{ padding: "5px", marginTop: "30px", maxWidth: "120px" }}
            />
          )}
        </div>
        <div className="row sidebar-row">
          {this.state.items.map((item, i) => {
            const itemord = Number(item.ord);
            if( item.status === 1 ){

              if (item.level === 1 && itemord >= 100) {
                const subitems = this.state.items.filter(
                  (item) =>
                    item.level === 2 &&
                    item.ord > itemord &&
                    item.ord < itemord + 99
                );
                return (
                  <div
                    className="col-12 sidebar-item"
                    id={item.id}
                    key={"parent_" + i}
                  >
                    <label
                      className={`btn text-white sidebar-drop-toggle ${
                        this.props.collapsed ? "px-4" : ""
                      } ${this.state.item_selected === item ? "toggled" : ""}`}
                    >
                      <span
                        onClick={() => {
                          this.state.item_selected === item
                            ? this.setState({
                                ...this.state,
                                item_selected: null,
                              })
                            : this.setState({
                                ...this.state,
                                item_selected: item,
                              });
                        }}
                      >
                        {this.handleSidebarIcon(item.name)}
                        {this.props.collapsed ? null : item.name}
                      </span>
                      <div className="d-flex flex-column sidebar-drop">
                        {subitems.map((sub_item, i) => {
                          return (
                            <label
                              key={"child_" + i}
                              className={`btn text-white ${
                                this.props.collapsed ? "px-4" : ""
                              }`}
                              onClick={() =>
                                this.setState({
                                  ...this.state,
                                  item_selected: sub_item,
                                })
                              }
                            >
                              {this.handleSidebarIcon(sub_item.name)}
                              {this.props.collapsed ? null : sub_item.name}
                            </label>
                          );
                        })}
                      </div>
                    </label>
                  </div>
                );
              } else if (item.level === 1) {
                return (
                  <div
                    className="col-12 sidebar-item"
                    id={item.id}
                    key={"parent_" + i}
                  >
                    <label
                      className={`btn text-white ${
                        this.props.collapsed ? "px-4" : ""
                      }`}
                      onClick={() =>
                        this.setState({ ...this.state, item_selected: item })
                      }
                    >
                      {this.handleSidebarIcon(item.name)}
                      {this.props.collapsed ? null : item.name}
                    </label>
                  </div>
                );
              }
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collapsed: state.navbarReducer.collapsed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    collapse: () => {
      dispatch({ type: "COLLAPSE" });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));
