import React, {Fragment} from 'react'

function HeaderPlacasRepeticion() {
    return (
        <Fragment>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Paciente</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Cantidad de placas</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Costo Final</p>
            </div>
            <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold">Detalle</p>
            </div>
        </Fragment>
    )
}

export default HeaderPlacasRepeticion