import React from 'react'

export default function Paginator ({paginator, getPaginator}) {
    return (
           <div className="col-12 d-flex flex-row justify-content-end">         
              <nav aria-label="Page navigation example">
                <ul class="pagination">

                  <li class="page-item">
                    <button class="page-link"  aria-label="Previous" onClick={() =>{
                       if(paginator.prev_page >= 1) getPaginator(paginator.prev_page)
                    }}>
                      <span aria-hidden="true">&laquo;</span>
                    </button>
                  </li>

                  {paginator?.previous?.map((pageNumber,index) => (
                    <li
                      key={index}
                      class="page-item"
                      onClick={() =>  getPaginator(pageNumber)  }
                    >
                      <button class="page-link" >
                        {pageNumber}
                      </button>
                    </li>
                  ))}

                  <li class="page-item active">
                    <button class="page-link active" >
                      {paginator?.current}
                    </button>
                  </li>

                  {paginator?.next?.map((pageNumber,index) => (
                    <li
                      key={index}
                      class="page-item"
                      onClick={() =>  getPaginator(pageNumber)}
                    >
                      <button class="page-link">
                        {pageNumber}
                      </button>
                    </li>
                  ))}


                  <li class="page-item">
                    <button class="page-link" aria-label="Next"  onClick={()=>{
                      if(paginator.next_page) getPaginator(paginator.next_page)                
                    }}>
                      <span aria-hidden="true">&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
    )
}