import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import apiClient from '../../../utils/backend'
import { useHistory } from 'react-router'

import PatientData from '../../../components/patient_data'
import { connect } from 'react-redux';

const LaboratoryModal = (props) => {
  const {
    className,
    id,
    opened,
    handleModal
  } = props;


  const [contentLoading, setContentLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState()
  const [observation, setObservation] = useState('')
  const history = useHistory()

  const [checked, setChecked] = useState()
  const [top, setTop] = useState([])
  const [bot, setBot] = useState([])

  useEffect(() => {
    handleGetData()
    let t = []
    let b = []

    /*for(let i = 0; i <= 12; i++){
      let tobj = {["top" + i]: false}
      t.push(tobj)

      let bobj = {["bottom" + i]: false}
      b.push(bobj)
    }
    console.log(b, t)
    setTop(t)
    setBot(b)*/
  }, [])

  function handleGetData() {
    console.log(props)

     apiClient.get(`/api/helper/getClinicalHistory/${props.id}`, { withCredentials: true, headers: { Authorization: `Bearer ${props.userReducer.login_token}` } })
      .then(res => {
        setData(res.data.data)
      })
      .then(res => setContentLoading(false))
      .catch(err => console.log(err))
  }

  function handleButtonClick({id, type}){
    if(type === 'top') {
      if(top.includes(id)) {
        let array = [...top];
        let index = array.indexOf(id)

        array.splice(index, 1);
        setTop(array)
      } else {
        let joined = top.concat(id);

        setTop(joined)
      }
    } else {
      if(bot.includes(id)) {
        let array = [...bot];
        let index = array.indexOf(id)

        array.splice(index, 1);
        setBot(array)
      } else {
        let joined = bot.concat(id);

        setBot(joined)
      }
    }
    console.log(top)
  }

  function handleSendData(){
    apiClient.post('/api/status/nextStatus', {treatment_id: id, process: 'ControlLaboratorio', incompleted: checked === 0, top: top, bot: bot, observation})
        .then(res => res.status === 200 ? window.location.reload() : console.log(res))
        .catch(err => console.log(err))
  }

  if(contentLoading){
    return (
      <div>
        <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div className="container">
              {/* <PatientData id={id} created_at={data.created_at} dentist={data.dentist} patient={data.patient} maxilla={data.maxilla_string} profile={data.documentation[0].files[0]}  /> */}
              <h6 className="mb-3 mt-1 fw-bold text-primary">Control de calidad laboratorio</h6>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-row">
                    <input type="checkbox" name="pass" id="pass" onClick={() => setChecked(1)} checked={checked === 1 ? true : false} />
                    <label htmlFor="pass" className="mx-2 mb-3 text-primary" >El caso pasa el control de calidad completo</label>
                  </div>
                  <div className="d-flex flex-row">
                    <input type="checkbox" name="nopass" id="nopass" onClick={() => setChecked(0)} checked={checked === 0 ? true : false}  />
                    <label htmlFor="nopass" className="mx-2 mb-3 text-primary" >El caso pasa el control de calidad incompleto</label>
                  </div>
                </div>
              </div>

              {
                checked === 0 &&
                <div className="w-90 m-auto">
                  <small className="mt-3">Se pide repeticion de las siguientes placas:</small>
                  <div className="w-100 mt-2">
                    <div className="d-flex flex-row">
                      <small htmlFor="" className="m-0 pt-1">SUPERIOR:</small>
                      <button onClick={() => handleButtonClick({id: 0, type: 'top'})} className={ top.includes(0) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >00</button>
                      <button onClick={() => handleButtonClick({id: 1, type: 'top'})} className={ top.includes(1) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >01</button>
                      <button onClick={() => handleButtonClick({id: 2, type: 'top'})} className={ top.includes(2) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >02</button>
                      <button onClick={() => handleButtonClick({id: 3, type: 'top'})} className={ top.includes(3) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >03</button>
                      <button onClick={() => handleButtonClick({id: 4, type: 'top'})} className={ top.includes(4) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >04</button>
                      <button onClick={() => handleButtonClick({id: 5, type: 'top'})} className={ top.includes(5) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >05</button>
                      <button onClick={() => handleButtonClick({id: 6, type: 'top'})} className={ top.includes(6) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >06</button>
                      <button onClick={() => handleButtonClick({id: 7, type: 'top'})} className={ top.includes(7) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >07</button>
                      <button onClick={() => handleButtonClick({id: 8, type: 'top'})} className={ top.includes(8) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >08</button>
                      <button onClick={() => handleButtonClick({id: 9, type: 'top'})} className={ top.includes(9) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >09</button>
                      <button onClick={() => handleButtonClick({id: 10, type: 'top'})} className={ top.includes(10) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >10</button>
                      <button onClick={() => handleButtonClick({id: 11, type: 'top'})} className={ top.includes(11) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >11</button>
                      <button onClick={() => handleButtonClick({id: 12, type: 'top'})} className={ top.includes(12) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >12</button>
                    </div>
                  </div>
                  <div className="col-12 mt-2">
                    <div className="d-flex flex-row">
                      <label htmlFor="" className="m-0 pt-1" style={{fontSize: '.9rem'}} >INFERIOR: </label>
                      <button onClick={() => handleButtonClick({id: 0, type: 'bot'})} className={ bot.includes(0) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >00</button>
                      <button onClick={() => handleButtonClick({id: 1, type: 'bot'})} className={ bot.includes(1) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >01</button>
                      <button onClick={() => handleButtonClick({id: 2, type: 'bot'})} className={ bot.includes(2) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >02</button>
                      <button onClick={() => handleButtonClick({id: 3, type: 'bot'})} className={ bot.includes(3) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >03</button>
                      <button onClick={() => handleButtonClick({id: 4, type: 'bot'})} className={ bot.includes(4) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >04</button>
                      <button onClick={() => handleButtonClick({id: 5, type: 'bot'})} className={ bot.includes(5) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >05</button>
                      <button onClick={() => handleButtonClick({id: 6, type: 'bot'})} className={ bot.includes(6) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >06</button>
                      <button onClick={() => handleButtonClick({id: 7, type: 'bot'})} className={ bot.includes(7) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >07</button>
                      <button onClick={() => handleButtonClick({id: 8, type: 'bot'})} className={ bot.includes(8) ===true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >08</button>
                      <button onClick={() => handleButtonClick({id: 9, type: 'bot'})} className={ bot.includes(9) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >09</button>
                      <button onClick={() => handleButtonClick({id: 10, type: 'bot'})} className={ bot.includes(10) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >10</button>
                      <button onClick={() => handleButtonClick({id: 11, type: 'bot'})} className={ bot.includes(11) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >11</button>
                      <button onClick={() => handleButtonClick({id: 12, type: 'bot'})} className={ bot.includes(12) === true ? "btn btn-primary p-0 mx-1" : "btn btn-outline-primary p-0 mx-1"} style={{fontSize: '.7rem', borderRadius: '5px', width: '30px', height: '25px', padding: '5px'}} >12</button>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <label htmlFor="observations">Observación:</label>
                      <textarea value={observation} onChange={(e)=>setObservation(e.target.value)} className="rounded" name="observations" id="observations" cols="60" rows="5"></textarea>
                    </div>
                  </div>
                </div>
              }

            </div>
          </ModalBody>
          <ModalFooter>
  
            {!loading &&
              <div>
                <button className="rounded-pill btn border btn-light text-dark mx-2" onClick={handleModal}>Volver</button>
                <button className="rounded-pill btn btn-success text-white" onClick={() => handleSendData()}>Guardar</button>
              </div>
            }
  
            {loading &&
              <button class="btn btn-success" type="button" disabled>
                <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
              </button>
            }
  
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(LaboratoryModal)