import { useEffect } from 'react'

export const useErrorList=(errors_list, updateErrors, fieldReference)=>{
    useEffect(()=>{
        if(!fieldReference) return;
        console.log('useeffect in hooks', {ref:fieldReference[0], err:errors_list.find(err=> err.order===1)})
        if(fieldReference[0] && errors_list.find(err=> err.order===1)){
            updateErrors(errors_list.filter(err=> err.order!==1))
        }
        if(fieldReference[1] && errors_list.find(err=> err.order===2)){
            updateErrors(errors_list.filter(err=> err.order!==2))
        }
        if(fieldReference[2] && errors_list.find(err=> err.order===3)){
            updateErrors(errors_list.filter(err=> err.order!==3))
        }
        if(fieldReference[3] && errors_list.find(err=> err.order===4)){
            updateErrors(errors_list.filter(err=> err.order!==4))
        }
        if(fieldReference[4] && errors_list.find(err=> err.order===5)){
            updateErrors(errors_list.filter(err=> err.order!==5))
        }
        if(fieldReference[5] && errors_list.find(err=> err.order===6)){
            updateErrors(errors_list.filter(err=> err.order!==6))
        }
        if(fieldReference[6] && errors_list.find(err=> err.order===7)){
            updateErrors(errors_list.filter(err=> err.order!==7))
        }
    },[fieldReference, updateErrors, errors_list])
}