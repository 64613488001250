import React, { Component } from "react";
import { connect } from "react-redux";
import apiClient from "../../utils/backend";
import * as qs from "query-string";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Loader from "./components/loader";

import logo from "../../assets/images/ks-smile.png";
import "react-toastify/dist/ReactToastify.css";
import { ChevronLeft } from "react-feather";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: "login",
      email: null,
      password: null,
      error: {
        error: false,
        message: "",
      },
      redirect: false,
      password1: null,
      password2: null,
      password_format_error: false,
      password_match: null,
      recover_email: null,
      loading: true
    }

    this.login = this.login.bind(this);
    this.handleRecoverPassword = this.handleRecoverPassword.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handlePasswordValidation = this.handlePasswordValidation.bind(this);
  }

  componentDidMount() {
    const path = window.location.pathname.split("/");

    if(path.indexOf('passwordUpdate') !== -1 && path.length > 2){
      let token = path[2]
      apiClient.get(`/api/helper/checkPasswordToken?token=${token}`)
      .then(res => {
        if(res.status === 502){
          window.location.href = '/login'
          console.log(res)
        } else {
          this.setState({loading: false, recover_email: res.data, token: token})
          console.log(res)
        }
      })
      .catch(err => {console.log(err)
        window.location.href = '/login'
      })
    } else {
      this.setState({ loading: false });
    }
  }

  login = async (cb) => {
    await apiClient
      .post("api/login", {
        email: this.state.email,
        password: this.state.password,
      })
      .then((res) => {
        if (res.data.data.token !== undefined) {
          let d = res.data.data;
          localStorage.setItem("lgt", d.token);
          localStorage.setItem("user_id", d.id);
          localStorage.setItem("profile", d.profile_id);
          this.props.login({
            id: d.id,
            token: d.token,
            email: d.email,
            gender: d.gender,
            name: d.name,
            surname: d.surname,
            profile: d.profile_id,
            birthdate: d.birthdate,
            smilecreator: d.dentist?.smile_creator_type,
            smilecreator_points: d.dentist?.smile_creator_points,
          });
          this.handleRedirect(d.type[0].title);
        } else {
          this.setState({
            error: {
              error: true,
              message: "Error en el login, por favor intente nuevamente.",
            },
          });
          console.log(res);
        }
      })
      .catch((err) => console.log(err));
  };

  handleRecoverPassword() {
    apiClient
      .post(`/api/forgotPassword/?email=${this.state.email}`)
      .then((res) => {
        console.log(res);
        toast.success(
          "Le enviamos un email a su casilla de correo para reestablecer la contraseña. 👍",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("🤔 Hubo un error, por favor, vuelva a intentar.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handlePasswordValidation() {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm;
    let match = regex.test(this.state.password1);

    if (this.state.password1 !== this.state.password2) {
      this.setState({ password_match: false });
    } else {
      this.setState({ password_match: true });
    }

    if (!match) {
      this.setState({ password_format_error: true });
    } else {
      this.setState({ password_format_error: false });
    }
  }

  handleSetPassword(){
    if(this.state.password1 === this.state.password2){
      apiClient.post(`/api/changePassword?password=${this.state.password1}&email=${this.state.recover_email}&token=${this.state.token}`)
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  handleRedirect(type){
    //this.props.history.push('/')
    if(type === "Administrador") {
      window.location.href = "/admin/home"
    } else{
      window.location.href="/od/home"
    }
  }

  render() {
    const path = window.location.pathname.split("/");

    if (this.state.loading) {
      return (
        <main className="h-100">
          <div className="container-fluid h-100">
            <div className="row h-100 align-middle">
              <div
                className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
                style={{ minHeight: "100%", alignItems: "center" }}
              >
                <div className="px-lg-3" style={{ marginTop: "10em" }}>
                  <Loader />
                </div>
              </div>
              <div className="col-4 bg-primary px-5">
                <div
                  className="w-75 d-flex flex-column"
                  style={{ marginTop: "15rem" }}
                >
                  <h1 className="text-white fw-bold mb-3">
                    Somos creadores de Sonrisas
                  </h1>
                  <img
                    src={logo}
                    alt="keepsmiling logo"
                    className="img img-fluid w-75"
                  />
                </div>
              </div>
            </div>
          </div>
      </main>)
    } else{ 
      if(path.indexOf('passwordUpdate') !== -1) {
        if(path.length > 2) {
  
          return (
            <main className="h-100">
              <div className="container-fluid h-100">
                <div className="row h-100 align-middle">
                  <div
                    className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
                    style={{ minHeight: "100%", alignItems: "center" }}
                  >
                    <div className="px-lg-3" style={{ marginTop: "10em" }}>
                      <div className="w-100 mb-5">
                        <h1 className="fw-bold text-primary mx-5">
                          Nueva contraseña
                        </h1>
                      </div>
                      {this.state.error.error && (
                        <div
                          class="alert alert-danger text-center"
                          role="alert"
                        >
                          {this.state.error.message}
                        </div>
                      )}
                      <form action="">
                        <div className="mb-3 mx-5">
                          <label htmlFor="password1" className="form-label">
                            Contraseña
                          </label>
                          <input
                            type="password"
                            name="password1"
                            onChange={(e) => {
                              this.handlePasswordValidation();
                              this.handleInputChange(e);
                            }}
                            className="form-control"
                            id="password1"
                            placeholder="********"
                          />
                        </div>
                        <div className="mb-3 mx-5">
                          <label htmlFor="password2" className="form-label">Repita la contraseña</label>
                          <input type="password" name="password2" onChange={(e) => {this.handlePasswordValidation(); this.handleInputChange(e)}} className={`${!this.state.password_match && ''} form-control`} id="password2" placeholder="********" />
                          {/* {!this.state.password_match && <p className="text-danger">Las contraseñas no coinciden.</p>} */}
                          {this.state.password_format_error && <p className="text-danger">Error en el formato de la contraseña.</p>}
                        </div>
                      </form>
                      <div className="w-100 d-flex justify-content-center mt-5">
                        <button className="btn btn-primary rounded-pill px-5 w-50" onClick={() => this.handleSetPassword(this.state.password)} >Enviar</button>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4 bg-primary px-5">
                    <div
                      className="w-75 d-flex flex-column"
                      style={{ marginTop: "15rem" }}
                    >
                      <h1 className="text-white fw-bold mb-3">
                        Somos creadores de Sonrisas
                      </h1>
                      <img
                        src={logo}
                        alt="keepsmiling logo"
                        className="img img-fluid w-75"
                      />
                    </div>
                  </div>
                </div>
            </main>
          );
        } else {
          window.location.href = "/login";
        }
      } else {
        return (
          // LOGIN - RECUPERAR CONTRASEÑA
          <main
            className="h-100"
            onKeyPress={(e) => {
              e.key === "Enter" &&
                this.login(this.state.email, this.state.password);
            }}
          >
            <div className="container-fluid h-100">
              <div className="row h-100 align-middle">
                <div
                  className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
                  style={{ minHeight: "100%", alignItems: "center" }}
                >
                  {this.state.view === "login" && (
                    <div className="px-lg-3" style={{ marginTop: "10em" }}>
                      <div className="w-100 mb-5">
                        <h1 className="fw-bold text-primary mx-5">
                          KeepSmiling
                        </h1>
                      </div>
                      {this.state.error.error ? (
                        <div
                          class="alert alert-danger text-center"
                          role="alert"
                        >
                          {this.state.error.message}
                        </div>
                      ) : null}

                      <Formik
                        initialValues={{ email: "", password: "" }}
                        validate={(values) => {
                          const errors = {};
                          if (!values.email) {
                            errors.email = "Campo requerido";
                          } else if (
                            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              values.email
                            )
                          ) {
                            errors.email = "Email inválido";
                          }

                          if (!values.password) {
                            errors.password = "Campo requerido";
                          }

                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          this.setState({
                            email: values.email,
                            password: values.password,
                          });
                          this.login((success) => {
                            if (!success) {
                              setSubmitting(false);
                            }
                          });
                        }}
                      >
                        {({ isSubmitting, errors }) => (
                          <Form className="mx-5">
                            <label htmlFor="email">Email</label>
                            <Field
                              type="email"
                              name="email"
                              className={`${
                                errors.email
                                  ? "form-control border border-1 border-danger rounded-3 mb-1"
                                  : "form-control"
                              }`}
                            />
                            <ErrorMessage
                              className="text-danger"
                              name="email"
                              component="div"
                            />
                            <label htmlFor="password" className="mt-2">
                              Contraseña
                            </label>
                            <Field
                              type="password"
                              name="password"
                              className={`${
                                errors.password
                                  ? "form-control border border-1 border-danger rounded-3 mb-1"
                                  : "form-control"
                              }`}
                            />
                            <ErrorMessage
                              className="text-danger"
                              name="password"
                              component="div"
                            />
                            <div className="w-100 d-flex justify-content-center mt-3">
                              <button
                                className="btn text-muted"
                                onClick={() =>
                                  this.setState({ view: "reset_password" })
                                }
                              >
                                ¡Olvidé mi contraseña!
                              </button>
                            </div>
                            <div className="w-100 d-flex justify-content-center mt-5">
                              {!isSubmitting && (
                                <button
                                  disabled={errors.email || errors.password}
                                  type="submit"
                                  className="btn btn-primary rounded-pill px-5 w-50"
                                >
                                  Ingresar
                                </button>
                              )}
                              {isSubmitting && (
                                <button
                                  className="btn btn-primary rounded-pill px-5 w-50"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </button>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}

                  {this.state.view === "reset_password" && (
                    <div>
                    <div className="px-lg-3" style={{ marginTop: "10em" }}>
                      <div className="w-100 mb-5">
                        <a
                          href="#"
                          className="mx-5 mb-2"
                          onClick={() => this.setState({ view: "login" })}
                        >
                          <small className="text-muted cursor-pointer">
                            <ChevronLeft size={14} /> Volver
                          </small>
                        </a>
                        <h1 className="fw-bold text-primary mx-5">
                          Recuperar contraseña
                        </h1>
                      </div>
                      {this.state.error.error ? (
                        <div
                          class="alert alert-danger text-center"
                          role="alert"
                        >
                          {this.state.error.message}
                        </div>
                      ) : null}
                      <form action="">
                        <div className="mb-3 mx-5">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Email o usuario
                          </label>
                          <input
                            type="email"
                            name="email"
                            onChange={(e) => this.handleInputChange(e)}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="ejemplo@keepsmiling.com.ar"
                          />
                        </div>
                      </form>
                      <div className="w-100 d-flex justify-content-center mt-5">
                        <button
                          className="btn btn-primary rounded-pill px-5 w-50"
                          onClick={() => this.handleRecoverPassword()}
                        >
                          Enviar
                        </button>
                      </div>
                    </div>
                    {this.state.error.error && (<div class="alert alert-danger text-center" role="alert">{this.state.error.message}</div>) }
  
  
                    <Formik
                      initialValues={{ email: '', password: '' }}
                      validate={values => {
                        const errors = {};
                        if (!values.email) {
                          errors.email = 'Campo requerido';
                        } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                        ) {
                          errors.email = 'Email inválido';
                        }
  
                        if(!values.password){
                          errors.password = 'Campo requerido'
                        }
  
                        return errors;
                      }}
                      onSubmit={({ values, setSubmitting }) => {
                          this.setState({email: values.email, password: values.password})
                          this.login((success) => {
                            if (!success) {
                              setSubmitting(false);
                            }
                          });
                        }}
                      >
                        {({ isSubmitting, errors }) => (
                          <Form className="mx-5">
                            <label htmlFor="email">Email</label>
                            <Field
                              type="email"
                              name="email"
                              className={`${
                                errors.email
                                  ? "form-control border border-1 border-danger rounded-3 mb-1"
                                  : "form-control"
                              }`}
                            />
                            <ErrorMessage
                              className="text-danger"
                              name="email"
                              component="div"
                            />
                            <label htmlFor="password" className="mt-2">
                              Contraseña
                            </label>
                            <Field
                              type="password"
                              name="password"
                              className={`${
                                errors.password
                                  ? "form-control border border-1 border-danger rounded-3 mb-1"
                                  : "form-control"
                              }`}
                            />
                            <ErrorMessage
                              className="text-danger"
                              name="password"
                              component="div"
                            />
                            <div className="w-100 d-flex justify-content-center mt-3">
                              <button
                                className="btn text-muted"
                                onClick={() =>
                                  this.setState({ view: "reset_password" })
                                }
                              >
                                ¡Olvidé mi contraseña!
                              </button>
                            </div>
                            <div className="w-100 d-flex justify-content-center mt-5">
                              {!isSubmitting && (
                                <button
                                  disabled={errors.email || errors.password}
                                  type="submit"
                                  className="btn btn-primary rounded-pill px-5 w-50"
                                >
                                  Ingresar
                                </button>
                              )}
                              {isSubmitting && (
                                <button
                                  className="btn btn-primary rounded-pill px-5 w-50"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span class="visually-hidden">
                                    Loading...
                                  </span>
                                </button>
                              )}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  )}
                </div>
                <div className="col-4 bg-primary px-5">
                  <div
                    className="w-75 d-flex flex-column"
                    style={{ marginTop: "15rem" }}
                  >
                    <h1 className="text-white fw-bold mb-3">
                      Somos creadores de Sonrisas
                    </h1>
                    <img
                      src={logo}
                      alt="keepsmiling logo"
                      className="img img-fluid w-75"
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>
        )
      }
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: ({
      token,
      email,
      name,
      surname,
      profile,
      id,
      smilecreator,
      smilecreator_points,
      gender,
      birthdate,
    }) => {
      dispatch({
        type: "LOGIN",
        payload: {
          token,
          email,
          name,
          surname,
          profile,
          id,
          smilecreator,
          smilecreator_points,
          gender,
          birthdate,
        },
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(index);
