import React from 'react'
import DeleteModal from './delete_modal'
import EditModal from './edit_user_modal'

import ARG from '../../../assets/images/Argentina2.png'
import CHI from '../../../assets/images/Chile2.png'
import COL from '../../../assets/images/Colombia2.png'
import PAR from '../../../assets/images/Paraguay2.png'
import PER from '../../../assets/images/Peru2.png'
import URU from '../../../assets/images/Uruguay2.png'

export default function UserList(props) {

    function handleCountryFlag(name){
        const countrys = {
          1: ARG,
          2: URU,
          3: PAR,
          4: COL,
          5: CHI,
          6: PER,
    
        }
        return countrys[name]
      }

    return (
        <div className="row shadow my-1 p-1" style={{borderRadius: '5px'}}>
            <div className="col-2 px-3"><p title={props.name} className="text-primary text-center m-0 mt-2 text-truncate">{props.name} {props.surname}</p></div>
            <div className="col-3 px-3"><p title={props.email} className="text-primary text-center m-0 mt-2 text-truncate">{props.email}</p></div>
            <div className="col-2 px-3"><p title={props.sector.description} className="text-primary text-center m-0 mt-2 text-truncate">{props.sector.description}</p></div>
            <div className="col-1 px-3"><p title={props.position.description} className="text-primary text-center m-0 mt-2 text-truncate">{props.position.description}</p></div>
            <div className="col-2 px-3 d-flex justify-content-center"><img style={{height: '25px', marginLeft:'15px',marginTop: '5px'}} src={handleCountryFlag(props.country)} alt="" /></div>
            <div className="col-2 px-3 d-flex flex-row justify-content-end">
                <EditModal props={props} />
                <DeleteModal username={props.name + ' ' + props.surname} id={props.id} />
            </div>
        </div>
    )
}
