import React, {useState} from 'react'

export default function NotificationItem({data}) {

    const [step, setStep] = useState(1)


    function handleBarWidth(){
        let sizes = {
            1: 0,
            2: 25,
            3: 50,
            4: 75,
            5: 100,
            6: 100
        }

        return sizes[step]
    }

    return (
        <div className="w-100 overflow-hidden pt-2 px-0 border-bottom">
            <div className="row p-0 m-0">
                <div className="col-2">
                    <div className="rounded-circle border border-1 mx-auto" style={{width: '40px', height: '40px'}}></div>
                </div>
                <div className="col-9 d-flex flex-column">
                    <small style={{fontSize: '.7rem', marginTop: '5px'}}>{data.treatment.patient.full_name}</small>
                    <h6 className="fw-bold" style={{fontSize: '.9rem'}}>{data.notification.title}</h6>
                </div>
            </div>
            <div className="row mt-2 mb-3">
                <div className="col-2"></div>
                <div className="col-10">
                    <p className="text-muted" style={{fontSize: '.8rem', paddingLeft: '5px', paddingRight: '10px'}}>{data.notification.description}</p>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 p-0 d-flex flex-row justify-content-center mt-2 mb-2 position-relative">
                <ul
                    className="w-100"
                    style={{
                      background: "#ECEEF1",
                      height: "5px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      listStyleType: "none",
                      position: "relative",
                      maxWidth: '80%',
                      marginRight: '20px'
                    }}
                  >
                    {/* <li style={{width: '110%', height: '100%', border: '3px solid #ECEEF1', position: 'absolute', padding: '23px', top: '-20px', borderRadius: '30px', left: '-3%', right: '0'}}></li> */}
                    <li
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        background: step >= 1 ? "#2ecc71": "#ECEEF1",
                        position: "absolute",
                        top: "-7px",
                        zIndex: 10,
                        left: "0",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          top: "25px",
                          // width: "100px",
                          textAlign: "center",
                          left: "-7px",
                          fontWeight: "500",
                          fontSize: ".5rem",
                        }}
                      >
                        Alta de paciente
                      </p>
                    </li>
                    <li
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        background: step >= 3 ? "#2ecc71" : step === 2 ? "#D8BF6E" : "#cecece",
                        position: "absolute",
                        top: "-7px",
                        zIndex: 10,
                        left: "25%",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          top: "25px",
                          // width: "100px",
                          textAlign: "center",
                          left: "-7px",
                          fontWeight: "500",
                          fontSize: ".5rem",
                        }}
                      >
                        Documentación completa
                      </p>
                    </li>
                    <li
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        background: step >= 4 ? "#2ecc71" : "#ECEEF1",
                        position: "absolute",
                        top: "-7px",
                        zIndex: 10,
                        left: "50%",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          top: "25px",
                          // width: "100px",
                          textAlign: "center",
                          left: "-7px",
                          fontWeight: "500",
                          fontSize: ".5rem",
                        }}
                      >
                        Propuesta de tratamiento
                      </p>
                    </li>
                    <li
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        background: step > 4 ? "#2ecc71" : "#ECEEF1",
                        position: "absolute",
                        top: "-7px",
                        zIndex: 10,
                        left: "75%",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          top: "25px",
                          // width: "100px",
                          textAlign: "center",
                          left: "-7px",
                          fontWeight: "500",
                          fontSize: ".5rem",
                        }}
                      >
                        En producción de alineadores
                      </p>
                    </li>
                    <li
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "50%",
                        background: step > 5 ? "#2ecc71" : "#ECEEF1",
                        position: "absolute",
                        top: "-7px",
                        zIndex: 10,
                        left: "100%",
                      }}
                    >
                      <p
                        style={{
                          position: "absolute",
                          top: "25px",
                          // width: "100px",
                          textAlign: "center",
                          left: "-7px",
                          fontWeight: "500",
                          fontSize: ".5rem",
                        }}
                      >
                        Entrega de tratamiento
                      </p>
                    </li>
                  </ul>
                  <ul
                    className={`w-${handleBarWidth()} ${step <= 1 && 'd-none'}`}
                    style={{
                      background: "#2ecc71",
                      height: "5px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      listStyleType: "none",
                      position: "absolute",
                      top: "0",
                      left: '20px',
                      zIndex: 9,
                      display: 'flex'
                    }}
                  ></ul>
                </div>
            </div>
            <hr className="mt-5 mb-0" />
        </div>
    )
}
