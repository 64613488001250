import React from 'react'
import { useHistory } from 'react-router-dom'

import saologo from '../../../assets/images/sao_logo.png'
import sglogo from '../../../assets/images/study_group_logo.png'

function PatientCard(props) {
  const { filter, type, item } = props
  const history = useHistory()

  return (
    <div className="row px-3">
      <div className="col-3 d-flex flex-row justify-content-center ">
        <p className="text-truncate" style={{ lineHeight: '38px' }}>
          {item.patient}
        </p>
      </div>
      <div className="col-1 d-flex flex-row justify-content-around">
        <p className="mb-0" style={{ lineHeight: '38px' }}>
          {item.user.groups.length !== 0
            ? item.user.groups.map((group) => (
                <img
                  src={group.id === 1 ? saologo : sglogo}
                  alt={group.name}
                  className="me-1"
                  style={{ width: '45px' }}
                />
              ))
            : 'Sin perfil'}
        </p>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        <p className="mb-0" style={{ lineHeight: '38px' }}>
          {item.treatments[0].type?.name || 'No hay tratamiento'}
        </p>
      </div>
      <div className="col-1 d-flex flex-row justify-content-center">
        <p className="mb-0" style={{ lineHeight: '38px' }}>
          {item.treatments[0].order}
        </p>
      </div>
      <div className="col-3 d-flex flex-row justify-content-center">
        <p className="mb-0 text-danger" style={{ lineHeight: '38px' }}>
          {item.treatments[0].pendings[0]}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center px-2">
        {item.treatments[0].pendings.length > 0 && (
          <button
            className="btn btn-outline-primary w-100 rounded-pill"
            onClick={() => history.push(`/od/documentacion/cargar/${item.id}`)}
          >
            Completar
          </button>
        )}
      </div>
      <div className="col-1 d-flex flex-row justify-content-center px-2">
        <button
          className="btn btn-outline-primary w-100 rounded-pill"
          onClick={() =>
            history.push(`/od/pacientes/${item.patient_id}/historiaclinica`)
          }
        >
          Ver
        </button>
      </div>
    </div>
  )
}

export default PatientCard
