import React, { useState, useEffect, useRef } from 'react'
import GeneralInstructions from './GeneralInstructions'
import AuxiliarResources from './AuxiliarResources'
import CanineRelationship from './CanineRelationship'
import CrossBite from './CrossBite'
import Diastems from './Diastems'
import MiddleLines from './MiddleLines'
import MolarRelationship from './MolarRelationship'
import OverjetOverbite from './OverjetOverbite'
import SequentialDistalation from './SequentialDistalation'
import Spaces from './Spaces'
import Stripping from './Stripping'

export default function PretratmentDiagnosis({step, colors}){
    const [stepInstructions, setStepInstructions] = useState(1)
    useEffect(()=>{
        setStepInstructions(step)

        refMenu1.current.style.setProperty("background-color",null)
        refMenu2.current.style.setProperty("background-color",null)
        refMenu3.current.style.setProperty("background-color",null)
        refMenu4.current.style.setProperty("background-color",null)
        refMenu5.current.style.setProperty("background-color",null)
        refMenu6.current.style.setProperty("background-color",null)
        refMenu7.current.style.setProperty("background-color",null)
        refMenu8.current.style.setProperty("background-color",null)
        refMenu9.current.style.setProperty("background-color",null)
        refMenu10.current.style.setProperty("background-color",null)
        refMenu11.current.style.setProperty("background-color",null)

        if(step>=1) refMenu1.current.style.setProperty("background-color",colors.color,"important")
        if(step>=2) refMenu2.current.style.setProperty("background-color",colors.color,"important")
        if(step>=3) refMenu3.current.style.setProperty("background-color",colors.color,"important")
        if(step>=4) refMenu4.current.style.setProperty("background-color",colors.color,"important")
        if(step>=5) refMenu5.current.style.setProperty("background-color",colors.color,"important")
        if(step>=6) refMenu6.current.style.setProperty("background-color",colors.color,"important")
        if(step>=7) refMenu7.current.style.setProperty("background-color",colors.color,"important")
        if(step>=8) refMenu8.current.style.setProperty("background-color",colors.color,"important")
        if(step>=9) refMenu9.current.style.setProperty("background-color",colors.color,"important")
        if(step>=10) refMenu10.current.style.setProperty("background-color",colors.color,"important")
        if(step>=11) refMenu11.current.style.setProperty("background-color",colors.color,"important")

    },[step, colors])

    const refMenu1 = useRef(null);
    const refMenu2 = useRef(null);
    const refMenu3 = useRef(null);
    const refMenu4 = useRef(null);
    const refMenu5 = useRef(null);
    const refMenu6 = useRef(null);
    const refMenu7 = useRef(null);
    const refMenu8 = useRef(null);
    const refMenu9 = useRef(null);
    const refMenu10 = useRef(null);
    const refMenu11 = useRef(null);
    return (
        <div className="d-flex">
            <div className="col-3">
                <ul className="stepper stepper-vertical">
                    <li className={stepInstructions>=1?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu1} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step col-8">Línea Media</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=2?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu2} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step col-8">Overjet/Overbite</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=3?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu3} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step col-8">Relación canina</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=4?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu4} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step col-8">Relación molar</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=5?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu5} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step force-inline-text col-8">Mordida cruzada</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=6?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu6} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step col-8">Apiñamiento</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=7?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu7} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step col-8">Diastemas</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=8?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu8} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step col-8">Espacios</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=9?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu9} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step force-inline-text col-8 small">Distalación secuencial</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=10?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu10} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step force-inline-text col-8">Recursos auxiliares</span>
                        </a>
                    </li>
                    <li className={stepInstructions>=11?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu11} className="circle-step">
                                {/* <i class="fas fa-exchange-alt"></i> */}
                            </span>
                            <span className="label-step force-inline-text col-8 small">Indicaciones generales</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="col-9" style={{zIndex:1000}}>
                {stepInstructions===1 ?
                    <MiddleLines colors={colors} />
                : stepInstructions===2 ? 
                    <OverjetOverbite colors={colors} />
                : stepInstructions===3 ? 
                    <CanineRelationship colors={colors} />
                : stepInstructions===4 ? 
                    <MolarRelationship colors={colors} />
                : stepInstructions===5 ? 
                    <CrossBite colors={colors} />
                : stepInstructions===6 ? 
                    <Stripping colors={colors} />
                : stepInstructions===7 ? 
                    <Diastems colors={colors} />
                : stepInstructions===8 ? 
                    <Spaces colors={colors} />
                : stepInstructions===9 ? 
                    <SequentialDistalation colors={colors} />
                : stepInstructions===10 ? 
                    <AuxiliarResources colors={colors} />
                : stepInstructions===11 ? 
                    <GeneralInstructions colors={colors} />
                : null
                }
            </div>
        </div>
    )
}