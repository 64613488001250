import React, {Fragment} from 'react'

function HeaderTable() {
    return (
        <Fragment>
            <div className="row mt-3">
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">ID</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">Paciente</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">Doctor</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">Tratamiento</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">Etapa</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">Fecha de ingreso</p>
                </div>
                <div className="col-2 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">Accion requerida</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">1° Reclamo</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">2° Reclamo</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">3° Reclamo</p>
                </div>
                <div className="col-1 d-flex flex-row justify-content-center">
                    <p className="text-primary fw-bold m-0 text-center">Reclamar</p>
                </div>
            </div>
        </Fragment>
    )
}

export default HeaderTable