import React, {useState} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import apiClient from '../../../utils/backend'
import { Trash} from 'react-feather';

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DangerTriangle from '../../../assets/images/danger_triangle.png';
import { useDispatch } from 'react-redux';
import { startGetUserList } from '../../../redux/actions/users';

const DeleteModal = (props) => {
        const [loading, setLoading] = useState(false);
        const [opened, setOpened] = useState(false);
        const dispatch = useDispatch();
      
        function handleDelete(){
            console.log(props.id)
            
            setLoading(true)
            apiClient.delete(`/api/admins/${props.id}`)
            .then(res => {
                toast.success('Usuario eliminado correctamente! Redirigiendo...', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    });
                    dispatch(startGetUserList())
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            })
            .catch(err => {
              toast.danger('Hubo un errror al eliminar el usuario... Vuelva a intentarlo.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                });
              console.log(err)
              setLoading(true)
            })
        }

        function handleModal(){
            setOpened(!opened)
        }

  return (
      <div>
            <button className="btn p-0 bg-none" onClick={() => setOpened(true)} >
              <Trash className="mx-1 mt-1" size={24} color="#00155C" />
            </button>
          <Modal isOpen={opened} toggle={handleModal} >
              <ModalBody>
                  <div className="container">
                    <div className="col-12 d-flex flex-column justify-content-center">
                      <img src={DangerTriangle} className="mx-auto my-3" style={{maxWidth: '75px'}} alt="Danger" />
                      <p className="mb-2 mt-2 mb-1 text-center fw-bold fs-5">¿Está seguro que desea eliminar el usuario: <span className="fw-bold">{props.username}</span>?</p>
                      <small className="my-4 text-center">Se eliminaran todos los registros existentes relacionados a este usuario</small>
                    </div>
                    <div className="col-12 mt-4">
                      {!loading && 
                        <div className="w-100 d-flex justify-content-center mb-4">
                          <button className="btn btn-outline-light border border-1 text-muted rounded-pill mx-3 px-5" onClick={handleModal}>Volver</button>
                          <button className="btn btn-outline-danger text-danger rounded-pill mx-3 px-5" onClick={handleDelete}>Eliminar</button>
                        </div>
                      }

                      {loading && 
                        <div className="w-100 d-flex justify-content-center mb-4">
                          <button disabled className="btn btn-outline-light text-muted rounded-pill mx-3 px-5" onClick={handleModal}>Volver</button>
                          <button disabled className="btn btn-outline-danger text-danger rounded-pill mx-3 px-5" onClick={handleDelete}>Eliminar</button>
                        </div>
                      }
                    </div>
                  </div>
              </ModalBody>
          </Modal>
      </div>
  );
}

export default DeleteModal