import React, { useState, useEffect, useRef } from 'react'
import Movements from './Movements'
import Extractions from './Extractions'
import VirtualThoots from './VirtualThoots'
import Attachments from './Attachments'

export default function PretratmentDiagnosis({step, colors}){
    const [stepPretreatmentDiagnosis, setStepPretreatmentDiagnosis] = useState('tab1')
    const refMenu1=useRef(null)
    const refMenu2=useRef(null)
    const refMenu3=useRef(null)
    const refMenu4=useRef(null)
    useEffect(()=>{
        setStepPretreatmentDiagnosis(step)
        refMenu1.current.style.setProperty("background-color",null)
        refMenu2.current.style.setProperty("background-color",null)
        refMenu3.current.style.setProperty("background-color",null)
        refMenu4.current.style.setProperty("background-color",null)

        if(step>=1) refMenu1.current.style.setProperty("background-color",colors.color,"important")
        if(step>=2) refMenu2.current.style.setProperty("background-color",colors.color,"important")
        if(step>=3) refMenu3.current.style.setProperty("background-color",colors.color,"important")
        if(step>=4) refMenu4.current.style.setProperty("background-color",colors.color,"important")        
    },[step, colors])
    return (
        <div className="d-flex">
            <div className="col-2">
                <div className="d-flex">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex mb-2">
                            <span className={`text-stepper${stepPretreatmentDiagnosis>=1?'':'-normal'} col-9 text-end pe-2`}>Movimientos</span>
                            <span className="circle col-1" ref={refMenu1}></span>
                        </div>
                        <div className="d-flex mb-2">
                            <span className={`text-stepper${stepPretreatmentDiagnosis>=2?'':'-normal'} col-9 text-end pe-2`}>Extracciones</span>
                            <span className="circle col-1" ref={refMenu2}></span>
                        </div>
                        <div className="d-flex mb-2">
                            <span className={`text-stepper${stepPretreatmentDiagnosis>=3?'':'-normal'} col-9 text-end pe-2`}>Dientes Virtuales</span>
                            <span className="circle col-1" ref={refMenu3}></span>
                        </div>
                        <div className="d-flex">
                            <span className={`text-stepper${stepPretreatmentDiagnosis>=4?'':'-normal'} col-9 text-end pe-2`}>Attachments</span>
                            <span className="circle col-1" ref={refMenu4}></span>
                        </div>
                    </div>
                    <div className="full-vertical-line ms-xl-L1 ms-lg-L1" style={{backgroundColor: colors.color}}></div>
                </div>
                {/* <ul className="stepper stepper-vertical">
                    <li className={(stepPretreatmentDiagnosis>=1?`active`:'')}>
                        <a href={()=> false}>
                            <span ref={refMenu1} className="circle-step">
                            </span>
                            <span className="label-step col-8">Movimientos</span>
                        </a>
                    </li>
                    <li className={stepPretreatmentDiagnosis>=2?`active`:''}>
                        <a href={()=> false} className='d-flex'>
                            <span ref={refMenu2} className="circle-step">
                            </span>
                            <span className="label-step col-8">Extracciones</span>
                        </a>
                    </li>
                    <li className={stepPretreatmentDiagnosis>=3?`active`:''}>
                        <a href={()=> false} className="d-flex">
                            <span ref={refMenu3} className="circle-step">
                            </span>
                            <span className="label-step force-inline-text col-8">Dientes Virtuales</span>
                        </a>
                    </li>
                    <li className={stepPretreatmentDiagnosis>=4?`active`:''}>
                        <a href={()=> false}>
                            <span ref={refMenu4} className="circle-step">
                            </span>
                            <span className="label-step col-8">Attachmets</span>
                        </a>
                    </li>
                </ul> */}
            </div>
            <div className="col-10" style={{zIndex:1000}}>
                {stepPretreatmentDiagnosis===1 ?
                    <Movements colors={colors} />
                : stepPretreatmentDiagnosis===2 ?
                    <Extractions colors={colors} /> 
                : stepPretreatmentDiagnosis===3 ?
                    <VirtualThoots colors={colors} />
                :   
                    <Attachments colors={colors} /> 
                }
            </div>
        </div>
    )
}