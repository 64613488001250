import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";

// COMPONENTS
import NotificationCard from "./components/notification_card";

import smile from "../../assets/images/ks-smile-blue.svg";

class Notificaciones extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      notifications: [],
    };
  }

  // Desabilitado hasta que exista la funcionalidad de notificaciones.
  // componentDidMount() {
  //   apiClient
  //     .get("/api/helper/getNotifications")
  //     .then((res) => {
  //       console.log(res);
  //       this.setState({ notifications: res.data, loading: false });
  //     })
  //     .catch((err) => console.log(err));
  // }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid px-5 position-relative">
          <div className="row mt-3 px-4">
            <div className="col-8 mt-4">
              <h3 className="fw-bold text-primary mb-2">Notificaciones</h3>
            </div>
            <div className="col-4 mt-2 d-flex justify-content-end">
              <input
                type="text"
                className="search-input w-100 border-0 px-3 mt-3 rounded-pill border border-1 mx-5"
                placeholder="Buscar..."
              />
            </div>
          </div>
          <hr />
          <div className="col-12 px-4">
            {console.log(this.state)}
            {this.state.notifications.length > 0 &&
              this.state.notifications.map((notification, i) => (
                <NotificationCard
                  key={i}
                  user={
                    this.props.state.userReducer.name +
                    " " +
                    this.props.state.userReducer.surname
                  }
                  data={notification}
                />
              ))}
            {this.state.notifications.length <= 0 && (
              <div className="d-flex flex-column w-100">
                <h2 className="text-primary text-center fw-bold mt-5">
                  Aún no tienes ninguna notificación...
                </h2>
                <img src={smile} className="w-25 mx-auto mt-5" alt="ks-smile" />
              </div>
            )}
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Notificaciones);
