import React, { Component } from 'react'
import apiClient from '../../utils/backend'
import { connect } from 'react-redux'

// COMPONENTS

import PatientCard from './components/patient_card'
import Paginator from '../../components/Paginator'

class Home extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      todos: [],
      acciones_requeridas: [],
      sao: [],
      step: 'todos',
      paginator: [],
    }
    this.handleChangePaginator = this.handleChangePaginator.bind(this)
  }
  componentDidMount() {
    apiClient
      .get(
        '/api/dentistPatients?cant_pages=15&page=1&include=treatments.dentists,user,user.groups,treatments.type'
      )
      .then((res) => {
        console.log(res)
        let todos = res.data.table.body
        const newArr = res.data.table.body
        newArr.shift()
        let sao = newArr.filter((item) => item.user.groups[0]?.id === 1)
        let acciones_requeridas = newArr.filter(
          (action) => action.treatments[0]?.pendings[0]?.length >= 0
        )
        let paginator = res.data.paginator
        this.setState({
          ...this.state,
          paginator,
          sao,
          acciones_requeridas,
          todos,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  handleTreatmentColor(type) {
    const colors = {
      'KeepSmiling Full': 1,
      'KeepSmiling Fast': 2,
      'KeepSmiling Medium': 3,
      'KeepSmiling Kids': 4,
      'KeepSmiling Kids Fast': 5,
      'KeepSmiling Teens': 6,
    }

    return colors[type]
  }
  handleChangePaginator = async (p) => {
    apiClient
      .get(
        `/api/dentistPatients?cant_pages=15&page=${p}&include=treatments.dentists,user,user.groups,treatments.type`
      )
      .then((res) => {
        let todos = res.data.table.body
        const newArr = res.data.table.body
        newArr.shift()
        let sao = newArr.filter((item) => item.user.groups[0]?.id === 1)
        let acciones_requeridas = newArr.filter(
          (action) => action.treatments[0]?.pendings[0]?.length >= 0
        )
        let paginator = res.data.paginator
        this.setState({
          ...this.state,
          paginator,
          sao,
          acciones_requeridas,
          todos,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  render() {
    if (this.state.loading) {
      return null
    } else {
      return (
        <div className="container-fluid px-5 position-relative">
          <div className="row pt-4" style={{ zIndex: 2 }}>
            <div className="col-8 text-start px-4">
              <h1 className="text-primary fw-bold">Mis pacientes</h1>
            </div>
            <div className="col-4 mb-3 d-flex justify-content-end">
              <input
                type="text"
                className="search-input w-100 border-0 px-3 py-2 rounded-pill border border-1"
                placeholder="Buscar..."
              />
            </div>
          </div>
          <hr />
          <div className="row px-2">
            <div className="col-12">
              <ul
                className="d-flex flex-row justify-content-start p-0"
                style={{ listStyleType: 'none' }}
              >
                <li style={{ marginRight: '15px' }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === 'todos' && 'disabled'
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: 'todos' })}
                  >
                    Todos{' '}
                    <span className="text-muted">
                      ({this.state.todos.length})
                    </span>
                  </a>
                </li>
                <li style={{ marginRight: '15px' }}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === 'sao' && 'disabled'
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: 'sao' })}
                  >
                    SAO{' '}
                    <span className="text-muted">
                      ({this.state.sao.length})
                    </span>
                  </a>
                </li>
                <li style={{}}>
                  <a
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.step === 'acciones_requeridas' && 'disabled'
                    }`}
                    href="#"
                    onClick={() =>
                      this.setState({ step: 'acciones_requeridas' })
                    }
                  >
                    Acciones Requeridas{' '}
                    <span className="text-muted">
                      ({this.state.acciones_requeridas.length})
                    </span>
                    {this.state.acciones_requeridas.length > 0 ? (
                      <div class="dot bg-red"></div>
                    ) : (
                      ''
                    )}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-3 px-4 ">
            <div className="col-3 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">Paciente</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center"></div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">
                Tratamiento
              </p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">Etapa</p>
            </div>
            <div className="col-7"></div>
          </div>
          <div className="row mt-3 px-1">
            <div className="col-12">
              {this.state[this.state.step].map((item, i) => {
                if (item.length === 0) {
                  return
                }
                return (
                  <div>
                    <div
                      className={`grow_hover w-100 shadow border border-1 rounded container-fluid py-2 text-start mt-2 mb-2 position-relative`}
                    >
                      <button
                        className={`clinic-history-button type-${this.handleTreatmentColor(
                          item.type?.name
                        )}`}
                        onClick={() =>
                          this.props.history.push(
                            `/od/pacientes/${item.patient_id}/historiaclinica`
                          )
                        }
                      />
                      <PatientCard
                        item={item}
                        filter="rejected"
                        type="intraoral"
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div>
            <Paginator
              paginator={this.state.paginator}
              getPaginator={this.handleChangePaginator}
            />
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({ state })

export default connect(mapStateToProps)(Home)
