import React from 'react'

export function GoldCard({active}) {
    return (
        <div className={"shadow " + (active ? "active" : "")} style={{borderRadius: '30px', width: '300px', minHeight: '400px', backgroundColor: '#fff', overflow: 'hidden', padding: 0}}>
            <div className="d-flex flex-column text-center p-3 pt-4" style={{width: '100%', height: '90px', backgroundColor: '#DDB802'}}>
                <h5 className="text-white fw-bold">Smile Creator <strong>Gold</strong></h5>
                <p className="text-white">Entre 5 y 20 casos</p>
            </div>
            <div className="d-flex p-2">
                <ul>
                    <li className="mt-3">1 (Un) cupón de 10% de descuento por tres meses*</li>
                    <li className="mt-3">Exposición en la web: <strong>Baja</strong></li>
                </ul>
            </div>
        </div>
    )
}
export function PlatinumCard({active}) {
    return (
        <div className="shadow" style={{borderRadius: '30px', width: '300px', minHeight: '400px', backgroundColor: '#fff', overflow: 'hidden', padding: 0, opacity: '.5'}}>
            <div className="d-flex flex-column text-center p-3 pt-4" style={{width: '100%', height: '90px', backgroundColor: '#E5E5E5'}}>
                <h5 className="text-white fw-bold">Smile Creator <strong>Platinum</strong></h5>
                <p className="text-white">Entre 20 y 35 casos</p>
            </div>
            <div className="d-flex p-2">
                <ul>
                    <li className="mt-3">1 (Un) cupón de 10% de descuento por tres meses*</li>
                    <li className="mt-3">Exposición en la web: <strong>Moderada</strong></li>
                    <li className="mt-3">Derivaciones de pacientes: <strong>Moderada</strong></li>
                    <li className="mt-3">Invitaciones gratuitas a eventos y capacitaciones</li>
                </ul>
            </div>
        </div>
    )
}
export function BlackCard() {
    return (
        <div className="shadow" style={{borderRadius: '30px', width: '300px', minHeight: '400px', backgroundColor: '#fff', overflow: 'hidden', padding: 0, opacity: '.5'}}>
            <div className="d-flex flex-column text-center p-3 pt-4" style={{width: '100%', height: '90px', backgroundColor: '#8C8C8C'}}>
                <h5 className="text-white fw-bold">Smile Creator <strong>Black</strong></h5>
                <p className="text-white">Mas de 35 casos</p>
            </div>
            <div className="d-flex p-2">
                <ul>
                    <li className="mt-3">3 (Tres) cupones de 10% de descuento por tres meses*</li>
                    <li className="mt-3">Exposición en la web: <strong>Alta</strong></li>
                    <li className="mt-3">Derivaciones de pacientes: <strong>Alta</strong></li>
                    <li className="mt-3">Invitaciones gratuitas a eventos y capacitaciones</li>
                    <li className="mt-3">Asesor de marketing asignado y materiales promocionales gratuitos</li>
                </ul>
            </div>
        </div>
    )
}
