import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Mail, Bell, MessageCircle} from "react-feather";
import apiClient from "../../../utils/backend";
import { toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';


const ModalReclamo = (props) => {
    const {
        className,
        claim
    } = props;

    const [modal, setModal] = useState(false);
    const [methods, setMethods] = useState([])

    const setMethod = (method) => {
        if(methods.includes(method)) {
            let array = [...methods];
            let index = array.indexOf(method)

            array.splice(index, 1);
            setMethods(array)
        } else {
            let joined = methods.concat(method);

            setMethods(joined)
        }
    }

    const sendClaim = () => {
        let data = {
            'treatment_id' : claim.id,
            'type' : 'documentation',
            'via' : methods
        }

        apiClient.post('/api/claims', data).then(res => {
            console.log("respuesta")
            console.log(res)

            toast.success('Reclamo enviado con exito.', {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            })
        })
    }

    const toggle = () => setModal(!modal);

    return (
        <div>

            <button onClick={toggle} className="btn btn-info text-white rounded-pill">Reclamar</button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalBody>
                    <div className="p-3">
                        <h4>Reclamo</h4>

                        <div className="row mt-4">
                            <div className="col-6">
                                <div className="row">
                                    <p style={{fontSize: 12}}>Paciente:</p>
                                    <p style={{fontSize: 15}}>{claim.patient}</p>
                                </div>

                                <div className="row mt-3">
                                    <p style={{fontSize: 12}}>Doctor:</p>
                                    <p style={{fontSize: 15}}>{claim.dentist}</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <p style={{fontSize: 12}}>Medio de Reclamo:</p>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-3 d-flex flex-column align-items-center">
                                        <Bell style={{cursor: 'pointer'}}/>
                                        <p className="mt-1 poi" style={{fontSize: 8}}>Notificación</p>
                                    </div>
                                    <div className="col-3 d-flex flex-column align-items-center">
                                        <Mail onClick={() => setMethod('mail')} style={{cursor: 'pointer', opacity: `${methods.includes('mail') ? 1 : 0.5}`}}/>
                                        <p className="mt-1" style={{fontSize: 8}}>Enviar mail</p>
                                    </div>
                                    <div className="col-3 d-flex flex-column align-items-center">
                                        <MessageCircle onClick={() => setMethod('whatsapp')} style={{cursor: 'pointer', opacity: `${methods.includes('whatsapp') ? 1 : 0.5}`}}/>
                                        <p className="mt-1" style={{fontSize: 8}}>WhatsApp</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4" style={{width: '100%', border: '1px solid gray', borderRadius: 15}}>
                            <div className="px-3 py-2">
                                <span style={{fontSize: 12}} className="text-danger fw-bold">{claim.required_action}</span>
                            </div>

                            {claim.required_action === '¡Documentación Incompleta!' && 
                            <div className="col-4 mt-2 px-3">
                                <p className="border border-1 rounded-pill text-center">Fotos</p>
                                <ul class="list-group list-group-flush">
                                    {claim.fotos_faltantes['photos'].map(item => <li class="list-group-item" style={{fontSize: '.7rem'}}>{item}</li>)}
                                </ul>
                            </div>}
                            {claim.required_action === '¡Documentación Incompleta!' && 
                            <div className="col-4 mt-2 px-3">
                                <p className="border border-1 rounded-pill text-center">Estudios</p>
                                <ul class="list-group list-group-flush">
                                    {claim.fotos_faltantes['studies'].map(item => <li class="list-group-item" style={{fontSize: '.7rem'}}>{item}</li>)}
                                </ul>
                            </div>}
                            {claim.required_action === '¡Documentación Incompleta!' && 
                            <div className="col-4 mt-2 px-3">
                                <p className="border border-1 rounded-pill text-center">Plan</p>
                                <ul class="list-group list-group-flush">
                                    {claim.fotos_faltantes['plan'].map(item => <li class="list-group-item" style={{fontSize: '.7rem'}}>{item}</li>)}
                                </ul>
                            </div>}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="text-white rounded-pill" color="info" onClick={sendClaim}>Reclamar</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalReclamo;