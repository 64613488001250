import React, {Component} from "react";
import Macro from '../../assets/images/macro.png'
import BBVA from '../../assets/images/bbva.png'
import smile from '../../assets/images/ks-smile.svg'

class Listado extends Component {
    componentDidMount() {

    }

    handleTreatmentColor(type){
        const colors = {
            "KeepSmiling Full": '#10456D',
            "KeepSmiling Fast":'#288B7E',
            "KeepSmiling Medium":'#5A468E',
            "KeepSmiling Kids":'#E0614A',
            "KeepSmiling Kids Fast":'#E8793A',
            "KeepSmiling Teens":'#F7C05B'
        }

        return colors[type]
    }

    render() {
        return (
            <div className="container-fluid px-3 mt-2" style={{color: this.handleTreatmentColor('KeepSmiling Full')}}>

                <div className="row">
                    <div className="col-12 position-relative">
                        <div className="pagos-bg" style={{height: '200px'}} />
                    </div>
                </div>

                <div className="row pt-2 mt-5 px-5" style={{zIndex: 1, height: '200px'}}>
                    <div className="col-12 px-4" style={{zIndex: 1}}>
                    <img src={smile} style={{maxWidth: '75px', margin: 0}} alt="" />
                        <h2 className="text-white fw-bold">Pagos</h2>
                        <h4 className="text-white">Listado de precios actualizados y datos bancarios</h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 px-5">
                        <h1 style={{fontWeight: 700}} className="text-primary">
                            Datos Bancarios
                        </h1>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-4 mx-auto">
                        <img src={BBVA} alt=""/>

                        <h3 className="mt-5 mb-3">Entidad: BBVA Francés</h3>
                        <p>Titular: KeepSmiling S.A.</p>
                        <p>CUIT: 30710916361</p>
                        <p>Sucursal N°: 304</p>

                        <p className="mt-5">Cuenta Corriente en PESOS</p>
                        <p>N°: 304-303387/9</p>
                        <p>CBU: 0170304520000030338797</p>

                        <p className="mt-5">Cuenta Corriente en DOLARES</p>
                        <p>N°: 304-44754/3</p>
                        <p>CBU: 0170304526000004475433</p>
                    </div>
                    <div className="col-4 mx-auto">
                        <img src={Macro} alt=""/>

                        <h3 className="mt-5 mb-3">Entidad: BBVA Francés</h3>
                        <p>Titular: KeepSmiling S.A.</p>
                        <p>CUIT: 30710916361</p>
                        <p>Sucursal N°: 328 Colegiales</p>

                        <p className="mt-5">Cuenta Corriente en PESOS</p>
                        <p>N°: 4-328-0951909076-4</p>
                        <p>CBU: 2850328-6-400951909764-8</p>
                    </div>
                </div>

                <div className="row mt-5 text-center">
                    <p>Remita el comprobante correspondiente a este mismo.</p>
                    <p>email: cobranzas@keepsmiling.com.ar indicando en el Asunto: Paciente y Concepto abonado.</p>
                </div>
            </div>
        );
    }
}

export default Listado
