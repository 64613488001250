import React, {useState} from 'react'
import apiClient from '../utils/backend'

export default function Searchbar({setData}) {

    const [query, setQuery] = useState(null)

    function handleRequest(){
        apiClient.get(`/api/helper/generalSearch?filter=${query}`)
        .then(res => setData(res))
        .catch(err => console.log(err))
    }

    return (
        <input
                type="text"
                className="search-input w-100 border-0 px-3 py-2 mt-2 rounded-pill border border-1"
                placeholder="Buscar..."
                onChange={e => setQuery(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleRequest()}
              />
    )
}
