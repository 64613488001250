import React from "react";
import { User, LogOut, Menu } from "react-feather";
import { connect } from "react-redux";

import logo from "../assets/images/logo-ks.svg";
import Notifications from "./notifications";

function Navbar(props) {
  function handleLogOut() {
    props.logOut();
    setTimeout(() => {
      window.location.href = "/login";
    }, 500);
  }
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary d-flex justify-content-between px-4">
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
        ></button>

        <div
          className="collapse navbar-collapse ml-auto"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <button
                className="btn bg-none"
                onClick={() => props.setSidebarCollapsed()}
              >
                <Menu size={24} color="#fff" />
              </button>
            </li>
          </ul>
        </div>

        <div className="d-none d-lg-flex nvb2 mr-auto">
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="KeepSmiling logo"
              style={{ maxWidth: "150px", height: "auto" }}
            />
          </a>
        </div>

        <div className="navbar-nav ml-auto search d-none d-lg-flex">
          <Notifications />
          <div className="dropdown btn">
            <a
              className="text-white dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <User className="mx-2" size={20} />
              <span>
                {props.userReducer.name + " " + props.userReducer.surname}
              </span>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a className="dropdown-item" href="/od/perfil">
                  <User size={16} /> Mi Perfil
                </a>
              </li>
              <li>
                <a className="dropdown-item" onClick={() => handleLogOut()}>
                  <LogOut size={16} /> Cerrar sesión
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="navbar-nav d-flex d-lg-none"></div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSidebarCollapsed: () => dispatch({ type: "COLLAPSE" }),
    logOut: () => dispatch({ type: "LOGOUT" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
