import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import apiClient from '../../../utils/backend'
import { useHistory } from 'react-router'

import PatientData from '../../../components/patient_data'
import { connect } from 'react-redux';

const MovementsModal = (props) => {
  const {
    className,
    id,
    opened,
    handleModal
  } = props;


  const [contentLoading, setContentLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState()
  const history = useHistory()

  // DATA STATE
  const [movements, setMovements] = useState()
  const [stripping, setStripping] = useState()
  const [maxillaSup, setMaxillaSup] = useState()
  const [maxillaInf, setMaxillaInf] = useState()
  const [normalProtocol, setNormalProtocol] = useState()
  const [protocol, setProtocol] = useState()
  const [obs, setObs] = useState()

  const number_of_rows = 15

  useEffect(() => {
    handleGetData()

    const maxilla_sup = []
    const maxilla_inf = []

    for(let i = 0; i < number_of_rows; i++){
      maxilla_sup.push({index: i, placas: null, alineador: null, milimetros: null})
      maxilla_inf.push({index: i, placas: null, alineador: null, milimetros: null})
    }

    setMaxillaSup(maxilla_sup)
    setMaxillaInf(maxilla_inf)
  }, [])

  function handleGetData() {
    console.log(props)

     apiClient.get(`/api/helper/getClinicalHistory/${props.id}`, { withCredentials: true, headers: { Authorization: `Bearer ${props.userReducer.login_token}` } })
      .then(res => {
        setData(res.data.data)
      })
      .then(res => setContentLoading(false))
      .catch(err => console.log(err))
  }

  function handleInputChange({type, index, input, value }){
    if(type === 'maxillaSup'){
      const arr = [...maxillaSup]
      const item = arr.find(item => item.index === index)
      item[input] = value
      arr[item] = item
      setMaxillaSup(arr)
    }

    if(type === 'maxillaInf'){
      const arr = [...maxillaInf]
      const item = arr.find(item => item.index === index)
      item[input] = value
      arr[item] = item
      setMaxillaInf(arr)
    }

    console.log(maxillaInf, maxillaSup)
  }

  if(contentLoading){
    return (
      <div>
        <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal size="lg" isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div className="container">
              <h5 className="fs-6">Movimientos</h5>
              <PatientData id={id} created_at={data.created_at} dentist={data.dentist} patient={data.patient} maxilla={data.maxilla_string} profile={data.documentation ? data.documentation[0] : null}  />
              <p className="mb-3 mt-5 fw-bold text-primary">Alineadores:</p>
              <div className="d-flex flex-row">
                <label htmlFor="movements" >Cantidad de movimientos:</label>
                <input type="number" className="form-control mx-2" style={{maxWidth: '60px', marginTop: '-10px'}} onChange={(e) => setMovements(e.target.value)}  />
              </div>

              <p className="mb-3 mt-3 fw-bold text-primary">Planilla para el profesional:</p>
              <div className="d-flex flex-row justify-content-around">
                <div>
                  <input type="checkbox" style={{'visibility': 'visible'}} name="stripping" id="stripping" className="form-check-input mx-1" checked={stripping} onChange={(e) => setStripping(e.target.checked)}  />
                  <label style={{fontSize: '.8rem', paddingBottom: '5px'}} htmlFor="stripping" >Stripping</label>
                </div>
                <div>
                  <input type="checkbox" style={{'visibility': 'visible'}} name="protocolo-normal" id="protocolo-normal" className="form-check-input mx-1" onChange={(e) => setNormalProtocol(e.target.value)}  />
                  <label style={{fontSize: '.8rem', paddingBottom: '5px'}} htmlFor="protocolo-normal" >Protocolo normal</label>
                </div>
                <div>
                  <input type="checkbox" style={{'visibility': 'visible'}} name="protocolo-levante-mordida" id="protocolo-levante-mordida" className="form-check-input mx-1" onChange={(e) => setProtocol(e.target.value)}  />
                  <label style={{fontSize: '.8rem', paddingBottom: '5px'}} htmlFor="protocolo-levante-mordida" >Protocolo levante de mordida</label>
                </div>
              </div>

              {normalProtocol && <div className="d-flex flex-row mt-3">
                <textarea className="form-control" name="observacion" id="observacion" cols="30" rows="5" onChange={(e) => setObs(e.target.value)}></textarea>
              </div>}

              {stripping && <div className="row mt-3">
                    <div className="col-6 references">
                      <table className="table">
                        <thead>
                          <tr>
                            <td style={{fontSize: '.7em'}}>Entre N° de Placas</td>
                            <td style={{fontSize: '.7em'}}>Entre N° de Alineador</td>
                            <td style={{fontSize: '.7em'}}>Total mm desgastados</td>
                          </tr>
                        </thead>
    
                        <tbody>
                          {maxillaSup.map(item => {
                            return <tr>
                                      <td><input className="form-control" onChange={(e) => handleInputChange({type: 'maxillaSup', index: item.index, input: "placas", value: e.target.value})} type="text" name={item.index + "-placas"} id={item.index + "-placas"} /></td>
                                      <td><input className="form-control" onChange={(e) => handleInputChange({type: 'maxillaSup', index: item.index, input: "alineador", value: e.target.value})} type="number" name={item.index + "-alineadores"} id={item.index + "-alineadores"} /></td>
                                      <td><input className="form-control" onChange={(e) => handleInputChange({type: 'maxillaSup', index: item.index, input: "milimetros", value: e.target.value})} type="number" name={item.index + "-milimetros"} id={item.index + "-milimetros"} /></td>
                                    </tr>
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-6 references">
                      <table className="table">
                        <thead>
                          <tr>
                            <td style={{fontSize: '.7em'}}>Entre N° de Placas</td>
                            <td style={{fontSize: '.7em'}}>Entre N° de Alineador</td>
                            <td style={{fontSize: '.7em'}}>Total mm desgastados</td>
                          </tr>
                        </thead>
    
                        <tbody>
                          {maxillaInf.map(item => {
                            return <tr>
                                      <td><input className="form-control" onChange={(e) => handleInputChange({type: 'maxillaInf', index: item.index, input: "placas", value: e.target.value})} type="text" name={item.index + "-placas"} id={item.index + "-placas"} /></td>
                                      <td><input className="form-control" onChange={(e) => handleInputChange({type: 'maxillaInf', index: item.index, input: "alineador", value: e.target.value})} type="number" name={item.index + "-alineadores"} id={item.index + "-alineadores"} /></td>
                                      <td><input className="form-control" onChange={(e) => handleInputChange({type: 'maxillaInf', index: item.index, input: "milimetros", value: e.target.value})} type="number" name={item.index + "-milimetros"} id={item.index + "-milimetros"} /></td>
                                    </tr>
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>}
            </div>
          </ModalBody>
          <ModalFooter>

            {!loading &&
              <div>
                <button className="rounded-pill btn border btn-light text-dark mx-2" onClick={handleModal}>Volver</button>
                <button className="rounded-pill btn btn-success text-white">Guardar</button>
              </div>
            }

            {loading &&
              <button class="btn btn-success" type="button" disabled>
                <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
              </button>
            }

          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(MovementsModal)
