import React, { Component, Fragment } from "react";
import apiClient from "../../../../utils/backend";
import { connect } from "react-redux";
import Select from 'react-select'
import Compressor from "compressorjs";
import { Upload, Edit2 } from "react-feather";

import Header from '../../components/header_image'
import StatusBar from './components/status_bar'

import trescuartos_perfil from "../../../../assets/images/34deperfilsonriendo.png";
import anterioroclusion from "../../../../assets/images/anteriorenoclusion.png";
import anteriorinoclusion from "../../../../assets/images/anterioreninoclusion.png";
import bucalderecho from "../../../../assets/images/bucalderecho.png";
import bucalizquierdo from "../../../../assets/images/bucalizquierdo.png";
import frontalreposo from "../../../../assets/images/frontalenreposo.png";
import frontalsonriendo from "../../../../assets/images/frontalsonriendo.png";
import oclusalmaxilarinf from "../../../../assets/images/oclusaldelmaxilarinferior.png";
import oclusalmaxilarsup from "../../../../assets/images/oclusaldelmaxilarsuperior.png";
import perfilizqreposo from "../../../../assets/images/perfilizquierdoenreposo.png";
import radioperfil from "../../../../assets/images/telerradiografiadeperfil.png";
import ricketts from "../../../../assets/images/cefalogramadericketts.png";
import rxpanoramica from "../../../../assets/images/rxpanoramica.png";

import {
    ImageUploadContainer,
    ImageUploadLabel,
    ImageUploadReference,
  } from "./components/styled";

class index extends Component {
    constructor(props){
        super(props)

        this.state = {
            step: 1,
            od_patients: [],
            documentation_completed: null,
            dentist_id: this.props.state.userReducer.id,

            first_data: {},
            second_data: {},
            third_data: {},
            before: {},
            after: {}
        }

        let files = [
            "frontal_reposo",
            "frontal_sonriendo",
            "perfil_izq_reposo",
            "trescuartos_perfil",
            "oclusal_maxilar_sup",
            "oclusal_maxilar_inf",
            "anterior_oclusion",
            "bucal_derecho",
            "bucal_izquierdo",
            "anterior_inoclusion",
            "otro",
            "rxpanoramica",
            "telerradiografia",
            "ricketts",
            "otro2",
            "plantratamiento",
          ];
        let files2 = [
            "frontal_reposo_1",
            "frontal_sonriendo_1",
            "perfil_izq_reposo_1",
            "trescuartos_perfil_1",
            "oclusal_maxilar_sup_1",
            "oclusal_maxilar_inf_1",
            "anterior_oclusion_1",
            "bucal_derecho_1",
            "bucal_izquierdo_1",
            "anterior_inoclusion_1",
            "otro_1",
            "rxpanoramica_1",
            "telerradiografia_1",
            "ricketts_1",
            "otro2_1",
            "plantratamiento_1",
          ];
      
          for(let i = 0; i <= files.length; i++){
            this[files[i]]= React.createRef();
            this[files2[i]] = React.createRef();
          }

          this.handleGetPatients = this.handleGetPatients.bind(this)
          this.handleFirstData = this.handleFirstData.bind(this)
          this.handleSecondImageSelect = this.handleSecondImageSelect.bind(this)
          this.handleThirdImageSelect = this.handleThirdImageSelect.bind(this)
          this.handleSendData = this.handleSendData.bind(this)
    }

    componentDidMount(){
      this.handleGetPatients(this.props.state.userReducer.id)
    }

    handleSendData(){
      apiClient.post('api/cases', {after: this.state.after, before: this.state.before, dentist_id: this.props.state.userReducer.id, patient_id: this.state.first_data.patient_id, reason: this.state.first_data.reason, maxilla: this.state.first_data.maxilla, stage_quantity: this.state.first_data.stage_quantity, align: this.state.first_data.align, plan: this.state.first_data.plan, diagnosis: this.state.first_data.diagnosis, })
      .then(res => console.log(res))
    }

    handleFirstData(e){
      console.log(e)
        let data = {
            ...this.state.first_data,
            [e.target.id]: e.target.value
        }

        this.setState({
            first_data: data
        })

        console.log(this.state)
    }

    handleSecondImageSelect = async (e) => {
        e.preventDefault();
    
        let image_name = e.target.id + "_image";
        let file = e.target.files[0];
        let $this = this;
    
        new Compressor(file, {
          quality: 0.1,
    
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            let reader = new FileReader();
            let file_compressed = result;
    
            reader.onloadend = () => {
              $this.setState({
                [image_name]: reader.result,
                cropper_img: reader.result,
                [e.target.className]: {
                  ...$this.state[e.target.className],
                  [e.target.id]: reader.result,
                },
                before: {...$this.state.before, [image_name]: reader.result}
              });
            };
    
            reader.readAsDataURL(file_compressed);
          },
          error(err) {
            console.log(err.message);
          },
        });

        console.log(this.state)
      };
    handleThirdImageSelect = async (e) => {
        e.preventDefault();
    
        let image_name = e.target.id + "_image2";
        let file = e.target.files[0];
        let $this = this;
    
        new Compressor(file, {
          quality: 0.1,
    
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            let reader = new FileReader();
            let file_compressed = result;
    
            reader.onloadend = () => {
              $this.setState({
                [image_name]: reader.result,
                cropper_img: reader.result,
                [e.target.className]: {
                  ...$this.state[e.target.className],
                  [e.target.id]: reader.result,
                },
                after: {...$this.state.after, [image_name]: reader.result}
              });
            };
    
            reader.readAsDataURL(file_compressed);
          },
          error(err) {
            console.log(err.message);
          },
        });

        console.log(this.state)
      };

      handleGetPatients(id) {
        apiClient
          .get(`api/helper/getPatientsByDentist/${id}`)
          .then((res) => {
            let patients = [];
            Object.entries(res.data).map(([key, value]) => {
              patients.push({ value: key, label: value, target: {id: 'patient', value: value} });
            });
            this.setState({ od_patients: patients });
            console.log(this.state);
          })
          .catch((err) => console.log(err));
        console.log(this.state);
      }

      handleDataValidation(){
        const first_validation = Object.keys(this.state.first_data).length
        const second_validation = Object.keys(this.state.second_data).length
        const third_validation = Object.keys(this.state.third_data).length

        if(first_validation < 7 || second_validation < 13 || third_validation < 13){
          this.setState({documentation_completed: false})
        } else {
          this.setState({documentation_completed: true})
        }
      }

  render() {
    const maxillaOptions = [{label: 'Superior', value: 1, target: {id: "maxilla", value: 1}}, {label: 'Inferior', value: 2, target: {id: "maxilla", value: 2}}, {label: "Ambos", value: 3, target: {id: "maxilla", value: 3}}]

    const selectStyle = {
      control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        borderBottom: "2px solid #0027a8!important",
        borderRadius: 0,
        margin: 0
      }),
    };

    return (
        <Fragment>
            {this.state.step === 1 && <div className="container-fluid p-0">
            <Header />
            <div className="row px-5 mt-4">
                <div className="col-12 px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                            <li className="breadcrumb-item"><a href="/ayuda/caso" className="text-muted">Presenta tu caso</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Enviar caso</li>
                        </ol>
                        </nav>
                </div>
            </div>
            <div className="shadow container pt-5 pb-4" style={{borderRadius: '20px'}}>
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>Enviar caso</h1>
                    </div>
                    <div className="col-12">
                        <StatusBar step={this.state.step} first_completed={Object.keys(this.state.first_data).length === 7 ? true : false} second_completed={Object.keys(this.state.second_data).length === 14 ? true : false} third_completed={Object.keys(this.state.third_data).length === 14 ? true : false} />
                    </div>
                </div>
                <div className="col-8 mx-auto mt-4">
                <div className="row px-5">
                    <div className="col-12 mt-2">
                        <label htmlFor="patient">Paciente</label>
                        <Select
                          styles={selectStyle}
                          options={this.state.od_patients}
                          placeholder="Seleccionar paciente"
                          id="patient"
                          onChange={e => {this.handleFirstData(e); this.setState({first_data: {...this.state.first_data, patient_id: e.value }})}}
                        />
                    </div>
                    <div className="col-12 mt-2">
                        <label htmlFor="reason">Motivo de consulta</label>
                        <textarea onChange={e => this.handleFirstData(e)} className="w-100" name="reason" id="reason" cols="30" rows="10"></textarea>
                    </div>
                    <div className="col-4 px-2 mt-2">
                        <label htmlFor="maxilla">Maxilar</label>
                        <Select
                        styles={selectStyle}
                        options={maxillaOptions}
                        placeholder="Seleccionar maxilar"
                        id="maxilla"
                        onChange={e => this.handleFirstData(e)}
                      />
                        {/* <input onChange={e => this.handleFirstData(e)} className="form-control" type="text" name="maxilla" id="maxilla" /> */}
                    </div>
                    <div className="col-4 px-2 mt-2">
                        <label htmlFor="stage_quantity">Cant. de etapas</label>
                        <input onChange={e => this.handleFirstData(e)} className="form-control" type="text" name="stage_quantity" id="stage_quantity" />
                    </div>
                    <div className="col-4 px-2 mt-2">
                        <label htmlFor="align">Cant. de alineadores</label>
                        <input onChange={e => this.handleFirstData(e)} className="form-control" type="text" name="align" id="align" />
                    </div>
                    <div className="col-12 mt-2">
                        <label htmlFor="plan">Plan de Tratamiento</label>
                        <textarea onChange={e => this.handleFirstData(e)} className="w-100" name="plan" id="plan" cols="30" rows="10"></textarea>
                    </div>
                    <div className="col-12 mt-2">
                        <label htmlFor="diagnosis">Diagnóstico</label>
                        <textarea onChange={e => this.handleFirstData(e)} className="w-100" name="diagnosis" id="diagnosis" cols="30" rows="10"></textarea>
                    </div>
                    <div className="col-12 mt-2 d-flex flex-row justify-content-end">
                        <button className="btn btn-primary rounded-pill px-5 mt-2" onClick={() => this.setState({step: 2})}>Continuar</button>
                    </div>
                </div>
                </div>
            </div>
        </div>}

        {this.state.step === 2 && <div className="container-fluid p-0">
            <Header />
            <div className="row px-5 mt-4">
                <div className="col-12 px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                            <li className="breadcrumb-item"><a href="/ayuda/caso" className="text-muted">Presenta tu caso</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Enviar caso</li>
                        </ol>
                        </nav>
                </div>
            </div>
            <div className="shadow container pt-5 pb-4" style={{borderRadius: '20px'}}>
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>Enviar caso</h1>
                    </div>
                    <div className="col-12">
                        <StatusBar step={this.state.step} first_completed={Object.keys(this.state.first_data).length === 7 ? true : false} second_completed={Object.keys(this.state.second_data).length === 14 ? true : false} third_completed={Object.keys(this.state.third_data).length === 14 ? true : false} />
                    </div>
                </div>
                <div className="col-12 mx-auto mt-4">
                <div className="row d-flex flex-row justify-content-center mx-auto px-5">
                <div
                  className="col-3 image-upload-container d-flex flex-column my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="frontal_reposo"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.frontal_reposo_image
                            ? this.state.frontal_reposo_image
                            : frontalreposo
                        }
                        alt="Imagen de"
                        ref={this.frontal_reposo}
                      />
                      {this.state.frontal_reposo_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("frontal_reposo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Frontal en Reposo</ImageUploadLabel>
                </div>

                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="frontal_sonriendo"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.frontal_sonriendo_image
                            ? this.state.frontal_sonriendo_image
                            : frontalsonriendo
                        }
                        alt="Imagen de"
                        ref={this.frontal_sonriendo}
                      />
                      {this.state.frontal_sonriendo_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("frontal_sonriendo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Frontal Sonriendo</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="perfil_izq_reposo"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.perfil_izq_reposo_image
                            ? this.state.perfil_izq_reposo_image
                            : perfilizqreposo
                        }
                        alt="Imagen de"
                        ref={this.perfil_izq_reposo}
                      />
                      {this.state.perfil_izq_reposo_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("perfil_izq_reposo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>
                    Perfil Izquierdo en Reposo
                  </ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="trescuartos_perfil"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.trescuartos_perfil_image
                            ? this.state.trescuartos_perfil_image
                            : trescuartos_perfil
                        }
                        alt="Imagen de"
                        ref={this.trescuartos_perfil}
                      />
                      {this.state.trescuartos_perfil_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("trescuartos_perfil", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>3/4 Perfil Sonriendo</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="oclusal_maxilar_sup"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.oclusal_maxilar_sup_image
                            ? this.state.oclusal_maxilar_sup_image
                            : oclusalmaxilarsup
                        }
                        alt="Imagen de"
                        ref={this.oclusal_maxilar_sup}
                      />
                      {this.state.oclusal_maxilar_sup_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("oclusal_maxilar_sup", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>
                    Oclusal del Maxilar Superior
                  </ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="oclusal_maxilar_inf"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.oclusal_maxilar_inf_image
                            ? this.state.oclusal_maxilar_inf_image
                            : oclusalmaxilarinf
                        }
                        alt="Imagen de"
                        ref={this.oclusal_maxilar_inf}
                      />
                      {this.state.oclusal_maxilar_inf_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("oclusal_maxilar_inf", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>
                    Oclusal del Maxilar Inferior
                  </ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="anterior_oclusion"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.anterior_oclusion_image
                            ? this.state.anterior_oclusion_image
                            : anterioroclusion
                        }
                        alt="Imagen de"
                        ref={this.anterior_oclusion}
                      />
                      {this.state.anterior_oclusion_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("anterior_oclusion", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Anterior en Oclusion</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="bucal_derecho"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.bucal_derecho_image
                            ? this.state.bucal_derecho_image
                            : bucalderecho
                        }
                        alt="Imagen de"
                        ref={this.bucal_derecho}
                      />
                      {this.state.bucal_derecho_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("bucal_derecho", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Bucal Derecho</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="bucal_izquierdo"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.bucal_izquierdo_image
                            ? this.state.bucal_izquierdo_image
                            : bucalizquierdo
                        }
                        alt="Imagen de"
                        ref={this.bucal_izquierdo}
                      />
                      {this.state.bucal_izquierdo_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("bucal_izquierdo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Bucal Izquierdo</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="anterior_inoclusion"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.anterior_inoclusion_image
                            ? this.state.anterior_inoclusion_image
                            : anteriorinoclusion
                        }
                        alt="Imagen de"
                        ref={this.anterior_inoclusion}
                      />
                      {this.state.anterior_inoclusion_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("anterior_inoclusion", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Anterior en Inoclusion</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="rxpanoramica"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.rxpanoramica_image
                            ? this.state.rxpanoramica_image
                            : rxpanoramica
                        }
                        alt="Imagen de"
                        ref={this.rxpanoramica}
                      />
                      {this.state.rxpanoramica_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("rxpanoramica", "estudios")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>RX Panorámica</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="telerradiografia"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.telerradiografia_image
                            ? this.state.telerradiografia_image
                            : radioperfil
                        }
                        alt="Imagen de"
                        ref={this.telerradiografia}
                      />
                      {this.state.telerradiografia_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("telerradiografia", "estudios")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Telerradiografía de perfil</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="ricketts"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.ricketts_image
                            ? this.state.ricketts_image
                            : ricketts
                        }
                        alt="Imagen de"
                        ref={this.ricketts}
                      />
                      {this.state.ricketts_image ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("ricketts", "estudios")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Cefalograma de Ricketts</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleSecondImageSelect}
                        id="otro"
                        className="second_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    {this.state.otro_image ? (
                      <ImageUploadReference
                        src={this.state.otro_image}
                        alt="Imagen de"
                        ref={this.otro}
                        onClick={() => this.showCropArea("otro", "second_data")}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          background: "#112154",
                        }}
                      ></div>
                    )}
                  </div>
                  <ImageUploadLabel>Otras</ImageUploadLabel>
                </div>
              </div>
                </div>
                <div className="col-12 mt-3 d-flex flex-row justify-content-center">
                 <button className="btn btn-outline-primary rounded-pill px-5 mt-2 mx-1" onClick={() => this.setState({step: 1})}>Volver</button>
                 <button className="btn btn-primary rounded-pill px-5 mt-2 mx-1" onClick={() => this.setState({step: 3})}>Continuar</button>
                </div>
            </div>
        </div>}
        {this.state.step === 3 && <div className="container-fluid p-0">
            <Header />
            <div className="row px-5 mt-4">
                <div className="col-12 px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                            <li className="breadcrumb-item"><a href="/ayuda/caso" className="text-muted">Presenta tu caso</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Enviar caso</li>
                        </ol>
                        </nav>
                </div>
            </div>
            <div className="shadow container pt-5 pb-4" style={{borderRadius: '20px'}}>
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>Enviar caso</h1>
                    </div>
                    <div className="col-12">
                        <StatusBar step={this.state.step} first_completed={Object.keys(this.state.first_data).length === 7 ? true : false} second_completed={Object.keys(this.state.second_data).length === 14 ? true : false} third_completed={Object.keys(this.state.third_data).length === 14 ? true : false} />
                    </div>
                </div>
                <div className="col-12 mx-auto mt-4">
                <div className="row d-flex flex-row justify-content-center mx-auto px-5">
                <div
                  className="col-3 image-upload-container d-flex flex-column my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="frontal_reposo"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.frontal_reposo_image2
                            ? this.state.frontal_reposo_image2
                            : frontalreposo
                        }
                        alt="Imagen de"
                        ref={this.frontal_reposo_1}
                      />
                      {this.state.frontal_reposo_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("frontal_reposo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Frontal en Reposo</ImageUploadLabel>
                </div>

                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="frontal_sonriendo"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.frontal_sonriendo_image2
                            ? this.state.frontal_sonriendo_image2
                            : frontalsonriendo
                        }
                        alt="Imagen de"
                        ref={this.frontal_sonriendo_1}
                      />
                      {this.state.frontal_sonriendo_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("frontal_sonriendo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Frontal Sonriendo</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="perfil_izq_reposo"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.perfil_izq_reposo_image2
                            ? this.state.perfil_izq_reposo_image2
                            : perfilizqreposo
                        }
                        alt="Imagen de"
                        ref={this.perfil_izq_reposo_1}
                      />
                      {this.state.perfil_izq_reposo_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("perfil_izq_reposo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>
                    Perfil Izquierdo en Reposo
                  </ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="trescuartos_perfil"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.trescuartos_perfil_image2
                            ? this.state.trescuartos_perfil_image2
                            : trescuartos_perfil
                        }
                        alt="Imagen de"
                        ref={this.trescuartos_perfil_1}
                      />
                      {this.state.trescuartos_perfil_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("trescuartos_perfil", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>3/4 Perfil Sonriendo</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="oclusal_maxilar_sup"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.oclusal_maxilar_sup_image2
                            ? this.state.oclusal_maxilar_sup_image2
                            : oclusalmaxilarsup
                        }
                        alt="Imagen de"
                        ref={this.oclusal_maxilar_sup_1}
                      />
                      {this.state.oclusal_maxilar_sup_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("oclusal_maxilar_sup", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>
                    Oclusal del Maxilar Superior
                  </ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="oclusal_maxilar_inf"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.oclusal_maxilar_inf_image2
                            ? this.state.oclusal_maxilar_inf_image2
                            : oclusalmaxilarinf
                        }
                        alt="Imagen de"
                        ref={this.oclusal_maxilar_inf_1}
                      />
                      {this.state.oclusal_maxilar_inf_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("oclusal_maxilar_inf", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>
                    Oclusal del Maxilar Inferior
                  </ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="anterior_oclusion"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.anterior_oclusion_image2
                            ? this.state.anterior_oclusion_image2
                            : anterioroclusion
                        }
                        alt="Imagen de"
                        ref={this.anterior_oclusion_1}
                      />
                      {this.state.anterior_oclusion_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("anterior_oclusion", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Anterior en Oclusion</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="bucal_derecho"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.bucal_derecho_image2
                            ? this.state.bucal_derecho_image2
                            : bucalderecho
                        }
                        alt="Imagen de"
                        ref={this.bucal_derecho_1}
                      />
                      {this.state.bucal_derecho_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("bucal_derecho", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Bucal Derecho</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="bucal_izquierdo"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.bucal_izquierdo_image2
                            ? this.state.bucal_izquierdo_image2
                            : bucalizquierdo
                        }
                        alt="Imagen de"
                        ref={this.bucal_izquierdo_1}
                      />
                      {this.state.bucal_izquierdo_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("bucal_izquierdo", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Bucal Izquierdo</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="anterior_inoclusion"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.anterior_inoclusion_image2
                            ? this.state.anterior_inoclusion_image2
                            : anteriorinoclusion
                        }
                        alt="Imagen de"
                        ref={this.anterior_inoclusion_1}
                      />
                      {this.state.anterior_inoclusion_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("anterior_inoclusion", "fotos")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Anterior en Inoclusion</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="rxpanoramica"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.rxpanoramica_image2
                            ? this.state.rxpanoramica_image2
                            : rxpanoramica
                        }
                        alt="Imagen de"
                        ref={this.rxpanoramica_1}
                      />
                      {this.state.rxpanoramica_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("rxpanoramica", "estudios")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>RX Panorámica</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="telerradiografia"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.telerradiografia_image2
                            ? this.state.telerradiografia_image2
                            : radioperfil
                        }
                        alt="Imagen de"
                        ref={this.telerradiografia_1}
                      />
                      {this.state.telerradiografia_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("telerradiografia", "estudios")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Telerradiografía de perfil</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="ricketts"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <ImageUploadReference
                        src={
                          this.state.ricketts_image2
                            ? this.state.ricketts_image2
                            : ricketts
                        }
                        alt="Imagen de"
                        ref={this.ricketts_1}
                      />
                      {this.state.ricketts_image2 ? (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            this.showCropArea("ricketts", "estudios")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <ImageUploadLabel>Cefalograma de Ricketts</ImageUploadLabel>
                </div>
                <div
                  className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                  style={{
                    maxHeight: "200px",
                    width: "200px",
                    position: "relative",
                  }}
                >
                  <div className="d-flex flex-row  m-0">
                    <ImageUploadContainer>
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        onChange={this.handleThirdImageSelect}
                        id="otro_1"
                        className="third_data"
                      />
                      <Upload />
                    </ImageUploadContainer>
                    {this.state.otro_1_image2 ? (
                      <ImageUploadReference
                        src={this.state.otro_1_image2}
                        alt="Imagen de"
                        ref={this.otro_1}
                        onClick={() => this.showCropArea("otro_2", "fotos")}
                      />
                    ) : (
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          background: "#112154",
                        }}
                      ></div>
                    )}
                  </div>
                  <ImageUploadLabel>Otras</ImageUploadLabel>
                </div>
              </div>
                </div>
                <div className="col-12 mt-3 d-flex flex-row justify-content-center">
                 <button className="btn btn-outline-primary rounded-pill px-5 mt-2 mx-1" onClick={() => this.setState({step: 2})}>Volver</button>
                 <button className="btn btn-primary rounded-pill px-5 mt-2 mx-1" onClick={() => this.setState({step: 4})}>Continuar</button>
                </div>
            </div>
        </div>}
        {this.state.step === 4 && <div className="container-fluid p-0">
            <Header />
            <div className="row px-5 mt-4">
                <div className="col-12 px-5">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/ayuda" className="text-muted">Centro de Ayuda</a></li>
                            <li className="breadcrumb-item"><a href="/ayuda/caso" className="text-muted">Presenta tu caso</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Enviar caso</li>
                        </ol>
                        </nav>
                </div>
            </div>
            <div className="shadow container pt-5 pb-4" style={{borderRadius: '20px'}}>
                <div className="row">
                    <div className="col-12 text-center">
                        {this.state.documentation_completed && <h1 className="fw-bold">¡Gracias por enviarnos tu caso!</h1>}
                        {!this.state.documentation_completed && <h1 className="fw-bold">Documentación incompleta</h1>}
                    </div>
                    <div className="col-12">
                        <StatusBar step={this.state.step} first_completed={Object.keys(this.state.first_data).length === 7 ? true : false} second_completed={Object.keys(this.state.second_data).length === 14 ? true : false} third_completed={Object.keys(this.state.third_data).length === 14 ? true : false} />
                    </div>
                </div>
                <div className="col-8 mx-auto mt-4 d-flex flex-row justify-content-center">
                    {this.state.documentation_completed && <p className="fs-4">El caso será evaluado por nuestro equipo de ortodoncistas y te confirmaremos si contás con el <strong>beneficio del 5% de descuento.</strong></p>}
                    {!this.state.documentation_completed && <p className="fs-4">Para enviar tu caso deberás cargar toda la documentación requerida.</p>}
                </div>
                <div className="col-12 d-flex flex-row justify-content-center mt-3">
                        {this.state.documentation_completed && <button className="btn btn-primary px-5 rounded-pill mx-2" onClick={() => this.handleSendData()}>Listo</button>}
                        {!this.state.documentation_completed && <Fragment><button className="btn btn-outline-primary px-5 rounded-pill mx-2" onClick={() => this.setState({step: 3})}>Volver</button><button className="btn btn-primary px-5 rounded-pill" onClick={() => this.handleSendData()}>Listo</button></Fragment>}
                </div>
            </div>
        </div>}
        </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
