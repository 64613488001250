import React from "react";
import '../Stages/index.css';

export const TreatmentLogo = (props) => {
  const { treatment_type } = props;
  return (
    <div
      className={`text-center p-3 treatments_logo ${treatment_type === 1 ? "color-1" : ""}${
        treatment_type === 2 ? "color-2": ""
      }${treatment_type === 3 ? "color-3" : ""}${
        treatment_type === 4 ? "color-4" : ""
      }${treatment_type === 5 ? "color-5":""}${
        treatment_type === 6 ? "color-6":""
      }`}
    >
      {treatment_type === 1 && "Full"}
      {treatment_type === 2 && "Kids"}
      {treatment_type === 3 && "Fast"}
      {treatment_type === 4 && "Kids Fast"}
      {treatment_type === 5 && "Medium"}
      {treatment_type === 6 && "Teen"}
    </div>
  );
};

