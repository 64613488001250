import React, {Component} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export default class RevisionModal extends Component {

  constructor(props){
    super(props)

    this.state = {
      buttons: 'permanents'
    }

    this.addTablesAndRows = this.addTablesAndRows.bind(this)
  }

  componentDidMount(){
    this.addTablesAndRows()
  }

  addTablesAndRows(){
    let bs = []
    let bi = []
    let bls = []
    let bli = []
    
    let tbs = []
    let tbi = []
    let tbls = []
    let tbli = []


    let vestibular_superior = []
    let vestibular_superior_left = []
    let vestibular_superior_right = []

    let bucal_inf = []
    let bucal_inf_left = []
    let bucal_inf_right = []

    for(let i = 48; i >= 41; i--){
      bucal_inf_left.push({label: i, value: ''})
    }

    for(let i = 31; i <= 38; i++){
      bucal_inf_right.push({label: i, value: ''})
    }

    bucal_inf = [...bucal_inf_left, ...bucal_inf_right]
    


    for(let i = 11; i <= 85; i++){
      if(i >= 11 && i <= 18){
        vestibular_superior_left.push({label: i, value: ''})
      }

      if(i >= 21 && i <= 28){
        vestibular_superior_right.push({label: i, value: ''})
      }

      if(i <= 28) {
        bs.push({label: i, value: ''})
        bls.push({label: i, value: ''})
      } else if(i >= 38 && i <= 48) {
        bi.push({label: i, value: ''})
        bli.push({label: i, value: ''})
      } else if(i === 55) {
        tbs.push({label:55, value: ''})
        tbs.push({label:54, value: ''})
        tbs.push({label:53, value: ''})
        tbs.push({label:52, value: ''})
        tbs.push({label:51, value: ''})

        tbs.push({label: 61, value: ''})
        tbs.push({label: 62, value: ''})
        tbs.push({label: 63, value: ''})
        tbs.push({label: 64, value: ''})
        tbs.push({label: 65, value: ''})
        
        tbls.push({label:55, value: ''})
        tbls.push({label:54, value: ''})
        tbls.push({label:53, value: ''})
        tbls.push({label:52, value: ''})
        tbls.push({label:51, value: ''})

        tbls.push({label: 61, value: ''})
        tbls.push({label: 62, value: ''})
        tbls.push({label: 63, value: ''})
        tbls.push({label: 64, value: ''})
        tbls.push({label: 65, value: ''})
        
      } else if(i >= 75 && i <= 85) {
        tbi.push({label: i, value: ''})
        tbli.push({label: i, value: ''})
      }
      else {
        console.log(tbs, tbls, tbi, tbli)
      }
    }

    bi = bi.reverse()
    bli = bli.reverse()
    tbi = tbi.reverse()
    tbli = tbli.reverse()

    vestibular_superior_left.reverse()
    vestibular_superior = [...vestibular_superior_left, ...vestibular_superior_right]

    this.setState({vestibular_superior, bs, bi: bucal_inf, bls: vestibular_superior, bli: bucal_inf, tbs, tbi, tbls, tbli, loaded: true})

    console.log(this.state)

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(type, label, value){
    let arr = this.state[type]
    let item = arr.find(item => item.label === label)
    item.value = value
    arr[item] = item

    this.setState({[type]: arr})
    console.log(this.state)
  }

  render(){
    const {
      className,
      opened,
      handleModal
    } = this.props;
    if(this.state.loaded ){
      return (
        <div>
          <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className} size="lg">
              <ModalBody>
              <div className="container">
                  <div className="row">
                    <div className="col-12 references">
                      <table className="table">
                        <thead>
                          <tr>
                            <td>Tip</td>
                            <td>Molar</td>
                            <td>Rotar Mesial</td>
                            <td>Rotar Distal</td>
                            <td>Excluir Intuir</td>
                            <td>Doble Tip</td>
                            <td>Barra de Presion</td>
                            <td>Extraccion</td>
                            <td>Ausente</td>
                          </tr>
                        </thead>
    
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>T</td>
                            <td>M</td>
                            <td>RM</td>
                            <td>RD</td>
                            <td>EL</td>
                            <td>DT</td>
                            <td>BP</td>
                            <td>EX</td>
                            <td>A</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
    
                  <div className="row my-2">
                    <div className="col-6 d-flex flex-row justify-content-center"><button className={this.state.buttons === 'permanents' ? `btn btn-primary disabled` : 'btn btn-primary'} onClick={() => this.setState({buttons: 'permanents'})}>Permanentes</button></div>
                    <div className="col-6 d-flex flex-row justify-content-center"><button className={this.state.buttons === 'temporals' ? `btn btn-primary disabled` : 'btn btn-primary'} onClick={() => this.setState({buttons: 'temporals'})}>Temporarios</button></div>
                  </div>
    
                  {this.state.buttons === 'permanents' && <div className="container">
                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Bucal Superior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.vestibular_superior.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`bs-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`bs-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('bs', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Bucal Inferior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.bi.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`bi-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`bi-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('bi', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Lingual Superior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.bls.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`bls-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`bls-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('bls', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Lingual Inferior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.bli.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`bli-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`bli-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('bli', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>
                  </div>}


                  {this.state.buttons === 'temporals' && <div className="container">
                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Temporal Bucal Superior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.tbs.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`tbs-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`tbs-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('tbs', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Temporal Bucal Inferior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.tbi.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`tbi-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`tbi-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('tbi', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Temporal Lingual Superior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.tbls.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`tbls-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`tbls-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('tbls', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>


                  <div className="row">
                    <div className="col-12 bg-primary d-flex flex-row justify-content-center text-center p-2 my-3">
                      <p className="text-white m-0">Botones - Temporal Lingual Inferior</p>
                    </div>
    
                    <div className="d-flex flex-row justify-content-between align-items-center flex-nowrap">
                      {this.state.tbli.map(item => {
                        return (<div className="d-flex flex-column justify-content-center text-center">
                          <label htmlFor={`tbli-${item.label}`} >{item.label}</label>        
                          <input type="text" id={`tbli-${item.label}`} className="form-control" value={item.value} onChange={(e) => this.handleInputChange('tbli', item.label, e.target.value)} />
                        </div> )
                      })}
                    </div>
                  </div>
                  </div>}
                  <div className="row mt-3">
                    <div className="col-6"><textarea name="permanents" onChange={(e) => this.setState({[e.target.id]: e.target.value})} id="text_permanents" cols="50" rows="5"></textarea></div>
                    <div className="col-6"><textarea name="temporals" onChange={(e) => this.setState({[e.target.id]: e.target.value})} id="text_temporals" cols="50" rows="5"></textarea></div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                
                  {!this.state.loading && 
                    <div>
                      <button className="btn btn-outline-light text-dark" onClick={handleModal}>Volver</button>
                      <button className="btn btn-success text-white" onClick={() => console.log(this.state)}>Aprobar</button>
                    </div>
                  }

                  {this.state.loading &&
                  <button class="btn btn-success" type="button" disabled>
                    <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
                  </button>
                  }
                  
              </ModalFooter>
          </Modal>
        </div>
      )

    } else {
      return null
    }
  }
}
