import React from 'react'
import {ChevronDown, ChevronUp, DollarSign} from 'react-feather'

function PaymentStatusCard({handleCard, doctor, current_payments, critical_payment, overdue_payments, i, card_opened}) {

  return (
    <div className="row" style={{paddingLeft: '15px'}}>
        <div className="col-6 d-flex flex-row justify-content-start px-4">
          <p className="mb-0" style={{lineHeight: '38px'}}>{doctor}</p>
        </div>
        <div className="col-1 d-flex flex-row justify-content-center">
          {critical_payment && <div className="bg-danger rounded-circle p-1 d-flex justify-content-center" style={{width: '32px', height: '32px'}} >
            <DollarSign color="#ffffff" size="18" style={{marginTop: '3px'}} />
          </div>}
        </div>
        <div className="col-1 d-flex flex-row justify-content-center">
          <p className="mb-0 text-black-50" style={{lineHeight: '38px'}}>${current_payments}</p>
        </div>
        <div className="col-1 d-flex flex-row justify-content-center">
          <p className="mb-0 text-danger" style={{lineHeight: '38px'}}>${overdue_payments}</p>
        </div>
        <div className="col-1 d-flex flex-row justify-content-center">
          <p className="mb-0" style={{lineHeight: '38px'}}>${current_payments + overdue_payments}</p>
        </div>
        <div className="col-2 d-flex flex-row justify-content-center">
          <p className="mb-0" style={{lineHeight: '38px'}}>
              {card_opened === i ? (
                  <ChevronUp onClick={() => handleCard(i)} color="#444" size="24" />
              ) : (
                  <ChevronDown onClick={() => handleCard(i)} color="#444" size="24" />
              )}
          </p>
        </div>
    </div>
  )
}

export default PaymentStatusCard