import React, { Component, Fragment } from "react";
import { Upload, Check, Edit2 } from "react-feather";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";
import * as cropro from "cropro";
import { Document, Page, pdfjs } from "react-pdf";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import "react-toastify/dist/ReactToastify.css";

import {
  ImageUploadContainer,
  ImageUploadLabel,
  ImageUploadReference,
} from "./components/styled";

import "./styles.css";

// COMPONENTS

// IMAGES STEP 2
import trescuartos_perfil from "../../assets/images/34deperfilsonriendo.png";
import anterioroclusion from "../../assets/images/anteriorenoclusion.png";
import anteriorinoclusion from "../../assets/images/anterioreninoclusion.png";
import bucalderecho from "../../assets/images/bucalderecho.png";
import bucalizquierdo from "../../assets/images/bucalizquierdo.png";
import frontalreposo from "../../assets/images/frontalenreposo.png";
import frontalsonriendo from "../../assets/images/frontalsonriendo.png";
import oclusalmaxilarinf from "../../assets/images/oclusaldelmaxilarinferior.png";
import oclusalmaxilarsup from "../../assets/images/oclusaldelmaxilarsuperior.png";
import perfilizqreposo from "../../assets/images/perfilizquierdoenreposo.png";

// IMAGES STEP 3
import radioperfil from "../../assets/images/telerradiografiadeperfil.png";
import ricketts from "../../assets/images/cefalogramadericketts.png";
import rxpanoramica from "../../assets/images/rxpanoramica.png";

import plan from "../../assets/images/filename.svg";

// CROPPER
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import StepCounter from "./components/step_counter";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numPages: null,
      pageNumber: 1,
      treatment_id: null,
      plan_file: null,
      step: 2,
      loading: true,
      data: {},

      // VISTA 1
      od_list: null,
      nombre: null,
      apellido: null,
      genero: "m",
      maxilar: 0,
      maxilar_string: "",
      external_id: null,
      odontologo: "",
      dentist_id: null,
      treatment_type: 1,
      intraoral: 1,
      etapa: 1,
      is_new_patient: true,
      od_patients: [],
      selected_patient: null,
      selected_od: null,

      fotos: {},
      estudios: {},
      plan: {},

      registration_datetime: null,

      // STEP DATA
      flag_photos: 0,
      flag_studies: 0,
      flag_plan: 0,

      // ERROR HANDLER
      error: {
        error: false,
        message: "",
      },

      // CROPPER
      cropper: false,
      cropper_img: null,
      cropper_file: null,

      errors: [],

      first_data: {},
      second_data: [],
      registry: true,
    };

    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleImagePreview = this.handleImagePreview.bind(this);
    this.handleFirstFormValidation = this.handleFirstFormValidation.bind(this);
    this.saveObservation = this.saveObservation.bind(this);
    this.showCropArea = this.showCropArea.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);

    this.cropperRef = React.createRef();

    let files = [
      "frontal_reposo",
      "frontal_sonriendo",
      "perfil_izq_reposo",
      "trescuartos_perfil",
      "oclusal_maxilar_sup",
      "oclusal_maxilar_inf",
      "anterior_oclusion",
      "bucal_derecho",
      "bucal_izquierdo",
      "anterior_inoclusion",
      "otro",
      "rxpanoramica",
      "telerradiografia",
      "ricketts",
      "otro2",
      "plantratamiento",
    ];

    for (let i = 0; i <= files.length; i++) {
      this[files[i]] = React.createRef();
    }
  }

  componentDidMount() {
    const path = window.location.pathname.split("/")[4];
    apiClient.get(`/api/helper/getClinicalHistory/${path}`).then((res) => {
      const d = res.data.data;
      console.log(d);

      const first_data = {
        name: d.patient.user.name,
        surname: d.patient.user.surname,
        gender: d.patient.user.gender,
        treatment_type: d.type,
      };
      console.log(Object.values(first_data));

      const second_data = [];
      d.photos_files.map((photo) => (second_data[photo.name] = photo));

      const third_data = [];
      d.studies_files.map((studie) => (third_data[studie.name] = studie));

      const fourth_data = d.plan_file;
      this.setState({ first_data, second_data, third_data, fourth_data });
      this.setState({
        data: res.data.data,
        treatment_id: res.data.data.id,
        loading: false,
      });
    });
    console.log(this.state);
  }

  onDocumentLoadSuccess({ numPages }) {
    this.setState({
      numPages: numPages,
    });
  }

  handleImageSelect = async (e) => {
    e.preventDefault();

    let image_name = e.target.id + "_image";
    let file = e.target.files[0];
    let $this = this;

    if (e.target.id === "plantratamiento") {
      let reader = new FileReader();

      reader.onloadend = () => {
        console.log("this");
        console.log($this);
        $this.setState({
          [image_name]: reader.result,
          cropper_img: reader.result,
          [e.target.className]: {
            ...$this.state[e.target.className],
            [e.target.id]: reader.result,
          },
        });
      };

      reader.readAsDataURL(file);
    } else {
      new Compressor(file, {
        quality: 0.1,
        success(result) {
          let reader = new FileReader();
          let file_compressed = result;
          console.log("result");
          console.log(result);

          reader.onloadend = () => {
            console.log("this");
            console.log($this);
            $this.setState({
              [image_name]: reader.result,
              cropper_img: reader.result,
              [e.target.className]: {
                ...$this.state[e.target.className],
                [e.target.id]: reader.result,
              },
            });
          };

          reader.readAsDataURL(file_compressed);
        },
        error(err) {
          console.log(err.message);
        },
      });
    }

    console.log(this.state);
  };

  handleGetPatients(id) {
    apiClient
      .get(`api/helper/getPatientsByDentist/${id}`)
      .then((res) => {
        let patients = [];
        Object.entries(res.data).map(([key, value]) => {
          patients.push({ value: key, label: value });
        });
        this.setState({ od_patients: patients });
        console.log(this.state);
      })
      .catch((err) => console.log(err));
    console.log(this.state);
  }

  handleImagePreview(e) {
    let file = e.target.files[0];
    let name = e.target.id + "_image";

    let url = URL.createObjectURL(file);
    this.setState({ [name]: url });
    console.log(url);
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  getFiles(ref) {
    console.log(ref);
    console.log(this.state.frontal_reposo);
    if (this[ref].current !== null) {
      console.log(this[ref].current);
      return this[ref].current.src;
    }
  }

  handleImageUpload = () => {
    console.log("sending all");
    toast.info("Guardando informacion... 👨‍💻", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    let data = {
      external_id: this.state.external_id,
      photos: this.state.fotos,
      studies: this.state.estudios,
      plan: this.state.plan,
    };

    if (Object.keys(this.state.fotos).length > 0) {
      console.log("entra fotos");
      data.hasPhotos = 1;
    }

    if (Object.keys(this.state.estudios).length > 0) {
      console.log("entra fotos");
      data.hasStudies = 1;
    }

    if (Object.keys(this.state.plan).length > 0) {
      data.hasPlan = 1;
    }

    const headers = {
      withCredentials: true,
    };

    apiClient
      .put(`/api/incomes/${this.state.treatment_id}`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data);
        if (res.status === 200 || res.data === "success") {
          toast.success(
            "Tratamiento actualizado correctamente 👍! Redireccionando...",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
            }
          );

          setTimeout(() => {
            window.location.href = "/od/solicitudes";
          }, 3000);
        }
      })
      .catch((err) => console.log(err));
  };

  handleFirstFormValidation() {
    this.setState({ step: 2 });
  }

  saveObservation() {
    console.log("asd");
    console.log(this.state.observation);
    apiClient
      .put(`/api/incomes/${this.state.treatment_id}`, {
        observation: this.state.observation,
      })
      .then((res) =>
        toast.success("Observación guardada correctamente.", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      )
      .catch((err) => console.log(err));
  }

  handleFaltan(tipo) {
    apiClient
      .put(`/api/incomes/${this.state.treatment_id}`, {
        [tipo]: !this.state["flag_" + tipo],
      })
      .then((res) => {
        console.log(res);
        this.setState({
          ["flag_" + tipo]: !this.state["flag_" + tipo],
        });
      })
      .catch((err) => console.log(err));
  }

  showCropArea(ref, type) {
    console.log("asdasd");
    if (this[ref].current !== null) {
      // create a CropArea
      const cropArea = new cropro.CropArea(this[ref].current);
      cropArea.displayMode = "popup";
      cropArea.zoomToCropEnabled = false;
      cropArea.isGridVisible = false;

      // attach an event handler to assign cropped image back to our image element
      cropArea.addRenderEventListener((dataUrl) => {
        this.setState({
          [type]: {
            ...this.state[type],
            [ref]: dataUrl,
          },
        });

        if (this[ref].current) {
          this[ref].current.src = dataUrl;
        }

        let documentation = Object.keys(this.state[type]);
        console.log(documentation);
        let next = documentation.findIndex((document) => document === ref) + 1;
        this.showCropArea(documentation[next], type);
      });
      console.log(this.state);
      // launch CROPRO
      cropArea.show();
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <Fragment>
          {console.log(this.state)}
          <div>
            {this.state.step === 1 && (
              <div style={{ height: "100%", overflowX: "hidden" }}>
                <div className="container mt-2">
                  <div className="row">
                    <div className="col-12 px-5">
                      <h1
                        style={{ fontWeight: 700 }}
                        className="text-primary text-center"
                      >
                        Documentación Incompleta
                      </h1>
                    </div>
                    <div className="col-12 mx-auto text-center">
                      <h2 style={{ fontWeight: 700 }}>Carga de Datos</h2>
                    </div>
                    <StepCounter
                      step={this.state.step}
                      data_completed={
                        Object.values(this.state.first_data).length === 4
                      }
                      fotos_completed={
                        this.state.data.photos_files.length >= 10
                      }
                      estudios_completed={
                        this.state.data.studies_files.length >= 3
                      }
                      plan_completed={this.state.fourth_data !== null}
                      registry_completed={this.state.registry}
                    />
                  </div>
                  <div className="row mt-4">
                    {this.state.error.error ? (
                      <div className="col-12 alert alert-danger text-center">
                        {this.state.error.message}
                      </div>
                    ) : null}
                    <div className="col-12 mx-auto">
                      <form className="row col-6 mx-auto">
                        <div className="col-12 mb-2">
                          <h4>Paciente</h4>
                        </div>
                        <div className="col-6">
                          <label htmlFor="nombre">Nombre</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            id="nombre"
                            value={this.state.data.patient.user.name}
                          />
                          <span style={{ color: "red" }}>
                            {this.state.errors["name"]}
                          </span>
                        </div>
                        <div className="col-6">
                          <label htmlFor="apellido">Apellido</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            id="apellido"
                            value={this.state.data.patient.user.surname}
                          />
                          <span style={{ color: "red" }}>
                            {this.state.errors["surname"]}
                          </span>
                        </div>
                      </form>
                      <form className="row mb-4 col-6 mx-auto">
                        <div className="col-12">
                          <label htmlFor="genero">Genero</label>
                          <select
                            name="genero"
                            id="genero"
                            className="form-control"
                            disabled
                            value={this.state.data.patient.user.gender}
                          >
                            <option value="m">Masculino</option>
                            <option value="f">Femenino</option>
                            <option value="o">Otro</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {this.state.errors["gender"]}
                          </span>
                        </div>
                      </form>
                      <form className="row col-6 mx-auto">
                        <div className="col-12 mb-2">
                          <h4>Tratamiento</h4>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ treatment_type: 1 });
                            }}
                            className={
                              this.state.data.type === "Full"
                                ? "btn w-100 my-2 rounded-pill px-5 disabled btn-primary active"
                                : "btn w-100 my-2 rounded-pill px-5 btn-outline-primary disabled"
                            }
                          >
                            KeepSmiling Full
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ treatment_type: 2 });
                            }}
                            className={
                              this.state.data.type === "Kids"
                                ? "btn w-100 my-2 rounded-pill px-5 btn-warning disabled active"
                                : "btn w-100 my-2 rounded-pill px-5 btn-outline-warning disabled"
                            }
                          >
                            KeepSmiling Kids
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ treatment_type: 3 });
                            }}
                            className={
                              this.state.data.type === "Fast"
                                ? "btn w-100 my-2 rounded-pill px-5 btn-secondary disabled active"
                                : "btn w-100 my-2 rounded-pill px-5 btn-outline-secondary disabled"
                            }
                          >
                            KeepSmiling Fast
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ treatment_type: 4 });
                            }}
                            className={
                              this.state.data.type === "Kids Fast"
                                ? "btn w-100 my-2 rounded-pill px-5 btn-danger disabled active"
                                : "btn w-100 my-2 rounded-pill px-5 btn-outline-danger disabled"
                            }
                          >
                            KeepSmiling Kids Fast
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ treatment_type: 5 });
                            }}
                            className={
                              this.state.data.type === "Medium"
                                ? "btn w-100 my-2 rounded-pill px-5 btn-info disabled active"
                                : "btn w-100 my-2 rounded-pill px-5 btn-outline-info disabled"
                            }
                          >
                            KeepSmiling Medium
                          </button>
                        </div>
                        <div className="col-6">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ treatment_type: 6 });
                            }}
                            className={
                              this.state.data.type === "Teens"
                                ? "btn w-100 my-2 rounded-pill px-5 btn-success disabled active"
                                : "btn w-100 my-2 rounded-pill px-5 btn-outline-success disabled"
                            }
                          >
                            KeepSmiling Teens
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-end">
                      <button
                        className="btn btn-primary rounded-pill px-5"
                        onClick={() => {
                          this.handleFirstFormValidation();
                        }}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.step === 2 && (
              <div style={{ height: "100%", overflowX: "hidden" }}>
                {console.log("PATH ", this.state.second_data["frontal_reposo"])}
                <div className="container mt-2">
                  <div className="row">
                    <div className="col-12 px-5">
                      <h1
                        style={{ fontWeight: 700 }}
                        className="text-primary text-center"
                      >
                        Documentación Incompleta
                      </h1>
                    </div>
                    <div className="col-12 mx-auto text-center">
                      <h2 style={{ fontWeight: 700 }}>Adjuntar fotos</h2>
                    </div>
                    <StepCounter
                      step={this.state.step}
                      data_completed={
                        Object.values(this.state.first_data).length === 4
                      }
                      fotos_completed={
                        this.state.data.photos_files.length >= 10
                      }
                      estudios_completed={
                        this.state.data.studies_files.length >= 3
                      }
                      plan_completed={this.state.fourth_data !== null}
                      registry_completed={this.state.registry}
                    />
                  </div>
                  {this.state.cropper && (
                    <div className="row d-flex flex-row justify-content-center mt-4 mb-4">
                      <div className="col-6 mx-auto">
                        <hr />
                        <div>
                          <img
                            ref={this.cropperRef}
                            src={this.state.cropper_file}
                            alt=""
                          />
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <label htmlFor="degree">Rotar imagen</label>
                          <input
                            type="range"
                            onChange={(e) => this.handleCropperRotate(e)}
                            className="form-range"
                            min={-180}
                            max={180}
                            step={5}
                            name=""
                            id="degree"
                          />
                        </div>
                        <div className="d-flex flex-row justify-content-end mt-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.getCrop()}
                          >
                            Cortar
                          </button>
                        </div>
                        <hr />
                      </div>
                    </div>
                  )}
                  <div className="row d-flex flex-row justify-content-center mx-auto px-5">
                    <div
                      className="col-3 image-upload-container d-flex flex-column my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["frontal_reposo"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["frontal_reposo"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="frontal_reposo"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["frontal_reposo"]
                                ? this.state.second_data["frontal_reposo"].path
                                : this.state.fotos.frontal_reposo
                                ? this.state.fotos.frontal_reposo
                                : frontalreposo
                            }
                            alt="Imagen de"
                            ref={this.frontal_reposo}
                          />
                          {this.state.second_data["frontal_reposo"] ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("frontal_reposo", "fotos")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>Frontal en Reposo</ImageUploadLabel>
                    </div>

                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["frontal_sonriendo"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["frontal_sonriendo"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="frontal_sonriendo"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["frontal_sonriendo"]
                                ? this.state.second_data["frontal_sonriendo"]
                                    .path
                                : this.state.fotos.frontal_sonriendo
                                ? this.state.fotos.frontal_sonriendo
                                : frontalsonriendo
                            }
                            alt="Imagen de"
                            ref={this.frontal_sonriendo}
                          />
                          {this.state.second_data["frontal_sonriendo"] ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("frontal_sonriendo", "fotos")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>Frontal Sonriendo</ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["perfil_izq_reposo"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["perfil_izq_reposo"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="perfil_izq_reposo"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["perfil_izq_reposo"]
                                ? this.state.second_data["perfil_izq_reposo"]
                                    .path
                                : this.state.fotos.perfil_izq_reposo
                                ? this.state.fotos.perfil_izq_reposo
                                : perfilizqreposo
                            }
                            alt="Imagen de"
                            ref={this.perfil_izq_reposo}
                          />
                          {this.state.perfil_izq_reposo_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("perfil_izq_reposo", "fotos")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>
                        Perfil Izquierdo en Reposo
                      </ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["trescuartos_perfil"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["trescuartos_perfil"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="trescuartos_perfil"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["trescuartos_perfil"]
                                ? this.state.second_data["trescuartos_perfil"]
                                    .path
                                : this.state.fotos.trescuartos_perfil
                                ? this.state.fotos.trescuartos_perfil
                                : trescuartos_perfil
                            }
                            alt="Imagen de"
                            ref={this.trescuartos_perfil}
                          />
                          {this.state.trescuartos_perfil_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("trescuartos_perfil", "fotos")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>3/4 Perfil Sonriendo</ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["oclusal_maxilar_sup"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["oclusal_maxilar_sup"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="oclusal_maxilar_sup"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["oclusal_maxilar_sup"]
                                ? this.state.second_data["oclusal_maxilar_sup"]
                                    .path
                                : this.state.fotos.oclusal_maxilar_sup
                                ? this.state.fotos.oclusal_maxilar_sup
                                : oclusalmaxilarsup
                            }
                            alt="Imagen de"
                            ref={this.oclusal_maxilar_sup}
                          />
                          {this.state.oclusal_maxilar_sup_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea(
                                  "oclusal_maxilar_sup",
                                  "fotos"
                                )
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>
                        Oclusal del Maxilar Superior
                      </ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["oclusal_maxilar_inf"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["oclusal_maxilar_inf"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="oclusal_maxilar_inf"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["oclusal_maxilar_inf"]
                                ? this.state.second_data["oclusal_maxilar_inf"]
                                    .path
                                : this.state.fotos.oclusal_maxilar_inf
                                ? this.state.fotos.oclusal_maxilar_inf
                                : oclusalmaxilarinf
                            }
                            alt="Imagen de"
                            ref={this.oclusal_maxilar_inf}
                          />
                          {this.state.oclusal_maxilar_inf_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea(
                                  "oclusal_maxilar_inf",
                                  "fotos"
                                )
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>
                        Oclusal del Maxilar Inferior
                      </ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["anterior_oclusion"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["anterior_oclusion"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="anterior_oclusion"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["anterior_oclusion"]
                                ? this.state.second_data["anterior_oclusion"]
                                    .path
                                : this.state.fotos.anterior_oclusion
                                ? this.state.fotos.anterior_oclusion
                                : anterioroclusion
                            }
                            alt="Imagen de"
                            ref={this.anterior_oclusion}
                          />
                          {this.state.anterior_oclusion_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("anterior_oclusion", "fotos")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>Anterior en Oclusion</ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["bucal_derecho"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["bucal_derecho"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="bucal_derecho"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["bucal_derecho"]
                                ? this.state.second_data["bucal_derecho"].path
                                : this.state.fotos.bucal_derecho
                                ? this.state.fotos.bucal_derecho
                                : bucalderecho
                            }
                            alt="Imagen de"
                            ref={this.bucal_derecho}
                          />
                          {this.state.bucal_derecho_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("bucal_derecho", "fotos")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>Bucal Derecho</ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["bucal_izquierdo"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["bucal_izquierdo"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="bucal_izquierdo"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["bucal_izquierdo"]
                                ? this.state.second_data["bucal_izquierdo"].path
                                : this.state.fotos.bucal_izquierdo
                                ? this.state.fotos.bucal_izquierdo
                                : bucalizquierdo
                            }
                            alt="Imagen de"
                            ref={this.bucal_izquierdo}
                          />
                          {this.state.bucal_izquierdo_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("bucal_izquierdo", "fotos")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>Bucal Izquierdo</ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["anterior_inoclusion"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.second_data["anterior_inoclusion"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="anterior_inoclusion"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.second_data["anterior_inoclusion"]
                                ? this.state.second_data["anterior_inoclusion"]
                                    .path
                                : this.state.fotos.anterior_inoclusion
                                ? this.state.fotos.anterior_inoclusion
                                : anteriorinoclusion
                            }
                            alt="Imagen de"
                            ref={this.anterior_inoclusion}
                          />
                          {this.state.anterior_inoclusion_image ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea(
                                  "anterior_inoclusion",
                                  "fotos"
                                )
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>
                        Anterior en Inoclusion
                      </ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data["otro"] ? "bg-d" : "bg-g"
                          }`}
                        >
                          {this.state.second_data["otro"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="otro"
                                className="fotos"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        {this.state.otro_image ? (
                          <ImageUploadReference
                            src={this.state.otro_image}
                            alt="Imagen de"
                            ref={this.otro}
                            onClick={() => this.showCropArea("otro", "fotos")}
                          />
                        ) : (
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              background: "#112154",
                            }}
                          ></div>
                        )}
                      </div>
                      <ImageUploadLabel>Otras</ImageUploadLabel>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12 f-flex text-end">
                      <button
                        className="btn btn-outline-primary mx-2 rounded-pill px-5"
                        onClick={() => this.setState({ step: 1 })}
                      >
                        Volver
                      </button>
                      <button
                        className="btn btn-primary rounded-pill px-5"
                        onClick={() => this.setState({ step: 3 })}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.step === 3 && (
              <div style={{ height: "100%", overflowX: "hidden" }}>
                <div className="container mt-2">
                  <div className="row">
                    <div className="col-12 px-5">
                      <h1
                        style={{ fontWeight: 700 }}
                        className="text-primary text-center"
                      >
                        Documentación Incompleta
                      </h1>
                    </div>
                    <div className="col-12 mx-auto text-center">
                      <h2 style={{ fontWeight: 700 }}>Adjuntar Estudios</h2>
                    </div>
                    <StepCounter
                      step={this.state.step}
                      data_completed={
                        Object.values(this.state.first_data).length === 4
                      }
                      fotos_completed={
                        Object.values(this.state.fotos).length >= 10
                      }
                      estudios_completed={
                        this.state.data.studies_files.length >= 3
                      }
                      plan_completed={this.state.fourth_data !== null}
                      registry_completed={this.state.registry}
                    />
                  </div>
                  {this.state.cropper && (
                    <div className="row d-flex flex-row justify-content-center mt-4 mb-4">
                      <div className="col-6 mx-auto">
                        <hr />
                        <div>
                          <img src={this.state.cropper_file} alt="" />
                          <Cropper
                            src={this.state[this.state.cropper_img]}
                            style={{ height: 400, width: "100%" }}
                            // Cropper.js options
                            initialAspectRatio={1 / 1}
                            guides={false}
                            crop={this.onCrop}
                            ref={this.cropperRef}
                            background={false}
                            rotatable={true}
                          />
                        </div>
                        <div className="d-flex flex-column mt-3">
                          <label htmlFor="degree">Rotar imagen</label>
                          <input
                            type="range"
                            onChange={(e) => this.handleCropperRotate(e)}
                            className="form-range"
                            min={-180}
                            max={180}
                            step={5}
                            name=""
                            id="degree"
                          />
                        </div>
                        <div className="d-flex flex-row justify-content-end mt-2">
                          <button
                            className="btn btn-primary"
                            onClick={() => this.getCrop()}
                          >
                            Cortar
                          </button>
                        </div>
                        <hr />
                      </div>
                    </div>
                  )}
                  <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.third_data["rxpanoramica"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.third_data["rxpanoramica"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="rxpanoramica"
                                className="estudios"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.third_data["rxpanoramica"]
                                ? this.state.third_data["rxpanoramica"].path
                                : this.state.estudios.rx_panoramica
                                ? this.state.estudios.rx_panoramica
                                : rxpanoramica
                            }
                            alt="Imagen de"
                            ref={this.rxpanoramica}
                          />
                          {this.state.third_data["rxpanoramica"] ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("rxpanoramica", "estudios")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>RX Panorámica</ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.third_data["telerradiografia"]
                              ? "bg-d"
                              : "bg-g"
                          }`}
                        >
                          {this.state.third_data["telerradiografia"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="telerradiografia"
                                className="estudios"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.third_data["telerradiografia"]
                                ? this.state.third_data["telerradiografia"].path
                                : this.state.estudios.telerradiografia
                                ? this.state.estudios.telerradiografia
                                : radioperfil
                            }
                            alt="Imagen de"
                            ref={this.telerradiografia}
                          />
                          {this.state.third_data["telerradiografia"] ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea(
                                  "telerradiografia",
                                  "estudios"
                                )
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>
                        Telerradiografía de perfil
                      </ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.third_data["ricketts"] ? "bg-d" : "bg-g"
                          }`}
                        >
                          {this.state.third_data["ricketts"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="ricketts"
                                className="estudios"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <ImageUploadReference
                            src={
                              this.state.third_data["ricketts"]
                                ? this.state.third_data["ricketts"].path
                                : this.state.estudios.ricketts
                                ? this.state.estudios.ricketts
                                : ricketts
                            }
                            alt="Imagen de"
                            ref={this.ricketts}
                          />
                          {this.state.third_data["ricketts"] ? (
                            <button
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "10px",
                                backgroundColor: "#cecece",
                                borderRadius: "0!important",
                                border: "none",
                              }}
                              onClick={() =>
                                this.showCropArea("ricketts", "estudios")
                              }
                            >
                              <Edit2 size="16" />
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <ImageUploadLabel>
                        Cefalograma de Ricketts
                      </ImageUploadLabel>
                    </div>
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.third_data["otro2"] ? "bg-d" : "bg-g"
                          }`}
                        >
                          {this.state.third_data["otro2"] ? (
                            <div className="bg-success rounded-circle p-2">
                              <Check color="#fff" size="28" strokeWidth="3" />
                            </div>
                          ) : (
                            <Fragment>
                              <input
                                type="file"
                                accept=".png, .jpg, .jpeg"
                                onChange={this.handleImageSelect}
                                id="otro2"
                                className="estudios"
                              />
                              <Upload />
                            </Fragment>
                          )}
                        </ImageUploadContainer>
                        {this.state.otro2_image ? (
                          <ImageUploadReference
                            src={this.state.otro2_image}
                            alt="Imagen de"
                            ref={this.otro2}
                            onClick={() =>
                              this.showCropArea("otro2", "estudios")
                            }
                          />
                        ) : (
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              background: "#112154",
                            }}
                          ></div>
                        )}
                      </div>
                      <ImageUploadLabel>Otras</ImageUploadLabel>
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12 f-flex text-end">
                      <button
                        className="btn btn-outline-primary mx-2 rounded-pill px-5"
                        onClick={() => this.setState({ step: 2 })}
                      >
                        Volver
                      </button>
                      <button
                        className="btn btn-primary rounded-pill px-5"
                        onClick={() => this.setState({ step: 4 })}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.step === 4 && (
              <div style={{ height: "100%", overflowX: "hidden" }}>
                <div className="container mt-2">
                  <div className="row">
                    <div className="col-12 px-5">
                      <h1
                        style={{ fontWeight: 700 }}
                        className="text-primary text-center"
                      >
                        Documentación Incompleta
                      </h1>
                    </div>
                    <div className="col-12 mx-auto text-center">
                      <h2 style={{ fontWeight: 700 }}>
                        Adjuntar Plan de Tratamiento
                      </h2>
                    </div>
                    <StepCounter
                      step={this.state.step}
                      data_completed={
                        Object.values(this.state.first_data).length === 4
                      }
                      fotos_completed={
                        Object.values(this.state.fotos).length >= 10
                      }
                      estudios_completed={
                        Object.values(this.state.estudios).length >= 3
                      }
                      plan_completed={
                        Object.values(this.state.plan).length === 1
                      }
                      registry_completed={this.state.registry}
                    />
                  </div>
                  <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
                    <div
                      className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                      style={{
                        maxHeight: "200px",
                        width: "200px",
                        position: "relative",
                      }}
                    >
                      <div className="d-flex flex-row  m-0">
                        <ImageUploadContainer
                          className={`${
                            this.state.second_data[""] ? "bg-d" : "bg-g"
                          }`}
                        >
                          <input
                            type="file"
                            accept=".png, .jpg, .jpeg, .pdf"
                            onChange={this.handleImageSelect}
                            id="plantratamiento"
                            className="plan"
                          />
                          <Upload />
                        </ImageUploadContainer>
                        {this.state.plantratamiento_image ? (
                          <div>
                            {this.state.plantratamiento_image.indexOf(
                              "application/pdf"
                            ) !== -1 ? (
                              <Document
                                file={this.state.plantratamiento_image}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                              >
                                <Page
                                  scale={0.15}
                                  pageNumber={this.state.pageNumber}
                                />
                              </Document>
                            ) : (
                              <ImageUploadReference
                                src={this.state.plantratamiento_image}
                                alt="Imagen de"
                                ref={this.plantratamiento}
                              />
                            )}
                            {this.state.plantratamiento_image.indexOf(
                              "application/pdf"
                            ) === -1 && (
                              <button
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  padding: "10px",
                                  backgroundColor: "#cecece",
                                  borderRadius: "0!important",
                                  border: "none",
                                }}
                                onClick={() =>
                                  this.showCropArea("plantratamiento", "plan")
                                }
                              >
                                <Edit2 size="16" />
                              </button>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              background: "#112154",
                            }}
                          >
                            <ImageUploadReference
                              src={plan}
                              alt="Imagen de"
                              ref={this.plan_file}
                            />
                          </div>
                        )}
                      </div>
                      <ImageUploadLabel>Plan de tratamiento</ImageUploadLabel>
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 f-flex text-end">
                      <button
                        className="btn btn-outline-primary mx-2 rounded-pill px-5"
                        onClick={() => this.setState({ step: 3 })}
                      >
                        Volver
                      </button>
                      <button
                        className="btn btn-primary rounded-pill px-5"
                        onClick={() => this.setState({ step: 5 })}
                      >
                        Continuar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.step === 5 && (
              <div style={{ height: "100%", overflowX: "hidden" }}>

                <div className="container mt-4">
                  <div className="row">
                    <div className="col-12 px-5">
                      <h1
                        style={{ fontWeight: 700 }}
                        className="text-primary text-center"
                      >
                        Documentación Incompleta
                      </h1>
                      <StepCounter
                        step={this.state.step}
                        data_completed={
                          Object.values(this.state.first_data).length === 4
                        }
                        fotos_completed={
                          Object.values(this.state.fotos).length >= 10
                        }
                        estudios_completed={
                          Object.values(this.state.estudios).length >= 3
                        }
                        plan_completed={
                          Object.values(this.state.plan).length === 1
                        }
                        registry_completed={this.state.registry}
                      />
                    </div>
                    {/* <Timeline step={this.state.step} width={100} /> */}
                  </div>
                  <div className="row mt-5">
                    <div className="col-12 d-flex text-center">
                      <div className="container shadow px-5 py-4">
                        <div className="row text-start">
                          <div className="col-12">
                            <h4 className="text-primary fw-bold">Resumen</h4>
                          </div>
                        </div>
                        <hr />
                        <div className="row text-start">
                          <div className="col-4">
                            <p>Fotos:</p>
                            <div className="d-flex flex-row flex-wrap">
                              <img
                                style={
                                  this.state.frontal_reposo_image !== undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.frontal_reposo_image
                                    ? this.state.fotos.frontal_reposo
                                    : frontalreposo
                                }
                                alt="Frontal en Reposo"
                                title="Frontal en Reposo"
                              />
                              <img
                                style={
                                  this.state.frontal_sonriendo_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.frontal_sonriendo_image
                                    ? this.state.fotos.frontal_sonriendo
                                    : frontalsonriendo
                                }
                                alt=""
                                title="Frontal Sonriendo"
                              />
                              <img
                                style={
                                  this.state.perfil_izq_reposo_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.perfil_izq_reposo_image
                                    ? this.state.fotos.perfil_izq_reposo
                                    : perfilizqreposo
                                }
                                alt=""
                                title="Perfil Izquierdo en Reposo"
                              />
                              <img
                                style={
                                  this.state.trescuartos_perfil_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.trescuartos_perfil_image
                                    ? this.state.fotos.trescuartos_perfil
                                    : trescuartos_perfil
                                }
                                alt=""
                                title="Tres cuartos de perfil"
                              />
                              <img
                                style={
                                  this.state.oclusal_maxilar_sup_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.oclusal_maxilar_sup_image
                                    ? this.state.fotos.oclusal_maxilar_sup
                                    : oclusalmaxilarsup
                                }
                                alt=""
                                title="Oclusal Maxilar Superior"
                              />
                              <img
                                style={
                                  this.state.oclusal_maxilar_inf_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.oclusal_maxilar_inf_image
                                    ? this.state.fotos.oclusal_maxilar_inf
                                    : oclusalmaxilarinf
                                }
                                alt=""
                                title="Oclusal Maxilar Inferior"
                              />
                              <img
                                style={
                                  this.state.anterior_oclusion_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.anterior_oclusion_image
                                    ? this.state.fotos.anterior_oclusion
                                    : anterioroclusion
                                }
                                alt=""
                                title="Anterior en Oclusion"
                              />
                              <img
                                style={
                                  this.state.bucal_derecho_image !== undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.bucal_derecho_image
                                    ? this.state.fotos.bucal_derecho
                                    : bucalderecho
                                }
                                alt=""
                                title="Bucal Derecho"
                              />
                              <img
                                style={
                                  this.state.bucal_izquierdo_image !== undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.bucal_izquierdo_image
                                    ? this.state.fotos.bucal_izquierdo
                                    : bucalizquierdo
                                }
                                alt=""
                                title="Bucal Izquierdo"
                              />
                              <img
                                style={
                                  this.state.anterior_inoclusion_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.anterior_inoclusion_image
                                    ? this.state.fotos.anterior_inoclusion
                                    : anteriorinoclusion
                                }
                                alt=""
                                title="Anterior en Inoclusion"
                              />
                            </div>
                          </div>
                          <div className="col-2">
                            <p>Estudios:</p>
                            <div className="d-flex flex-colum flex-wrap justify-content-left">
                              <img
                                style={
                                  this.state.rxpanoramica_image !== undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.rxpanoramica_image
                                    ? this.state.estudios.rxpanoramica
                                    : rxpanoramica
                                }
                                alt=""
                                title="RX Panoramica"
                              />
                              <img
                                style={
                                  this.state.telerradiografia_image !==
                                  undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.telerradiografia_image
                                    ? this.state.estudios.telerradiografia
                                    : radioperfil
                                }
                                alt=""
                                title="Telerradiografia"
                              />
                              <img
                                style={
                                  this.state.ricketts !== undefined
                                    ? {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                      }
                                    : {
                                        objectFit: "cover",
                                        width: "50px",
                                        height: "50px",
                                        margin: "2px",
                                        opacity: ".5",
                                      }
                                }
                                src={
                                  this.state.ricketts_image
                                    ? this.state.estudios.ricketts
                                    : ricketts
                                }
                                alt=""
                                title="Ricketts"
                              />
                            </div>
                          </div>
                          <div className="col-3">
                            <p>Plan de tratamiento:</p>
                            {this.state.plan_file != null && (
                              <div className="w-100 h-100 border border-1">
                                {this.state.plantratamiento_image.indexOf(
                                  "application/pdf"
                                ) === -1 ? (
                                  <ImageUploadReference
                                    src={this.state.plantratamiento_image}
                                  />
                                ) : (
                                  <div>
                                    <Document
                                      file={this.state.plantratamiento_image}
                                      onLoadSuccess={this.onDocumentLoadSuccess}
                                    >
                                      <Page
                                        height={500}
                                        scale={0.4}
                                        pageNumber={this.state.pageNumber}
                                      />
                                    </Document>
                                    <a
                                      href={this.state.plan_file}
                                      target="_blank"
                                    >
                                      <p>
                                        Page {this.state.pageNumber} of{" "}
                                        {this.state.numPages}
                                      </p>
                                    </a>
                                    <button
                                      disabled={this.state.pageNumber === 1}
                                      onClick={() =>
                                        this.setState({
                                          pageNumber: this.state.pageNumber - 1,
                                        })
                                      }
                                    >
                                      -
                                    </button>
                                    <button
                                      disabled={
                                        this.state.pageNumber ===
                                        this.state.numPages
                                      }
                                      onClick={() =>
                                        this.setState({
                                          pageNumber: this.state.pageNumber + 1,
                                        })
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <div className="col-3">
                            <p>Envio de registro:</p>
                            <div className="w-100 h-100 border border-1"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6 d-flex flex-row justify-content-end">
                      <button
                        onClick={() => this.setState({ step: 5 })}
                        className="btn btn-outline-primary rounded-pill px-5 w-75"
                      >
                        Volver
                      </button>
                    </div>
                    <div className="col-6 d-flex flex-row justify-content-start">
                      <button
                        onClick={() => this.handleImageUpload()}
                        className="btn btn-primary rounded-pill px-5 w-75"
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps, null)(index);
