import React, {useState} from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment'

import SCIcon from './smile_creator_icons'
import apiClient from '../../../utils/backend';
import { PlayCircle } from 'react-feather';

function PaymentsCard({external_id, doctor, patient, sc_type, created_at, end_time, treatment_name, maxilla, video_restrict, payment_status,status, id, start, index, discount_value, cost_value, finalcost_value}) {

  const [discount, setDiscount] = useState(discount_value||'')
  const [cost, setCost] = useState(cost_value||'')
  const [totalCost, setTotalCost] = useState(null)
  const [restrict, setRestrict] = useState(video_restrict === 1 ? true : false)

  function startPayment() {
    if(discount !== null /*&& discount > 0*/ && cost !== null){

      let finalCost;

      if(discount == 0) {
        console.log("sin descuento")
       finalCost = cost
      } else {
        console.log("con descuento")
       finalCost = cost - (cost * discount) / 100
      }

      start(id, index, restrict, discount, cost, finalCost)
    }
  }

  return (
    <div className="row">
      <div className="col-half d-flex flex-row justify-content-center">
            <p className="mb-0">{external_id}</p>
        </div>
        <div className="col-2 d-flex flex-row justify-content-center">
          <p className="mb-0">{doctor}</p>
        </div>
        <div className="col-2 d-flex flex-row justify-content-center">
          <p className="mb-0">{patient}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <SCIcon type={sc_type} />
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <p className="mb-0">{moment(created_at).format('DD-MM-YYYY')}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <p className="mb-0">{moment(created_at).format('DD-MM-YYYY')}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center force-inline-text">
          <p className="mb-0">{treatment_name}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <p className="mb-0">{maxilla}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center pt-3">
          {/* <input className="" onChange={() => setRestrict(!restrict)} checked={restrict} type="checkbox" /> */}
          <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={() => setRestrict(!restrict)} checked={restrict} />
          </div>
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <input type="number" id="discount" onChange={(e) => setDiscount(e.target.value)} value={discount} placeholder="%" className="form-control mb-0" />
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <input type="number" id="cost" onChange={(e) => setCost(e.target.value)} value={finalcost_value||''} placeholder="$" className="form-control mb-0" />
        </div>
        <div className="col d-flex flex-row justify-content-center">
            {status > 0 ? <div style={{width: '50px', height: '50px'}}>
                <CircularProgressbar value={payment_status} text={`${payment_status}%`} strokeWidth={15} 
                styles={buildStyles({
                    pathColor: payment_status < 50 ? '#e74c3c' : payment_status >= 50 && payment_status < 100 ? '#f1c40f' : '#2ecc71',
                    textColor: payment_status < 50 ? '#e74c3c' : payment_status >= 50 && payment_status < 100 ? '#f1c40f' : '#2ecc71',
                    textSize: 20,
                     })}  />
            </div>
            : <button onClick={() => startPayment()} className={discount === null || discount === 0 || cost === 0 || cost === null ? "btn p-0 disabled" : "btn p-0"}><PlayCircle size="50" color="#d6d6d6" strokeWidth={2} /></button>}
        </div>
    </div>
  )
}

export default PaymentsCard