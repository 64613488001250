import React, { useState, useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps, type_dispatch } from '../../propsStateConnect';
import { yes_no_options, diastemas_options } from '../Utilities/OptionsRadios';
import { useErrorList } from '../Utilities/UseErrorsList';

function Diastems(props){
    type_dispatch.step='InstructionsTratment'
    type_dispatch.field='Diastems'
    const { colors, updateState, updateErrors }= props;
    const middle_state= props.formOnlineReducer[type_dispatch.step][type_dispatch.field];

    const diastemas=middle_state.diastems
    const diastemasMesi=middle_state.mesiDiastems
    const diastemasRetro=middle_state.retroDiastems

    const errors_list=props.formOnlineReducer.ErrorsList
    const error1=errors_list.find(err=>err.order===1)
    useErrorList(errors_list, updateErrors, [diastemas])
    return (
        <div>
            <div className="w-100 fw-bolder pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                Diastemas
            </div>
            {error1 && 
                <div className="alert-danger alert-link p-2 mt-1">
                    {error1.message}
                </div>
            }
            <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={diastemas_options}
            setter={(value)=>updateState({key:'diastems', value})}
            stateField={diastemas} name='diastemas' />    
            {diastemas === 2 &&
            <div>
                <div className="d-flex mt-3">
                    <div className="col-6 ms-3">
                        <div className="w-90 fw-bolder small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                            Cerrar por mesialización
                        </div>
                        <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={yes_no_options}
                        setter={(value)=>updateState({key:'mesiDiastems', value})}
                        stateField={diastemasMesi} name='diastemasMesi' />
                    </div>
                    <div className="col-6">
                        <div className="w-100 fw-bolder small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                            Cerrar por retroinclinación
                        </div>
                        <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={yes_no_options}
                        setter={(value)=>updateState({key:'retroDiastems', value})}
                        stateField={diastemasRetro} name='diastemasRetro' />
                    </div>
                </div>
                <div className='ms-4'>
                <div className="mt-3 mt-2 small fw-bolder">
                    ¿Desea dejar espacio remanente? Indique donde:
                </div>
                <textarea
                disabled={!props.formOnlineReducer.Enabled}
                onChange={(e)=>updateState({key:'description', value:e.target.value})} 
                value={middle_state.description}
                className="form-control mt-3" name="observacion" id="observacion" cols="30" rows="3"></textarea>
                </div>
            </div>
            }
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Diastems)