let initialState = {
    collapsed: true
}

const navbarReducer = (state = initialState, action) => {
    switch(action.type) {
        case "COLLAPSE":
            return {
                ...state,
                collapsed: !state.collapsed
            }
            default: {
                return state
            }
    }
}

export default navbarReducer