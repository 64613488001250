import React, { useState, useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps, type_dispatch } from '../../propsStateConnect';
import { apply_noapply_options } from '../Utilities/OptionsRadios';
import { useErrorList } from '../Utilities/UseErrorsList';

function SequentialDistalation(props){
    type_dispatch.step='InstructionsTratment'
    type_dispatch.field='SequentialDistalation'
    const { colors, updateState, updateErrors }= props;
    const middle_state= props.formOnlineReducer[type_dispatch.step][type_dispatch.field];
    
    const sequentialDistalation= middle_state.sequentialDistalation
    const upRight= middle_state.upRight
    const upLeft= middle_state.upLeft
    const downRight= middle_state.downRight
    const downLeft= middle_state.downLeft

    const errors_list=props.formOnlineReducer.ErrorsList
    const error1=errors_list.find(err=>err.order===1)
    useErrorList(errors_list, updateErrors, [sequentialDistalation])
    return (
        <div>
            <div className="w-100 fw-bolder pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                Distalación Secuencial
            </div>
            {error1 && 
                <div className="alert-danger alert-link p-2 mt-1">
                    {error1.message}
                </div>
            }
            <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={apply_noapply_options}
            setter={(value)=>updateState({key:'sequentialDistalation', value})}
            stateField={sequentialDistalation} name='sequentialDistalation' />
            {sequentialDistalation===1 &&
            <div className="d-flex ms-3 mt-3">
                <div className="col-6">
                    <div className="w-90 fw-bolder small pb-1 mb-2" style={{borderBottom:`1px solid ${colors.color}` }}>
                        Superior
                    </div>
                    <div className="d-flex align-items-end ms-4">
                        <small className="h-0 col-2 mb-1">Derecha</small>
                        <div className="col-2 d-flex justify-content-center align-items-end">
                        <input
                            name="rightLeft_value"
                            id="rightLeft_value"
                            onChange={(e)=> updateState({key:'upRight', value:e.target.value})}
                            value={upRight}
                            type="number"
                            tabIndex="1"
                            maxLength="100"
                            style={{border: `1px solid ${colors.color}`}}
                            className="small w-60 p-1 rounded"
                            disabled={!props.formOnlineReducer.Enabled}
                        />
                        <small className="h-0 ms-1 mb-1">mm</small>
                        </div>
                    </div>
                    <div className="d-flex align-items-end ms-4 mt-2">
                        <small className="h-0 col-2 mb-1">Izquierda</small>
                        <div className="col-2 d-flex justify-content-center align-items-end mt-2">
                        <input
                            name="upLeft_value"
                            id="upLeft_value"
                            onChange={(e)=> updateState({key:'upLeft', value:e.target.value})}
                            value={upLeft}
                            type="number"
                            tabIndex="1"
                            maxLength="100"
                            style={{border: `1px solid ${colors.color}`}}
                            className="small w-60 p-1 rounded"
                            disabled={!props.formOnlineReducer.Enabled}
                        />
                        <small className="h-0 ms-1 mb-1">mm</small>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="w-90 fw-bolder small pb-1 mb-2" style={{borderBottom:`1px solid ${colors.color}` }}>
                        Inferior
                    </div>
                    <div className="d-flex align-items-end ms-4">
                        <small className="h-0 col-2 mb-1">Derecha</small>
                        <div className="col-2 d-flex justify-content-center align-items-end">
                        <input
                            name="rightLeft_value"
                            id="rightLeft_value"
                            onChange={(e)=> updateState({key:'downRight', value:e.target.value})}
                            value={downRight}
                            type="number"
                            tabIndex="1"
                            maxLength="100"
                            style={{border: `1px solid ${colors.color}`}}
                            className="small w-60 p-1 rounded"
                            disabled={!props.formOnlineReducer.Enabled}
                        />
                        <small className="h-0 ms-1 mb-1">mm</small>
                        </div>
                    </div>
                    <div className="d-flex align-items-end mt-2 ms-4">
                        <small className="h-0 col-2 mb-1">Izquierda</small>
                        <div className="col-2 d-flex justify-content-center align-items-end mt-2">
                            <input
                                name="upLeft_value"
                                id="upLeft_value"
                                onChange={(e)=> updateState({key:'downLeft', value:e.target.value})}
                                value={downLeft}
                                type="number"
                                tabindex="1"
                                maxLength="100"
                                style={{border: `1px solid ${colors.color}`}}
                                className="small w-60 p-1 rounded"
                                disabled={!props.formOnlineReducer.Enabled}
                            />
                            <small className="h-0 ms-1 mb-1">mm</small>
                        </div>
                    </div>
                </div>
            </div>
        }
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(SequentialDistalation)