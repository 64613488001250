import React from 'react'
import SCIcon from './smile_creator_icons'

function AccountCard({doctor, sc_type, account_amount, id, handleCardShow}) {

  return (
    <div className="row" style={{paddingLeft: '15px'}}>
        <div className="col-2 d-flex flex-row justify-content-center">
          <p className="mb-0" style={{lineHeight: '38px'}}>{doctor}</p>
        </div>
        <div className="col-2 d-flex flex-row justify-content-center">
          <SCIcon type={sc_type} />
        </div>
        <div className="col-4 d-flex flex-row justify-content-center">
        </div>
        <div className="col-2 d-flex flex-row justify-content-center">
          <p className="mb-0" style={{lineHeight: '38px'}}>{account_amount}</p>
        </div>
        <div className="col-2 d-flex flex-row justify-content-center">
          <p className="mb-0" style={{lineHeight: '38px'}}><button className="btn btn-outline-primary w-100 rounded-pill px-5" onClick={() => handleCardShow(id)} >Ver</button></p>
        </div>
    </div>
  )
}

export default AccountCard