import React, {Component} from "react";
import apiClient from "../../../utils/backend";
import moment from 'moment'
import Select from 'react-select'

export default class PaymentsForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: null,
            payment_date: null,
            payment_amount: null,
            bill: null,
            bill_date: null,
            bill_amount: null,
            payment_method: null,
        }

        this.handleSendData = this.handleSendData.bind(this)
    }

    componentDidMount() {
        console.log("propsssssssssssss")
        console.log(this.props)
        const {
            id,
            bill,
            bill_amount,
            bill_date,
            payment_amount,
            payment_date,
            payment_method,
            order,
            expected,
            expired_date
        } = this.props.payment_data
        this.setState({
            id,
            payment_date,
            payment_amount,
            bill,
            bill_date,
            bill_amount,
            payment_method,
            order,
            expected,
            expired_date
        })
    }

    handleInputChange({id, e}) {
        let state = this.state
        state[id] = e.target.value
    }

    handleSendData() {
        console.log("mandando")
        console.log(this.props.paymentId)
        this.props.updateDetail({
            payment_id: this.props.paymentId,
            id: this.state.id,
            payment_amount: this.state.payment_amount,
            bill: this.state.bill,
            bill_amount: this.state.bill_amount,
            payment_method: this.state.payment_method,
            payment_date: this.state.payment_date,
            bill_date: this.state.bill_date,
            index: this.props.index
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-4 px-3 mb-5">
                    <div className="d-flex flex-column justify-content-between">
                        <div
                            className="rounded-pill border-primary px-4 py-2 w-50 bg-success"
                            style={{opacity: 0.5}}
                        >
                            <span className="text-white">{this.state.order}° Pago</span>
                        </div>

                        <small className="mt-2" style={{fontSize: ".7rem", marginLeft: "5px"}}>
                            Se espera el {this.state.expected}% del costo final.
                        </small>

                        <div className="d-flex flex-row mt-3">
                            <div className="col-6">
                                <small className="text-primary fs-6">Monto</small>
                                <p className="fw-bold text-primary mt-2 fs-5">${this.state.payment_amount}</p>
                            </div>
                            <div className="col-6">
                                <small className="text-primary fs-6">Fecha vencimiento</small>
                                <p className="text-primary mt-2 fs-5">{moment(this.state.expired_date).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
