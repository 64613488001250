import React, { useState, useEffect } from 'react'
import { Document, Page } from 'react-pdf'
import { useHistory } from 'react-router'
import './index.css'
import { ImageUploadReference } from '../components/styled'
import trescuartos_perfil from '../../../../assets/images/34deperfilsonriendo.png'
import anterioroclusion from '../../../../assets/images/anteriorenoclusion.png'
import anteriorinoclusion from '../../../../assets/images/anterioreninoclusion.png'
import bucalderecho from '../../../../assets/images/bucalderecho.png'
import bucalizquierdo from '../../../../assets/images/bucalizquierdo.png'
import frontalreposo from '../../../../assets/images/frontalenreposo.png'
import frontalsonriendo from '../../../../assets/images/frontalsonriendo.png'
import oclusalmaxilarinf from '../../../../assets/images/oclusaldelmaxilarinferior.png'
import oclusalmaxilarsup from '../../../../assets/images/oclusaldelmaxilarsuperior.png'
import perfilizqreposo from '../../../../assets/images/perfilizquierdoenreposo.png'
import radioperfil from '../../../../assets/images/telerradiografiadeperfil.png'
import ricketts from '../../../../assets/images/cefalogramadericketts.png'
import rxpanoramica from '../../../../assets/images/rxpanoramica.png'
import apiClient from '../../../../utils/backend'
import toast from 'react-hot-toast'

export const ViabilitySixDentist = ({
  state,
  setState,
  stageTwoFinish,
  stageThreeFinish,
  stageFourFinish,
  checkFrotalSonriendo,
}) => {
  const history = useHistory()
  const [treatmentId, setTreatmentId] = useState(null)
  const [step2, setstep2] = useState(false)
  const [step3, setstep3] = useState(false)
  const [step4, setstep4] = useState(false)
  const [flags, setFlags] = useState({
    photos: stageTwoFinish,
    studies: stageThreeFinish,
    plan: stageFourFinish,
  })
  const headers = {
    'Content-Type': 'multipart/form-data',
  }

  const imageStyle = {
    objectFit: 'cover',
    width: '50px',
    height: '50px',
    margin: '2px',
  }
  const imageStyleOpacity = {
    objectFit: 'cover',
    width: '50px',
    height: '50px',
    margin: '2px',
    opacity: '.5',
  }

  const toastProps = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({
      ...state,
      numPages: numPages,
    })
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }

  const handleSaveTreatment = async () => {
    const dataStepOne = {
      name: state.nombre,
      surname: state.apellido,
      comment: state.consulta,
      registration_datetime: state.newIntraoralScanDate
        ? state.newIntraoralScanDate
        : state.sendImpressions?.takeover_date,
    }
    await apiClient
      .post(`api/viability/information`, dataStepOne)
      .then((res) => {
        setTreatmentId(res.data.data.id)
        toast.success('Nueva solicitud cargada exitosamente', toastProps)
      })
      .catch((err) => toast.error('Information step 1 erronea', toastProps))
  }

  useEffect(() => {
    if (!treatmentId) return
    //SETFLAGS

    apiClient
      .patch(`api/incomes/${treatmentId}`, flags)
      .then((res) => toast.success('Flag ok'))
      .catch((err) => toast.error('Fail flag', toastProps))

    //STEP 2
    if (Object.keys(state.fotos).length < 1) setstep2(true)
    Object.entries(state.fotos).forEach((entry, index) => {
      const [key, value] = entry
      if (value !== null) {
        const file = dataURLtoFile(value, key + '.jpg')
        let dataStepTwo = new FormData()
        dataStepTwo.append('image', file)
        dataStepTwo.append('name', key)
        apiClient
          .post(`api/incomes/${treatmentId}/images`, dataStepTwo, headers)
          .then((res) => {
            if (index + 1 == Object.keys(state.fotos).length) {
              setstep2(true)
            }
          })
          .catch((err) => toast.error('Information step 2 erronea', toastProps))
      }
    })

    //STEP 3
    if (Object.keys(state.estudios).length < 1) setstep3(true)
    Object.entries(state.estudios).forEach((entry, index) => {
      const [key, value] = entry
      if (value !== null) {
        const file = dataURLtoFile(value, key + '.jpg')
        let dataStepThree = new FormData()
        dataStepThree.append('study', file)
        dataStepThree.append('name', key)
        apiClient
          .post(`api/incomes/${treatmentId}/studies`, dataStepThree, headers)
          .then((res) => {
            if (index + 1 == Object.keys(state.estudios).length) {
              setstep3(true)
            }
          })
          .catch((err) => toast.error('Information step 3 erronea', toastProps))
      }
    })

    //STEP 4
    if (state.plantratamiento) {
      if (state.plantratamiento.type === 'application/pdf') {
        let dataStepFour = new FormData()
        dataStepFour.append('plan', state.plantratamiento)
        dataStepFour.append('name', 'plantratamiento')
        apiClient
          .post(`api/incomes/${treatmentId}/plan`, dataStepFour, headers)
          .then((res) => setstep4(true))
          .catch((err) => toast.error('Information step 3 erronea', toastProps))
      } else {
        const file = dataURLtoFile(state.plantratamiento, 'plan' + '.jpg')
        let dataStepFour = new FormData()
        dataStepFour.append('plan', file)
        dataStepFour.append('name', 'plantratamiento')
        apiClient
          .post(`api/incomes/${treatmentId}/plan`, dataStepFour, headers)
          .then((res) => setstep4(true))
          .catch((err) => toast.error('Information step 3 erronea', toastProps))
      }
    } else setstep4(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treatmentId])

  useEffect(() => {
    if (step2 && step3 && step4) {
      toast.success('Data cargada exitosamente', toastProps)
      setTimeout(() => history.push('/od/solicitud'), 2000)
    }
  }, [step2, step3, step4])

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="row mt-5 mx-auto">
        <div className="col-12 d-flex text-center">
          <div className="container central_container shadow-lg mx-5 px-5 py-4 position-relative">
            <div className="row text-start d-flex flex-row align-items-center">
              <div className="col-2">
                <div className="circulo" style={{ overflow: 'hidden' }}>
                  <img
                    src={checkFrotalSonriendo()}
                    alt="Frontal sonriendo"
                    className="avatar_image"
                  />
                </div>
              </div>
              <div className="col-2">
                <p>Paciente:</p>
                <h5 className="fw-bold">
                  {state?.nombre + ' ' + state?.apellido}
                </h5>
              </div>
              <div className="col-3">
                <p>Doctor:</p>
                <h5 className="fw-bold">{state?.odontologo?.label}</h5>
              </div>
            </div>
            <hr />
            <div className="row text-start">
              <div className="col-3">
                <p>Fotos:</p>
                <div className="d-flex flex-row flex-wrap">
                  <img
                    style={
                      state?.frontal_reposo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.frontal_reposo
                        ? state?.frontal_reposo
                        : frontalreposo
                    }
                    alt="Frontal en Reposo"
                    title="Frontal en Reposo"
                  />
                  <img
                    style={
                      state?.frontal_sonriendo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.frontal_sonriendo
                        ? state?.frontal_sonriendo
                        : frontalsonriendo
                    }
                    alt=""
                    title="Frontal Sonriendo"
                  />
                  <img
                    style={
                      state?.perfil_izq_reposo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.perfil_izq_reposo
                        ? state?.perfil_izq_reposo
                        : perfilizqreposo
                    }
                    alt=""
                    title="Perfil Izquierdo en Reposo"
                  />
                  <img
                    style={
                      state?.trescuartos_perfil !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.trescuartos_perfil
                        ? state?.trescuartos_perfil
                        : trescuartos_perfil
                    }
                    alt=""
                    title="Tres cuartos de perfil"
                  />
                  <img
                    style={
                      state?.oclusal_maxilar_sup !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.oclusal_maxilar_sup
                        ? state?.oclusal_maxilar_sup
                        : oclusalmaxilarsup
                    }
                    alt=""
                    title="Oclusal Maxilar Superior"
                  />
                  <img
                    style={
                      state?.oclusal_maxilar_inf !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.oclusal_maxilar_inf
                        ? state?.oclusal_maxilar_inf
                        : oclusalmaxilarinf
                    }
                    alt=""
                    title="Oclusal Maxilar Inferior"
                  />
                  <img
                    style={
                      state?.anterior_oclusion !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.anterior_oclusion
                        ? state?.anterior_oclusion
                        : anterioroclusion
                    }
                    alt=""
                    title="Anterior en Oclusion"
                  />
                  <img
                    style={
                      state?.bucal_derecho !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.bucal_derecho ? state?.bucal_derecho : bucalderecho
                    }
                    alt=""
                    title="Bucal Derecho"
                  />
                  <img
                    style={
                      state?.bucal_izquierdo !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.bucal_izquierdo
                        ? state?.bucal_izquierdo
                        : bucalizquierdo
                    }
                    alt=""
                    title="Bucal Izquierdo"
                  />
                  <img
                    style={
                      state?.anterior_inoclusion !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.anterior_inoclusion
                        ? state?.anterior_inoclusion
                        : anteriorinoclusion
                    }
                    alt=""
                    title="Anterior en Inoclusion"
                  />

                  {state?.otro !== undefined && (
                    <img
                      style={imageStyle}
                      src={state.otro}
                      alt="otro"
                      title="Otro"
                    />
                  )}
                </div>
              </div>
              <div className="col-1">
                <p>Estudios:</p>
                <div className="d-flex flex-colum flex-wrap justify-content-left">
                  <img
                    style={
                      state?.rxpanoramica !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.rxpanoramica ? state?.rxpanoramica : rxpanoramica
                    }
                    alt=""
                    title="RX Panoramica"
                  />
                  <img
                    style={
                      state?.telerradiografia !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={
                      state?.telerradiografia
                        ? state?.telerradiografia
                        : radioperfil
                    }
                    alt=""
                    title="Telerradiografia"
                  />
                  <img
                    style={
                      state?.ricketts !== undefined
                        ? imageStyle
                        : imageStyleOpacity
                    }
                    src={state?.ricketts ? state?.ricketts : ricketts}
                    alt=""
                    title="Ricketts"
                  />

                  {state?.otro2 !== undefined && (
                    <img
                      style={imageStyle}
                      src={state.otro2}
                      alt="otro2"
                      title="Otro"
                    />
                  )}
                </div>
              </div>
              <div className="col-2">
                <p>Plan de tratamiento:</p>
                {state?.plantratamiento !== null ? (
                  <div
                    className="w-100 h-100"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {!state?.plantratamiento?.type ? (
                      <ImageUploadReference src={state?.plantratamiento} />
                    ) : (
                      <div
                        style={{
                          height: '100px',
                          width: '100px',
                          overflow: 'hidden',
                        }}
                      >
                        <Document
                          file={state?.plantratamiento}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page
                            height={125}
                            scale={1}
                            pageNumber={state?.pageNumber}
                          />
                        </Document>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="w-100 h-75 border border-1"></div>
                )}
              </div>
              <div className="col-2">
                <p>Envio de registro:</p>
                <div className="w-100 btn btn-primary scanButton">
                  {state.intraoral_file
                    ? 'Escaneo intraoral'
                    : 'Envio de impresiones'}
                </div>
              </div>
              <div className="col-4 px-2">
                <p>Consulta:</p>
                <div className="h-75 border border-1 form-control">
                  {state.consulta}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6 d-flex flex-row justify-content-end">
          <button
            onClick={() => history.push('/od/solicitud')}
            className="btn volver rounded-pill px-5 py-3"
          >
            Volver a nueva solicitud
          </button>
        </div>
        <div className="col-6 d-flex flex-row justify-content-start">
          <button
            onClick={() => handleSaveTreatment()}
            className="btn btn-primary rounded-pill px-5 py-3"
          >
            Confirmar solicitud
          </button>
        </div>
      </div>
    </div>
  )
}
