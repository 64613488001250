import React, { useEffect, useState } from 'react'
import { Trash, X } from 'react-feather'
import { useDispatch } from 'react-redux'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import Danger from '../assets/images/danger_triangle.png'
import { deleteTreatment } from '../redux/actions/odTreatments'

const ConfirmationModal = (props) => {
  const { type, data, deleteDentist,principalDentist } = props
  const [opened, setOpened] = useState(false)
  const dispatch = useDispatch()
  const [isTreatmentDelete, setIsTreatmentDelete] = useState(false)
  const [isDentistDelete, setIsDentistDelete] = useState(false)
  useEffect(() => {
    switch (type) {
      case 'treatment-delete':
        setIsTreatmentDelete(true)
        setIsDentistDelete(false)
        break
      case 'dentist-delete':
        setIsDentistDelete(true)
        setIsTreatmentDelete(false)
      default:
        break
    }
  }, [])

  const handleDeleteTreatment = (id) => {
    dispatch(deleteTreatment(id))
    setOpened(false)
  }

  return (
    <>
      {isTreatmentDelete && (
        <>
          <Trash onClick={() => setOpened(!opened)} />
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="col-12 flex text-center">
                    <img
                      src={Danger}
                      className="mt-1"
                      style={{ width: '80px' }}
                    />
                    <p className="mt-3 text-black fs-5 fw-bold">
                      ¿Estas seguro que deseas eliminar el tratamiento:
                    </p>
                    <p className="text-black pt-2 fs-6 fw-bold">
                      Paciente: {data?.patient.full_name}
                    </p>
                    <p className="text-black fs-6 fw-bold">
                      Doctor: {principalDentist?.user?.name}
                    </p>
                    <p className="text-black fs-6 fw-bold">
                      Tratamiento {data?.treatment} ?
                    </p>
                    <p className="text-black pt-2 fs-6">
                      Se eliminara toda la documentación relacionada a este
                      tratamiento
                    </p>
                    <div className="my-3 mt-4">
                      <button
                        onClick={() => setOpened(!opened)}
                        className="btn btn-outline-dark me-4 rounded-pill px-4"
                      >
                        Volver
                      </button>
                      <button
                        onClick={() => handleDeleteTreatment(data?.id)}
                        className="btn btn-outline-warning rounded-pill px-4"
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
      {isDentistDelete && (
        <>
          <X
            size={15}
            onClick={() => setOpened(!opened)}
            className="text-muted ms-1"
          />
          <Modal
            style={{ marginTop: '150px' }}
            isOpen={opened}
            toggle={() => setOpened(!opened)}
          >
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="col-12 flex text-center">
                    <img
                      src={Danger}
                      className="mt-1"
                      style={{ width: '80px' }}
                    />
                    <p className="mt-2 text-black fs-4 fw-bold">
                      ¿Estas seguro que deseas eliminar el dentista:
                    </p>
                    <p className="text-black pt-2 fs-5 fw-bold">
                      {data?.user.name} {data?.user.surname}
                    </p>
                    <p
                      style={{ color: 'red' }}
                      className="text-black pt-2 fs-6"
                    >
                      Se eliminara este dentista del tratamiento seleccionado
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-center">
              <button
                onClick={() => setOpened(!opened)}
                className="btn btn-outline-dark me-4 rounded-pill px-4"
              >
                Volver
              </button>
              <button
                onClick={() => {
                  setOpened(!opened)
                  deleteDentist(data.id)
                }}
                className="btn btn-outline-warning rounded-pill px-4"
              >
                Eliminar
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </>
  )
}

export default ConfirmationModal
