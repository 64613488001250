import React, { Component, useCallback } from 'react'
import apiClient from '../utils/backend'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment'
import {Document, Page} from 'react-pdf'
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
  useLightgallery
} from "react-lightgallery";
import {ArrowUpCircle, ArrowUp, ArrowRight, Edit2} from 'react-feather'

// Components
import PaymentProcess from './payment_process'
import PhotoItem from './PhotoItem'
import * as cropro from "cropro";

import trescuartos_perfil from '../assets/images/34deperfilsonriendo.png'
import anterior_inoclusion from '../assets/images/anterioreninoclusion.png'
import anterior_oclusion from '../assets/images/anteriorenoclusion.png'
import bucal_derecho from '../assets/images/bucalderecho.png'
import bucal_izquierdo from '../assets/images/bucalizquierdo.png'
import ricketts from '../assets/images/cefalogramadericketts.png'
import frontal_reposo from '../assets/images/frontalenreposo.png'
import frontal_sonriendo from '../assets/images/frontalsonriendo.png'
import oclusal_maxilar_inf from '../assets/images/oclusaldelmaxilarinferior.png'
import oclusal_maxilar_sup from '../assets/images/oclusaldelmaxilarsuperior.png'
import perfil_izq_reposo from '../assets/images/perfilizquierdoenreposo.png'
import rxpanoramica from '../assets/images/rxpanoramica.png'
import telerradiografia from '../assets/images/telerradiografiadeperfil.png'
import cefalogramadericketts from '../assets/images/cefalogramadericketts.png'

import Chile from '../assets/images/Chile.png' 
import Colombia from '../assets/images/Colombia.png' 
import Paraguay from '../assets/images/Paraguay.png' 
import Peru from '../assets/images/Peru.png' 
import Uruguay from '../assets/images/Uruguay.png' 
import FormOnline from '../pages/FormularioOnline/admin';
// import {Demo} from './cropper_modal'

class ModalHistoriaClinica extends Component {

  constructor(props) {
    super(props)

    this.state = {
      //data: this.props.modal_id,
      statuses: [],
      loaded: false,
      step: 1,
      status: null,
      //id: this.props.modal_id,
      treatment_id: null,
      plan_base64: null,
      numPages: null,
      pageNumber: 1,
      default_images: {
        frontal_sonriendo: frontal_sonriendo,
        frontal_reposo: frontal_reposo,
        perfil_izq_reposo: perfil_izq_reposo,
        trescuartos_perfil: trescuartos_perfil,
        oclusal_maxilar_sup: oclusal_maxilar_sup,
        bucal_izquierdo: bucal_izquierdo,
        anterior_oclusion: anterior_oclusion,
        anterior_inoclusion: anterior_inoclusion,
        bucal_derecho: bucal_derecho,
        oclusal_maxilar_inf: oclusal_maxilar_inf,
        rxpanoramica: rxpanoramica,
        telerradiografia: telerradiografia,
        cefalogramadericketts: cefalogramadericketts
      },
      loading: true,
      error: {
        error: false,
        message: ''
      }
    }

    this.handleImageSource = this.handleImageSource.bind(this)
    this.handlePlanSource = this.handlePlanSource.bind(this)
    this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
    this.handleGetData = this.handleGetData.bind(this)
    this.handleProfileImage = this.handleProfileImage.bind(this)
    this.forceStatus = this.forceStatus.bind(this)
    this.handleAlta = this.handleAlta.bind(this)

//     let files = [
//       'frontal_reposo',
//       'frontal_sonriendo',
//       'perfil_izq_reposo',
//       'trescuartos_perfil',
//       'oclusal_maxilar_sup',
//       'oclusal_maxilar_inf',
//       'anterior_oclusion',
//       'bucal_derecho',
//       'bucal_izquierdo',
//       'anterior_inoclusion',
//       'otro',
//       'rxpanoramica',
//       'telerradiografia',
//       'ricketts',
//       'otro2',
//       'plantratamiento'
//     ]

    // for(let i = 0; i <= files.length; i++) {
    //   // console.log(files[i])
    //   this[files[i]] = React.createRef();
    // }

    this.showCropArea = this.showCropArea.bind(this)
  }

  resetState = () => {
    this.setState({
      frontal_reposo: undefined,
      frontal_sonriendo: undefined,
      perfil_izq_reposo: undefined,
      trescuartos_perfil: undefined,
      oclusal_maxilar_sup: undefined,
      oclusal_maxilar_inf: undefined,
      anterior_oclusion: undefined,
      bucal_derecho: undefined,
      bucal_izquierdo: undefined,
      anterior_inoclusion: undefined,
      otro: undefined,
    });
  }

  componentDidUpdate(prevProps) {
    if(prevProps.id !== this.props.id) {
      this.setState({
        loading: true
      });

//       this.resetState();

      console.log(this.props.id, prevProps.id)

      apiClient
      .post(`/api/treatment`, {
        id: this.props.id,
        withCredentials: true,
        headers: { Authorization: `Bearer ${this.props.login_token}` }
      })
      .then((res) => {
        console.log(res)
        if (res.status === 500 || res.status === 404) {
          this.setState({
            loading: false,
            error: {
              error: true,
              message:
                'Error cargando la historia clinica de este tratamiento. Contáctese con el equipo de sistemas con el código 0x0500h'
            }
          })
        }

        this.setState(
          {
            data: res.data.data.history,
            status: res.data.data.history[0].status_from,
            library_images: res.data.data.documentation,
            treatment_id: res.data.data.history[0].treatment_id,
            loading: false
          },
          () => {
            this.handleImageSource()
            this.handlePlanSource()
          }
        )
        console.log('entering setstate')
      })
      .catch((err) => {
        console.log(err)
        this.setState({
          loading: false,
          error: {
            error: true,
            message: `Error cargando la historia clinica de este tratamiento. Contáctese con el equipo de sistemas con el código 0x0500h-${this.props.id}`
          }
        })
      })

      apiClient.get('/api/helper/getGroupedStatus', { withCredentials: true, headers: { Authorization: `Bearer ${this.props.login_token}` }})
          .then(res => {
            console.log("statuses")
            console.log(res.data)
            this.setState({
              statuses: res.data
            })
          })
          .catch(err => console.log(err))
    }
  }

  handleGetData() {

  }

  async getImageBase64(path) {
    let base64 = await apiClient.post('/api/helper/getImageBase64',{path: path}, { withCredentials: true, headers: { Authorization: `Bearer ${this.props.login_token}` }})

    return base64.data
  }

  async showCropArea(ref) {
    if (this[ref].current !== null) {
      // create a CropArea
      const cropArea = new cropro.CropArea(this[ref].current);
      cropArea.displayMode = "popup"
      cropArea.zoomToCropEnabled = false;
      cropArea.isGridVisible = false;
      // attach an event handler to assign cropped image back to our image element
      cropArea.addRenderEventListener(dataUrl => {
        this.setState({
          [ref]: dataUrl
        })

        apiClient.post('/api/helper/updateImageClinicalHistory', {file: this.state[ref+'_file'], base64: dataUrl})
            .then(res => console.log(res))
            .catch(err => console.log(err))

        let documentation = [...this.state.data.photos_files]
        let next = documentation.findIndex(document => document.name === ref) + 1
        this.props.handleModal(this.props.id)
        this.showCropArea(documentation[next].name)
      })

      cropArea.addCloseEventListener(() => {
        this.props.handleModal(this.props.id)
      })
      // launch CROPRO
      this.props.handleModal(this.props.id);
      cropArea.show();
    }
  }

  async handleImageSource() {
    let documentation = [...this.state.data.photos_files]

    if (documentation.length > 0) {
      for (let i = 0;i < documentation.length; i++) {
        let base64 = await this.getImageBase64(documentation[i].path)

        this.setState({
          [documentation[i].name]: base64,
          [documentation[i].name+'_file']: documentation[i].file,
        })
      }
    }
  }

  async handlePlanSource(name) {
    console.log("aca")
    if(this.state.plan_file !== null){
      let base64 = await this.getImageBase64(this.state.data.plan_file)

      this.setState({
          plan_base64: base64,
      })
    }
  }

  onDocumentLoadSuccess({numPages}) {
    this.setState({
      numPages: numPages
    })
  }

  handleStudySource(name) {
    let documentation = [...this.state.data.studies_files]
    let image

    if (documentation.length > 0) {
      // let estudios = documentation[1].files ? documentation[1].files : null
      let estudios = documentation
      image = estudios.find(estudio => estudio.name === name)
    }

    if (image) {
      return image.path
    } else {
      return this.state.default_images[name]
    }
  }

  handleProfileImage() {
    let profile = this.state.data.photos_files.find(p => p.path !== null && p.name === "frontal_sonriendo")
    console.log(profile)
    if(profile !== undefined){
      return profile.path
    } else {
      return frontal_sonriendo
    }
  }

  handleCountryImage(){
    let latam = {
      "Chile": Chile,
      "Colombia": Colombia,
      "Peru": Peru,
      "Paraguay": Paraguay,
      "Uruguay": Uruguay
    }

    // return <img style={{width: '30px', height: '100px'}} src={latam[country]} alt={latam[country]} />
    return latam[this.state.data.country]
  }

  handleAlta(){
    apiClient.post('/api/incomes/nextStatus', {treatment_id: this.props.id})
    .then(res => res.status === 200 ? window.location.reload() :  window.location.reload())
    .catch(err => console.log(err))
  }

  handleGallery(pos){
    this.props.openGallery("very_cool_group")
  }

  changePriority() {
    if(this.state.data.priority === 'medium'){
      this.setState({
        data: {
          ...this.state.data,
          priority: 'high'
        }
      })
  
        apiClient.put(`api/incomes/${this.props.id}`, {priority: 'high'})
            .then(res => console.log(res))
            .catch(err => console.log(err))
    } else {
      this.setState({
        data: {
          ...this.state.data,
          priority: 'medium'
        }
      })
  
        apiClient.put(`api/incomes/${this.props.id}`, {priority: 'medium'})
            .then(res => console.log(res))
            .catch(err => console.log(err))
    }
    
  }

  forceStatus(e) {
    if(window.confirm('Esta seguro que desea cambiar de estado?')) {
      apiClient.put(`api/incomes/${this.props.id}`, {status_id: e.target.value})
          .then(res => window.location.reload())
          .catch(err => console.log(err))
    }
  }

  render() {

    const {
      className,
      id,
      opened,
      handleModal,
      switchStage
    } = this.props;

    if (this.state.loading) {
      return <div>
        {/* <Button color="danger" onClick={toggle}>{buttonLabel}</Button> */}
        <Modal isOpen={opened} toggle={() => handleModal(this.props.id)} className={className}>
          <ModalHeader toggle={() => handleModal(this.props.id)}>Historia clinica</ModalHeader>
          <ModalBody>
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {/* <Button color="primary" onClick={toggle}>Do Something</Button>{' '}
                        <Button color="secondary" onClick={toggle}>Cancel</Button> */}
          </ModalFooter>
        </Modal>
      </div>
    } else {
      if(this.state.error.error){
        return (
          <div>
            <Modal size="md" isOpen={opened} toggle={() => handleModal(this.props.id)} className={className}>
              <ModalBody>
                <div className="row"><div className="col-12 d-flex flex-row justify-content-end"><button onClick={() => this.props.handleModal()} className="btn bg-none text-dark">X</button></div></div>
                <div className="row">
                  <div className="col-12 px-3">
                    <h6 className="text-primary">{this.state.error.message}</h6>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-end">
                <button onClick={() => this.props.handleModal(this.props.id)} className="btn btn-primary">Cerrar</button>
              </ModalFooter>
            </Modal>
          </div>
        )
      } else {
        return (
          <div>
            <Modal size="xl" isOpen={opened} toggle={() => handleModal(this.props.id)} className={className}>
              <ModalBody>
                <div className="row"><div className="col-12 d-flex flex-row justify-content-end"><button onClick={() => this.props.handleModal(this.props.id)} className="btn bg-none text-dark">X</button></div></div>
                <div className="row">
                  <div className="col-12 d-flex flex-row justify-content-between">

                    <div className="d-flex flex-row mb-2">
                      {this.state.data.country !== "Argentina" && <img style={{maxWidth: '30px', maxHeight: '60px'}} src={this.handleCountryImage()} alt="Country image" />}
                      <h2 className="fw-bold text-primary mb-0 mx-2 align-bottom pt-3" style={{lineHeight: '55px'}}>{this.state.data.patient.full_name}</h2>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <p className="text-primary mx-1 fw-bold" style={{marginBottom: 0, lineHeight: '35px'}}>ID:</p>
                      <input value={this.state.treatment_id} onChange={(e) => this.setState({treatment_id: e.target.value})} type="text" name="id" id="id" className="form-control" style={{fontSize: '.8rem', maxWidth: '100px', marginBottom: 0}} />
                      {this.state.treatment_id !== this.state.data.external_id && <button style={{padding: 0, margin: 0, marginLeft: '5px', border: 'none', background: 'none', maxHeight: '35px'}} ><ArrowRight size="18" color="#00155c" /></button>}
                    </div>
                  </div>
                  <div className="col-2 d-flex justify-content-center">
                    {/* <div style={{ width: '100%', height: '150px', border: '1px solid #444' }}></div> */}
                    <img src={this.handleProfileImage()} alt="Profile" style={{ maxWidth: '175px', height: '175px', objectFit: 'cover' }} />
                  </div>
  
                  <div className="col-6">
                    <div className="row">
                      <div className="d-flex flex-row">
                      <p className="fs-4 text-primary">{this.state.data.type} {this.state.data.stage}° Etapa</p>
                      <div className="mx-2">
                        <div className="btn-group dropup">
                          <button onClick={() => this.changePriority()} className="btn p-0">
                            {this.state.data.priority === 'high' ? <ArrowUpCircle color="#ec7063" size="24" /> : <ArrowUpCircle color="#ccc" size="24" />}
                          </button>
                        </div>
                      </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <div>
                          <small>Doctor</small>
                          <p className="fw-bold">{this.state.data.dentist.full_name}</p>
                        </div>
                        <div>
                          <small>Fecha de solicitud</small>
                          <p className="fw-bold">{moment(this.state.data.created_at).format('DD-MM-YYYY')}</p>
                        </div>
  
                      </div>
                      <div className="col-3">
                        <div>
                          <small>Fecha de ingreso</small>
                          {this.state.status > 2 && this.state.data.movements !== undefined ? <p className="fw-bold">{moment(this.state.data.created_at).format('DD-MM-YYYY')}</p> : <p className="text-muted">Sin asignar</p>}
                        </div>
                        <div>
                          <small>Fecha de salida</small>
                          <p className="fw-bold">{moment(this.state.data.created_at).format('DD-MM-YYYY')}</p>
                        </div>
                      </div>
                      <div className="col-3">
                        <div>
                          <small>Maxilar</small>
                          <p className="fw-bold">{this.state.data.maxilla}</p>
                        </div>
                        <div>
                          <small>Attachments</small>
                          {this.state.status > 2 && this.state.data.attachments !== undefined ? <p className="fw-bold">{this.state.data.attachments}</p> : <p className="text-muted">Sin asignar</p>}
                        </div>
                      </div>
                      <div className="col-2">
                        <div>
                          <small>Movimientos</small>
                          {this.state.status > 2 && this.state.data.movements !== undefined ? <p className="fw-bold">{this.state.data.movements}</p> : <p className="text-muted">Sin asignar</p>}
                        </div>
                        <div>
                          <small>Planilla</small>
                          {/* CHEQUEAR LA PLANILLA EN LA DATA DEL STATE */}
                          {this.state.status > 2 && this.state.data.payroll !== undefined ? <p className="fw-bold">{this.state.data.payroll}</p> : <p className="text-muted">Sin asignar</p>}
                          {/* CHEQUEAR LA PLANILLA EN LA DATA DEL STATE */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row">
                      <div className="col-6">
                        <div className="border border-1 shadow rounded p-2 text-center w-100 h-100">
                          <small className="fw-bold">Estado de tratamiento:</small>
                          <div className="mt-2 text-left">
                            {
                              this.state.status === 2 ?
                                  <React.Fragment>
                                    <small className="text-black-50">Pendiente en:</small>
                                    <p className="text-warning">{this.state.data.status.name}</p>
                                  </React.Fragment> :
                                  <React.Fragment>
                                    <small className="text-black-50">{this.state.data.status.name} en:</small>
                                    <p className="text-warning">{this.state.data.process}</p>
                                  </React.Fragment>
                            }

                          </div>
                        </div>
                      </div>
                      {this.state.status > 2 && <div className="col-6 ">
                        <div className="border border-1 shadow rounded p-2 text-center">
                          <small className="fw-bold mb-2">Estado de pago:</small>
                          <PaymentProcess payment_status={this.state.data.payment_status} />
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 d-flex flex-row justify-content-between mt-3">
                    <div className="shadow d-flex flex-column p-2">
                            <p className="text-danger">Documentacion incompleta:</p>
                            <div className="d-flex flex-row">
                              {this.state.data.photos <= 0 && <p className="text-primary mx-2 mb-0" style={{lineHeight: '40px'}} >Fotos</p>}
                              {this.state.data.studies <= 0 && <p className="text-primary mx-2 mb-0" style={{lineHeight: '40px'}} >Estudios</p>}
                              {this.state.data.plan <= 0 && <p className="text-primary mx-2 mb-0" style={{lineHeight: '40px'}} >Plan</p>}
                              <button className="btn btn-outline-primary rounded-pill px-3 mx-3">Reclamar</button>
                            </div>
                    </div>
                    <div>
                      <button className="btn btn-outline-primary rounded-pill mx-2 px-5">Retiro</button>
                      {this.state.status === 2 && <button onClick={this.handleAlta} className="btn btn-primary rounded-pill px-5">Dar de alta</button>}
                    </div>
                  </div>
                </div>
                <hr />
                {this.state.data.back !== null && <button onClick={() => switchStage(this.state.data.back)} className="btn btn-success">Anterior</button>}
                {this.state.data.next !== null && <button onClick={() => switchStage(this.state.data.next)} className="btn btn-success">Siguiente</button>}
                <div className="row">
                  <div className="col-6 d-flex flex-row justify-content-start">
                    <ul className="d-flex flex-row p-0" style={{ listStyleType: 'none' }}>
                      <li style={{ marginRight: '15px' }}><a className={this.state.step === 1 ? "text-primary fs-6 btn border-0 m-0 disabled" : "text-primary cursor-pointer fs-6 btn border-0 m-0"} href="#" onClick={() => this.setState({ step: 1 })}>Fotos</a></li>
                      <li style={{ marginRight: '15px' }}><a className={this.state.step === 2 ? "text-primary fs-6 btn border-0 m-0 disabled" : "text-primary cursor-pointer fs-6 btn border-0 m-0"} href="#" onClick={() => this.setState({ step: 2 })}>Estudios</a></li>
                      <li style={{ marginRight: '15px' }}><a className={this.state.step === 3 ? "text-primary fs-6 btn border-0 m-0 disabled" : "text-primary cursor-pointer fs-6 btn border-0 m-0"} href="#" onClick={() => this.setState({ step: 3 })}>Plan de tratamiento</a></li>
                      <li style={{ marginRight: '15px' }}><a className={this.state.step === 4 ? "text-primary fs-6 btn border-0 m-0 disabled" : "text-primary cursor-pointer fs-6 btn border-0 m-0"} href="#" onClick={() => this.setState({ step: 4 })}>Visor Original</a></li>
                    </ul>
                  </div>
                </div>
  
                {this.state.step === 1 && <div className="row d-flex flex-row justify-content-center">
                {this.state.data.photos <= 0 && <p className="text-danger" style={{marginLeft: '30px'}} >¡Faltan fotos!</p>}
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}}>
                    {/* <PhotoItem image={this.handleImageSource('frontal_sonriendo')} /> */}
                    {this.state.frontal_sonriendo !== undefined ? <PhotoItem ref={this.frontal_sonriendo} image={this.state.frontal_sonriendo} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.frontal_sonriendo}/>}
                    <p style={{ fontSize: '.9rem' }}>Frontal sonriendo</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('frontal_sonriendo')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.frontal_reposo !== undefined ? <PhotoItem ref={this.frontal_reposo} image={this.state.frontal_reposo} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.frontal_reposo}/>}
                    <p style={{ fontSize: '.9rem' }}>Frontal en reposo</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('frontal_reposo')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.perfil_izq_reposo !== undefined ? <PhotoItem ref={this.perfil_izq_reposo} image={this.state.perfil_izq_reposo} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.perfil_izq_reposo}/>}
                    <p style={{ fontSize: '.9rem' }}>Perfil en reposo</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('perfil_izq_reposo')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.trescuartos_perfil !== undefined ? <PhotoItem ref={this.trescuartos_perfil} image={this.state.trescuartos_perfil} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.trescuartos_perfil}/>}
                    <p style={{ fontSize: '.9rem' }}>3/4 perfil sonriendo</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('trescuartos_perfil')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.oclusal_maxilar_sup !== undefined ? <PhotoItem ref={this.oclusal_maxilar_sup} image={this.state.oclusal_maxilar_sup} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.oclusal_maxilar_sup}/>}
                    <p style={{ fontSize: '.9rem' }}>Oclusal maxilar superior</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('oclusal_maxilar_sup')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.bucal_izquierdo !== undefined ? <PhotoItem ref={this.bucal_izquierdo} image={this.state.bucal_izquierdo} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.bucal_izquierdo}/>}
                    <p style={{ fontSize: '.9rem' }}>Bucal izquierdo</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('bucal_izquierdo')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.anterior_oclusion !== undefined ? <PhotoItem ref={this.anterior_oclusion} image={this.state.anterior_oclusion} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.anterior_oclusion}/>}
                    <p style={{ fontSize: '.9rem' }}>Anterior en oclusion</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('anterior_oclusion')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.anterior_inoclusion !== undefined ? <PhotoItem ref={this.anterior_inoclusion} image={this.state.anterior_inoclusion} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.anterior_inoclusion}/>}
                    <p style={{ fontSize: '.9rem' }}>Anterior en inoclusion</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('anterior_inoclusion')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.bucal_derecho !== undefined ? <PhotoItem ref={this.bucal_derecho} image={this.state.bucal_derecho} group="photos"/> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.bucal_derecho}/>}
                    <p style={{ fontSize: '.9rem' }}>Bucal derecho</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('bucal_derecho')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center" style={{width: '150px', height: '150px', position: 'relative', marginBottom: '30px'}} >
                    {this.state.oclusal_maxilar_inf !== undefined ? <PhotoItem ref={this.oclusal_maxilar_inf} image={this.state.oclusal_maxilar_inf} group="photos" /> : <img style={{ width: '100%', height: '100%', maxWidth: '150px', maxHeight: '150px', objectFit: 'cover' }} src={this.state.default_images.oclusal_maxilar_inf}/>}
                    <p style={{ fontSize: '.9rem' }}>Oclusal maxilar inferior</p>
                    <button style={{border: 'none', padding: '10px', backgroundColor: '#ccc', position: 'absolute', top: 0, right: 0, backgroundColor: '#fff'}} onClick={() => this.showCropArea('oclusal_maxilar_inf')}><Edit2 color="#ec7063" size="15" /></button>
                  </div>
                </div>}
  
                {this.state.step === 2 && <div className="row d-flex flex-row justify-content-center">
                {this.state.data.studies <= 0 && <p className="text-danger" style={{marginLeft: '30px'}} >¡Faltan estudios!</p>}
                  <div className="col-2 mx-2 my-2 text-center">
                    <PhotoItem image={this.handleStudySource('rxpanoramica')} group="studies"/>
                    <p className="mt-1" style={{ fontSize: '.9rem' }}>RX Panoramica</p>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center">
                    <PhotoItem image={this.handleStudySource('telerradiografia')} group="studies"/>
                    <p className="mt-1" style={{ fontSize: '.9rem' }}>Telerradiografia de perfil</p>
                  </div>
                  <div className="col-2 mx-2 my-2 text-center">
                    <PhotoItem image={this.handleStudySource('cefalogramadericketts')} group="studies"/>
                    <p className="mt-1" style={{ fontSize: '.9rem' }}>Cefalograma de Ricketts</p>
                  </div>
                </div>}
  
                {this.state.step === 3 && <div className="row d-flex flex-row justify-content-center">
                {this.state.data.plan <= 0 && <p className="text-danger" style={{marginLeft: '30px'}} >¡Falta plan!</p>}
                  <div className="col-12 p-3">
                    <div className="container border border-1">
                      {/* <img src="https://dummyimage.com/600x600/000/fff" alt="" style={{ width: '100%', height: '100%' }} /> */}
                      {
                        this.state.plan_base64 ?
                          <div>
                            <Document
                                file={this.state.plan_base64}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                              <Page height={500} scale={1} pageNumber={this.state.pageNumber} />
                            </Document>
                            <a href={this.state.plan_file} target="_blank"><p>Page {this.state.pageNumber} of {this.state.numPages}</p></a>
                            <button disabled={this.state.pageNumber === 1} onClick={() => this.setState({
                              pageNumber: this.state.pageNumber - 1
                            })}>-</button>
                            <button disabled={this.state.pageNumber === this.state.numPages} onClick={() => this.setState({
                              pageNumber: this.state.pageNumber + 1
                            })}>+</button>
                          </div>
                          : <FormOnline treatment_id={this.state.treatment_id} />
                      }
                    </div>
                  </div>
                  {/* <div className="col-6 p-3">
                    <div className="container">
                      <img src="https://dummyimage.com/600x600/000/fff" alt="" style={{ width: '100%', height: '100%' }} />
                    </div>
                  </div> */}
                </div>}
  
                {this.state.step === 4 && <div className="row d-flex flex-row justify-content-center mt-3">
                  <div className="col-12">
                  <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://viewer3d.keepsmilinglog.com/view/189c35be-b69f-4297-92f2-771f0a91ff07' />"}} />
                  </div>
                </div>}
              </ModalBody>
              <ModalFooter className="d-flex flex-row justify-content-between">
                <button className="btn btn-outline-primary rounded-pill px-5">Eliminar solicitud</button>
                {this.state.status > 2 && <select onChange={this.forceStatus} style={{border: '1px solid #00155c', padding: '10px', borderRadius: '20px', fontWeight: 'bold', color: '#00155c', margin: '0'}}>
                  <option selected disabled>Cambiar estado</option>
                  {
                    Object.entries(this.state.statuses).map(([key, value]) => {
                      return (
                          <optgroup label={key}>
                            {
                              value.map((value) =>
                                  <option value={value.id}>{value.name}</option>
                              )
                            }
                          </optgroup>
                      )
                    })
                  }
                </select>}
              </ModalFooter>
            </Modal>
          </div>
        )
      }
    }

  }
}

const mapStateToProps = (state) => {
  return {
    login_token: state.userReducer.login_token
  }
}

export default connect(mapStateToProps)(ModalHistoriaClinica)
