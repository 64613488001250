import React, {Component, useState, useRef} from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import apiClient from '../../../utils/backend'
import { useHistory } from 'react-router'


// CROPPER
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// CROPPER

const RevisionModal = (props) => {
  const {
          className,
          id,
          patient,
          doctor,
          registry,
          opened,
          handleModal
        } = props;

        const [obs, setObs] = useState('');
        const [loading, setLoading] = useState(false);
        const history = useHistory()
      
        function handleReject() {
      
          setLoading(true)
      
          apiClient.post(`/api/status/altStatus`, {treatment_id: id, process: 'Aprobacion', observation: obs})
          .then(res => {
            console.log(res.data)
            
            if(res.status === 200) {
              history.go(0)
            }
          })
          .catch(err => console.log(err))
        }

        //CROPPER
        const cropperRef = useRef(null);
        const onCrop = () => {
          const imageElement = cropperRef ? cropperRef.current : null;
          const cropper = imageElement ? imageElement.cropper : null;
          console.log(cropper.getCroppedCanvas().toDataURL());
        };
        //CROPPER

  return (
      <div>
          <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
              <ModalBody>
                  <div className="container">
                  <div className="mb-5 text-primary">Aprobación</div>
                  <div className="mb-3 text-primary fs-5">Desea rechazar el registro de:</div>
                  <div className="row">
                    <div className="col-4 d-flex flex-column">
                      <small>Paciente:</small>
                      <p className="mt-1 text-primary">{patient}</p>
                    </div>
                    <div className="col-2 d-flex flex-column">
                      <small>Id:</small>
                      <p className="mt-1 text-primary">{id}</p>
                    </div>
                    <div className="col-4 d-flex flex-column">
                      <small>Doctor:</small>
                      <p className="mt-1 text-primary">{doctor}</p>
                    </div>
                    <div className="col-2 d-flex flex-column">
                      <small>Registro:</small>
                      <p className="mt-1 text-primary">{registry}</p>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <label htmlFor="observations">Observaciones:</label>
                      <textarea className="rounded" name="observations" id="observations" cols="60" rows="5"></textarea>
                    </div>
                  </div>
                  </div>
              </ModalBody>
              <ModalFooter className='d-flex justify-content-center'>
                
                  {!loading && 
                    <div className="w-50 d-flex justify-content-around">
                      <button className="btn btn-light border rounded-pill" onClick={handleModal}>Volver</button>
                      <button className="btn btn-danger text-white rounded-pill" onClick={handleReject}>Rechazar</button>
                    </div>
                  }

                  {loading &&
                  <button className="btn btn-danger" type="button" disabled>
                    <span className="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
                  </button>
                  }
                  
              </ModalFooter>
          </Modal>
      </div>
  );
}

export default RevisionModal