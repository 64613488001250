import React, { useState, useRef, useEffect } from "react";
import { Edit2, Upload } from "react-feather";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

import ARG from '../../../assets/images/Argentina2.png'
import CHI from '../../../assets/images/Chile2.png'
import COL from '../../../assets/images/Colombia2.png'
import PAR from '../../../assets/images/Paraguay2.png'
import PER from '../../../assets/images/Peru2.png'
import URU from '../../../assets/images/Uruguay2.png'

import { toast} from "react-toastify";
import { ImageUploadContainer } from "../../CargarTratamiento/components/styled";
import Compressor from "compressorjs";
import { useDispatch, useSelector } from "react-redux";
import { startGetUserList, startSaveUser } from "../../../redux/actions/users";


export default function EditModal({ props }) {
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    id: props?.id,
    name: props?.name,
    surname: props?.surname,
    avatar: props?.avatar,
    profile_id: props?.sector.id,
    country_id: props?.country,
    position_id: props?.position.id,
  });
  const [avatar, setAvatar] = useState()
  const [hasAvatar, setHasAvatar] = useState(false);
  const { profilesData } = useSelector((state) => state.usersReducer);
  const profileDataRef = useRef(profilesData);

  const handleInputChange = ({ target }) => {
    setUserData({
      ...userData,
      [target.name]: target.value,
    });
  };

  const dispatch = useDispatch();

  function saveUser() {
    if (!userData.name.trim() || !userData.surname.trim()) {
      toast.error("Complete los campos por favor!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        containerId:"edit_user_toast"
      });
      return;
    }
    const data = {userData,avatar,hasAvatar,setHasAvatar}
    dispatch(startSaveUser(data));
    dispatch(startGetUserList());
    // setOpened(false);
    // setTimeout(() => {
    //   window.location.reload()
    // }, 1500);
  }

  function handleModal() {
    setOpened(!opened);
  }

  function handleCountryFlag(name) {
    const countrys = {
      Argentina: ARG,
      Chile: CHI,
      Colombia: COL,
      Peru: PER,
      Paraguay: PAR,
      Uruguay: URU,
    };
    return countrys[name];
  }

  const handleImageSelect = async (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg"
    ) {
      toast.error(
        "👎 Solo se permite la carga de archivos tipo PNG,JPG,JPEG. Intente nuevamente.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
        }
      );
      setHasAvatar(false);
      return;
    }
    setAvatar(file)
    new Compressor(file, {
      quality: 0.1,
      
      success(result) {
        let reader = new FileReader();
        let file_compressed = result;
        
        reader.onloadend = () => {
          setUserData({ ...userData, avatar: reader.result });
          setHasAvatar(true);
        };

        reader.readAsDataURL(file_compressed);
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  return (
    <div>

      <button
        onClick={() => setOpened(true)}
        className="btn btn-outline-primary bg-none py-2 mx-3 px-4 rounded-pill d-flex flex-row justify-content-end"
      >
        <Edit2 className="mx-1" size={18} color="#00155C" /> Editar
      </button>
      <Modal isOpen={opened} toggle={handleModal} size="lg">

        <ModalBody>
          <div className="container">
            <div className="mb-3 text-primary fw-bold">Editar Usuario</div>
            <div className="row">
              <div className="col-3">
                {userData.avatar === null || userData.avatar === "" ? (
                  <ImageUploadContainer className="m-auto">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageSelect}
                      id="profile"
                      className="estudios"
                    />
                    <Upload className="m-auto" />
                  </ImageUploadContainer>
                ) : (
                  <div className="d-flex flex-column">
                  <ImageUploadContainer className="align-self-end" style={{maxWidth:"40px",maxHeight:"40px",backgroundColor:"#fff"}}> 
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageSelect}
                      id="profile"
                      className="estudios"
                    />
                    <Edit2  style={{maxWidth:"20px"}} color="#00155C"/>
                  </ImageUploadContainer>
                  <img name="photo" id="photo" src={userData.avatar} alt={userData.avatar} className="ms-4" style={{maxWidth:"150px",maxHeight:"150px"}} />
                  </div>
                )}
              </div>
              <div className="col-6 px-4">
                <form>
                  <div class="">
                    <label
                      for="name"
                      class="form-label"
                      style={{ fontSize: ".7em" }}
                    >
                      Nombre
                    </label>
                    <input
                      name="name"
                      value={userData.name}
                      onChange={handleInputChange}
                      type="text"
                      class="form-control"
                      style={{ maxHeight: "30px" }}
                      id="name"
                    />
                  </div>
                  <div class="">
                    <label
                      for="surname"
                      class="form-label"
                      style={{ fontSize: ".7em" }}
                    >
                      Apellido
                    </label>
                    <input
                      name="surname"
                      value={userData.surname}
                      onChange={handleInputChange}
                      type="text"
                      class="form-control"
                      style={{ maxHeight: "30px" }}
                      id="surname"
                    />
                  </div>
                </form>
              </div>
              
            </div>
            <div className="row w-100 mt-4">
              <div className="col-6 p-0">
                <p className="text-primary">Perfil</p>
                <div className="d-flex flex-row flex-wrap row justify-content-left">
                  {profileDataRef.current.profiles?.map((profile) => (
                    <button
                      onClick={() => {
                        setUserData({ ...userData, profile_id: profile.id });
                      }}
                      className={
                        `btn px-2 col-3 rounded-3 mx-2 my-1 ` +
                        (userData.profile_id === profile.id
                          ? "btn-primary"
                          : "btn-outline-primary")
                      }
                    >
                      {profile.title}
                    </button>
                  ))}
                </div>
              </div>
              <div className="col-6 p-0">
                <p className="text-primary">Pais</p>
                <div className="d-flex flex-row flex-wrap row justify-content-left">
                  {profileDataRef.current.countries?.map((country) => (
                    <button
                      onClick={() => {
                        setUserData({ ...userData, country_id: country.id });
                      }}
                      className={
                        `btn  px-2 col-3 rounded-3 mx-2 my-1 ` +
                        (userData.country_id === country.id
                          ? "btn-primary"
                          : "btn-outline-primary")
                      }
                    >
                      {country.name[0].toString().toUpperCase() +
                        country.name[1].toString().toUpperCase()}
                      {(
                        <img
                          src={handleCountryFlag(country.name)}
                          style={{ maxHeight: "25px", marginLeft: "3px" }}
                          alt={country.name}
                        />
                      )}
                    </button>
                  ))}
                </div>
              </div>
              <div className="col-12 p-0 mt-4">
                <p className="text-primary">Puesto</p>
                <div className=" row p-0">
                  {profileDataRef.current.positions?.map((position) => (
                    <div className="col-3">
                      <button
                        onClick={() => {
                          setUserData({
                            ...userData,
                            position_id: position.id,
                          });
                        }}
                        className={
                          `btn px-2 w-100 rounded-3 mx-2 my-1 ` +
                          (userData.position_id === position.id
                            ? "btn-primary"
                            : "btn-outline-primary")
                        }
                      >
                        {position.title}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          {!loading && (
            <div className="w-100 d-flex justify-content-end">
              <button
                className="btn btn-primary text-white rounded-pill px-5"
                onClick={saveUser}
              >
                Guardar
              </button>
            </div>
          )}

          {loading && (
            <button class="btn btn-success rounded-pill" type="button" disabled>
              <span
                class="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}
