let initialState = {
  dentistId: null,
  id: null,
  name: null,
  surname: null,
  email: null,
  profile: null,
  birthdate: null,
  smilecreator: null,
  smilecreator_points: null,
  gender: null,
  loged_in: null,
  login_token: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      let d = action.payload
      return {
        ...state,
        dentistId: d.dentistId,
        name: d.name,
        surname: d.surname,
        gender: d.gender,
        email: d.email,
        profile: d.profile,
        birthdate: d.birthdate,
        smilecreator: d.smilecreator,
        smilecreator_points: d.smilecreator_points,
        login_token: d.token,
        loged_in: true,
        id: d.id,
        countryId: d.country,
      }
    case 'LOGOUT':
      return initialState
    default:
      return state
  }
}

export default userReducer
