import {AestheticPriority, PretratmentDiagnosisValues, InstructionsTratment, ErrorsList, CurrentStep, Header } from '../../pages/FormularioOnline/components/Utilities/Types'
const initialState = {
    AestheticPriority,
    PretratmentDiagnosisValues,
    InstructionsTratment,
    ErrorsList,
    CurrentStep,
    Enabled: true,
    Header
}
  
const formOnlineReducer = (state = initialState, action ) => {
    const { type, field, payload }=action
    const step=type
    console.log({step, field, payload})
    if(type==="enable"){
        console.log({type, payload})
        return {
            ...state,
            Enabled: payload
        }
    }
    if(type==="errors_form_online"){
        return {
            ...state,
            ErrorsList: payload
        }
    }
    if(type==='clear_form_online'){
        state = initialState
        return state
    }
    if(type==='set_form_online'){
        return {...state, 
            AestheticPriority: payload.AestheticPriority,
            PretratmentDiagnosisValues: payload.PretreatmentDiagnosisValues,
            InstructionsTratment: payload.TreatmentInstructions,
        }
    }
    if(payload?.key==='age'){
        return {...state, Header:{
            ...state.Header, MainInformation: {
                ...state.Header.MainInformation, age:payload.value
            }
        }}
    }
    if(!step || !payload || !field){
        return state
    }
    if(isNaN(Number(payload.index))){
        return {
            ...state,
                [step]: 
                { ...state[step], 
                    [field]: 
                    { ...state[step][field], 
                        [payload.key]: payload.value
                    } 
                }
            }
    }else{
        return {
            ...state,
            [step]: 
                { ...state[step], 
                    [field]: 
                    { ...state[step][field], 
                        [payload.key]: 
                            state[step][field][payload.key].map((item,index)=>{
                                return index===payload.index?payload.value:item
                            })
                    } 
                }
        }
    }
}

export default formOnlineReducer;