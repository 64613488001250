import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";
import RegistryCard from "./components/card";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      yeso: [],
      intraoral: [],
      yeso_rech: [],
      intra_rech: [],
      yeso_all: [],
      intra_all: [],
      type: 1,
      loading: true,

      search: "",
      filter_yeso: false,
      yeso_intraoral: false,
      date_yeso: "",
      date_yeso_rechazado: "",
      tipo_yeso: "",
      tipo_yeso_rechazado: "",
      date_intraoral: "",
      date_intraoral_rechazado: "",
      tipo_intraoral: "",
      tipo_intraoral_rechazado: "",

      modal_id: null,
    };
  }

  componentDidMount() {
    apiClient
      .get("/api/incomes")
      .then((res) => {
        console.log(res);
        let yeso_rech = res.data.data.yeso_rechazado.map((item) => item);
        let intra_rech = res.data.data.intraoral_rechazado.map((item) => item);
        let yeso = res.data.data.intraoral.map((item) => item);
        let intra = res.data.data.yeso.map((item) => item);

        this.setState({ yeso_rech, intra_rech, yeso, intra, loading: false });
        console.log({ yeso_rech, intra_rech, yeso, intra, loading: false });
        console.log(res);
      })
      .catch((err) => console.log(err));
  }

  handleRedirect = () => {
    this.props.history.push("/tratamientos/cargar");
  };

  handleInputChange(e) {
    e.preventDefault();
    let search = e.target.value;
    let filter = {
      search: this.state.search,
      filter_yeso: false,
      yeso_intraoral: false,
      date_yeso: this.state.date_yeso,
      date_yeso_rechazado: this.state.date_yeso_rechazado,
      tipo_yeso: this.state.tipo_yeso,
      tipo_yeso_rechazado: this.state.tipo_yeso_rechazado,
      date_intraoral: this.state.date_intraoral,
      date_intraoral_rechazado: this.state.date_intraoral_rechazado,
      tipo_intraoral: this.state.tipo_intraoral,
      tipo_intraoral_rechazado: this.state.tipo_intraoral_rechazado,
    };

    if (search.length > 3) {
      apiClient
        .get("api/treatments/search", { filter })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container mt-2">
          <div className="row">
            <div className="col-12">
              <h1 style={{ fontWeight: 700 }} className="text-primary">
                Registro
              </h1>
            </div>
            <div className="col-12 mt-4 d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <div className="row shadow py-2 mx-2 rounded">
                  <div className="col-4">
                    <h2 className="justify-content-center align-middle text-center m-0">
                      {this.state.yeso_all.length + this.state.intra_all.length}
                    </h2>
                  </div>
                  <div className="col-8">
                    <p className="justify-content-center align-middle m-0">
                      Casos pendientes de carga de registro
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <ul
                className="d-flex flex-row justify-content-end p-0"
                style={{ listStyleType: "none" }}
              >
                <li style={{ marginRight: "15px" }}>
                  <a
                    className="text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                    href="#"
                  >
                    Argentina
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                    href="#"
                    onClick={() => this.setState({ type: 2 })}
                  >
                    Chile
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                    href="#"
                    onClick={() => this.setState({ type: 2 })}
                  >
                    Uruguay
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                    href="#"
                    onClick={() => this.setState({ type: 2 })}
                  >
                    Paraguay
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                    href="#"
                    onClick={() => this.setState({ type: 2 })}
                  >
                    Peru
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                    href="#"
                    onClick={() => this.setState({ type: 2 })}
                  >
                    Colombia
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12">
              <input
                type="text"
                className="w-100 border-0 p-3"
                placeholder="Escribe al menos 3 caracteres..."
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-1 d-flex flex-row justify-content-center px-4">
              <p className="text-primary fw-bold">ID</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Paciente</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Doctor</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Tratamiento</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Etapa</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Fecha ingr.</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Registro</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Observacion</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold">Aprobacion</p>
            </div>
          </div>
          <RegistryCard />
          <RegistryCard />
          <RegistryCard />
          <RegistryCard />
          <RegistryCard />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
