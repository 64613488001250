import React, { Fragment, useState, useEffect } from "react";
import HeaderTable from "./components/HeaderTable";
import BodyTable from "./components/BodyTable";
import apiClient from "../../utils/backend";

import Searchbar from "../../components/searchbar";

function Reclamos() {
  const [filter, setFilter] = useState("all");
  const [claims, setClaims] = useState({
    all: [],
    documentation: [],
    approbation: [],
    other: [],
  });

  useEffect(() => {
    apiClient.get("/api/claims").then((res) => {
      setClaims(res.data);
    });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row pt-5 px-4">
        <div className="col-8">
          <h1 className="fw-bold text-primary mb-2">Reclamos</h1>
        </div>
        <div className="col-4">
          <Searchbar />
        </div>
        <div className="col-12 mt-3">
          <ul
            className="d-flex flex-row justify-content-start p-0"
            style={{ listStyleType: "none" }}
          >
            <li style={{ marginRight: "15px" }}>
              <a
                onClick={() => setFilter("all")}
                className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                  filter === "all" && "disabled"
                }`}
                href="#"
              >
                Todos <span className="text-muted">({claims.all.length})</span>
              </a>
            </li>
            <li style={{ marginRight: "15px" }}>
              <a
                onClick={() => setFilter("documentation")}
                className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                  filter === "documentation" && "disabled"
                }`}
                href="#"
              >
                Documentacion Faltante{" "}
                <span className="text-muted">
                  ({claims.documentation.length})
                </span>
              </a>
            </li>
            <li style={{ marginRight: "15px" }}>
              <a
                onClick={() => setFilter("approbation")}
                className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                  filter === "approbation" && "disabled"
                }`}
                href="#"
              >
                Aprobación de Video{" "}
                <span className="text-muted">
                  ({claims.approbation?.length || 0})
                </span>
              </a>
            </li>
            <li style={{ marginRight: "15px" }}>
              <a
                onClick={() => setFilter("other")}
                className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                  filter === "other" && "disabled"
                }`}
                href="#"
              >
                Otros{" "}
                <span className="text-muted">
                  ({claims.other?.length || 0})
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <HeaderTable />

      {claims[filter]?.map((claim) => {
        return <BodyTable claim={claim} />;
      })}
    </div>
  );
}

export default Reclamos;
