import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";
import { ChevronDown, ChevronUp } from "react-feather";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// COMPONENTS
import Modals from "./components/modals";
import PaymentsCard from "./components/payments_card";
import PaymentsForm from "./components/payments_form";
import AccountCard from "./components/account_card";
import AssignMoneyModal from "./components/assign_money_modal";
import CurrentAccountCard from "./components/currentaccount_card";
import AssignCurrentMoneyModal from "./components/assign_money_currentaccount_modal";
import PaymentStatusCard from "./components/payment_status_card";
import PaymentStatusSubcard from './components/payment_status_subcard'
import PlacasRepeticion from "./PlacasRepeticion";

class index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      table: {},
      cards: [],
      loading: true,
      isopened: false,
      card_opened: null,
      step: "all",
      filter: 'all',
      modal_opened: false,
      card_show: null,
      currentaccount_modal: false,
      modal_data: {
        patient: null,
        created_at: null,
        treatment: null,
        maxilla: null,
        discount: null,
        cost: null,
        payment_status: null,
        status: null,
        id: null,
      },
      estado_pagos_data: [],
      currentaccount_modal_data: [],
      total_current: 0,
      total_future: 0,
      total_overdue: 0,
      count_pending: 0
    };

    this.handleModal = this.handleModal.bind(this);
    this.handleButtonType = this.handleButtonType.bind(this);
    this.handleCardShow = this.handleCardShow.bind(this);
    this.handleCard = this.handleCard.bind(this);
    this.startPayment = this.startPayment.bind(this);
    this.updateDetail = this.updateDetail.bind(this);
    this.handleCurrentAccountModal = this.handleCurrentAccountModal.bind(this);
    this.handleAddPaymentCA = this.handleAddPaymentCA.bind(this);
    this.handleStep = this.handleStep.bind(this);
  }

  componentDidMount() {
    apiClient
      .get(`/api/payments`)
      .then((res) => {
        console.log(res.data);
        res.data.table.body=res.data.table.body.map(item=>{
          return {...item, details: item.details.map(detail=>{
            let payment_done = false
            if (detail.payment_method &&
              detail.bill &&
              detail.bill_date &&
              detail.bill_amount
              ){
                payment_done=true
              }
              return { ...detail, payment_done }
            })}
          })
        console.log(res.data);
        this.setState({
          table: res.data.table,
          cards: res.data.card,
          loading: false,
        });
        console.log(this.state);
      })
      .catch((err) => console.log(err));

    this.handleAccountModal = this.handleAccountModal.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step) {
      this.setState({loading: true})
      if(this.state.step === 'cuenta_corriente'){
        this.setState({loading: true})
        apiClient.get('/api/currentAccounts')
        .then(res => {
          console.log("respuesta")
          console.log(res.data)
          let keys = Object.keys(res.data.data)
          let values = Object.values(res.data.data)
          this.setState({
            data: res.data.data,
            keys,
            values,
            count_pending: res.data.count_pending,
            loading: false
          })
        })
      } else if(this.state.step === 'estado_pagos'){
        this.setState({loading: true})
        apiClient.get('/api/paymentStatuses')
        .then(res => {
          console.log(res.data)
          let keys = Object.keys(res.data.data)
          let values = Object.values(res.data.data)
          let cards = []
          let cp = []
          let critical_payments = values.filter(item => item.critical_payment)

          // let critical_payments = res.data.filter(item => item.critical_payment)
          keys.map((key, i) => cards.push([key, values[i]]))
          keys.map((key, i) => cp.push([key, critical_payments[i]]))
          console.log(critical_payments)
          this.setState({
            estado_pagos_data: cards,
            total_current: res.data.total_current,
            total_overdue: res.data.total_overdue,
            total_future: res.data.total_future,
            total_critical: res.data.total_critical,
            critical_payments: cp,
            loading: false
          })
        })
      }

      this.setState({loading: false})
    }
  }

  handleModal() {
    this.setState({ modal_opened: !this.state.modal_opened });
  }

  handleButtonType({ button, id }) {}

  startPayment(id, index, restrict, discount, cost, finalCost) {
    console.log("entra aca");
    apiClient.put(`api/payments/${id}`, {video_restrict: restrict, discount: discount, cost: cost, final_cost: finalCost})
        .then(res => {
          // Actualizamos el item correspondiente
          let payments = [...this.state.table.body];
          payments[index] = res.data;

          this.setState({
            table: {
              body: payments
            }
          })
        })
        .catch(err => console.log(err))
  }

  updateDetail(payment) {
    console.log("acaaaaaaaaaa")
    console.log(payment)
    apiClient.put(`/api/payments/${payment.payment_id}/details/${payment.id}`, {payment_amount: payment.payment_amount, bill: payment.bill, bill_amount: payment.bill_amount, payment_method: payment.payment_method, payment_date: payment.payment_date, bill_date: payment.bill_date})
        .then(res => {
          // Actualizamos el payment status
          let payments = [...this.state.table.body];
          console.log("payments")
          console.log(payments)
          payments[payment.index].payment_status = res.data;
          payments[payment.index].details=payments[payment.index].details.map(detail=>{
            console.log({paymentId:payment.id, iterate: detail.id});
            if(detail.id === payment.id){
              console.log('updated!', {...detail, payment_done : true});
              return {...detail, payment_done : true}
            }
            return detail
          })

          this.setState({
            table: {
              body: payments
            }
          })
        })
        .catch(err => console.log(err))
  }

  handleAddPayment(id, index){
    apiClient.post(`/api/payments/${id}/details`)
    .then(res => {
      console.log(res);

      let payments = [...this.state.table.body];
      payments[index].details = payments[index].details.concat(res.data)
      console.log(payments);
      this.setState({
        table: {
          body: payments
        }
      })
    })

    // EXPIRED_DATE, PAYMENT_AMOUNT, EXPECTED
  }

  // handleAddPayment de current account
  handleAddPaymentCA(id){
    console.log("aca")
    apiClient.post(`/api/payments/${id}/details`)
        .then(res => {
          console.log(res);

          this.setState({
              currentaccount_modal_data: {
                ...this.state.currentaccount_modal_data,
                details: this.state.currentaccount_modal_data.details.concat(res.data)
              }
          })
        })

    // EXPIRED_DATE, PAYMENT_AMOUNT, EXPECTED
  }

  handleStep(step) {
    this.setState({
      step: step
    })
  }

  handleCardShow(id) {
    this.setState({card_show: id})
  }

  handleCard(i){
    if(this.state.card_opened === i) {
      this.setState({
        card_opened: null
      })
    } else {
      this.setState({card_opened: i})
    }
  }

  handleCurrentAccountModal(item) {
    this.setState({modal_data: item})
  }

  handleAccountModal(data){
    if(data.id){
      if(data.id !== this.state.currentaccount_modal_data.id) {
        console.log("datadfgdfhgfh")
        console.log(this.state.currentaccount_modal_data)
        console.log(data)
        this.setState({currentaccount_modal: !this.state.currentaccount_modal, currentaccount_modal_data: data})
      }
    }

    this.setState({currentaccount_modal: !this.state.currentaccount_modal})
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      if (this.state.step === "all") {
        return (
          <div className="container-fluid px-3 mt-2">
            <div className="row">
              <div className="col-12">
                <h1 style={{ fontWeight: 700 }} className="text-primary">
                  Pagos
                </h1>
              </div>
              {this.state.cards.map((card) => {
                return (
                  <div className="col-3 mt-4 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row">
                      <div className="row shadow py-2 mx-2 rounded">
                        <div className="col-4">
                          <h2 className="justify-content-center align-middle text-center m-0">
                            {card.value}
                          </h2>
                        </div>
                        <div className="col-8">
                          <p className="justify-content-center align-middle m-0">
                            {card.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <input
                  type="text"
                  className="w-100 border-0 p-3"
                  placeholder="Escribe al menos 3 caracteres..."
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-12">
                <ul
                  className="d-flex flex-row justify-content-start p-0"
                  style={{ listStyleType: "none" }}
                >
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                      href="#"
                    >
                      Todos
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() =>
                        this.setState({ step: "cuenta_corriente", loading: true })
                      }
                    >
                      Cuenta Corriente
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() => this.setState({ step: 'estado_pagos' })}
                    >
                      Estado pagos
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                        href="#"
                        onClick={() => this.setState({ step: 'placas_repeticion' })}
                    >
                      Placas de Repetición
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-half d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">ID</p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Doctor</p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Paciente</p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Smile Creator
                </p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Fecha Ingreso
                </p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Fecha Salida
                </p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Tratamiento
                </p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Maxilar</p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Restringe Video
                </p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Descuento
                </p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Costo</p>
              </div>
              <div className="col d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Estado de pago
                </p>
              </div>
            </div>
            {this.state.table.body.map((item, i) => {
              let asd = Object.entries(item);
              let actions = asd.pop();
              let id = item["id"];
              let payment_status = parseFloat(item.payment_status).toFixed(2);
              console.log(item);

              return (
                <div className="grow_hover row w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
                  {/* <button
                    className="clinic-history-button type-1"
                    style={{maxHeight: '70px'}}
                    onClick={() =>
                      this.setState({ modal_show: `modal_hist_clinica-${id}` })
                    }
                  /> */}
                  <PaymentsCard
                    id={id}
                    index={i}
                    created_at={item["created_at"]}
                    external_id={item["id"]}
                    patient={item["patient"]}
                    doctor={item["dentist"]}
                    treatment_name={item["type"]}
                    maxilla={item["maxilla"]}
                    sc_type={item["smile_creator_type"]}
                    video_restrict={item["video_restrict"]}
                    // payment_status={item["payment_status"]}
                    payment_status={payment_status}
                    status={item["status"]}
                    discount_value={item['discount']}
                    cost_value={item['cost']}
                    finalcost_value={item['final_cost']}
                    start={this.startPayment}
                  />
                  <Modals
                    handleModal={this.handleModal}
                    patient={item["patient"]}
                    doctor={item["dentist"]}
                    registro={item["registro"]}
                    id={id}
                    opened={this.state.modal_show}
                  />
                  <button
                    className={item.status > 0 ? "btn bg-none" : 'd-none'}
                    onClick={() =>
                      this.setState({
                        card_opened: this.state.card_opened !== id ? id : null,
                      })
                    }
                    style={{
                      position: "absolute",
                      top: "20px",
                      maxWidth: "20px",
                      right: '15px',
                    }}
                  >
                    {this.state.card_opened === id ? (
                      <ChevronUp color="#444" size="24" />
                    ) : (
                      <ChevronDown color="#444" size="24" />
                    )}
                  </button>
                  {this.state.card_opened === id && (
                    <div
                      className="container px-5 pb-4"
                      style={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "#fefefe",
                      }}
                    >
                      <hr />
                      <div className="row">
                          {item.details.map((payment) => (
                            <PaymentsForm updateDetail={this.updateDetail} payment_data={payment} paymentId={item["id"]} index={i} />
                          ))}
                        <div className="col mx-auto">
                          {item.payment_status < 100 && 
                          <button 
                          className='btn btn-outline-primary rounded-pill'
                          style={item.details.some(payment=>!payment.payment_done)?{background:'lightgray'}:null}
                          disabled={item.details.find(payment=>!payment.payment_done)}
                          onClick={() => this.handleAddPayment(id, i)}>
                            Agregar pago
                          </button>}
                        </div>
                        <div className="col-2 text-center">
                          <p className="fw-bold mt-3 mb-3">Estado del pago</p>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgressbar
                              value={payment_status}
                              text={`${payment_status}%`}
                              strokeWidth={15}
                              styles={buildStyles({
                                pathColor:
                                  payment_status < 50
                                    ? "#e74c3c"
                                    : payment_status >= 50 &&
                                      payment_status < 100
                                    ? "#f1c40f"
                                    : "#2ecc71",
                                textColor:
                                  payment_status < 50
                                    ? "#e74c3c"
                                    : payment_status >= 50 &&
                                      payment_status < 100
                                    ? "#f1c40f"
                                    : "#2ecc71",
                                textSize: 20,
                              })}
                            />
                            <p className="fw-bold mt-2 mb-2">Saldo pendiente: {item.pending_amount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      } else if (this.state.step === "cuenta_corriente") {
        if(this.state.loading){
          return null
        }

        return (
          <div className="container-fluid px-3 mt-2">
            <AssignMoneyModal
              opened={this.state.modal_opened}
              handleModal={this.handleModal}
            />
            <div className="row">
              <div className="col-12 d-flex flex-row">
                <h1 style={{ fontWeight: 700 }} className="text-primary">
                  Cuenta corriente
                </h1>
                <button
                  className="btn btn-primary px-5 rounded"
                  style={{ marginLeft: "auto", marginRight: "100px" }}
                  onClick={() => this.handleModal()}
                >
                  Asignar dinero a doctor
                </button>
              </div>
                  <div className="col-3 mt-4 d-flex flex-row justify-content-between">
                    <div className="d-flex flex-row">
                      <div className="row shadow py-2 mx-2 rounded">
                        <div className="col-4">
                          <h2 className="justify-content-center align-middle text-center m-0">
                            {this.state.count_pending}
                          </h2>
                        </div>
                        <div className="col-8">
                          <p className="justify-content-center align-middle m-0">
                            Nuevos Ingresos de doctores con cuenta corriente
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
            <div className="row mt-5">
              <div className="col-12">
                <input
                  type="text"
                  className="w-100 border-0 p-3"
                  placeholder="Escribe al menos 3 caracteres..."
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-12">
                <ul
                  className="d-flex flex-row justify-content-start p-0"
                  style={{ listStyleType: "none" }}
                >
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() =>
                          this.setState({ step: "all", loading: true })
                      }
                    >
                      Todos
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                      href="#"
                      onClick={() =>
                        this.setState({ step: "cuenta_corriente", loading: true })
                      }
                    >
                      Cuenta Corriente
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() => this.setState({ step: 'estado_pagos' })}
                    >
                      Estado pagos
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                        href="#"
                        onClick={() => this.setState({ step: 'placas_repeticion' })}
                    >
                      Placas de Repetición
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Doctor</p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Smile Creator
                </p>
              </div>
              <div className="col-4 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center"></p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Dinero en cuenta corriente
                </p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Asignar dinero a tratamiento
                </p>
              </div>
            </div>
            <AssignCurrentMoneyModal data={this.state.currentaccount_modal_data} opened={this.state.currentaccount_modal} handleModal={this.handleAccountModal} handleAddPayment={this.handleAddPaymentCA} />
            {this.state.keys !== undefined ? this.state.keys.map((item, i) => {
              const doctor = item
              const sc_type = this.state.data[item].smile_creator_type
              const account_amount = this.state.data[item].current_account_amount
              return (
                <div className="row grow_hover w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
                  <button
                    className="clinic-history-button type-1"
                    style={{maxHeight: '60px'}}
                    onClick={() =>
                      this.setState({ modal_show: `modal_hist_clinica-${i}` })
                    }
                  />
                  <AccountCard
                    id={i}
                    doctor={doctor}
                    sc_type={sc_type}
                    account_amount={account_amount}
                    handleCardShow={this.handleCardShow}
                  />
                  <button
                    className="btn bg-none"
                    onClick={() =>
                      this.setState({
                        card_opened: this.state.card_opened !== i ? i : null,
                      })
                    }
                    style={{
                      position: "absolute",
                      bottom: "-10px",
                      maxWidth: "20px",
                      left: "50%",
                    }}
                  >
                  </button>
                  {this.state.card_show === i && (
                    <div
                      className="container bg-light pt-3"
                      style={{
                        width: "100%",
                        height: "100%",
                        marginTop: '20px',
                        marginBottom: '-16px',
                        paddingBottom: '10px'
                      }}
                    >
                      <div className="row">
                        <div className="col-2 text-center"><p className="fw-bold text-primary">Paciente</p></div>
                        <div className="col-2 text-center"><p className="fw-bold text-primary">Fecha inicio</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Tratamiento</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Maxilar</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Descuento</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Costo</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Estado del pago</p></div>
                        <div className="col-1 text-center"></div>
                        <div className="col-2 text-center"></div>
                      </div>
                      <hr className="m-0" />
                      {/*<AssignCurrentMoneyModal data={this.state.modal_data} />*/}
                      {this.state.data[item].payments.map((item, i) => {
                        const data = item.treatment
                        console.log(item.details)
                        return <CurrentAccountCard details={item.details} id={item.id} patient={data.patient.full_name} created_at={data.created_at} treatment={data.type_id} maxilla={data.maxilla_string} discount={item.discount} cost={item.cost} payment_status={item.payment_status} pending_amount={item.pending_amount} status={item.status}  handleModal={this.handleAccountModal} />
                      })}
                    </div>
                  )}
                </div>
              ); 
            }) : null}
          </div>
        );
      } else if (this.state.step === "estado_pagos") {
        if(this.state.loading){
          return <div>loading</div>
        }

       return <div className="container-fluid px-3 mt-2">
            <div className="row">
              <div className="col-12">
                <h1 style={{ fontWeight: 700 }} className="text-primary">
                  Pagos
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-6 px-3 d-flex flex-column">
                <div className="shadow">
                  <div className="w-100 py-2 bg-danger">
                    <h4 className="text-center text-white fw-bold">Pagos Vencidos</h4>
                  </div>
                  <div className="w-100 py-4">
                    <h2 className="fw-bold text-center text-danger">${this.state.total_overdue}</h2>
                  </div>
                </div>
              </div>
              <div className="col-6 px-3 d-flex flex-column">
                <div className="shadow">
                  <div className="w-100 py-2 bg-dark">
                    <h4 className="text-center text-white fw-bold">Pagos Vigentes</h4>
                  </div>
                  <div className="w-100">
                    <h2 className="fw-bold text-center text-dark py-4">${this.state.total_current}</h2>
                  </div>
                </div>
              </div>
            </div>
           <div className="row mt-4">
             <div className="col-6 px-3 d-flex flex-column">
               <div className="shadow">
                 <div className="w-100 py-2 bg-warning">
                   <h4 className="text-center text-white fw-bold">Pagos Futuros</h4>
                 </div>
                 <div className="w-100 py-4">
                   <h2 className="fw-bold text-center text-warning">${this.state.total_future}</h2>
                 </div>
               </div>
             </div>
             <div className="col-6 px-3 d-flex flex-column">
               <div className="shadow">
                 <div className="w-100 py-2 bg-danger">
                   <h4 className="text-center text-white fw-bold">Pagos Criticos</h4>
                 </div>
                 <div className="w-100 py-4">
                   <h2 className="fw-bold text-center text-warning">${this.state.total_critical}</h2>
                 </div>
               </div>
             </div>
           </div>
            <div className="row mt-5">
              <div className="col-12">
                <input
                  type="text"
                  className="w-100 border-0 p-3"
                  placeholder="Escribe al menos 3 caracteres..."
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="col-12 d-flex justify-content-between">
                <ul
                  className="d-flex flex-row justify-content-start p-0"
                  style={{ listStyleType: "none" }}
                >
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() =>
                          this.setState({ step: "all", loading: true })
                      }
                    >
                      Todos
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                      href="#"
                      onClick={() =>
                        this.setState({ step: "cuenta_corriente", loading: true })
                      }
                    >
                      Cuenta Corriente
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                      className="text-primary cursor-pointer fs-6 btn disabled border-0 m-0"
                      href="#"
                      onClick={() => this.setState({ step: 'estado_pagos' })}
                    >
                      Estado pagos
                    </a>
                  </li>
                  <li style={{ marginRight: "15px" }}>
                    <a
                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                        href="#"
                        onClick={() => this.setState({ step: 'placas_repeticion' })}
                    >
                      Placas de Repetición
                    </a>
                  </li>
                </ul>
                <ul
                  className="d-flex flex-row justify-content-end p-0"
                  style={{ listStyleType: "none" }}
                >
                  <li style={{ marginLeft: "15px" }}>
                    <a
                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                        href="#"
                        onClick={() => this.setState({ filter: 'all' })}
                    >
                      Todos
                    </a>
                  </li>
                  <li style={{ marginLeft: "15px" }}>
                    <a
                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                        href="#"
                        onClick={() => this.setState({ filter: 'critical_payments' })}
                    >
                      Deuda critica
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6 d-flex flex-row justify-content-start px-5">
                <p className="text-primary fw-bold m-0 text-start">Doctor</p>
              </div>
              <div className="col-1 d-flex flex-row justify-content-start">
                <p className="text-primary fw-bold m-0 text-start">Deuda Critica</p>
              </div>
              <div className="col-1 d-flex flex-row justify-content-start">
                <p className="text-primary fw-bold m-0 text-start">Total Pagos Vigentes</p>
              </div>
              <div className="col-1 d-flex flex-row justify-content-start">
                <p className="text-primary fw-bold m-0 text-start">
                  Total Pagos Vencidos
                </p>
              </div>
              <div className="col-1 d-flex flex-row justify-content-start">
                <p className="text-primary fw-bold m-0 text-start">
                  Total a Pagar
                </p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Detalle
                </p>
              </div>
            </div>
            {this.state.filter === 'all' && this.state.estado_pagos_data.map((item, i) => {
              let payments = item[1].payments;
              console.log(item[1].current_payments)
              console.log(item[1].overdue_payments)

                return <div className="row grow_hover w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
                  <PaymentStatusCard
                    doctor={item[0]}
                    critical_payment={item[1].critical_payment}
                    current_payments={item[1].current_payments}
                    overdue_payments={item[1].overdue_payments}
                    handleCard={this.handleCard}
                    i={i}
                    card_opened={this.state.card_opened}
                  />
                  {this.state.card_opened === i && (
                    <div
                      className="container bg-light pt-3"
                      style={{
                        width: "100%",
                        height: "100%",
                        marginTop: '20px',
                        marginBottom: '-16px',
                        paddingBottom: '10px'
                      }}
                    >
                      <div className="row px-4">
                        <div className="col-2 text-center"><p className="fw-bold text-primary">Paciente</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary"></p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary"></p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary"></p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary"></p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary"></p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Total</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Total</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Total</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Reclamar</p></div>
                      </div>
                      <hr className="m-0" />
                      {this.state.estado_pagos_data[i].map((item, i) => {
                        if(item.payments !== undefined){
                          return item.payments.map(item => <PaymentStatusSubcard patient={item.treatment.patient.full_name} current_payments={item.current_payments} overdue_payments={item.overdue_payments} item={item}  />)
                        }
                      })}
                    </div>
                  )}
                </div>
            })}
            {this.state.filter === 'critical_payments' && this.state.critical_payments.map((item, i) => {
              let payments = item[1].payments;
              console.log(item[1].current_payments)
              console.log(item[1].overdue_payments)

                return <div className="row grow_hover w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
                  <PaymentStatusCard
                    doctor={item[0]}
                    critical_payment={item[1].critical_payment}
                    current_payments={item[1].current_payments}
                    overdue_payments={item[1].overdue_payments}
                    handleCard={this.handleCard}
                    i={i}
                  />
                  {this.state.card_opened === i && (
                    <div
                      className="container bg-light pt-3"
                      style={{
                        width: "100%",
                        height: "100%",
                        marginTop: '20px',
                        marginBottom: '-16px',
                        paddingBottom: '10px'
                      }}
                    >
                      <div className="row px-4">
                        <div className="col-2 text-center"><p className="fw-bold text-primary">Paciente</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">1er Pago</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">1er Pago</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">2do Pago</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">2do Pago</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">3er Pago</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">3er Pago</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Total</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Total</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Total</p></div>
                        <div className="col-1 text-center"><p className="fw-bold text-primary">Reclamar</p></div>
                      </div>
                      <hr className="m-0" />
                      {this.state.estado_pagos_data[i].map((item, i) => {
                        if(item.payments !== undefined){
                          return item.payments.map(item => <PaymentStatusSubcard patient={item.treatment.patient.full_name} current_payments={item.current_payments} overdue_payments={item.overdue_payments} item={item}  />)
                        }
                      })}
                    </div>
                  )}
                </div>
            })}
          </div>
      } else if (this.state.step === "placas_repeticion") {
          return <PlacasRepeticion handleStep={this.handleStep}/>
      }
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(index);
