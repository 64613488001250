import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Paginator from '../../components/Paginator'
import apiClient from '../../utils/backend'

import PatientCard from './components/patient_card'
const Pacientes = () => {
  const [state, setState] = useState({
    patients: [],
    loading: true,
    paginator: { current: null, next: null, previous: null },
    current_page: 1,
    cant_per_page: 15,
    step: -1,
    type: 'Todos',
  })
  const history = useHistory()

  /*  const principalDentist = props.treatments[0].dentists.find(
    (dentist) => dentist.id === props.principal_dentist
  ) */

  useEffect(() => {
    apiClient
      .get(
        `/api/patients/?page=${state.current_page}&cant_pages=15&include=treatments.dentists`
      )
      .then((res) => {
        let patient = res.data.table.body
        setState({
          ...state,
          patients: patient,
          loading: false,
          paginator: res.data.paginator,
        })
      })
      .catch((err) => console.log(err))
  }, [])

  const handleChangePaginator = async (p, cant) => {
    try {
      const res = await apiClient.get(
        `/api/patients/?page=${p}&cant_pages=15&include=treatments.dentists`
      )
      let patients = await res.data.table.body
      setState({
        ...state,
        patients: patients,
        paginator: res.data.paginator,
        loading: false,
        current_page: p,
        cant_per_page: cant,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const getAllPatients = async (type) => {
    try {
      const res = await apiClient.get(
        `/api/patients/?page=${state.current_page}&cant_pages=15&include=treatments.dentists`
      )
      let patients = await res.data.table.body
      setState({
        ...state,
        patients: patients,
        paginator: res.data.paginator,
        loading: false,
        type: type,
        step: -1,
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeCountry = async (id) => {
    try {
      const res = await apiClient.get(
        `/api/patients/?page=${state.current_page}&cant_pages=15&include=treatments.dentists&filter[country_id]=${id}`
      )
      let patients = await res.data.table.body
      setState({
        ...state,
        patients: patients,
        paginator: res.data.paginator,
        loading: false,
        step: id,
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      {state.loading ? null : (
        <div className="container mt-4">
          <div className="row mt-3 mb-3">
            <div className="col-8 d-flex flex-row justify-content-between px-4">
              <h1 style={{ fontWeight: 700 }} className="text-primary">
                Pacientes
              </h1>
            </div>
            <div className="col-4 mt-2 d-flex justify-content-end">
              <input
                type="text"
                className="search-input w-100 border-0 px-3 py-2 rounded-pill border border-1 mx-5"
                placeholder="Buscar..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex flex-row">
              <div className="col-6">
                <ul
                  className="d-flex flex-row justify-content-start align-items-center p-0 mb-0"
                  style={{ listStyleType: 'none' }}
                >
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step > -1
                          ? ''
                          : state.type === 'Todos' && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => getAllPatients('Todos')}
                    >
                      Todos
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step > -1
                          ? ''
                          : state.type === 'SAO' && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => getAllPatients('SAO')}
                    >
                      SAO
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <ul
                  className="d-flex flex-row justify-content-start align-items-center p-0 mb-0"
                  style={{ listStyleType: 'none' }}
                >
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step === 1 && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => handleChangeCountry(1)}
                    >
                      Argentina
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step === 5 && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => handleChangeCountry(5)}
                    >
                      Chile
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step === 2 && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => handleChangeCountry(2)}
                    >
                      Uruguay
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step === 3 && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => handleChangeCountry(3)}
                    >
                      Paraguay
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step === 6 && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => handleChangeCountry(6)}
                    >
                      Peru
                    </a>
                  </li>
                  <li className="ms-3">
                    <a
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                        state.step === 4 && 'text-muted'
                      }`}
                      href="#"
                      onClick={() => handleChangeCountry(4)}
                    >
                      Colombia
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr />
          <div className="row mt-3 ">
            <div className="col-3 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">Paciente</p>
            </div>
            <div className="col-3 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">Doctor</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">Perfil</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-primary fw-bold m-0 text-center">
                Tratamiento
              </p>
            </div>
          </div>
          <div className="row mt-3 px-4">
            <div className="col-12">
              {state.type === 'Todos' &&
                state.patients?.map((patient, i) => {
                  return (
                    <div>
                      <div
                        className={`grow_hover w-100 shadow border border-1 rounded container-fluid py-2 text-start mt-2 mb-2 position-relative`}
                      >
                        <button
                          className={`clinic-history-button type-1`}
                          onClick={() =>
                            history.push(
                              `/od/pacientes/${patient.id}/historiaclinica`
                            )
                          }
                        />
                        <PatientCard key={i} props={patient} />
                      </div>
                    </div>
                  )
                })}
              {state.type === 'SAO' &&
                state.patients
                  ?.filter((patient) =>
                    patient?.groups.find((group) => group.id === 1)
                  )
                  .map((patient, i) => {
                    return (
                      <div>
                        <div
                          className={`grow_hover w-100 shadow border border-1 rounded container-fluid py-2 text-start mt-2 mb-2 position-relative`}
                        >
                          <button
                            className={`clinic-history-button type-1`}
                            onClick={() =>
                              history.push(
                                `/od/pacientes/${patient.id}/historiaclinica`
                              )
                            }
                          />
                          <PatientCard key={i} props={patient} />
                        </div>
                      </div>
                    )
                  })}
            </div>
          </div>
          <div className="row px-4">
            <Paginator
              paginator={state.paginator}
              getPaginator={handleChangePaginator}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Pacientes
