import React, { useState, useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps, type_dispatch } from '../../propsStateConnect';
import { mantain_keep_not_apply_options,improve_cross_bite_options } from '../Utilities/OptionsRadios';
import { useErrorList } from '../Utilities/UseErrorsList';

function CrossBite(props){
    type_dispatch.step='InstructionsTratment'
    type_dispatch.field='CrossBite'
    const { colors, updateState, updateErrors }= props;
    const middle_state= props.formOnlineReducer[type_dispatch.step][type_dispatch.field];

    const crossBite=middle_state.crossBite
    const improveCrossBite=middle_state.improveCrossBite

    const othersCrossBiteRef = useRef(null)

    useEffect(()=>{
        if(improveCrossBite===5){
            othersCrossBiteRef.current.focus()
        }
    },[improveCrossBite])

    const errors_list=props.formOnlineReducer.ErrorsList
    const error1=errors_list.find(err=>err.order===1)
    useErrorList(errors_list, updateErrors, [crossBite])

    return (
        <div>
            <div className="w-100 fw-bolder pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                Mordida cruzada posterior
            </div>
            <div className="mt-3 ms-3">
                <div className="fw-bolder small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                    Mordida cruzada
                </div>
                {error1 && 
                    <div className="alert-danger alert-link p-2 mt-1">
                        {error1.message}
                    </div>
                }
                <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={mantain_keep_not_apply_options}
                setter={(value)=>updateState({key:'crossBite', value})}
                stateField={crossBite} name='crossBite' />
                {crossBite===2 &&
                <div>
                    <div className="ms-2 mt-3 fw-bolder small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                        ¿Cómo desea mejorar la mordida cruzada?
                    </div>
                    <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={improve_cross_bite_options}
                    setter={(value)=>updateState({key:'improveCrossBite', value})}
                    stateField={improveCrossBite} name='improveCrossBite' />
                    {improveCrossBite===5 &&
                    <div className="mt-3 ms-4">
                        <small>Otras:</small>
                        <textarea
                        disabled={!props.formOnlineReducer.Enabled}
                        onChange={(e)=>updateState({key:'improveCrossBiteOthers', value:e.target.value})} 
                        value={middle_state.improveCrossBiteOthers}
                        ref={othersCrossBiteRef} className="form-control mt-1" name="observacion" id="observacion" cols="30" rows="3"></textarea>
                    </div>
                    }
                </div>}
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(CrossBite)