import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps, type_dispatch } from '../../propsStateConnect';
import { lower_overbite_options, lower_overjet_options, mantain_keep_options, upper_overbite_options, upper_overjet_options } from '../Utilities/OptionsRadios';
import RadioList from '../Utilities/RadioList'

function OverjetOverBite(props){
    type_dispatch.step='InstructionsTratment'
    type_dispatch.field='OverjetOverbite'
    const { colors, updateState, updateErrors }= props;
    const middle_state= props.formOnlineReducer[type_dispatch.step][type_dispatch.field];
    const overjet = middle_state.overjet
    const upperOverjet = middle_state.overjetUp
    const lowerOverjet = middle_state.overjetDown

    const overbite = middle_state.overbite
    const upperOverbite = middle_state.overbiteUp
    const lowerOverbite = middle_state.overbiteDown

    const upperOverjetRef = useRef(null)
    const lowerOverjetRef = useRef(null)
    const upperOverbiteRef = useRef(null)
    const lowerOverbiteRef = useRef(null)

    useEffect(()=>{
        if(upperOverjet===5){
            upperOverjetRef.current.focus()
        }
    },[upperOverjet])

    useEffect(()=>{
        if(lowerOverjet===5){
            lowerOverjetRef.current.focus()
        }
    },[lowerOverjet])

    useEffect(()=>{
        if(lowerOverbite===5){
            lowerOverbiteRef.current.focus()
        }
    },[lowerOverbite])

    useEffect(()=>{
        if(upperOverbite===5){
            upperOverbiteRef.current.focus()
        }
    },[upperOverbite])

    const errors_list=props.formOnlineReducer.ErrorsList
    const error1=errors_list.find(err=>err.order===1)
    const error2=errors_list.find(err=>err.order===2)
    useEffect(()=>{
        if(overjet && errors_list.find(err=> err.order===1)){
            updateErrors(errors_list.filter(err=> err.order===2))
        }
    },[overjet, updateErrors, errors_list])
    useEffect(()=>{
        if(overbite && errors_list.find(err=> err.order===2)){
            updateErrors(errors_list.filter(err=> err.order===1))
        }
    },[overbite, updateErrors, errors_list])

    return (
        <div>
            <div className="w-100 fw-bold pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                Overjet
            </div>
            {error1 && 
            <div className="alert-danger alert-link p-2 mt-1">
                {error1.message}
            </div>
            }
            <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={mantain_keep_options} 
            setter={(value)=>updateState({key:'overjet', value})} 
            stateField={overjet} name='overjet' />
            {overjet===2 &&
            <div className='ms-2'>
                <div className="mt-3 ms-3 small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                    ¿Cómo desea mejorar el Overjet?
                </div>
                <div className="d-flex ms-3 mt-3">
                    <div className="col-6">
                        <div className="w-90 small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                            Overjet Aumentado
                        </div>
                        <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={upper_overjet_options}
                        setter={(value)=>updateState({key:'overjetUp', value})} 
                        stateField={upperOverjet} name='upperOverjet' />
                        {upperOverjet===5 &&
                        <div className="d-flex justify-content-center">
                            <div className="mt-3 w-60">
                                <small>Otras:</small>
                                <textarea
                                disabled={!props.formOnlineReducer.Enabled}
                                value={middle_state.overjetUpOthers}
                                onChange={(e)=>updateState({key:'overjetUpOthers', value: e.target.value})} 
                                ref={upperOverjetRef} className="form-control mt-1" name="observacion" id="observacion" cols="30" rows="3"></textarea>
                            </div>
                        </div>
                        }
                    </div>
                    <div className="col-6">
                        <div className="w-100 small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                            Overjet Disminuido
                        </div>
                        <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={lower_overjet_options}
                        setter={(value)=>updateState({key:'overjetDown', value})} 
                        stateField={lowerOverjet} name='lowerOverjet' />
                        {lowerOverjet===5 &&
                        <div className="d-flex justify-content-center">
                            <div className="mt-3 w-60">
                                <small>Otras:</small>
                                <textarea ref={lowerOverjetRef} 
                                disabled={!props.formOnlineReducer.Enabled}
                                value={middle_state.overjetDownOthers}
                                onChange={(e)=>updateState({key:'overjetDownOthers', value: e.target.value})} 
                                className="form-control mt-1" name="observacion" 
                                id="observacion" cols="30" rows="3"></textarea>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            }
            <div className="mt-5 w-100">
                <div className="w-100 fw-bold pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                    Overbite - Entrecruzamiento
                </div>
                {error2 && 
                    <div className="alert-danger alert-link p-2 mt-1">
                        {error2.message}
                    </div>
                }
                <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={mantain_keep_options}
                setter={(value)=>updateState({key:'overbite', value})} 
                stateField={overbite} name='overbite' />
            {overbite===2 &&
                <div className='ms-2'>
                    {props.formOnlineReducer.Enabled  &&
                    <div className="rounded mb-3 mt-2 text-light p-2 d-flex flex-column"  style={{backgroundColor:colors.color}}>
                        <small className="fw-bold text-center force-inline-text">RECUERDE QUE PARA MEJORAR OVERBITE SERÁN NECESARIOS ATTACHMENTS PARA MOVIMIENTOS VERTICALES</small>
                        <small className="mt-4 text-center">Compruebe que su respuesta coincida con lo indicado en la sección de acuerdo al uso de attachments en su tratamiento. Cualquier duda sobre el uso de attachments puede comunicarse con nuestro equipo de ortodoncia antes de completar esta sección.</small>
                    </div>
                    }
                    <div className="mt-3 ms-3 fw-bolder small" style={{borderBottom:`1px solid ${colors.color}` }}>
                        ¿Cómo desea mejorar el Overbite?
                    </div>
                    <div className="d-flex ms-3 mt-3">
                        <div className="col-6">
                            <div className="w-90 fw-bolder small" style={{borderBottom:`1px solid ${colors.color}` }}>
                                Overbite Aumentado
                            </div>
                            <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={upper_overbite_options}
                            setter={(value)=>updateState({key:'overbiteUp', value})} 
                            stateField={upperOverbite} name='upperOverbite' />
                            {upperOverbite===5 &&
                            <div className="d-flex justify-content-center">
                                <div className="mt-3 w-60">
                                    <small>Otras:</small>
                                    <textarea
                                    disabled={!props.formOnlineReducer.Enabled}
                                    onChange={(e)=>updateState({key:'overbiteUpOthers', value:e.target.value})} 
                                    value={middle_state.overbiteUpOthers}
                                    ref={upperOverbiteRef} className="form-control mt-1" name="observacion" id="observacion" cols="30" rows="3"></textarea>
                                </div>
                            </div>
                            }
                        </div>
                        <div className="col-6">
                            <div className="w-100 fw-bolder small" style={{borderBottom:`1px solid ${colors.color}` }}>
                                Overbite Disminuido
                            </div>
                            <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={lower_overbite_options}
                            setter={(value)=>updateState({key:'overbiteDown', value})} 
                            stateField={lowerOverbite} name='lowerOverbite' />
                            {lowerOverbite===5 &&
                            <div className="d-flex justify-content-center">
                                <div className="mt-3 w-60">
                                    <small>Otras:</small>
                                    <textarea
                                    disabled={!props.formOnlineReducer.Enabled}
                                    onChange={(e)=>updateState({key:'overbiteDownOthers', value:e.target.value})} 
                                    value={middle_state.overbiteDownOthers}
                                    ref={lowerOverbiteRef} className="form-control mt-1" name="observacion" id="observacion" cols="30" rows="3"></textarea>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            }
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(OverjetOverBite)