import React, { useState, useEffect } from 'react'
import apiClient from '../../../../utils/backend'

export const TreatmentAdminStepFive = ({ state, setState }) => {
  const [offices, setOffices] = useState()

  useEffect(() => {
    apiClient
      .get(`api/dentists/${state.dentist_id}/offices`)
      .then((res) => setOffices(res.data.table))
      .catch((err) => console.log(err))
  }, [])

  return (
    <div style={{ height: '100%', overflowX: 'hidden' }}>
      <div className="container mt-2" style={{ minHeight: '300px' }}>
        <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
          <div className="col-6 d-flex justify-content-center">
            <div className="row">
              <div className="col-12 d-flex flex-row justify-content-center">
                <button
                  onClick={() =>
                    setState({
                      ...state,
                      intraoral: 1,
                      intraoral_file: false,
                      sendImpressions: {},
                    })
                  }
                  className={`btn btn-outline-primary border-2 p-4 ${
                    state.intraoral === 1 && 'disabled bg-primary text-white'
                  }`}
                  style={{ maxHeight: '65px', borderRadius: '15px' }}
                >
                  Escaneo intraoral
                </button>
              </div>
              <div className="col-12 d-flex flex-row justify-content-center">
                {state.intraoral === 1 && (
                  <div
                    className="image-upload-container d-flex flex-column justify-content-center mt-4  my-3"
                    style={{
                      position: 'relative',
                    }}
                  >
                    <div>
                      <input
                        type="radio"
                        onClick={() =>
                          setState({
                            ...state,
                            intraoral_file: false,
                            newIntraoralScanDate: null,
                          })
                        }
                        checked={!state.intraoral_file}
                        name="not_scan"
                        id="not_scan"
                      />
                      <label
                        style={{ marginLeft: '5px', fontSize: '1rem' }}
                        htmlFor="not_scan"
                      >
                        El paciente todavía no se escaneo
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        onClick={() =>
                          setState({ ...state, intraoral_file: true })
                        }
                        checked={state.intraoral_file}
                        name="stl_file"
                        id="stl_file"
                      />
                      <label
                        style={{ marginLeft: '5px', fontSize: '1rem' }}
                        htmlFor="stl_file"
                      >
                        Asignar fecha de escaneo
                      </label>
                    </div>
                    {state.intraoral_file && (
                      <input
                        value={state?.newIntraoralScanDate}
                        type="date"
                        className="form-control mt-2"
                        onChange={(e) =>
                          setState({
                            ...state,
                            newIntraoralScanDate: e.target.value,
                          })
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-6 d-flex flex-colum justify-content-center">
            <div className="row">
              <div className="col-12 d-flex flex-row justify-content-center">
                <button
                  onClick={() =>
                    setState({
                      ...state,
                      intraoral: 0,
                      intraoral_file: false,
                      newIntraoralScanDate: null,
                    })
                  }
                  className={`btn btn-outline-primary border-2 p-4 ${
                    state.intraoral === 0 && 'disabled bg-primary text-white'
                  }`}
                  style={{ maxHeight: '65px', borderRadius: '15px' }}
                >
                  Envio de impresiones
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex flex-row justify-content-center">
            {state.intraoral === 0 && (
              <div
                className="d-flex flex-column mt-4  my-3"
                style={{
                  position: 'relative',
                }}
              >
                <form className="row">
                  <div className="col-12">
                    <label htmlFor="takeover_dir">Dirección de retiro</label>
                    <select
                      value={state.sendImpressions?.withdrawalAddress}
                      placeholder="Seleccionar direccion..."
                      className="form-control"
                      type="text"
                      name="takeover_dir"
                      id="takeover_dir"
                      onChange={(e) =>
                        setState({
                          ...state,
                          sendImpressions: {
                            ...state.sendImpressions,
                            withdrawalAddress: e.target.value,
                          },
                        })
                      }
                    >
                      <option selected value="">
                        Seleccionar direccion...
                      </option>
                      {offices?.map((office) => (
                        <option value={office.id}>{office.full_address}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-12 d-flex">
                    <div className="col-6">
                      <label htmlFor="takeover_date">
                        Asignar fecha de retiro
                      </label>
                      <input
                        onChange={(e) =>
                          setState({
                            ...state,
                            sendImpressions: {
                              ...state.sendImpressions,
                              takeover_date: e.target.value,
                            },
                          })
                        }
                        value={state.sendImpressions?.takeover_date}
                        className="form-control"
                        type="date"
                        name="takeover_date"
                        id="registration_datetime"
                      />
                    </div>
                    <div className="col-6  ms-2">
                      <label htmlFor="dateToGet">Horario de retiro</label>
                      <select
                        value={state.sendImpressions?.dateToGet}
                        onChange={(e) =>
                          setState({
                            ...state,
                            sendImpressions: {
                              ...state.sendImpressions,
                              dateToGet: e.target.value,
                            },
                          })
                        }
                        className="form-control"
                        name="dateToGet"
                        id="dateToGet"
                      >
                        <option selected value="">
                          Seleccionar horario...
                        </option>
                        <option value={'8:00-12:00'}>8:00-12:00</option>
                        <option value={'13:00-16:00'}>13:00-16:00</option>
                        <option value={'16:00-18:00'}>16:00-18:00</option>
                      </select>
                    </div>
                  </div>
                </form>
                <p className="mx-auto">
                  * La fecha y horario será confirmada por el equipo de
                  KeepSmiling
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
