import React, { useState, useEffect } from "react";
import { Bell } from "react-feather";
import { Link } from "react-router-dom";
import NotificationItem from "./notification_item";

import smile from "../assets/images/ks-smile-blue.svg";
import apiClient from "../utils/backend";

export default function Notifications() {
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [notifications, setNotifications] = useState([]);

  // Desabilitado hasta que exista la funcionalidad de notificaciones.
  // useEffect(() => {
  //   apiClient.get('/api/helper/getNotifications')
  //   .then(res => {setNotifications(res.data); setLoading(false)})
  //   .catch(err => console.log(err))
  // }, [])

  if (loading) {
    return <div></div>;
  } else {
    return (
      <div style={{ position: "relative" }}>
        <button className="btn bg-none" onClick={() => setOpened(!opened)}>
          <Bell size={20} color="#fff" />
        </button>

        <div
          className={
            opened ? "notifications-container shadow container-fluid" : "d-none"
          }
        >
          <div className="row">
            <div className="col-12 border-bottom border-1 p-2 px-4">
              <h4 className="text-primary fw-bold">Notificaciones</h4>
            </div>
          </div>
          <div className="row h-75 overflow-auto">
            {notifications.length > 0 &&
              notifications.map((notification) => (
                <NotificationItem data={notification} />
              ))}
            {notifications.length <= 0 && (
              <div className="d-flex flex-column w-100 mt-5">
                <h5 className="text-primary text-center fw-bold mt-5">
                  Aún no tienes ninguna notificación...
                </h5>
                <img src={smile} className="w-25 mx-auto mt-3" alt="ks-smile" />
              </div>
            )}
          </div>
          <div
            className="row border-top rounded-bottom"
            style={{ backgroundColor: "#D7E4EB" }}
          >
            <div className="col-12">
              <Link
                to="/od/notificaciones"
                onClick={() => setOpened(false)}
                className="btn bg-none w-100 h-100 p-3 text-center text-primary w-100 m-0"
              >
                Ver todas
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
