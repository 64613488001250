import React, { useEffect, useRef, useState } from "react";
import { Upload, Edit2, ChevronLeft } from "react-feather";
import apiClient from "../../utils/backend";
import * as cropro from "cropro";
import { Document, Page, pdfjs } from "react-pdf";
import { toast } from "react-toastify";

import {
  ImageUploadContainer,
  ImageUploadLabel,
  ImageUploadReference,
} from "./components/styled";

import "react-toastify/dist/ReactToastify.css";

// COMPONENTS

// IMAGES STEP 2
import trescuartos_perfil from "../../assets/images/34deperfilsonriendo.png";
import anterioroclusion from "../../assets/images/anteriorenoclusion.png";
import anteriorinoclusion from "../../assets/images/anterioreninoclusion.png";
import bucalderecho from "../../assets/images/bucalderecho.png";
import bucalizquierdo from "../../assets/images/bucalizquierdo.png";
import frontalreposo from "../../assets/images/frontalenreposo.png";
import frontalsonriendo from "../../assets/images/frontalsonriendo.png";
import oclusalmaxilarinf from "../../assets/images/oclusaldelmaxilarinferior.png";
import oclusalmaxilarsup from "../../assets/images/oclusaldelmaxilarsuperior.png";
import perfilizqreposo from "../../assets/images/perfilizquierdoenreposo.png";

// IMAGES STEP 3
import radioperfil from "../../assets/images/telerradiografiadeperfil.png";
import ricketts from "../../assets/images/cefalogramadericketts.png";
import rxpanoramica from "../../assets/images/rxpanoramica.png";

import plan from "../../assets/images/filename.svg";

import ImageInput from "./components/ImageInput";
import StepsBar from "./components/stepsBar";
import { UploadTreatment } from "./UploadTreatment";
import { UploadTreatmentStepSix } from "./UploadTreatmentStepSix";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const CargarTratamiento = ({ props }) => {
  const [state, setState] = useState({
    numPages: null,
    pageNumber: 1,
    treatment_id: null,
    plan_file: null,
    step: 1,

    // VISTA 1
    od_list: null,
    nombre: null,
    apellido: null,
    genero: "m",
    maxilar: 0,
    maxilar_string: "",
    external_id: null,
    odontologo: "",
    dentist_id: null,
    treatment_type: 1,
    intraoral: 1,
    etapa: 1,

    od_patients: [],
    selected_patient: null,
    selected_od: null,

    fotos: {},
    estudios: {},
    plan: {},

    registration_datetime: null,

    // STEP DATA
    flag_photos: 0,
    flag_studies: 0,
    flag_plan: 0,

    // ERROR HANDLER
    error: {
      error: false,
      message: "",
    },

    // CROPPER
    cropper: false,
    cropper_img: null,
    cropper_file: null,

    errors: [],
  });

  const cropperRef = React.createRef();

  const frontal_reposo = useRef();
  const frontal_sonriendo = useRef();
  const perfil_izq_reposo = useRef();
  const trescuartos_perfil = useRef();
  const oclusal_maxilar_sup = useRef();
  const oclusal_maxilar_inf = useRef();
  const anterior_oclusion = useRef();
  const bucal_derecho = useRef();
  const bucal_izquierdo = useRef();
  const anterior_inoclusion = useRef();
  const otro = useRef();
  const rxpanoramica = useRef();
  const telerradiografia = useRef();
  const ricketts = useRef();
  const plantratamiento = useRef();
  const otro2 = useRef();
  const plan_file = useRef();

  useEffect(() => {
    console.log(props);
    apiClient
      .get("/api/helper/getDentists")
      .then((res) => {
        if (res.status === 200) {
          let formatted = [];

          for (var i in res.data)
            formatted.push({ value: Number(i), label: res.data[i] });

          setState({ ...state, od_list: formatted });
          console.log(formatted);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState({
      ...state,
      numPages: numPages,
    });
  };

  const handleImageSelect = async (name, img, type) => {
    setState({
      ...state,
      [name]: img,
      cropper_img: img,
      [type]: {
        ...state[type],
        [name]: img,
      },
    });
  };

  const handleGetPatients = (id) => {
    apiClient
      .get(`api/helper/getPatientsByDentist/${id}`)
      .then((res) => {
        let patients = [];
        Object.entries(res.data).map(([key, value]) => {
          patients.push({ value: key, label: value });
        });
        setState({ ...state, od_patients: patients });
        console.log(state);
      })
      .catch((err) => console.log(err));
    console.log(state);
  };

  const handleImagePreview = (e) => {
    let file = e.target.files[0];
    let name = e.target.id + "_image";

    let url = URL.createObjectURL(file);
    setState({ ...state, [name]: url });
    console.log(url);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const getFiles = (ref) => {
    console.log(ref);
    console.log(state.frontal_reposo);
    if (this[ref].current !== null) {
      console.log(this[ref].current);
      return this[ref].current.src;
    }
  };

  const handleImageUpload = () => {
    console.log("sending all");

    let data = {
      name: state.nombre,
      surname: state.apellido,
      external_id: state.external_id,
      dentist_id: state.dentist_id,
      gender: state.genero,
      type_id: state.treatment_type,
      maxilla: state.maxilar,
      order: state.etapa,
      intraoral: state.intraoral,
      registration_date: state.registration_datetime,
      photos: state.fotos,
      studies: state.estudios,
      plan: state.plan,
    };

    data.new_patient = state.is_new_patient ? 1 : 0;
    state.is_new_patient === false
      ? (data.patient_id = Number(state.selected_patient.value))
      : console.log("");

    if (Object.keys(state.fotos).length > 0) {
      console.log("entra fotos");
      data.hasPhotos = 1;
    }

    if (Object.keys(state.estudios).length > 0) {
      console.log("entra fotos");
      data.hasStudies = 1;
    }

    if (Object.keys(state.plan).length > 0) {
      data.hasPlan = 1;
    }

    const headers = {
      withCredentials: true,
    };

    apiClient
      .post("/api/incomes", data, { headers: headers })
      .then((res) => {
        console.log(res.data);
        setState({
          ...state,
          treatment_id: res.data.data.id,
          flag_photos: res.data.data.photos,
          flag_studies: res.data.data.studies,
          flag_plan: res.data.data.plan,
          plan_file: res.data.data.plan_file,
        });
      })
      .catch((err) => console.log(err));
  };

  const handleFirstFormValidation = () => {
    apiClient
      .post("/api/incomes/validate-step", {
        name: state.nombre,
        surname: state.apellido,
        type_id: state.treatment_type,
        order: state.etapa,
        dentist_id: state.dentist_id,
        external_id: state.external_id,
        gender: state.genero,
        maxilla: state.maxilar,
      })
      .then((res) => setState({ ...state, step: 2 }))
      .catch((err) =>
        setState({
          ...state,
          errors: err.response.data.errors,
        })
      );
  };

  const saveObservation = () => {
    apiClient
      .put(`/api/incomes/${state.treatment_id}`, {
        observation: state.observation,
      })
      .then((res) =>
        toast.success("Observación guardada correctamente.", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        })
      )
      .catch((err) => console.log(err));
  };

  const handleFaltan = (tipo) => {
    apiClient
      .put(`/api/incomes/${state.treatment_id}`, {
        [tipo]: !state["flag_" + tipo],
      })
      .then((res) => {
        console.log(res);
        setState({
          ...state,
          ["flag_" + tipo]: !state["flag_" + tipo],
        });
      })
      .catch((err) => console.log(err));
  };

  const showCropArea = (ref, type) => {
    console.log("asdasd");
    if (this[ref].current !== null) {
      const cropArea = new cropro.CropArea(this[ref].current);
      cropArea.displayMode = "popup";
      cropArea.zoomToCropEnabled = false;
      cropArea.isGridVisible = false;

      cropArea.addRenderEventListener((dataUrl) => {
        setState({
          ...state,
          [type]: {
            ...state[type],
            [ref]: dataUrl,
          },
        });

        if (this[ref].current) {
          this[ref].current.src = dataUrl;
        }

        let documentation = Object.keys(state[type]);
        console.log(documentation);
        let next = documentation.findIndex((document) => document === ref) + 1;
        showCropArea(documentation[next], type);
      });
      console.log(state);
      cropArea.show();
    }
  };
  return (
    <div>
      <div className="container mt-4">
        <div className="row">
          <div className="col-12 mx-auto text-center">
            <h2 style={{ fontWeight: 700 }}>Nuevo Ingreso de Tratamiento</h2>
            <h5>Carga de datos</h5>
          </div>
          <StepsBar
            step={state.step}
            fotos={state.fotos}
            estudios={state.estudios}
            plan={state.plan}
          />
        </div>
      </div>
      {state.step === 1 && (
        <UploadTreatment
          state={state}
          setState={setState}
          handleGetPatients={handleGetPatients}
        />
      )}

      {state.step === 2 && (
        <div style={{ height: "100%", overflowX: "hidden" }}>
          <div className="container mt-2">
            <div className="row d-flex flex-row justify-content-center mx-auto px-5">
              <ImageInput
                name="Frontal en Reposo"
                src="frontal_reposo"
                type="fotos"
                image={state.frontal_reposo_image}
                ref={frontal_reposo}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Frontal Sonriendo"
                src="frontal_sonriendo"
                type="fotos"
                image={state.frontal_sonriendo_image}
                ref={frontal_sonriendo}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Perfil Izquierdo en Reposo"
                src="perfil_izq_reposo"
                type="fotos"
                image={state.perfil_izq_reposo_image}
                ref={perfil_izq_reposo}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="3/4 Perfil Sonriendo"
                src="trescuartos_perfil"
                type="fotos"
                image={state.trescuartos_perfil_image}
                ref={trescuartos_perfil}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Oclusal del Maxilar Superior"
                src="oclusal_maxilar_sup"
                type="fotos"
                image={state.oclusal_maxilar_sup_image}
                ref={oclusal_maxilar_sup}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Oclusal del Maxilar Inferior"
                src="oclusal_maxilar_inf"
                type="fotos"
                image={state.oclusal_maxilar_inf_image}
                ref={oclusal_maxilar_inf}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Anterior en Oclusion"
                src="anterior_oclusion"
                type="fotos"
                image={state.anterior_oclusion_image}
                ref={anterior_oclusion}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Bucal Derecho"
                src="bucal_derecho"
                type="fotos"
                image={state.bucal_derecho_image}
                ref={bucal_derecho}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Bucal Izquierdo"
                src="bucal_izquierdo"
                type="fotos"
                image={state.bucal_izquierdo_image}
                ref={bucal_izquierdo}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Anterior en Inoclusion"
                src="anterior_inoclusion"
                type="fotos"
                image={state.anterior_inoclusion_image}
                ref={anterior_inoclusion}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Otro"
                src="otro"
                type="fotos"
                image={state.otro_image}
                ref={otro}
                handleImageSelect={handleImageSelect}
              />
            </div>
          </div>
        </div>
      )}

      {state.step === 3 && (
        <div style={{ height: "100%", overflowX: "hidden" }}>
          <div className="container mt-2">
            <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
              <ImageInput
                name="RX Panorámica"
                src="rxpanoramica"
                type="estudios"
                image={state.rxpanoramica_image}
                ref={rxpanoramica}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Telerradiografia de Perfil"
                src="telerradiografia"
                type="estudios"
                image={state.telerradiografia_image}
                ref={telerradiografia}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Cefalograma de Ricketts"
                src="ricketts"
                type="estudios"
                image={state.ricketts_image}
                ref={ricketts}
                handleImageSelect={handleImageSelect}
              />

              <ImageInput
                name="Otro"
                src="otro"
                type="estudios"
                ref={otro2}
                handleImageSelect={handleImageSelect}
              />
            </div>
          </div>
        </div>
      )}

      {state.step === 4 && (
        <div style={{ height: "100%", overflowX: "hidden" }}>
          {console.log("STEP 4: ", Object.keys(state.fotos).length)}
          <div className="container mt-2">
            <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
              <div
                className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                style={{
                  maxHeight: "200px",
                  width: "200px",
                  position: "relative",
                }}
              >
                <div className="d-flex flex-row  m-0">
                  <ImageUploadContainer>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .pdf"
                      onChange={handleImageSelect}
                      id="plantratamiento"
                      className="plan"
                    />
                    <Upload />
                  </ImageUploadContainer>
                  {state.plantratamiento_image ? (
                    <div>
                      {state.plantratamiento_image.indexOf(
                        "application/pdf"
                      ) !== -1 ? (
                        <Document
                          file={state.plantratamiento_image}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page scale={0.15} pageNumber={state.pageNumber} />
                        </Document>
                      ) : (
                        <ImageUploadReference
                          src={state.plantratamiento_image}
                          alt="Imagen de"
                          ref={plantratamiento}
                        />
                      )}
                      {state.plantratamiento_image.indexOf(
                        "application/pdf"
                      ) === -1 && (
                        <button
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "#cecece",
                            borderRadius: "0!important",
                            border: "none",
                          }}
                          onClick={() =>
                            showCropArea("plantratamiento", "plan")
                          }
                        >
                          <Edit2 size="16" />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        background: "#112154",
                      }}
                    >
                      <ImageUploadReference
                        src={plan}
                        alt="Imagen de"
                        ref={plan_file}
                      />
                    </div>
                  )}
                </div>
                <ImageUploadLabel>Plan de tratamiento</ImageUploadLabel>
              </div>
            </div>
          </div>
        </div>
      )}

      {state.step === 5 && (
        <div style={{ height: "100%", overflowX: "hidden" }}>
          <div className="container mt-2">
            <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
              <div className="col-6 d-flex justify-content-center">
                <div className="row">
                  <div className="col-12 d-flex flex-row justify-content-center">
                    <button
                      onClick={() => setState({ ...state, intraoral: 1 })}
                      className={`btn btn-primary p-4 ${
                        state.intraoral && "disabled"
                      }`}
                      style={{ maxHeight: "65px" }}
                    >
                      Escaneo intraoral
                    </button>
                  </div>
                  <div className="col-12 d-flex flex-row justify-content-center">
                    {state.intraoral && (
                      <div
                        className="image-upload-container d-flex flex-column mt-4  my-3 mx-2"
                        style={{
                          maxHeight: "200px",
                          width: "200px",
                          position: "relative",
                        }}
                      >
                        <div className="d-flex flex-column mt-3">
                          <label htmlFor="scan_date">
                            Asignar fecha de escaneo
                          </label>
                          <input
                            type="date"
                            name="scan_date"
                            id="registration_datetime"
                            className="form-control"
                            onChange={(e) =>
                              setState({
                                ...state,
                                [e.target.id]: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-6 d-flex flex-colum justify-content-center">
                <div className="row">
                  <div className="col-12 d-flex flex-row justify-content-center">
                    <button
                      onClick={() => setState({ ...state, intraoral: 0 })}
                      className={`btn btn-primary p-4 ${
                        !state.intraoral && "disabled"
                      }`}
                      style={{ maxHeight: "65px" }}
                    >
                      Envio de impresiones
                    </button>
                  </div>

                  <div className="col-12 d-flex flex-row justify-content-center">
                    {!state.intraoral && (
                      <div
                        className="d-flex flex-column mt-4  my-3"
                        style={{
                          position: "relative",
                        }}
                      >
                        <form className="row">
                          <div className="col-12">
                            <label htmlFor="takeover_dir">
                              Dirección de retiro
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="takeover_dir"
                              id="takeover_dir"
                            />
                          </div>
                          <div className="col-12">
                            <label htmlFor="takeover_date">
                              Asignar fecha de retiro
                            </label>
                            <input
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  [e.target.id]: e.target.value,
                                })
                              }
                              className="form-control"
                              type="datetime-local"
                              name="takeover_date"
                              id="registration_datetime"
                            />
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {state.step === 6 && (
        <UploadTreatmentStepSix
          state={state}
          setState={setState}
          frontalreposo={frontalreposo}
          frontalsonriendo={frontalsonriendo}
          perfilizqreposo={perfilizqreposo}
          trescuartos_perfil={trescuartos_perfil}
          oclusalmaxilarsup={oclusalmaxilarsup}
          oclusalmaxilarinf={oclusalmaxilarinf}
          anterioroclusion={anterioroclusion}
          bucalderecho={bucalderecho}
          bucalizquierdo={bucalizquierdo}
          anteriorinoclusion={anteriorinoclusion}
          rxpanoramica={rxpanoramica}
          radioperfil={radioperfil}
          ricketts={ricketts}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          handleFaltan={handleFaltan}
          saveObservation={saveObservation}
        />
      )}

      {state.step !== 6 && (
        <div className="row mt-5 px-5 mx-5">
          <div className="col-12 f-flex text-end">
            {state.step > 1 && (
              <button
                className="btn btn-light text-primary rounded-pill px-4 mx-2"
                onClick={() =>
                  setState({
                    ...state,
                    step: state.step > 1 && state.step - 1,
                  })
                }
              >
                <ChevronLeft size="16" /> Volver
              </button>
            )}
            <button
              className="btn btn-primary rounded-pill px-4"
              onClick={() => setState({ ...state, step: state.step + 1 })}
            >
              Continuar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CargarTratamiento;
