import React, { useEffect, useState } from "react";
import {  toast } from "react-toastify";
import { Message } from "../../components/Message";
import logo from "../../assets/images/ks-smile.png";
import apiClient from "../../utils/backend";

export const NewPassword = (props) => {
  const { error } = props;

  const [recover_email, setRecover_email] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [password_match, setPassword_match] = useState(true);
  const [password_format_error, setPassword_format_error] = useState(false);
  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);

  useEffect(() => {
    const path = window.location.pathname.split("/");

    if (path.indexOf("passwordReset") !== -1 && path.length > 2) {
      let data = path[2];
      apiClient
        .get(`/api/helper/checkPasswordToken?token=${data}`)
        .then((res) => {
          if (res.status === 502) {
            window.location.href = "/login";
          } else {
            setLoading(false);
            setRecover_email(res.data);
            setToken(data);
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
          window.location.href = "/login";
        });
    } else {
      setLoading(false);
    }
  }, []);

  const handlePasswordValidation = () => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm;

    setButtonLoading(true);

    if (password1 !== password2) {
      setPassword_match(false);
      setButtonLoading(false);
    } else {
      setPassword_match(true);
    }

    if (password_match) {
      handleSetPassword();
    }
  };

  const handleSetPassword = () => {
    console.log(password1, recover_email, token);
    apiClient
      .post(
        `/api/changePassword?password=${password1}&email=${recover_email}&token=${token}`
      )
      .then((res) => {
        toast.success("Contraseña modificada con exito. Redirigiendo...", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 2000);
      })
      .catch((err) => {
        toast.error("Error al modificar la contraseña", {
          position: "top-right",
        });
        setButtonLoading(false);
      });
  };

  if (loading) {
    return null;
  } else {
    return (
      <>
        <main className="h-100">
          <div className="container-fluid h-100">
            <div className="row h-100 align-middle">
              <div
                className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
                style={{ minHeight: "100%", alignItems: "center" }}
              >
                <div className="px-lg-3" style={{ marginTop: "10em" }}>
                  <div className="w-100 mb-5">
                    <p className="h2 fw-bold text-primary mx-5">
                      Nueva contraseña
                    </p>
                  </div>
                  {error?.error && (
                    <div
                      className="alert alert-danger text-center"
                      role="alert"
                    >
                      {error?.message}
                    </div>
                  )}
                  <form action="">
                    <div className="mb-3 mx-5">
                      <label htmlFor="password1" className="form-label">
                        Contraseña
                      </label>
                      <input
                        type="password"
                        name="password1"
                        onChange={(e) => {
                          setPassword1(e.target.value);
                        }}
                        className="form-control"
                        id="password1"
                        placeholder="********"
                        value={password1}
                      />
                    </div>
                    <div className="mb-3 mx-5">
                      <label htmlFor="password2" className="form-label">
                        Repita la contraseña
                      </label>
                      <input
                        type="password"
                        name="password2"
                        onChange={(e) => {
                          setPassword2(e.target.value);
                        }}
                        className="form-control"
                        id="password2"
                        placeholder="********"
                        value={password2}
                      />
                      {!password_match && (
                        <Message
                          type="text-danger"
                          message="Las contraseñas no coinciden."
                        />
                      )}
                      {password_format_error && (
                        <Message
                          type="text-danger"
                          message="Error en el formato de la contraseña."
                        />
                      )}
                    </div>
                  </form>
                  {buttonLoading && (
                    <div className="w-100 d-flex justify-content-center mt-5">
                      <button
                        className="btn btn-primary rounded-pill px-5 w-50 disabled"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                      </button>
                    </div>
                  )}

                  {!buttonLoading && (
                    <div className="w-100 d-flex justify-content-center mt-5">
                      <button
                        className="btn btn-primary rounded-pill px-5 w-50"
                        onClick={() => handlePasswordValidation()}
                      >
                        Enviar
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4 bg-primary px-5">
                <div
                  className="w-75 d-flex flex-column"
                  style={{ marginTop: "15rem" }}
                >
                  <p className="h1 text-white fw-bold mb-3">
                    Somos creadores de Sonrisas
                  </p>
                  <img
                    src={logo}
                    alt="keepsmiling logo"
                    className="img img-fluid w-75"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
};
