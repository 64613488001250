import React, {Fragment, useState} from 'react'
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {ChevronDown, ChevronUp} from "react-feather";
import PaymentsForm from "./payments_form";

function BodyPlacasRepeticion({item}) {
    const [cardOpened, setCardOpened] = useState(null)

    return (
        <div
            className="row  w-100 d-flex flex-row bg-white shadow rounded m-0 justify-content-between py-3  my-2 align-items-middle tc-container ">
            <button className="clinic-history-button type-1"></button>
            <div
                className="col d-flex flex-row justify-content-center text-center">
                <p className="m-0 text-center justify-items-middle text-wrap" style={{fontSize: '.8rem'}}>{item.patient}</p>
            </div>
            <div
                className="col d-flex flex-row justify-content-center text-center">
                <p className="m-0 text-center justify-items-middle text-wrap" style={{fontSize: '.8rem'}}>{item.quantity}</p>
            </div>
            <div
                className="col d-flex flex-row justify-content-center text-center">
                <p className="m-0 text-center justify-items-middle text-wrap" style={{fontSize: '.8rem'}}>{item.carries_cost === 'not' ? '¡Bonificada!' : item.cost}</p>
            </div>
            <div
                className="col d-flex flex-row justify-content-center text-center">
                <p className="m-0 text-center justify-items-middle text-wrap" style={{fontSize: '.8rem'}}></p>
            </div>
        </div>
    )
}

export default BodyPlacasRepeticion