import React from 'react'

export default function UserCard(props) {
    return (
        <div className="p-2" style={{width: '20%'}}>
            <div className="shadow w-100 h-100 d-flex flex-column" style={{borderRadius: '20px', paddingTop: '2.5rem', paddingBottom: '2.5rem'}}>
                <div className="country"></div>
                <div className="d-flex flex-column user-info">
                    <img style={{maxWidth: '90px', borderRadius: '50%'}} className="mx-auto mb-3" src="https://randomuser.me/api/portraits/med/women/75.jpg" alt="" />
                    <p className="fs-5 fw-bold text-primary text-center w-100">{props.name} {props.surname}</p>
                    <p className="text-primary text-center w-100">{props.position}</p>
                    <p className="mt-3 text-primary text-center w-100">Sector: <span className="fw-bold">{props.sector}</span></p>
                </div>
            </div>
        </div>
    )
}
