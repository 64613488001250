import {React, Fragment} from 'react';
import ClinicHistoryModal from "../../../components/modal_hist_clinica";

const path = window.location.pathname.split("/")[2];

const Modals = (props) => {
    return (
        <Fragment>
            <ClinicHistoryModal patient={props.patient} doctor={props.doctor} id={props.id} registry={props.registro} opened={props.opened} className={`modal_hist_clinica-${props.id}`} handleModal={props.handleModal} />
        </Fragment>
    )
};

export default Modals;

