

export const filterPath = ( inputs ) => {
    let path = `/api/treatments/15?filter`;
    Object.filter = (mainObject, filterFunction)=>
    Object.keys(mainObject)
          .filter( (ObjectKey)=>filterFunction(mainObject[ObjectKey]))
          .reduce( (result, ObjectKey)=> ( result[ObjectKey] = mainObject[ObjectKey], result ), {} );
    let valuesFiltered = Object.filter(inputs, (input)=> input !== null );

    if( valuesFiltered?.created_at ){
        path += `[created_at]=${valuesFiltered.created_at}&`;
    }
    if( valuesFiltered?.finished_at ){
        path += `[finished_at]=${valuesFiltered.finished_at}&`;
    }
    if( valuesFiltered['dentists.id'] ){
        path += `[dentists.id]=${valuesFiltered['dentists.id']}&`;
    }
    if( valuesFiltered['patient.id'] ){
        path += `[patient.id]=${valuesFiltered['patient.id']}&`;
    }
    return path;
}
