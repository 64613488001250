import React, { useState, useEffect } from 'react'
import { ChevronLeft } from 'react-feather'
import { StepBar } from './components/StepBar'
import apiClient from '../../../utils/backend'
import loadingIcon from '../../../assets/images/loading.svg'
import { type_dispatch } from '../../FormularioOnline/propsStateConnect'
import { TreatmentStepOne } from './Stages/TreatmentStepOne'
import { TreatmentStepTwo } from './Stages/TreatmentStepTwo'
import { TreatmentStepThree } from './Stages/TreatmentStepThree'
import { TreatmentStepFour } from './Stages/TreatmentStepFour'
import { TreatmentStepFive } from './Stages/TreatmentStepFive'
import { TreatmentStepSix } from './Stages/TreatmentStepSix'
import frontalsonriendo from '../../../assets/images/frontalsonriendo.png'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'

const NewTreatmentSolicitud = () => {
  const userId = useSelector((state) => state.userReducer.dentistId)
  const [stageOneFinish, setStageOneFinish] = useState(false)
  const [stageTwoFinish, setStageTwoFinish] = useState(false)
  const [stageThreeFinish, setStageThreeFinish] = useState(false)
  const [stageFourFinish, setStageFourFinish] = useState(false)
  const [stageFiveFinish, setStageFiveFinish] = useState(false)
  const [state, setState] = useState({
    dentist_id: userId,
    numPages: null,
    pageNumber: 1,
    treatment_id: null,
    plantratamiento: null,
    plantratamiento_image: null,
    step: 1,
    loading: true,
    uploaded: false,

    gender_string: null,
    od_list: null,
    nombre: null,
    apellido: null,
    external_id: null,
    genero: null,
    gender_select: null,
    maxilla: null,
    maxilar: 0,
    maxilar_string: '',
    odontologo: '',
    treatment_type: 1,
    intraoral: true,
    etapa: 1,
    is_new_patient: true,
    od_patients: [],
    selected_patient: null,
    selected_od: null,
    intraoral_file: false,
    online_plan: false,
    image_plan: false,
    dentistOffices: null,
    newIntraoralScanDate: null,
    sendImpressions: {},

    fotos: {},
    fotos_files: [],
    estudios: {},
    estudios_files: [],
    plan: {},

    registration_datetime: null,
    scan_file: null,

    // STEP DATA
    flag_photos: 0,
    flag_studies: 0,
    flag_plan: 0,

    // ERROR HANDLER
    error: {
      error: false,
      message: '',
    },

    // CROPPER
    cropper: false,
    cropper_img: null,

    errors: [],
  })

  const stageTitleArray = [
    null,
    'Cargar datos',
    'Adjuntar fotos',
    'Adjuntar estudios',
    'Adjuntar Plan de Tratamiento',
    'Envío de Registro',
  ]

  const text = (
    <p className="text-center">
      Recuerde que puede cargar fotos y estudios en otro momento. Podra acceder
      a sus pacientes con acciones requeridas para adjuntar las fotos o estudios
      faltantes, <br />
      <b>
        KeepSmiling no dara comienzo al tratamiento hasta no recibir toda la
        documentacion.
      </b>
    </p>
  )

  const stageFooterArray = [
    null,
    <p className="text-center">
      Todas las solicitudes enviadas, seran revisadas por el equipo de
      KeepSmiling y usted recibira un seguimiento por parte <br /> del equipo.
      Una solicitud no es un paciente ingresado aun. <br />
      Para cualquier consulta, puede comunicarse por ... al XXXXXXXXXXXXXXXX
    </p>,
    text,
    text,
    text,
    text,
  ]

  type_dispatch.step = 'Header'
  type_dispatch.field = 'MainInformation'

  const toastProps = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  }

  const handleChangeStep = () => {
    if (!stageOneFinish)
      return toast.error('Todos los campos son requeridos', toastProps)

    if (state.step === 5 && !stageFiveFinish)
      return toast.error('Todos los campos son requeridos', toastProps)

    setState({ ...state, step: state.step + 1 })
  }

  const checkFrotalSonriendo = () => {
    if (state?.frontal_sonriendo) return state.frontal_sonriendo

    if (state?.selected_patient?.patientImage)
      return state?.selected_patient?.patientImage

    return frontalsonriendo
  }

  // set current dentist
  useEffect(() => {
    apiClient
      .get('/api/dentists/?cant_pages=all')
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          let odont = {}
          res.data.table.body.map((od) => {
            if (od.id === state.dentist_id)
              return (odont = {
                value: od.id,
                id: od.id,
                label: od.user.full_name,
                name: od.user.name,
                surname: od.user.surname,
              })
          })
          setState({
            ...state,
            odontologo: odont,
            loading: false,
          })
        }
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    // Validate Step 1
    if (
      state.nombre &&
      state.apellido &&
      state.genero &&
      state.maxilla &&
      state.odontologo
    ) {
      setStageOneFinish(true)
    } else setStageOneFinish(false)

    // Validate Step 2
    let i = 0
    const values = Object.values(state.fotos)
    values.forEach((value) => {
      if (value !== null) ++i
    })
    if (i > 9) setStageTwoFinish(true)
    else setStageTwoFinish(false)

    // Validate Step 3
    if (state.telerradiografia && state.rxpanoramica && state.ricketts) {
      setStageThreeFinish(true)
    } else setStageThreeFinish(false)

    //Validate Step 4
    if (state.plantratamiento) {
      setStageFourFinish(true)
    } else setStageFourFinish(false)

    //Validate Step 5
    if (state) {
      if (state.newIntraoralScanDate) return setStageFiveFinish(true)
      if (
        state.sendImpressions.takeover_date &&
        state.sendImpressions.dateToGet &&
        state.sendImpressions.withdrawalAddress
      )
        return setStageFiveFinish(true)
      setStageFiveFinish(false)
    }
  }, [state])

  return (
    <>
      {state.loading ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <img
            src={loadingIcon}
            style={{ width: '200px', height: 'auto' }}
            alt="Loading..."
          />
        </div>
      ) : (
        <div>
          <div className="col-12 mx-auto text-center mt-4">
            <h2 style={{ fontWeight: 700, cursor: 'pointer' }}>
              Nueva Solicitud de Ingreso de Tratamiento
            </h2>
            <h5>{stageTitleArray[state.step]}</h5>
          </div>

          <StepBar
            state={state}
            stageTwoFinish={stageTwoFinish}
            stageThreeFinish={stageThreeFinish}
            stageFourFinish={stageFourFinish}
          />

          {state.step === 1 && (
            <TreatmentStepOne state={state} setState={setState} />
          )}

          {state.step === 2 && (
            <TreatmentStepTwo state={state} setState={setState} />
          )}

          {state.step === 3 && (
            <TreatmentStepThree state={state} setState={setState} />
          )}

          {state.step === 4 && (
            <TreatmentStepFour
              state={state}
              setState={setState}
              checkFrotalSonriendo={checkFrotalSonriendo}
            />
          )}

          {state.step === 5 && (
            <TreatmentStepFive state={state} setState={setState} />
          )}

          {state.step === 6 && (
            <TreatmentStepSix
              state={state}
              setState={setState}
              stageTwoFinish={stageTwoFinish}
              stageThreeFinish={stageThreeFinish}
              stageFourFinish={stageFourFinish}
              checkFrotalSonriendo={checkFrotalSonriendo}
            />
          )}

          {state.step !== 6 && (
            <div className="row mt-5 px-5 mx-5">
              <div className="col-12 f-flex text-end">
                {state.step > 1 && (
                  <button
                    className="btn btn-light text-primary rounded-pill px-4 mx-2"
                    onClick={() => {
                      setState({
                        ...state,
                        step: state.step > 1 && state.step - 1,
                      })
                    }}
                  >
                    <ChevronLeft size="16" /> Volver
                  </button>
                )}
                <button
                  className="btn btn-primary rounded-pill px-4"
                  onClick={() => handleChangeStep()}
                >
                  Continuar
                </button>
              </div>
            </div>
          )}
          <div className="col-9 mx-auto mt-5">
            {stageFooterArray[state.step]}
          </div>
        </div>
      )}
    </>
  )
}
export default NewTreatmentSolicitud
