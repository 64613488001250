import React, {Component} from "react";
import apiClient from "../../../utils/backend";
import moment from 'moment'
import Select from 'react-select'

export default class NextDetail extends Component {

    constructor(props) {
        super(props)

        this.state = {
            id: null,
            payment_date: null,
            payment_amount: null,
            bill: null,
            bill_date: null,
            bill_amount: null,
            payment_method: null,
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-4 px-3 mb-5">
                    <div className="d-flex flex-column justify-content-between">
                        <div
                            className={`rounded-pill border-primary px-4 py-2 w-50 ${this.props.next_detail.status}`}
                            style={{opacity: 0.5}}
                        >
                            <span className="text-white">{this.props.next_detail.order}° Pago</span>
                        </div>

                        <small className="mt-2" style={{fontSize: ".7rem", marginLeft: "5px"}}>
                            Se espera el {this.props.next_detail.expected}% del costo final.
                        </small>

                        <div className="d-flex flex-row mt-3">
                            <div className="col-6">
                                <small className="text-primary fs-6">Monto</small>
                                <p className="fw-bold text-primary mt-2 fs-5">${this.props.next_detail.payment_amount}</p>
                            </div>
                            <div className="col-6">
                                <small className="text-primary fs-6">Fecha vencimiento</small>
                                <p className="text-primary mt-2 fs-5">{moment(this.props.next_detail.expired_date).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
