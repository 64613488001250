
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import frontalenreposo from '../../../assets/images/frontalenreposo.png'
import { type_dispatch, mapStateToProps, mapDispatchToProps } from '../propsStateConnect'

function Header(props)
{
    const { colors, updateState }=props
    type_dispatch.step='Header' 
    type_dispatch.field='MainInformation'
    //{Header:{MainInformation:{}},Enabled:true}
    const state_form=props.formOnlineReducer
    console.log('Render Header!', state_form)
    // useEffect(()=>{
    //     setPacient(pacient_data)
    // },[pacient_data])

    const getDescriptionType=(type)=>{
        switch(type){
            default:
                return 'Full';
            case 2:
                return 'Kids';
            case 3:
                return 'Fast';
            case 4:
                return 'Kids Fast';
            case 5:
                return 'Medium';
        }
    }
    return (
        <div className='mb-3 form-online-header-border pb-3'>
            <label className='fs-4 p-2'>Formulario Online</label>
            <div className="d-flex">
                {/* <div className="col-1 d-flex justify-content-center">
                    <div className="border rounded-circle w-60 h-100" style={{backgroundImage:`url(${frontalenreposo})`, backgroundPosition:'center',backgroundSize: 'cover'}}>
                    </div>
                </div> */}
                <div className="col-11 d-flex align-items-center ms-2">
                <div className="col-3 d-flex flex-column">
                    <label>Paciente</label>
                    <span className="fw-bold fs-5">{`${state_form[type_dispatch.step][type_dispatch.field].firstName} ${state_form[type_dispatch.step][type_dispatch.field].lastName}`}</span>
                </div>
                <div className="col-3 d-flex flex-column">
                    <label>Tratamiento</label>
                    <div className="d-flex">
                        <span className={`fs-5`} style={{color:colors.color}}>KeepSmiling</span>
                        <span className={`fw-bold fs-5 ms-1`} style={{color:colors.color}}>{getDescriptionType(state_form[type_dispatch.step][type_dispatch.field].type)}</span>
                    </div>
                </div>
                <div className="col-3 d-flex flex-column">
                    <label>Maxilar</label>
                    <span className="fw-bold fs-5">{state_form[type_dispatch.step][type_dispatch.field].maxilar}</span>
                </div>
                <div className="col-3 d-flex flex-column">
                    <label>Edad</label>
                    <input type="number" 
                    disabled={!state_form.Enabled}
                    value={state_form[type_dispatch.step][type_dispatch.field].age}
                    onChange={(e)=> updateState({key:'age', value:e.target.value})}
                    style={{border: `1px solid ${colors.color}`}}
                    className="w-50 p-1 rounded" />
                </div>
            </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
//export default Header