import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { startLogin } from "../../redux/actions/auth";
import { ChangePassword } from "./ChangePassword";
import { ResetPassword } from "./ResetPassword";
import logo from "../../assets/images/ks-smile.png";

export const Login = (props) => {
  const {
    dispatch,
    email,
    password,
    error,
    setError,
    setView,
    handleInputChange,
    forgotEmail,
    handleRecoverPassword,
    view,
  } = props;

  return (
    <main
      className="h-100"
      onKeyPress={(e) => {
        e.key === "Enter" && dispatch(startLogin(email, password));
      }}
    >
      <div className="container-fluid h-100">
        <div className="row h-100 align-middle">
          <div
            className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
            style={{ minHeight: "100%", alignItems: "center" }}
          >
            {view === "login" && (
              <div className="px-lg-3" style={{ marginTop: "10em" }}>
                <div className="w-100 mb-5">
                  <p className="h1 fw-bold text-primary mx-5">KeepSmiling</p>
                </div>
                {error.error ? (
                  <div className="alert alert-danger text-center" role="alert">
                    {error.message}
                  </div>
                ) : null}

                <Formik
                  initialValues={{ email: "", password: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = "Campo requerido";
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.email
                      )
                    ) {
                      errors.email = "Email inválido";
                    }

                    if (!values.password) {
                      errors.password = "Campo requerido";
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    localStorage.removeItem("lgt");
                    dispatch(startLogin(values.email, values.password));
                    setTimeout(() => {
                      const token = localStorage.getItem("lgt");
                      if (!token) {
                        setSubmitting(false);
                        return setError({
                          error: true,
                          message:
                            "Error en el login, por favor intente nuevamente.",
                        });
                      }
                      setError({ error: false, message: "" });
                    }, 2000);
                  }}
                >
                  {({ isSubmitting, errors }) => (
                    <Form className="mx-5">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        className={`${
                          errors.email
                            ? "form-control border border-1 border-danger rounded-3 mb-1"
                            : "form-control"
                        }`}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="email"
                        component="div"
                        value={email}
                      />
                      <label htmlFor="password" className="mt-2">
                        Contraseña
                      </label>
                      <Field
                        type="password"
                        name="password"
                        className={`${
                          errors.password
                            ? "form-control border border-1 border-danger rounded-3 mb-1"
                            : "form-control"
                        }`}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="password"
                        component="div"
                        value={password}
                      />
                      <div className="w-100 d-flex justify-content-center mt-3">
                        <a
                          className="btn text-muted"
                          onClick={() => setView("reset_password")}
                          href="#"
                        >
                          ¡Olvidé mi contraseña!
                        </a>
                      </div>
                      <div className="w-100 d-flex justify-content-center mt-5">
                        {!isSubmitting && (
                          <button
                            disabled={errors.email || errors.password}
                            type="submit"
                            className="btn btn-primary rounded-pill px-5 w-50"
                          >
                            Ingresar
                          </button>
                        )}
                        {isSubmitting && (
                          <button
                            className="btn btn-primary rounded-pill px-5 w-50"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="visually-hidden">Loading...</span>
                          </button>
                        )}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}

            {view === "reset_password" && (
              <div className="px-lg-3" style={{ marginTop: "10em" }}>
                <ResetPassword
                  setView={setView}
                  error={error}
                  handleInputChange={handleInputChange}
                  forgotEmail={forgotEmail}
                  handleRecoverPassword={handleRecoverPassword}
                />
              </div>
            )}

            {view === "change_password" && (
              <div className="px-lg-3" style={{ marginTop: "10em" }}>
                <ChangePassword
                  error={error}
                  setError={setError}
                  setView={setView}
                  dispatch={dispatch}
                />
              </div>
            )}
          </div>
          <div className="col-4 bg-primary px-5">
            <div
              className="w-75 d-flex flex-column"
              style={{ marginTop: "15rem" }}
            >
              <p className="h1 text-white fw-bold mb-3">
                Somos creadores de Sonrisas
              </p>
              <img
                src={logo}
                alt="keepsmiling logo"
                className="img img-fluid w-75"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
