import {React, Fragment} from 'react';
import ClinicHistoryModal from "../../../components/modal_hist_clinica";
import ApprobationModal from "./approbation_modal";
import RejectModal from "./reject_modal";
import RegistryModal from "./registry_modal";
import MovementsModal from "./movements_modal";
import VideoModal from "./video_modal";
import LaboratoryModal from './laboratory_modal';
import ButtonsModal from './revision_modal'

const Modals = (props) => {
    const path = window.location.pathname.split("/")[2];
    console.log({opened:props.opened, className:`modal_stripping-${props.id}`});
    return (
        <Fragment>
            {/* <ClinicHistoryModal patient={props.patient} doctor={props.doctor} id={props.id} registry={props.registro} opened={props.opened} className={`modal_hist_clinica-${props.id}`} handleModal={props.handleModal} /> */}
            {path === 'aprobacion' && <ApprobationModal patient={props.patient} doctor={props.doctor} id={props.id} registry={props.registro} opened={props.opened} className={`modal_approved-${props.id}`} handleModal={props.handleModal} />}
            {path === 'aprobacion' && <RejectModal patient={props.patient} doctor={props.doctor} id={props.id} registry={props.registro} opened={props.opened} className={`modal_rejected-${props.id}`} handleModal={props.handleModal} />}
            {path === 'registro' && <RegistryModal id={props.id} opened={props.opened} className={`modal_registro-${props.id}`} handleModal={props.handleModal} />}
            {path === 'movimiento' && <MovementsModal id={props.id} opened={props.opened} className={`modal_stripping-${props.id}`} handleModal={props.handleModal} />}
            {path === 'video' && <VideoModal id={props.id} opened={props.opened} className={`modal_video-${props.id}`} handleModal={props.handleModal} />}
            {path === 'revision' && <ButtonsModal id={props.id} opened={props.opened} className={`modal_botones-${props.id}`} handleModal={props.handleModal} />}
            {path === 'controlLaboratorio' && <LaboratoryModal id={props.id} opened={props.opened} className={`modal_control_laboratorio-${props.id}`} handleModal={props.handleModal} />}
        </Fragment>
    )
};

export default Modals;

