
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux';
import PretratmentDiagnosis from './PretreatmentDiagnosis'
import TratmentInstructions from './TratmentInstructions'
import { type_dispatch, mapStateToProps, mapDispatchToProps } from '../propsStateConnect'
import { useErrorList } from './Utilities/UseErrorsList';

function PacientData(props)
{
    type_dispatch.step='AestheticPriority' 
    type_dispatch.field='General'
    const state_form=props.formOnlineReducer
    const { colors, updateState, updateErrors, clearState, enable, nextParentStep }= props;
    const [stepMain, setStepMain] = useState(state_form.CurrentStep.Step.ParentStep)
    const [stepPretratment, setStepPretratment] = useState(state_form.CurrentStep.Step.ParentStep===2?state_form.CurrentStep.Step.ChildStep:1)
    const [stepInstructions, setStepInstructions] = useState(state_form.CurrentStep.Step.ParentStep===3?state_form.CurrentStep.Step.ChildStep:1)
    
    useEffect(()=>{
        enable(true)
    },[enable])

    const nextStepMain=()=>{
        if(!validateRequiredFields()) return;
        if(stepMain === 2 && stepPretratment<4){
            setStepPretratment(stepPretratment+1)
            saveStep(stepMain, stepPretratment+1)
        } else if(stepMain === 3 && stepInstructions<11){
            setStepInstructions(stepInstructions+1)
            saveStep(stepMain, stepInstructions+1)
        }else if(stepMain<3){
            setStepMain(stepMain+1)
            saveStep(stepMain+1, 1)
        }
    }
    const backStepMain=()=>{
        if(stepMain === 2 && stepPretratment>1){
            setStepPretratment(stepPretratment-1)
            saveStep(2, stepPretratment-1)
        } else if(stepMain === 3 && stepInstructions>1){
            setStepInstructions(stepInstructions-1)
            saveStep(3, stepInstructions-1)
        }else if(stepMain>1){
            setStepMain(stepMain-1)
            saveStep(stepMain-1,(stepMain-1)===2?4:1)
        }
    }
    const saveStep=(parent, child)=>{
        type_dispatch.step='CurrentStep' 
        type_dispatch.field='Step'
        updateState({key:"ParentStep", value: parent})
        updateState({key:"ChildStep", value: child})
        type_dispatch.step='AestheticPriority' 
        type_dispatch.field='General'
    }
    const finishFormTyping=()=>{
        if(!validateRequiredFields()) return;
        //clearState();
        //setTimeout(()=>{window.location.reload()}, 1000)
        
        // setTimeout(()=>{window.location.href=`/tratamiento-view`}, 1000)
        // alert("Formulario online almacenado exitósamente...")
        nextParentStep()
    }
    const validateRequiredFields=()=>{
        let success=true;
        let errorsList=[];
        switch(stepMain){
            default:
                break;
            case 1:
                if(!state_form.AestheticPriority.General.description){
                    errorsList=[...errorsList, {order:1, message: 'Capture éste campo'}]
                    success=false;
                }
                break;
            case 2:
                switch(stepPretratment){
                    default:
                        if(!state_form.PretratmentDiagnosisValues.Movements.movement){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 2:
                        if(!state_form.PretratmentDiagnosisValues.Extractions.extraction){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 3:
                        if(!state_form.PretratmentDiagnosisValues.VirtualTooths.virtualTooth){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 4:
                        if(!state_form.PretratmentDiagnosisValues.Attachments.attachment){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                }
                break;
            case 3:
                switch(stepInstructions){
                    default:
                        if(!state_form.InstructionsTratment.MiddleLine.middleLineHigh){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.MiddleLine.middleLineLow){
                            errorsList=[...errorsList, {order:2, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 2:
                        if(!state_form.InstructionsTratment.OverjetOverbite.overjet){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.OverjetOverbite.overbite){
                            errorsList=[...errorsList, {order:2, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 3:
                            if(!state_form.InstructionsTratment.CanineRelationship.right){
                                errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                                success=false;
                            }
                            if(!state_form.InstructionsTratment.CanineRelationship.left){
                                errorsList=[...errorsList, {order:2, message:'Seleccione una opción'}]
                                success=false;
                            }
                            break;
                    case 4:
                            if(!state_form.InstructionsTratment.MolarRelationship.right){
                                errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                                success=false;
                            }
                            if(!state_form.InstructionsTratment.MolarRelationship.left){
                                errorsList=[...errorsList, {order:2, message:'Seleccione una opción'}]
                                success=false;
                            }
                            break;
                    case 5:
                            if(!state_form.InstructionsTratment.CrossBite.crossBite){
                                errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                                success=false;
                            }
                            break;
                    case 6:
                        if(!state_form.InstructionsTratment.Stripping.stripping){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.Stripping.virtualRemodel){
                            errorsList=[...errorsList, {order:2, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.Stripping.withInclination){
                            errorsList=[...errorsList, {order:3, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.Stripping.withoutInclination){
                            errorsList=[...errorsList, {order:4, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.Stripping.withGain){
                            errorsList=[...errorsList, {order:5, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.Stripping.withoutGain){
                            errorsList=[...errorsList, {order:6, message:'Seleccione una opción'}]
                            success=false;
                        }
                        if(!state_form.InstructionsTratment.Stripping.othersGain){
                            errorsList=[...errorsList, {order:7, message:'Capture éste campo'}]
                            success=false;
                        }
                        break;
                    case 7:
                        if(!state_form.InstructionsTratment.Diastems.diastems){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 8:
                        if(!state_form.InstructionsTratment.Spaces.spaces){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 9:
                        if(!state_form.InstructionsTratment.SequentialDistalation.sequentialDistalation){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 10:
                        if(!state_form.InstructionsTratment.AuxiliarResources.auxiliarResources){
                            errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                            success=false;
                        }
                        break;
                    case 11:
                        // if(!state_form.InstructionsTratment.GeneralInstructions.instructions){
                        //     errorsList=[...errorsList, {order:1, message:'Seleccione una opción'}]
                        //     success=false;
                        // }
                        // if(!state_form.InstructionsTratment.GeneralInstructions.attachmentFile.name){
                        //     errorsList=[...errorsList, {order:2, message:'Seleccione un archivo'}]
                        //     success=false;
                        // }
                        break;
                    }
                break;
            }
        updateErrors(errorsList)
        return success;
    }
    const errors_list=props.formOnlineReducer.ErrorsList
    const error1=errors_list.find(err=>err.order===1)
    useErrorList(errors_list, updateErrors, stepMain===1?[props.formOnlineReducer.AestheticPriority.General.description]:null)
    return (
        <div>
            <div className="col-12">
                <nav>
                    <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist" style={{borderTop:'solid 3px #dbdbdb'}}>
                      <div className="d-flex justify-content-center col-4">
                        <a className={`nav-item2 nav-link2 col-10`} style={{borderBottom:'solid 5px '+(stepMain>=1?colors.color:'#dbdbdb'), color:colors.alternativeColor}} id="nav-home-tab" href={()=> false}>Prioridad estética del paciente</a>
                      </div>
                      <div className="d-flex justify-content-center col-4">
                        <a className={`nav-item2 nav-link2 col-10`} style={{borderBottom:'solid 5px '+(stepMain>=2?colors.color:'#dbdbdb'), color:colors.alternativeColor}} id="nav-profile-tab" href={()=> false}>Diagnóstico previo al tratamiento</a>
                      </div>
                      <div className="d-flex justify-content-center col-4">
                        <a className={`nav-item2 nav-link2 col-10`} style={{borderBottom:'solid 5px '+(stepMain>=3?colors.color:'#dbdbdb'), color:colors.alternativeColor}} id="nav-contact-tab" href={()=> false}>Indicaciones de tratamiento</a>
                      </div>
                    </div>
                  </nav>
            </div>
            <div className='pt-4'>
                <div style={{minHeight:'10rem'}}>
                {stepMain===1 &&
                <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column col-9">
                        {error1 && 
                            <div className="alert-danger alert-link p-2 mt-1">
                                {error1.message}
                            </div>
                        }
                        <label className="mt-3">Indique a continuación la prioridad estética del paciente:</label>                            
                        <textarea value={props.formOnlineReducer.AestheticPriority.General.description} onChange={(e)=> updateState({key:"description", value: e.target.value})} className="form-control" name="observacion" id="observacion" cols="30" rows="10"></textarea>
                    </div>
                </div>
                }
                {stepMain===2 &&
                    <PretratmentDiagnosis 
                        colors={colors} 
                        step={stepPretratment} />
                }
                {stepMain===3 &&
                    <TratmentInstructions colors={colors} step={stepInstructions} />
                }
                </div>
                <div className="d-flex justify-content-end mt-2 me-2">
                    {stepMain>1 &&
                        <button onClick={backStepMain} className="rounded-pill btn border btn-light text-dark">Anterior</button>
                    }
                    {stepMain===3 && stepInstructions===11 ?
                    <button onClick={finishFormTyping} className="rounded-pill btn btn-primary btn-lg">Finalizar</button>
                    :
                    <button onClick={nextStepMain} className="rounded-pill btn btn-primary btn-lg">Siguiente</button>
                    }
                </div>
            </div>
        </div>
    )
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PacientData)