import { toast } from "react-toastify";
import apiClient from "../../utils/backend";

export const startGetUserList = () => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get("/api/admins");
      const usersList = { usersList: res.data };
      dispatch(setUserList(usersList));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getProfilesPositions = () => {
  return async (dispatch) => {
    try {
      const res = await apiClient.get("/api/helper/getProfilesPositions");
      const profilePositions = {
        profiles: res.data[0],
        positions: res.data[1],
        countries: res.data[2],
      };
      dispatch(setProfilesData(profilePositions));
    } catch (error) {
      console.log(error);
    }
  };
};

export const startSaveUser = ({userData,avatar,hasAvatar,setHasAvatar}) => {
  return async (dispatch) => {
    try {

      const data = new FormData();
    data.append("avatar", avatar);
    data.append("_method", "PUT");
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      const res = await apiClient.put(`/api/admins/${userData.id}`, userData);
      if( hasAvatar ){
        const userAvatarResponse = await apiClient.post(
          `/api/admins/${userData.id}/avatar`,
          data,headers
        );
        setHasAvatar(false)
      }
      if(res.status === 200){
          toast.success("Usuario guardado correctamente 👍", {
            position: 'top-right',
            autoClose: 1500, 
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            limit:1
          });
      }
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      
      dispatch(startGetUserList());
    } catch (error) {
      toast.error("Error al guardar el usuario!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        containerId:"edit_user_toast",
      });
    }
  };
};
export const startSaveNewUser = (userData,image) => {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append("avatar", image);
      data.append("_method", "PUT");
        const headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await apiClient.post(`/api/admins/`, userData);
        if(res.status === 200){
          toast.success("Usuario guardado correctamente 👍", {
            position: "top-right",
            autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          containerId:"edit_user_toast",
        });
        const userAvatarResponse = await apiClient.post(
          `/api/admins/${res.data.id}/avatar`,
          data,headers
        );
      }
      dispatch(startGetUserList());
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (error) {
      toast.error("Error al guardar el usuario!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        containerId:"edit_user_toast",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };
};

const setProfilesData = (data) => ({ type: "SET_PROFILE_DATA", payload: data });

const setUserList = (users) => ({ type: "GET_USER_LIST", payload: users });
