import React, { Component } from 'react'
import apiClient from '../../utils/backend'
import { connect } from 'react-redux'
import { ArrowDown } from 'react-feather'

// COMPONENTS
import TreatmentCard from './components/treatments_card'
import Modal from '../../components/modal_hist_clinica'
import Pusher from 'pusher-js'

import Searchbar from '../../components/searchbar'
import { Link } from 'react-router-dom'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      yeso_rech: [],
      intra_rech: [],
      yeso_all: [],
      intra_all: [],
      type: 'todos',
      loading: true,
      filter_intraoral: false,
      filter_yeso: false,

      searchYeso: '',
      yesoDateOne: '',
      yesoDateTwo: '',

      searchIntra: '',
      intraDateOne: '',
      intraDateTwo: '',

      modal_id: null,
      modal_active: null,
      modal_show: false,
    }

    this.handleRetirementFilter = this.handleRetirementFilter.bind(this)
    this.handleModal = this.handleModal.bind(this)
    this.switchStage = this.switchStage.bind(this)
  }

  componentDidMount() {
    const pusher = new Pusher('5d69832dc84bf7a3b094', {
      cluster: 'us2',
      encrypted: true,
    })

    const channel = pusher.subscribe('incomes')

    channel.bind('new', (data) => {
      if (data.income.intraoral) {
        let joined = this.state.intra_all.concat(data.income)
        this.setState({ intra_all: joined })
        alert('nuevo tratamiento intraoral')
      } else {
        let joined = this.state.yeso_all.concat(data.income)
        this.setState({ yeso_all: joined })
        alert('nuevo tratamiento yeso')
      }
    })

    apiClient
      .get('/api/incomes?include=patient,dentists,status,type')
      .then((res) => {
        console.log(res)
        let yeso = res.data.table.body.filter(
          (item) => item.type_income === 'Retirement'
        )
        let intra = res.data.table.body.filter(
          (item) => item.type_income === 'Intraoral'
        )

        let intra_rech = intra.filter((item) => item.rejected === true)
        let yeso_rech = yeso.filter((item) => item.rejected === true)

        let intra_all = intra.filter((item) => item.rejected === false)
        let yeso_all = yeso.filter((item) => item.rejected === false)

        this.setState({
          yeso_rech,
          intra_rech,
          yeso_all,
          intra_all,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  handleRedirect = () => {
    this.props.history.push('/ingresos/nuevo')
  }

  handleModal(income) {
    this.setState({
      modal_show: !this.state.modal_show,
      modal_active: income,
    })
  }

  switchStage(stage) {
    this.setState({
      modal_active: stage,
    })
  }

  handleTreatmentColor(type) {
    const colors = {
      'KeepSmiling Full': 1,
      'KeepSmiling Fast': 2,
      'KeepSmiling Medium': 3,
      'KeepSmiling Kids': 4,
      'KeepSmiling Kids Fast': 5,
      'KeepSmiling Teens': 6,
    }
    return colors[type]
  }

  handleRetirementFilter(e, searchDentist, firstDate, secondDate) {
    e.preventDefault()
    apiClient
      .get(
        `/api/incomes?include=patient,dentists,status,type&filter[registration_datetime]=${firstDate},${secondDate}`
      )
      .then((res) => {
        let yeso = res.data.table.body.filter(
          (item) => item.type_income === 'Retirement'
        )

        let yesoNormalized = []
        yeso.map((item) => {
          if (
            item.dentists[0]?.user?.full_name
              .toLowerCase()
              .includes(searchDentist.toLowerCase())
          )
            return yesoNormalized.push(item)
          else return null
        })

        if (this.state.type === 'rechazados') {
          let yeso_rech = yesoNormalized.filter(
            (item) => item.rejected === true
          )
          this.setState({
            yeso_rech,
            loading: false,
          })
        } else {
          let yeso_all = yesoNormalized.filter(
            (item) => item.rejected === false
          )
          this.setState({
            yeso_all,
            loading: false,
          })
        }
      })
      .catch((err) => console.log(err))
  }

  handleIntraFilter(e, searchDentist, firstDate, secondDate) {
    e.preventDefault()
    apiClient
      .get(
        `/api/incomes?include=patient,dentists,status,type&filter[registration_datetime]=${firstDate},${secondDate}`
      )
      .then((res) => {
        let intra = res.data.table.body.filter(
          (item) => item.type_income === 'Intraoral'
        )

        let intraNormalized = []
        intra.map((item) => {
          if (
            item.dentists[0]?.user?.full_name
              .toLowerCase()
              .includes(searchDentist.toLowerCase())
          )
            return intraNormalized.push(item)
          else return null
        })

        if (this.state.type === 'rechazados') {
          let intra_rech = intraNormalized.filter(
            (item) => item.rejected === true
          )
          this.setState({
            intra_rech,
            loading: false,
          })
        } else {
          let intra_all = intraNormalized.filter(
            (item) => item.rejected === false
          )
          this.setState({
            intra_all,
            loading: false,
          })
        }
      })
      .catch((err) => console.log(err))
  }

  clearIntraFilter(e) {
    e.preventDefault()
    this.setState({ searchIntra: '' })
    this.setState({ intraDateOne: '' })
    this.setState({ intraDateTwo: '' })
    this.setState({ filter_intraoral: false })
    apiClient
      .get('/api/incomes?include=patient,dentists,status,type')
      .then((res) => {
        let intra = res.data.table.body.filter(
          (item) => item.type_income === 'Intraoral'
        )
        let intra_rech = intra.filter((item) => item.rejected === true)
        let intra_all = intra.filter((item) => item.rejected === false)

        this.setState({
          intra_rech,
          intra_all,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  clearYesoFilter(e) {
    e.preventDefault()
    this.setState({ searchYeso: '' })
    this.setState({ yesoDateOne: '' })
    this.setState({ yesoDateTwo: '' })
    this.setState({ filter_yeso: false })
    apiClient
      .get('/api/incomes?include=patient,dentists,status,type')
      .then((res) => {
        let yeso = res.data.table.body.filter(
          (item) => item.type_income === 'Retirement'
        )
        let yeso_rech = yeso.filter((item) => item.rejected === true)
        let yeso_all = yeso.filter((item) => item.rejected === false)
        this.setState({
          yeso_rech,
          yeso_all,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  clearAllFilters() {
    this.setState({ searchIntra: '' })
    this.setState({ intraDateOne: '' })
    this.setState({ intraDateTwo: '' })
    this.setState({ searchYeso: '' })
    this.setState({ yesoDateOne: '' })
    this.setState({ yesoDateTwo: '' })
    this.setState({ filter_intraoral: false })
    this.setState({ filter_yeso: false })
    apiClient
      .get('/api/incomes?include=patient,dentists,status,type')
      .then((res) => {
        let intra = res.data.table.body.filter(
          (item) => item.type_income === 'Intraoral'
        )

        let yeso = res.data.table.body.filter(
          (item) => item.type_income === 'Retirement'
        )

        let intra_rech = intra.filter((item) => item.rejected === true)
        let intra_all = intra.filter((item) => item.rejected === false)

        let yeso_rech = yeso.filter((item) => item.rejected === true)
        let yeso_all = yeso.filter((item) => item.rejected === false)

        this.setState({
          yeso_rech,
          yeso_all,
          intra_rech,
          intra_all,
          loading: false,
        })
      })
      .catch((err) => console.log(err))
  }

  render() {
    if (this.state.loading) {
      return null
    } else {
      return (
        <div className="container mt-2">
          <Modal
            id={this.state.modal_active}
            opened={this.state.modal_show}
            handleModal={this.handleModal}
            switchStage={this.switchStage}
          />
          <div className="row">
            <div className="col-12">
              <h1 style={{ fontWeight: 700 }} className="text-primary mt-3">
                Ingresos
              </h1>
            </div>
            <div className="col-9 mt-4 d-flex flex-row justify-content-between pt-3">
              <div className="d-flex flex-row">
                <div
                  className="row shadow py-2 mx-2 border border-1 rounded"
                  style={{
                    maxWidth: '230px',
                    maxHeight: '65px',
                    marginBottom: '11px',
                  }}
                >
                  <div className="col-4">
                    <h2 className="justify-content-center align-middle text-center m-0">
                      {this.state.yeso_all.length + this.state.intra_all.length}
                    </h2>
                  </div>
                  <div className="col-8">
                    <p className="justify-content-center align-middle m-0">
                      Solicitudes de ingreso pendientes
                    </p>
                  </div>
                </div>
                <div
                  className="row shadow py-2 mx-2 border border-1 rounded"
                  style={{
                    maxWidth: '230px',
                    maxHeight: '66px',
                    marginBottom: '10px',
                  }}
                >
                  <div className="col-4">
                    <h2 className="justify-content-center align-middle text-center m-0">
                      {this.state.yeso_rech.length +
                        this.state.intra_rech.length}
                    </h2>
                  </div>
                  <div className="col-8">
                    <p className="justify-content-center align-middle m-0">
                      Casos rechazados para coordinar
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-3 d-flex flex-column mt-4 px-4">
              <Link
                to="/ingresos/nuevo"
                className="btn btn-primary rounded-pill px-5 mb-2"
              >
                Nuevo ingreso
              </Link>
              <Searchbar />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              <ul
                className="d-flex flex-row p-0"
                style={{ listStyleType: 'none' }}
              >
                <li style={{ marginRight: '30px' }}>
                  <button
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.type === 'todos' && 'disabled'
                    }`}
                    onClick={() => {
                      this.clearAllFilters()
                      this.setState({ type: 'todos' })
                    }}
                  >
                    Todos
                  </button>
                </li>
                <li
                  className="d-flex flex-row"
                  style={{
                    marginRight: '30px',
                  }}
                >
                  <button
                    className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${
                      this.state.type === 'rechazados' && 'disabled'
                    }`}
                    onClick={() => {
                      this.clearAllFilters()
                      this.setState({ type: 'rechazados' })
                    }}
                  >
                    Rechazados
                  </button>
                  {this.state.yeso_rech.length + this.state.intra_rech.length >
                  0 ? (
                    <div
                      style={{
                        marginTop: '13px',
                        marginLeft: '-8px',
                        background: 'red',
                        width: '8px',
                        height: '8px',
                        borderRadius: '4px',
                      }}
                    ></div>
                  ) : null}
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6 d-flex flex-column ">
              <button
                className="btn d-flex flex-row justify-content-between text-primary w-100 p-2 bg-white rounded shadow border border-1"
                onClick={() =>
                  this.setState({ filter_yeso: !this.state.filter_yeso })
                }
              >
                <p></p>
                <p>Nuevo Retiro de Impresiones</p>
                <ArrowDown size={18} />
              </button>
              {this.state.filter_yeso && (
                <div className="incomes-filter-container mx-3 rounded-bottom bg-white">
                  <form
                    className="px-4 py-2"
                    onSubmit={(e) =>
                      this.handleRetirementFilter(
                        e,
                        this.state.searchYeso,
                        this.state.yesoDateOne,
                        this.state.yesoDateTwo
                      )
                    }
                  >
                    <div className="row">
                      <label>Fecha de solicitud</label>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_from"
                          id="date_from"
                          value={this.state.yesoDateOne}
                          onChange={(e) => {
                            this.setState({ yesoDateOne: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_to"
                          id="date_to"
                          value={this.state.yesoDateTwo}
                          onChange={(e) => {
                            this.setState({ yesoDateTwo: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="dentist">Doctor</label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          name="dentist"
                          id="dentist"
                          value={this.state.searchYeso}
                          onChange={(e) => {
                            this.setState({ searchYeso: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12 d-flex flex-row justify-content-end">
                        <button
                          className="btn btn-outline-primary rounded-pill px-3 mx-2"
                          onClick={(e) => this.clearYesoFilter(e)}
                        >
                          Limpiar filtro
                        </button>
                        <button className="btn btn-outline-primary rounded-pill px-3">
                          Aplicar filtro
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <div>
                {this.state.type === 'todos' &&
                  this.state.yeso_all.map((item, i) => {
                    return (
                      <div key={i}>
                        <div
                          className={`w-100 shadow border border-1 rounded container-fluid tc-container text-start mt-2 mb-2`}
                        >
                          <button
                            className={`clinic-history-button type-${this.handleTreatmentColor(
                              item.type_treatment
                            )}`}
                            onClick={() => this.handleModal(item.id)}
                          />
                          <TreatmentCard
                            id={item.id}
                            external_id={item.external_id}
                            doctor={item.dentists[0].user.full_name}
                            patient={item.patient}
                            request_time={item.retirement_date}
                            maxillary={item.maxillary}
                            treatment_name={item.type_treatment}
                            treatment_phase={item.order}
                            handleModal={this.handleModal}
                            filter="all"
                            type="yeso"
                          />
                        </div>
                      </div>
                    )
                  })}
                {this.state.type === 'rechazados' &&
                  this.state.yeso_rech.map((item, i) => {
                    return (
                      <div key={i}>
                        <div
                          className={`w-100 shadow border border-1 rounded container-fluid tc-container text-start mt-2 mb-2`}
                        >
                          <button
                            className={`clinic-history-button type-${this.handleTreatmentColor(
                              item.type_treatment
                            )}`}
                            onClick={() => this.handleModal(item.id)}
                          />
                          <TreatmentCard
                            id={item.id}
                            external_id={item.external_id}
                            doctor={item.dentists[0].user.full_name}
                            patient={item.patient}
                            request_time={item.retirement_date}
                            maxillary={item.maxillary}
                            treatment_name={item.type_treatment}
                            treatment_phase={item.order}
                            handleModal={this.handleModal}
                            filter="all"
                            type="yeso"
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
            <div className="col-6 d-flex flex-column">
              <button
                className="btn d-flex flex-row justify-content-between text-primary w-100 p-2 bg-white rounded shadow border border-1"
                onClick={() =>
                  this.setState({
                    filter_intraoral: !this.state.filter_intraoral,
                  })
                }
              >
                <p></p>
                <p>Nuevo Escaneo Intraoral</p>
                <ArrowDown size={18} />
              </button>
              {this.state.filter_intraoral && (
                <div className="incomes-filter-container mx-3 rounded-bottom bg-white">
                  <form
                    className="px-4 py-2"
                    onSubmit={(e) =>
                      this.handleIntraFilter(
                        e,
                        this.state.searchIntra,
                        this.state.intraDateOne,
                        this.state.intraDateTwo
                      )
                    }
                  >
                    <div className="row">
                      <label>Fecha de solicitud</label>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_from"
                          id="date_from"
                          value={this.state.intraDateOne}
                          onChange={(e) => {
                            this.setState({ intraDateOne: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          required
                          className="form-control"
                          type="date"
                          name="date_to"
                          id="date_to"
                          value={this.state.intraDateTwo}
                          onChange={(e) => {
                            this.setState({ intraDateTwo: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="dentist">Doctor</label>
                        <input
                          required
                          className="form-control"
                          type="text"
                          name="dentist"
                          id="dentist"
                          value={this.state.searchIntra}
                          onChange={(e) => {
                            this.setState({ searchIntra: e.target.value })
                          }}
                        />
                      </div>
                      <div className="col-12 d-flex flex-row justify-content-end">
                        <button
                          className="btn btn-outline-primary rounded-pill px-3 mx-2"
                          onClick={(e) => this.clearIntraFilter(e)}
                        >
                          Limpiar filtro
                        </button>
                        <button className="btn btn-outline-primary rounded-pill px-3">
                          Aplicar filtro
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <div>
                {this.state.type === 'todos' &&
                  this.state.intra_all.map((item, i) => {
                    return (
                      <div key={i}>
                        <div
                          className={`w-100 shadow border border-1 rounded container-fluid tc-container text-start mt-2 mb-2`}
                        >
                          <button
                            className={`clinic-history-button type-${this.handleTreatmentColor(
                              item.type_treatment
                            )}`}
                            onClick={() => this.handleModal(item.id)}
                          />
                          <TreatmentCard
                            id={item.id}
                            external_id={item.external_id}
                            doctor={
                              item.dentists.length > 0 &&
                              item.dentists[0].user.full_name
                            }
                            patient={item.patient}
                            request_time={item.retirement_date}
                            maxillary={item.maxillary}
                            treatment_name={item.type_treatment}
                            treatment_phase={item.order}
                            handleModal={this.handleModal}
                            filter="all"
                            type="intraoral"
                          />
                        </div>
                      </div>
                    )
                  })}
                {this.state.type === 'rechazados' &&
                  this.state.intra_rech.map((item, i) => {
                    return (
                      <div key={i}>
                        <div
                          className={`w-100 shadow border border-1 rounded container-fluid tc-container text-start mt-2 mb-2`}
                        >
                          <button
                            className={`clinic-history-button type-${this.handleTreatmentColor(
                              item.type_treatment
                            )}`}
                            onClick={() => this.handleModal(item.id)}
                          />
                          <TreatmentCard
                            id={item.id}
                            external_id={item.external_id}
                            doctor={item.dentists[0].user.full_name}
                            patient={item.patient}
                            request_time={item.retirement_date}
                            maxillary={item.maxillary}
                            treatment_name={item.type_treatment}
                            treatment_phase={item.order}
                            handleModal={this.handleModal}
                            filter="all"
                            type="intraoral"
                          />
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => ({ state })

export default connect(mapStateToProps)(Index)
