import React from 'react'
import DeleteModal from './delete_modal'
import EditPatient from './edit_patient_modal'
import saologo from '../../../assets/images/sao_logo.png'
import ubalogo from '../../../assets/images/UBA.png'

function PatientCard({ props }) {
  const principalDentist = props.treatments[0]?.dentists.find(
    (dentist) => dentist.id === props.treatments[0]?.principal_dentist
  )
  return (
    <div className="row">
      <div className="col-3 d-flex flex-row justify-content-left px-2">
        <div className="border border-1 px-3 mx-2 my-1 rounded-circle"></div>
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {props?.name}
        </p>
      </div>
      <div className="col-3 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {principalDentist !== null ? principalDentist?.user.full_name :
            props.treatments[0]?.dentist[0]?.user.full_name}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {props.groups.length === 0 ? (
            <p>No hay perfil</p>
          ) : (
            props.groups.map((group) => (
              <img
                style={{ maxWidth: '35px' }}
                src={group.id === 1 ? saologo : group.id === 3 && ubalogo}
                alt={group.name}
              />
            ))
          )}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-center">
        <p className="mb-0 text-truncate" style={{ lineHeight: '38px' }}>
          {props?.treatments[0].type.name}
        </p>
      </div>
      <div className="col-2 d-flex flex-row justify-content-end">
        <EditPatient color="#444" size={16} props={props} principalDentist={principalDentist} />
        <DeleteModal username={props?.name} id={props?.id} />
      </div>
    </div>
  )
}

export default PatientCard
