import React from 'react'

export default function Input(props) {
  return (
    <div>
      <label htmlFor="name">{props.label}</label>
      <input
        type={props.type}
        className="form-control"
        id={props.id}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
      />
      <span style={{ color: 'red' }}>{props.error}</span>
    </div>
  )
}
