import React, { Component } from 'react'
import apiClient from '../../utils/backend'
import moment from 'moment'

// COMPONENTS
import Navbar from '../../components/navbar'
import Sidebar from '../../components/sidebar'
import ViabilityCard from './components/viability_card'

export default class index extends Component {

  constructor(props){
    super(props)

    this.state = {
      yeso: [],
      intraoral: [],
      loading: true
    }
  }

  componentDidMount(){
    apiClient.get('/api/viability')
    .then(res => {
      
      let viability = res.data.data

      this.setState({viability, loading: false})
      console.log(viability)
    })
    .catch(err => console.log(err))
  }

  handleRedirect = () => {
    this.props.history.push('/tratamientos/cargar')
  }

  render() {
    if(this.state.loading){
      return null
    } else {
      return (
            <div className="container mt-2">
            <div className="row">
                    <div className="col-12">
                      <h1 style={{fontWeight: 700}} className="text-primary">Viabilidad</h1>
                    </div>
                    <div className="col-12 mt-4 d-flex flex-row justify-content-between">
                      <div className="d-flex flex-row">
                        <div className="row shadow py-2 mx-2 border border-1 rounded">
                          <div className="col-4"><h2 className="justify-content-center align-middle text-center m-0">{this.state.viability.length}</h2></div>
                          <div className="col-8"><p className="justify-content-center align-middle m-0">Nuevas consultas de viabilidad</p></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5">
                  <div className="col-12">
                    <input type="text" className="w-100 border-0 px-3 py-2 rounded" placeholder="Buscar..." />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                      {this.state.viability.map(item => {
                        return <ViabilityCard id={item.id} external_id={item.external_id} doctor={item.dentist.full_name} patient={item.patient.full_name} request_time={moment(item.created_at).format('YYYY-MM-DD')} treatment_type={item.type.id} treatment_name={item.type.name} treatment_phase={item.order} comment={item.viability_comment} fotos={item.fotos} estudios={item.estudios} plan={item.plan}  />
                      })}
                  </div>
            </div>
            </div>
      )
    }
  }
}
