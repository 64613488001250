import React, { useEffect, useState } from 'react'
import apiClient from '../../utils/backend'

// COMPONENTS
import ClinicHistoryModal from '../../components/modal_hist_clinica'
import { Card } from './components/card'
import FilterModal from './components/filter_modal'
import Searchbar from '../../components/searchbar'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDataFromCountry,
  getDataSao,
} from '../../redux/actions/odTreatments'
import { Message } from '../../components/Message'
import Paginator from '../../components/Paginator'

export const NewTreatment = () => {
  const { treatments, paginator } = useSelector((st) => st.odTreatments)
  const dispatch = useDispatch()
  const [state, setState] = useState({
    data: [],
    modal_hist_clinica: false,
    origin: '',
    modal_show: false,
    modal_id: null,
    country:-1,
    filterType:"all"
  })

  
  const getData = async (currentPage,saoFilter) => {
    if (saoFilter) {
      try {
        const res = await apiClient.get(
          `/api/treatments?page=${currentPage}&cant_pages=15&include=dentists,patient.user.groups&filter[patient.user.groups.id]=1`
        )
        const data = {
          treatments: res.data.table.body,
          paginator: res.data.paginator,
        }
        dispatch(setTreatments(data))
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        const res = await apiClient.get(
          `/api/treatments?page=${currentPage}&cant_pages=15&include=dentists,patient.user.groups`
        )
        const data = {
          treatments: res.data.table.body,
          paginator: res.data.paginator,
        }
        dispatch(setTreatments(data))
      } catch (error) {
        console.log(error)
      }
    }
  }


  const setTreatments = (t) => ({ type: 'GET_OD_TREATMENTS', payload: t })

  const handleModal = (id) => {
    setState({ ...state, modal_show: !state.modal_show, modal_id: id })
  }

  const handleHistClinicaModal = (id) => {
    setState({
      ...state,
      modal_hist_clinica: !state.modal_hist_clinica,
      modal_hist_clinica_id: id,
    })
  }

  const handleButtonType = ({ button, id }) => {
    const path = window.location.pathname.split('/')[2]

    if (button.type === 'modal') {
      handleModal(button.modal_id + '-' + id)
      const path = window.location.pathname.split('/')[2]
    } else {
      apiClient
        .post(`${button.path}`, { treatment_id: id, process: path })
        .then((res) =>
          res.status === 200 ? window.location.reload() : console.log(res)
        )
        .catch((err) => console.log(err))
    }
  }

  const handleSearchbar = (treatments) => {
    // console.log(typeof treatments.data)
    // console.log(typeof treatments)
    // setState({...state,data:treatments.data})
  }

  const dataFiltered = (data) => {
    setState({ ...state, data: data })
  }

  const getAllPatients = (id) => {
    getData(id,false)
  }

  const handleChangeCountry = (id) => {
    dispatch(getDataFromCountry(id,false))
  }

  const getSaoPatients = (id) => {
    dispatch(getDataSao(id,true))
  }
  
  useEffect(() => {
    getData(1)
  }, [])

  return (
    <>
      {state.loading ? (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100px' }}
        >
          <p>Cargando...</p>
        </div>
      ) : (
        <div className="container-fluid px-3 mt-2">
          <ClinicHistoryModal
            id={state.modal_hist_clinica_id}
            opened={state.modal_hist_clinica}
            handleModal={handleHistClinicaModal}
            // switchStage={switchStage}
          />
          <div className="row mt-4">
            <div className="col-9">
              <h2
                style={{ fontWeight: 700, marginLeft: '30px' }}
                className="text-primary"
              >
                Tratamientos
              </h2>
            </div>
            <div className="col-3 d-flex flex-column px-3">
              <Searchbar setData={handleSearchbar} />
              <FilterModal dataFiltered={dataFiltered} />
            </div>
          </div>
          <div>
            <div
              className="d-flex flex-row justify-content-between mt-4 pb-2"
              style={{ borderBottom: '5px solid rgba(230,230,230)' }}
            >
              <div className="">
                <ul
                  className="d-flex flex-row justify-content-start align-items-center p-0 mb-0"
                  style={{ listStyleType: 'none' }}
                >
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.filterType === "all" && "disabled"}`}
                      onClick={() => {setState({...state,filterType:"all",saoFilter:false,country:-1});getAllPatients(1)}}
                    >
                      Todos
                    </button>
                  </li>
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.filterType === "sao" && "disabled"} `}
                      onClick={() => {setState({...state,filterType:"sao",saoFilter:true,country:-1});getSaoPatients(1)}}
                    >
                      SAO
                    </button>
                  </li>
                </ul>
              </div>
              <div className="">
                <ul
                  className="d-flex flex-row justify-content-start align-items-center p-0 mb-0"
                  style={{ listStyleType: 'none' }}
                >
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.country === 1 && "disabled"}`}
                      onClick={() => {handleChangeCountry(1);setState({...state,filterType:"",country:1})}}
                    >
                      Argentina
                    </button>
                  </li>
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.country === 5 && "disabled"}`}
                      onClick={() => {handleChangeCountry(5);setState({...state,filterType:"",country:5})}}
                    >
                      Chile
                    </button>
                  </li>
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.country === 2 && "disabled"}`}
                      onClick={() => {handleChangeCountry(2);setState({...state,filterType:"",country:2})}}
                    >
                      Uruguay
                    </button>
                  </li>
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.country === 3 && "disabled"}`}
                      onClick={() => {handleChangeCountry(3);setState({...state,filterType:"",country:3})}}
                    >
                      Paraguay
                    </button>
                  </li>
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.country === 6 && "disabled"}`}
                      onClick={() => {handleChangeCountry(6);setState({...state,filterType:"",country:6})}}
                    >
                      Peru
                    </button>
                  </li>
                  <li className="ms-3">
                    <button
                      className={`text-primary cursor-pointer fs-6 btn border-0 m-0 ${state.country === 4 && "disabled"}`}
                      onClick={() => {handleChangeCountry(4);setState({...state,filterType:"",country:4})}}
                    >
                      Colombia
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="row mt-3">
              <div className="col-1" style={{ maxWidth: '75px' }}>
                <p className="text-primary fw-bold m-0 text-center">ID</p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Paciente</p>
              </div>
              <div className="col-2 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Doctor</p>
              </div>
              <div className="col-1 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">Perfil</p>
              </div>
              <div className="col-1 d-flex flex-row justify-content-center">
                <p className="text-primary fw-bold m-0 text-center">
                  Tratamiento
                </p>
              </div>
              <div className="col-1">
                <p className="text-primary fw-bold m-0 text-center">Etapa</p>
              </div>
              <div className="col-1">
                <p className="text-primary fw-bold m-0 text-center">Maxilar</p>
              </div>
              <div className="col-1">
                <p className="text-primary fw-bold m-0 text-center">
                  Movimiento
                </p>
              </div>
              <div className="col-1">
                <p className="text-primary fw-bold m-0 text-center">Estado</p>
              </div>
              <div className="col-1">
                <p className="text-primary fw-bold m-0 text-center"></p>
              </div>
            </div>
          </div>
          {treatments.length > 0 ? (
            treatments.map((item, i) => (
              <Card
                key={item.id}
                data={item}
                i={i}
                handleModal={handleHistClinicaModal}
              />
            ))
          ) : (
            <Message
              type="alert alert-danger w-50 mt-3 text-center mx-auto"
              message="No se encontraron tratamientos."
            />
          )}
          <Paginator paginator={paginator} getPaginator={getData} />
        </div>
      )}
    </>
  )
}
