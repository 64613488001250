import { combineReducers } from "redux";

import userReducer from './reducers/userReducer'
import navbarReducer from './reducers/navbarReducer'
import modalReducer from './reducers/modalReducer'
import formOnlineReducer from './reducers/formOnlineReducer'
import usersReducer from './reducers/usersReducer'
import { patientReducer } from "./reducers/patientReducer";
import {odTreatments} from './reducers/odTreatmentsReducer'

const reducers = combineReducers({userReducer, navbarReducer, modalReducer, formOnlineReducer,patientReducer,usersReducer,odTreatments})

export default reducers;