import React from 'react'

export default function CheckBoxList({radios: checks, stateField, setter, name, colors, enabled}){
    return (
        <div>
            {checks.map((chk, i)=>
                <div className={`form-check mt-${i===0?'3':'2'}`} key={i}>
                    <input disabled={!enabled} style={stateField.indexOf(chk.value)>-1?{backgroundColor: colors.color, borderColor: colors.color, cursor:'pointer',visibility:'inherit'}:{cursor:'pointer', visibility:'inherit'}} checked={stateField.indexOf(chk.value)>-1} onChange={(e)=>setter(chk.value, e.target.checked)} className="form-check-input rounded" type="checkbox" name={name+i} id={name+i} />
                    <label className="form-check-label force-inline-text">
                        {chk.label}
                    </label>
                </div>
                )
            }
        </div>
    )
}