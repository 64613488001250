import React, { Component } from "react";
import apiClient from "../../utils/backend";
import { connect } from "react-redux";

// COMPONENTS
import RequestCard from "./components/request_card";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      todos: [],
      tratamiento: [],
      etapa: [],
      viabilidad: [],
      placaRepeticion: [],
      step: "todos",
    };
  }

  componentDidMount() {
    apiClient
      .get("/api/pendings/od")
      .then((res) => {
        console.log("res");
        console.log(res);
        let todos = res.data.todos.map((item) => item);
        let acciones_pendientes = res.data.acciones_pendientes.map(
          (item) => item
        );
        console.log("all");
        console.log(todos);

        this.setState({ todos, acciones_pendientes, loading: false });
      })
      .catch((err) => console.log(err));
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <div className="container-fluid px-5 position-relative">
          <div className="row">
            <div className="col-12 text-center mt-5 px-4">
              <h2 className="text-primary fw-bold">Solicitudes Pendientes</h2>
              <h4 className="text-primary">
                Todas sus solicitudes que están pendientes de revision por el
                equipo de KeepSmiling
              </h4>
            </div>
          </div>
          <div className="row pt-5">
            <div className="col-12">
              <h4 className="fw-bold text-primary mb-2 mx-3">
                Mis solicitudes
              </h4>
            </div>
            <div className="col-12 mt-3">
              <ul
                className="d-flex flex-row justify-content-start p-0"
                style={{ listStyleType: "none" }}
              >
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-5 btn border-0 m-0 ${
                      this.state.step === "todos" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "todos" })}
                  >
                    Todos
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-5 btn border-0 m-0 ${
                      this.state.step === "tratamiento" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "tratamiento" })}
                  >
                    Nuevo Tratamiento
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-5 btn border-0 m-0 ${
                      this.state.step === "etapa" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "etapa" })}
                  >
                    Nueva Etapa
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-5 btn border-0 m-0 ${
                      this.state.step === "viabilidad" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "viabilidad" })}
                  >
                    Viabilidad
                  </a>
                </li>
                <li style={{ marginRight: "15px" }}>
                  <a
                    className={`text-primary cursor-pointer fs-5 btn border-0 m-0 ${
                      this.state.step === "placaRepeticion" && "disabled"
                    }`}
                    href="#"
                    onClick={() => this.setState({ step: "placaRepeticion" })}
                  >
                    Placa de Repeticion
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-3 d-flex flex-row justify-content-center">
              <p className="text-muted fw-bold m-0 text-center">Paciente</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-muted fw-bold m-0 text-center">
                Fecha de solicitud
              </p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
              <p className="text-muted fw-bold m-0 text-center">Tratamiento</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-muted fw-bold m-0 text-center">
                Tipo de solicitud
              </p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center">
              <p className="text-muted fw-bold m-0 text-center">Estado</p>
            </div>
            <div className="col-1"></div>
          </div>
          <div className="row mt-3 px-4">
            <div className="col-12">
              {this.state[this.state.step].map((item, i) => {
                console.log(item.id);
                return (
                  <div>
                    <div
                      className={`grow_hover w-100 shadow border border-1 rounded container-fluid py-2 text-start mt-2 mb-2 position-relative`}
                    >
                      <RequestCard
                        id={item.id}
                        external_id={item.external_id}
                        patient={item.patient}
                        created_at={item.created_at}
                        treatment_type={item.type.id}
                        treatment={item.type.name.split(" ")[1]}
                        phase={item.order}
                        pendings={item.pendings}
                        handleModal={this.handleModal}
                        filter="rejected"
                        type="intraoral"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({ state });

export default connect(mapStateToProps)(Home);
