import React, {Component} from 'react'
import apiClient from '../../utils/backend'
import moment from 'moment'
import {connect} from 'react-redux'
import 'moment/locale/es';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import HeaderPlacasRepeticion from "./components/HeaderPlacasRepeticion";
import BodyPlacasRepeticion from "./components/BodyPlacasRepeticion";

class PlacasRepeticion extends Component {
    constructor(props) {
        super(props)

        this.state = {
            table: {},
            title: "",
            cards: [],
            loading: true,
            country: '',
            country_filter: false,
            isopened: false,
            type: 'todos'
        }


        this.handleModal = this.handleModal.bind(this)
    }

    componentDidMount() {
        const path = window.location.pathname.split("/")[2]
        moment.locale('es')
        console.log(path)
        console.log(this.props)

        apiClient.get(`/api/treatmentPlateRepeat`)
            .then(res => {
                console.log("data")
                console.log(res.data)
                this.setState({
                    table: res.data.table,
                    title: res.data.title,
                    country_filter: res.data.country_filter,
                    country: res.data.user_country,
                    cards: res.data.card,
                    loading: false
                })
            })
            .catch(err => console.log(err))
    }

    handleModal(modal) {
        console.log('handling modal, ', modal)
        this.setState({modal_show: modal})
    }

    handleStatus(id, status) {
        apiClient.post(`/api/treatmentPlateRepeat/changeStatus`, {id: id, status: status})
            .then(res => {
                console.log("data")
                console.log(res.data)
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    render() {

        moment.locale()
        const dia = moment().format('dddd')
        const fecha = moment().format('LL')

        if (this.state.loading) {
            return null
        } else {
            return (
                <div className="container-fluid px-3 mt-2">
                    <div className="row">
                        <div className="col-12">
                            <h1 style={{fontWeight: 700}} className="text-primary">Placas de Repetición</h1>
                        </div>
                        <div className="col-3 mt-4 d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row">
                                <div className="row shadow py-2 mx-2 rounded">
                                    <div className="col-4"><h2
                                        className="justify-content-center align-middle text-center m-0">25</h2></div>
                                    <div className="col-8"><p className="justify-content-center align-middle m-0">Nuevas
                                        placas para repetir impresión</p></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 mt-4 d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row">
                                <div className="row shadow py-2 mx-2 rounded">
                                    <div className="col-4"><h2
                                        className="justify-content-center align-middle text-center m-0">25</h2></div>
                                    <div className="col-8"><p className="justify-content-center align-middle m-0">Nuevas
                                        placas de impresión</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <input
                                type="text"
                                className="w-100 border-0 p-3"
                                placeholder="Escribe al menos 3 caracteres..."
                                onChange={this.handleInputChange}
                            />
                        </div>
                        <div className="col-12">
                            <ul
                                className="d-flex flex-row justify-content-start p-0"
                                style={{ listStyleType: "none" }}
                            >
                                <li style={{ marginRight: "15px" }}>
                                    <a
                                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                                        href="#"
                                        onClick={() =>
                                            this.props.handleStep('all')
                                        }
                                    >
                                        Todos
                                    </a>
                                </li>
                                <li style={{ marginRight: "15px" }}>
                                    <a
                                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                                        href="#"
                                        onClick={() =>
                                            this.props.handleStep('cuenta_corriente')
                                        }
                                    >
                                        Cuenta Corriente
                                    </a>
                                </li>
                                <li style={{ marginRight: "15px" }}>
                                    <a
                                        className="text-primary cursor-pointer fs-6 btn border-0 m-0"
                                        href="#"
                                        onClick={() => this.props.handleStep('estado_pagos')}
                                    >
                                        Estado pagos
                                    </a>
                                </li>
                                <li style={{ marginRight: "15px" }}>
                                    <a
                                        className="text-primary cursor-pointer fs-6 disabled btn border-0 m-0"
                                        href="#"
                                    >
                                        Placas de Repetición
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mt-5">
                       <HeaderPlacasRepeticion/>
                    </div>
                    {this.state.table.body['todos'][this.state.country].map((item, i) => (
                        item.carries_cost === "yes" && <BodyPlacasRepeticion key={i} item={item}/>
                    ))}

                    {/* <WeeklyResume /> */}
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => ({state})

export default connect(mapStateToProps)(PlacasRepeticion)
