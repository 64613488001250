import React, {useState} from 'react'
import Header from './components/StickyHeader'
//import MultiStep from 'react-multistep'
import PacientData from './components/PacientData'
// import AttatchPhotos from './components/AttatchPhotos'
// import AttatchStudies from './components/AttatchStudies'
// import AttatchTreatments from './components/AttatchTreatments'

export default function FormOnline(props)
{
    const get_colors=()=>{
        //switch(props.match.params.id){
        switch(props.type){
            default:
                return { color: '#288B7E', alternative_color:'#fff' };
            case 1://'full'
                return { color: '#10456D', alternative_color:'#fff' };
            case 3://fast
                return { color: '#288B7E', alternative_color:'#fff' };
            case 5://'medium'
                return { color: '#5A468E', alternative_color:'#fff' };
            case 2://'kids'
                return { color: '#E0614A', alternative_color:'#000' };
            case 4://'kids_fast'
                return { color: '#E8793A', alternative_color:'#000' };
        }
    }
    // const steps = [
    //     {name: 'Datos del Paciente', component: <PacientData colors={get_colors()}/>},
    //     {name: 'Adjuntar fotos', component: <AttatchPhotos/>},
    //     {name: 'Adjuntar estudios', component: <AttatchStudies/>},
    //     {name: 'Adjuntar plan de tratamiento', component: <AttatchTreatments/>},
    //     {name: 'Envío de Registro', component: null}
    //   ];
    return (
        <div className="mt-3 shadow w-100 p-3 bg-white rounded">
            {/* <div className="d-flex justify-content-center">
                <h3>Nueva Solicitud de Tratamiento</h3>
            </div> */}
            {/* HEADER DEL FORM ONLINE */}
            <Header colors={get_colors()} />
            <div className="d-flex justify-content-center">
                <div className="col-12">
                    {/* <MultiStep showNavigation={true} steps={steps} color={'#E0614A'} /> */}
                    <PacientData colors={get_colors()} type={props.type}
                    nextParentStep={props.nextParentStep}
                    />
                </div>
            </div>
        </div>
    )
}