import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import apiClient from "../../../utils/backend";
import { Trash2 } from "react-feather";
import Danger from "../../../assets/images/danger_triangle.png";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);

  function handleDelete() {
    apiClient.delete(`/api/patients/${props.id}`).then((res) => {
      toast.success("Usuario eliminado correctamente! Redirigiendo...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  }

  function handleModal() {
    setOpened(!opened);
  }

  return (
    <div>
      <button
        className="btn bg-danger p-1 mt-2"
        onClick={() => setOpened(true)}
      >
        <Trash2 size={20} color="#fff" />
      </button>
      <Modal isOpen={opened} toggle={handleModal}>
        <ModalBody>
          <div className="container mt-4">
            <div className="row">
              <div className="col-12 flex text-center">
                <img src={Danger} className="mt-1" style={{ width: "80px" }} />
                <p className="mt-2 text-black fs-4 fw-bold">
                  ¿Estas seguro que deseas eliminar el paciente: {props.username}?
                </p>
                <p style={{ color:"#000",fontSize: "13px"}} className="pt-2 mt-4">
                  Se eliminaran todos los tratamientos existentes asignados a este paciente
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <div className="d-flex justify-content-center my-5">
          {!loading && (
            <div className="w-50 d-flex justify-content-around">
              <button
                className="btn btn-light border rounded-pill px-5 me-3"
                onClick={handleModal}
              >
                Volver
              </button>
              <button
                className="btn btn-outline-warning rounded-pill px-5 ms-3"
                onClick={handleDelete}
              >
                Eliminar
              </button>
            </div>
          )}

          {loading && (
            <button className="btn btn-danger" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm text-white"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
