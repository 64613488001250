import React, { useEffect, useState, useRef } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import apiClient from '../../../utils/backend'
import { useHistory } from 'react-router'

import PatientData from '../../../components/patient_data'
import { connect } from 'react-redux';

const VideoModal = (props) => {
  const {
    className,
    id,
    opened,
    handleModal
  } = props;

  const propostal_ref= useRef(null)
  const [contentLoading, setContentLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState()
  const history = useHistory()

  const [items, setItems] = useState([{i: 0, url: ''}])
  const [index, setIndex] = useState(0)
  const [error, setError] = useState()

  useEffect(() => {
    handleGetData()
  }, [])

  function handleGetData() {
    console.log(props)

     apiClient.get(`/api/helper/getClinicalHistory/${props.id}`, { withCredentials: true, headers: { Authorization: `Bearer ${props.userReducer.login_token}` } })
      .then(res => {
        console.log('data item',res.data.data)
        setData(res.data.data)
      })
      .then(res => setContentLoading(false))
      .catch(err => console.log(err))
  }

  function handleAddUrl({url, i}){
    let urls = [...items]
    let item = urls[i]

    item.url = url

    // setItems(urls)
    console.log(urls)
  }

  function handleAddItem(){
    let list = [...items]
    let item = {i: index + 1, url: ''}
    list.push(item)
    setItems(list)
    setIndex(index + 1)
    propostal_ref.current.value = ""
  }

  function handleSendData(){
    apiClient.post('/api/status/nextStatus', {treatment_id: id, process: 'Video', url: items[0].url})
    // DESPUES VALIDAR ENVIAR UN ARRAY DE URLS
    .then(res => res.status === 200 ? window.location.reload() : setError('Error intentando pasar de estado el tratamiento.'))
    .catch(err => console.log(err))
  }

  if(contentLoading){
    return (
      <div>
        <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div className="container">
              <h5 className="fs-6">Video</h5>
              <PatientData movements={'?'} buttons={'?'} stripping={'?'} birthdate={data.patient.birthdate} type={data.type} stage={data.stage} id={id} created_at={data.created_at} dentist={data.dentist} patient={data.patient} maxilla={data.maxilla} profile_image={data.photos_files.length > 0 && data.photos_files[0].path !== undefined ? data.photos_files[0].path : null}  />
              <div className="row mt-5">
              <div className="d-flex flex-wrap">
                {items.map((item, index) => 
                  {return index+1<items.length &&
                  <div className='col-3 p-2' key={index}>
                    <label className='fs-6 fw-bold text-primary'>{item.i + 1}° Propuesta</label>
                    <div className="col-12 d-flex justify-content-between">
                      {item.url}
                    </div>
                  </div>
                  }
                  )}
                  </div>
                  <h6 className="mb-3 mt-3 fw-bold text-primary">Cargar link del visor</h6>
                  <div>
                    <label className='fs-6 fw-bold text-primary'>{items.length}° Propuesta</label>
                    <div className="col-12 d-flex justify-content-between">
                      <input ref={propostal_ref} style={{marginBottom:'auto', width:'70%'}} type="url" name="1" id="1" className="form-control" onChange={(e) => handleAddUrl({url: e.target.value, i: items.length-1})} />
                      <button className="rounded-pill btn btn-success text-white w-10" onClick={null}>Ok</button>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-row justify-content-start mt-5">
                    <button className="rounded-pill btn btn-outline-primary" onClick={() => handleAddItem()}>Agregar propuesta</button>
                  </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

            {!loading &&
              <div>
                <button className="rounded-pill btn border btn-light text-dark mx-2" onClick={handleModal}>Volver</button>
                <button className="rounded-pill btn btn-success text-white" onClick={() => handleSendData()}>Guardar</button>
              </div>
            }

            {loading &&
              <button class="btn btn-success" type="button" disabled>
                <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
              </button>
            }

          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(VideoModal)
