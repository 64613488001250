import React, { Component } from "react";
import Select from "react-select";
import { Upload } from "react-feather";
import axios from "axios";

import {
  ImageUploadContainer,
  ImageUploadLabel,
  ImageUploadReference,
} from "./components/styled";

// COMPONENTS
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";

// IMAGES STEP 2
import trescuartos_perfil from "../../assets/images/34deperfilsonriendo.png";
import anterioroclusion from "../../assets/images/anteriorenoclusion.png";
import anteriorinoclusion from "../../assets/images/anterioreninoclusion.png";
import bucalderecho from "../../assets/images/bucalderecho.png";
import bucalizquierdo from "../../assets/images/bucalizquierdo.png";
import frontalreposo from "../../assets/images/frontalenreposo.png";
import frontalsonriendo from "../../assets/images/frontalsonriendo.png";
import oclusalmaxilarinf from "../../assets/images/oclusaldelmaxilarinferior.png";
import oclusalmaxilarsup from "../../assets/images/oclusaldelmaxilarsuperior.png";
import perfilizqreposo from "../../assets/images/perfilizquierdoenreposo.png";

// IMAGES STEP 3
import radioperfil from "../../assets/images/telerradiografiadeperfil.png";
import ricketts from "../../assets/images/cefalogramadericketts.png";
import rxpanoramica from "../../assets/images/rxpanoramica.png";

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,

      // VISTA 1
      od_list: null,
      nombre: "",
      apellido: "",
      genero: "M",
      maxilar: 1,
      external_id: null,
      odontologo: null,
      dentist_id: null,
      treatment_type: 7,
      etapa: 1,
      new_patient: true,

      // VISTA 2
      frontal_reposo: undefined,
      frontal_sonriendo: undefined,
      perfil_izq_reposo: undefined,
      trescuartos_perfil: undefined,
      oclusal_maxilar_sup: undefined,
      oclusal_maxilar_inf: undefined,
      anterior_oclusion: undefined,
      bucal_derecho: undefined,
      bucal_izquierdo: undefined,
      anterior_inoclusion: undefined,
      otro: undefined,
      viability_comment: "",

      // VISTA 3
      rxpanoramica: undefined,
      telerradiografia: undefined,
      ricketts: undefined,
      otro2: undefined,

      // VISTA 4
      plantratamiento: undefined,

      // STEP DATA
      fotos: 0,
      estudios: 0,
      plan: 0,
    };

    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  componentDidMount() {
    axios
      .get("https://api.keepsmiling.app/api/helper/getDentists")
      .then((res) => {
        if (res.status === 200) {
          let formatted = [];

          for (var i in res.data)
            formatted.push({ value: i, label: res.data[i] });

          this.setState({ od_list: formatted });
        }
      })
      .catch((err) => console.log(err));
  }

  handleImageSelect = (e) => {
    console.log(e.target.id, e.target.files[0]);
    this.setState({ [e.target.id]: e.target.files[0] });

    switch (this.state.step) {
      case 2:
        console.log("ENTERING FOTOS");
        if (this.state.fotos === 0) {
          this.setState({ fotos: 1 });
        }
        break;
      case 3:
        console.log("ENTERING STUDIO");
        if (this.state.estudios === 0) {
          this.setState({ estudios: 1 });
        }
        break;
      case 4:
        console.log("ENTERING PLAN");
        if (this.state.plan === 0) {
          this.setState({ plan: 1 });
        }
        break;
      default:
        break;
    }
  };

  handleImageUpload = () => {
    console.log(this.state);

    const fd = new FormData();

    fd.append("external_id", this.state.external_id);
    fd.append("dentist_id", this.state.dentist_id);
    fd.append("name", this.state.nombre);
    fd.append("surname", this.state.apellido);
    fd.append("gender", this.state.genero);
    fd.append("type_id", this.state.treatment_type);
    fd.append("maxilla", this.state.maxilar);
    fd.append("order", this.state.etapa);
    fd.append("intraoral", 1);
    fd.append("new_patient", true);
    fd.append("viability_comment", this.state.viability_comment);

    fd.append("image_1", this.state.frontal_reposo);
    fd.append("image_2", this.state.frontal_sonriendo);
    fd.append("image_3", this.state.perfil_izq_reposo);
    fd.append("image_4", this.state.trescuartos_perfil);
    fd.append("image_5", this.state.oclusal_maxilar_sup);
    fd.append("image_6", this.state.oclusal_maxilar_inf);
    fd.append("image_7", this.state.anterior_oclusion);
    fd.append("image_8", this.state.bucal_derecho);
    fd.append("image_9", this.state.bucal_izquierdo);
    fd.append("image_10", this.state.anterior_inoclusion);
    fd.append("image_11", this.state.otro);

    fd.append("image_12", this.state.rxpanoramica);
    fd.append("image_13", this.state.telerradiografia);
    fd.append("image_14", this.state.ricketts);
    fd.append("image_15", this.state.otro2);

    fd.append("image_16", this.state.plantratamiento);

    fd.append("name_1", "frontal_reposo");
    fd.append("name_2", "frontal_sonriendo");
    fd.append("name_3", "perfil_izq_reposo");
    fd.append("name_4", "trescuartos_perfil");
    fd.append("name_5", "oclusal_maxilar_sup");
    fd.append("name_6", "oclusal_maxilar_inf");
    fd.append("name_7", "anterior_oclusion");
    fd.append("name_8", "bucal_derecho");
    fd.append("name_9", "bucal_izquierdo");
    fd.append("name_10", "anterior_inoclusion");
    fd.append("name_11", "otro");

    fd.append("name_12", "rxpanoramica");
    fd.append("name_13", "telerradiografia");
    fd.append("name_14", "ricketts");
    fd.append("name_15", "otro2");

    fd.append("name_16", "plantratamiento");

    fd.append("fotos", this.state.fotos);
    fd.append("estudios", this.state.estudios);
    fd.append("plan", this.state.plan);

    axios
      .post("https://api.keepsmiling.app/api/viability", fd)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  render() {
    const selectStyle = {
      control: (base) => ({
        ...base,
        border: 0,
        boxShadow: "none",
        borderBottom: "2px solid #0027a8!important",
      }),
    };

    switch (this.state.step) {
      case 1:
        return (
          <div style={{ height: "100%", overflowX: "hidden" }}>
            <Sidebar />
            <div id="content" className="container">
              <Navbar />
              <div className="container mt-2">
                <div className="row">
                  <div className="col-12 px-5">
                    <h1 style={{ fontWeight: 700 }} className="text-primary">
                      Nuevo Ingreso
                    </h1>
                  </div>
                  <div className="col-12 mx-auto text-center">
                    <h2 style={{ fontWeight: 700 }}>Carga de Datos</h2>
                  </div>
                  <div
                    className="col-12 mt-5 mb-5 mx-auto"
                    style={{ position: "relative", maxWidth: "70vw" }}
                    id="timeline"
                  >
                    <ul
                      className="w-100"
                      style={{
                        background: "#cecece",
                        height: "3px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "relative",
                      }}
                    >
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 1 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "0",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Datos del paciente
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 2 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "25%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar fotos
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 3 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "50%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Estudios
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 4 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "75%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Plan de Tratamiento
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 5 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "100%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Envio de Registro
                        </p>
                      </li>
                    </ul>
                    <ul
                      className="w-0"
                      style={{
                        background: "#2ecc71",
                        height: "5px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "absolute",
                        top: "0",
                        zIndex: 9,
                      }}
                    ></ul>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-6 mx-auto">
                    <form className="row mb-4">
                      <div className="col-3">
                        <label htmlFor="id_external">Id Externo</label>
                        <input
                          type="text"
                          className="form-control"
                          id="external_id"
                          onChange={(e) =>
                            this.setState({ [e.target.id]: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-9">
                        <label htmlFor="odontologo">Odontologo</label>
                        <Select
                          styles={selectStyle}
                          options={this.state.od_list}
                          isSearchable
                          onChange={(e) =>
                            this.setState({
                              odontologo: e.label,
                              dentist_id: Number(e.value),
                            })
                          }
                        />
                      </div>
                      <div className="col-12 text-end align-center">
                        <input
                          type="checkbox"
                          name=""
                          id="existent_patient"
                          className="mx-2 rounded"
                        />
                        <label htmlFor="existent_patient">
                          Usar paciente existente
                        </label>
                      </div>
                    </form>
                    <form className="row ">
                      <div className="col-12 mb-2">
                        <h4>Paciente</h4>
                      </div>
                      <div className="col-6">
                        <label htmlFor="nombre">Nombre</label>
                        <input
                          type="text"
                          className="form-control"
                          id="nombre"
                          onChange={(e) =>
                            this.setState({ [e.target.id]: e.target.value })
                          }
                        />
                      </div>
                      <div className="col-6">
                        <label htmlFor="apellido">Apellido</label>
                        <input
                          type="text"
                          className="form-control"
                          id="apellido"
                          onChange={(e) =>
                            this.setState({ [e.target.id]: e.target.value })
                          }
                        />
                      </div>
                    </form>
                    <form className="row mb-4">
                      <div className="col-12">
                        <label htmlFor="genero">Genero</label>
                        <select
                          name="genero"
                          id="genero"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({ genero: e.target.value })
                          }
                        >
                          <option value="M">Hombre</option>
                          <option value="F">Mujer</option>
                          <option value="O">No binario</option>
                        </select>
                      </div>
                    </form>
                    <form className="row ">
                      <div className="col-12 mb-2">
                        <h4>Tratamiento</h4>
                      </div>
                      <div className="col-6">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ treatment_type: 1 });
                          }}
                          className={
                            this.state.treatment_type === 1
                              ? "btn w-100 my-2 rounded btn-outline-primary active"
                              : "btn w-100 my-2 rounded btn-outline-primary"
                          }
                        >
                          KeepSmiling Full
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ treatment_type: 2 });
                          }}
                          className={
                            this.state.treatment_type === 2
                              ? "btn w-100 my-2 rounded btn-outline-warning active"
                              : "btn w-100 my-2 rounded btn-outline-warning"
                          }
                        >
                          KeepSmiling Kids
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ treatment_type: 3 });
                          }}
                          className={
                            this.state.treatment_type === 3
                              ? "btn w-100 my-2 rounded btn-outline-secondary active"
                              : "btn w-100 my-2 rounded btn-outline-secondary"
                          }
                        >
                          KeepSmiling Fast
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ treatment_type: 4 });
                          }}
                          className={
                            this.state.treatment_type === 4
                              ? "btn w-100 my-2 rounded btn-outline-danger active"
                              : "btn w-100 my-2 rounded btn-outline-danger"
                          }
                        >
                          KeepSmiling Kids Fast
                        </button>
                      </div>
                      <div className="col-6">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ treatment_type: 5 });
                          }}
                          className={
                            this.state.treatment_type === 5
                              ? "btn w-100 my-2 rounded btn-outline-info active"
                              : "btn w-100 my-2 rounded btn-outline-info"
                          }
                        >
                          KeepSmiling Medium
                        </button>
                      </div>
                    </form>
                    <form className="row mt-2">
                      <div className="col-12 mb-2"></div>
                      <div className="col-6">
                        <label htmlFor="maxilar">Maxilar</label>
                        <select
                          name="maxilar"
                          id="maxilar"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({ maxilar: e.target.value })
                          }
                        >
                          <option value="1">Superior</option>
                          <option value="2">Inferior</option>
                          <option value="3">Ambos</option>
                        </select>
                      </div>
                      <div className="col-6">
                        <label htmlFor="etapa">Etapa</label>
                        <select
                          name="maxilar"
                          id="maxilar"
                          className="form-control"
                          onChange={(e) =>
                            this.setState({ maxilar: e.target.value })
                          }
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                      <div className="col-12 mt-2">
                        <label htmlFor="comment">Comentario</label>
                        <textarea
                          name="comment"
                          id="comment"
                          cols="50"
                          rows="5"
                          className="w-100"
                          onChange={(e) =>
                            this.setState({ viability_comment: e.target.value })
                          }
                        ></textarea>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-end">
                    <button
                      className="btn btn-primary"
                      onClick={() => this.setState({ step: 2 })}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div style={{ height: "100%", overflowX: "hidden" }}>
            <Sidebar />
            <div id="content" className="container">
              <Navbar />
              <div className="container mt-2">
                <div className="row">
                  <div className="col-12 px-5">
                    <h1 style={{ fontWeight: 700 }} className="text-primary">
                      Nuevo Ingreso
                    </h1>
                  </div>
                  <div className="col-12 mx-auto text-center">
                    <h2 style={{ fontWeight: 700 }}>Adjuntar fotos</h2>
                  </div>
                  <div
                    className="col-12 mt-5 mb-5 mx-auto"
                    style={{ position: "relative", maxWidth: "70vw" }}
                    id="timeline"
                  >
                    <ul
                      className="w-100"
                      style={{
                        background: "#cecece",
                        height: "3px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "relative",
                      }}
                    >
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 1 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "0",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Datos del paciente
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 2 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "25%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar fotos
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 3 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "50%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Estudios
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 4 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "75%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Plan de Tratamiento
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 5 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "100%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Envio de Registro
                        </p>
                      </li>
                    </ul>
                    <ul
                      className="w-25"
                      style={{
                        background: "#2ecc71",
                        height: "5px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "absolute",
                        top: "0",
                        zIndex: 9,
                      }}
                    ></ul>
                  </div>
                </div>
                <div className="row d-flex flex-row justify-content-center mx-auto px-5">
                  <div
                    className="col-3 image-upload-container d-flex flex-column my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="frontal_reposo"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={frontalreposo}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>Frontal en Reposo</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="frontal_sonriendo"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={frontalsonriendo}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>Frontal Sonriendo</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="perfil_izq_reposo"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={perfilizqreposo}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>
                      Perfil Izquierdo en Reposo
                    </ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="trescuartos_perfil"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={trescuartos_perfil}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>3/4 Perfil Sonriendo</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="oclusal_maxilar_sup"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={oclusalmaxilarsup}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>
                      Oclusal del Maxilar Superior
                    </ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="oclusal_maxilar_inf"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={oclusalmaxilarinf}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>
                      Oclusal del Maxilar Inferior
                    </ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="anterior_oclusion"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={anterioroclusion}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>Anterior en Oclusion</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="bucal_derecho"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={bucalderecho}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>Bucal Derecho</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="bucal_izquierdo"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={bucalizquierdo}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>Bucal Izquierdo</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="anterior_inoclusion"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={anteriorinoclusion}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>Anterior en Inoclusion</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="otro"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          background: "#112154",
                        }}
                      ></div>
                    </div>
                    <ImageUploadLabel>Otras</ImageUploadLabel>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12 f-flex text-end">
                    <button
                      className="btn btn-outline-primary mx-2"
                      onClick={() => this.setState({ step: 1 })}
                    >
                      Volver
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.setState({ step: 3 })}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ height: "100%", overflowX: "hidden" }}>
            <Sidebar />
            <div id="content" className="container">
              <Navbar />
              <div className="container mt-2">
                <div className="row">
                  <div className="col-12 px-5">
                    <h1 style={{ fontWeight: 700 }} className="text-primary">
                      Nuevo Ingreso
                    </h1>
                  </div>
                  <div className="col-12 mx-auto text-center">
                    <h2 style={{ fontWeight: 700 }}>Adjuntar Estudios</h2>
                  </div>
                  <div
                    className="col-12 mt-5 mb-5 mx-auto"
                    style={{ position: "relative", maxWidth: "70vw" }}
                    id="timeline"
                  >
                    <ul
                      className="w-100"
                      style={{
                        background: "#cecece",
                        height: "3px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "relative",
                      }}
                    >
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 1 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "0",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Datos del paciente
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 2 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "25%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar fotos
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 3 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "50%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Estudios
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 4 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "75%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Plan de Tratamiento
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 5 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "100%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Envio de Registro
                        </p>
                      </li>
                    </ul>
                    <ul
                      className="w-50"
                      style={{
                        background: "#2ecc71",
                        height: "5px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "absolute",
                        top: "0",
                        zIndex: 9,
                      }}
                    ></ul>
                  </div>
                </div>
                <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="rxpanoramica"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference
                        src={rxpanoramica}
                        alt="Imagen de"
                      />
                    </div>
                    <ImageUploadLabel>RX Panorámica</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="telerradiografia"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference src={radioperfil} alt="Imagen de" />
                    </div>
                    <ImageUploadLabel>
                      Telerradiografia de perfil
                    </ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="ricketts"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <ImageUploadReference src={ricketts} alt="Imagen de" />
                    </div>
                    <ImageUploadLabel>Cefalograma de Ricketts</ImageUploadLabel>
                  </div>
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="otro2"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          background: "#112154",
                        }}
                      ></div>
                    </div>
                    <ImageUploadLabel>Otras</ImageUploadLabel>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12 f-flex text-end">
                    <button
                      className="btn btn-outline-primary mx-2"
                      onClick={() => this.setState({ step: 2 })}
                    >
                      Volver
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => this.setState({ step: 4 })}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 4:
        return (
          <div style={{ height: "100%", overflowX: "hidden" }}>
            <Sidebar />
            <div id="content" className="container">
              <Navbar />
              <div className="container mt-2">
                <div className="row">
                  <div className="col-12 px-5">
                    <h1 style={{ fontWeight: 700 }} className="text-primary">
                      Nuevo Ingreso
                    </h1>
                  </div>
                  <div className="col-12 mx-auto text-center">
                    <h2 style={{ fontWeight: 700 }}>
                      Adjuntar Plan de Tratamiento
                    </h2>
                  </div>
                  <div
                    className="col-12 mt-5 mb-5 mx-auto"
                    style={{ position: "relative", maxWidth: "70vw" }}
                    id="timeline"
                  >
                    <ul
                      className="w-100"
                      style={{
                        background: "#cecece",
                        height: "3px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "relative",
                      }}
                    >
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 1 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "0",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Datos del paciente
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 2 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "25%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar fotos
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 3 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "50%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Estudios
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 4 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "75%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Adjuntar Plan de Tratamiento
                        </p>
                      </li>
                      <li
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "50%",
                          background:
                            this.state.step > 5 ? "#2ecc71" : "#cecece",
                          position: "absolute",
                          top: "-15px",
                          zIndex: 10,
                          left: "100%",
                        }}
                      >
                        <p
                          style={{
                            position: "absolute",
                            top: "40px",
                            width: "100px",
                            textAlign: "center",
                            left: "-30px",
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          Envio de Registro
                        </p>
                      </li>
                    </ul>
                    <ul
                      className="w-75"
                      style={{
                        background: "#2ecc71",
                        height: "5px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        listStyleType: "none",
                        position: "absolute",
                        top: "0",
                        zIndex: 9,
                      }}
                    ></ul>
                  </div>
                </div>
                <div className="row d-flex flex-row justify-content-center mx-auto px-5 mb-5">
                  <div
                    className="col-3 image-upload-container d-flex flex-column  my-3 mx-2"
                    style={{
                      maxHeight: "200px",
                      width: "200px",
                      position: "relative",
                    }}
                  >
                    <div className="d-flex flex-row  m-0">
                      <ImageUploadContainer>
                        <input
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={this.handleImageSelect}
                          id="plantratamiento"
                        />
                        <Upload />
                      </ImageUploadContainer>
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          background: "#112154",
                        }}
                      ></div>
                    </div>
                    <ImageUploadLabel>Plan de tratamiento</ImageUploadLabel>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 f-flex text-end">
                    <button
                      className="btn btn-outline-primary mx-2"
                      onClick={() => this.setState({ step: 3 })}
                    >
                      Volver
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        this.handleImageUpload();
                        this.setState({ step: 5 });
                      }}
                    >
                      Continuar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div style={{ height: "100%", overflowX: "hidden" }}>
            <Sidebar />
            <div id="content" className="container">
              <Navbar />
              <div className="container mt-2">
                <div className="row">
                  <div className="col-12 px-5">
                    <h1 style={{ fontWeight: 700 }} className="text-primary">
                      Nuevo Ingreso
                    </h1>
                  </div>
                  <div className="col-12 mx-auto text-center">
                    <h2 style={{ fontWeight: 700 }} className="text-success">
                      ¡Viabilidad Cargada con Exito!
                    </h2>
                  </div>
                </div>
                <div className="row mt-5">
                  <div className="col-12 d-flex text-center">
                    <div className="container shadow px-5 py-4">
                      <div className="row text-start">
                        <div className="col-12">
                          <h4 className="text-primary text-bold mb-3">
                            KeepSmiling Full 1er Etapa
                          </h4>
                        </div>
                        <div className="col-3">
                          <p>Doctor:</p>
                          <h5>{this.state.odontologo}</h5>
                        </div>
                        <div className="col-3">
                          <p>Paciente:</p>
                          <h5>
                            {this.state.nombre + " " + this.state.apellido}
                          </h5>
                        </div>
                        <div className="col-3">
                          <p>Fecha de Escaneo:</p>
                          <h5>31/03/2021</h5>
                        </div>
                      </div>
                      <hr />
                      <div className="row text-start">
                        <div className="col-3">
                          <p>Fotos:</p>
                          <div className="d-flex flex-row flex-wrap">
                            <img
                              style={
                                this.state.frontal_reposo !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={frontalreposo}
                              alt=""
                            />
                            <img
                              style={
                                this.state.frontal_sonriendo !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={frontalsonriendo}
                              alt=""
                            />
                            <img
                              style={
                                this.state.perfil_izq_reposo !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={perfilizqreposo}
                              alt=""
                            />
                            <img
                              style={
                                this.state.trescuartos_perfil !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={trescuartos_perfil}
                              alt=""
                            />
                            <img
                              style={
                                this.state.oclusal_maxilar_sup !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={oclusalmaxilarsup}
                              alt=""
                            />
                            <img
                              style={
                                this.state.oclusal_maxilar_inf !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={oclusalmaxilarinf}
                              alt=""
                            />
                            <img
                              style={
                                this.state.anterior_oclusion !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={anterioroclusion}
                              alt=""
                            />
                            <img
                              style={
                                this.state.bucal_derecho !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={bucalderecho}
                              alt=""
                            />
                            <img
                              style={
                                this.state.bucal_izquierdo !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={bucalizquierdo}
                              alt=""
                            />
                            <img
                              style={
                                this.state.anterior_inoclusion !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={anteriorinoclusion}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-1">
                          <p>Estudios:</p>
                          <div className="d-flex flex-colum flex-wrap justify-content-left">
                            <img
                              style={
                                this.state.rxpanoramica !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={rxpanoramica}
                              alt=""
                            />
                            <img
                              style={
                                this.state.telerradiografia !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={radioperfil}
                              alt=""
                            />
                            <img
                              style={
                                this.state.ricketts !== undefined
                                  ? { maxWidth: "50px", margin: "2px" }
                                  : {
                                      maxWidth: "50px",
                                      margin: "2px",
                                      opacity: ".5",
                                    }
                              }
                              src={ricketts}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-2">
                          <p>Plan de tratamiento:</p>
                          <div className="w-100 h-100 border border-1"></div>
                        </div>
                        <div className="col-2">
                          <p>Envio de registro:</p>
                          <div className="w-100 h-100 border border-1"></div>
                        </div>
                        <div className="col-4 px-2">
                          <p>Etiquetas:</p>
                          <div className="">
                            <p className="border border-1 border-danger rounded text-danger py-2 px-5 w-100 text-center my-2">
                              Faltan fotos
                            </p>
                            <p className="border border-1 border-danger rounded text-danger py-2 px-5 w-100 text-center my-2">
                              Faltan estudios
                            </p>
                            <p className="border border-1 border-danger rounded text-danger py-2 px-5 w-100 text-center my-2">
                              Falta Plan de tratamiento
                            </p>
                          </div>
                          <p>Observaciones:</p>
                          <div className="">
                            <textarea
                              name=""
                              id=""
                              cols="45"
                              rows="5"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="content container">
            <div className="row">
              <div className="col-12">
                <h3>Cargar datos de Tratamiento</h3>
              </div>
              <div className="col-6 mx-auto">
                <div className="nuevo_paciente hide">
                  <div className="form-group">
                    <label className="desc" htmlFor="t_nombre">
                      {" "}
                      Nombre{" "}
                    </label>
                    <input
                      name="t_nombre"
                      id="t_nombre"
                      type="text"
                      tabindex="1"
                      maxlength="100"
                      className="field text medium form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label className="desc" htmlFor="t_apellido">
                      {" "}
                      Apellido{" "}
                    </label>
                    <input
                      name="t_apellido"
                      id="t_apellido"
                      type="text"
                      tabindex="2"
                      maxlength="100"
                      className="field text medium form-control"
                    />
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="desc" htmlFor="genero">
                        {" "}
                        Género
                      </label>

                      <select
                        name="genero"
                        id="genero"
                        tabindex="3"
                        className="field text form-control"
                      >
                        <option value="" disable>
                          Seleccionar...
                        </option>
                        <option value="M">Masculino</option>
                        <option value="F">Femenino</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="desc" htmlFor="edad">
                        {" "}
                        Edad{" "}
                      </label>
                      <input
                        name="edad"
                        id="edad"
                        type="number"
                        tabindex="1"
                        maxlength="100"
                        className="field text medium form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="desc" htmlFor="maxilar">
                      {" "}
                      Maxilar
                    </label>

                    <select
                      name="maxilar"
                      id="maxilar"
                      tabindex="4"
                      className="field text form-control"
                    >
                      <option value="" disable>
                        Seleccionar...
                      </option>
                      <option value="1">Superior</option>
                      <option value="2">Inferior</option>
                      <option value="3">Ambos</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label className="desc" htmlFor="etapa_select">
                      {" "}
                      Tratamiento
                    </label>
                    <select
                      name="etapa_select"
                      id="etapa_select"
                      tabindex="5"
                      className="field text form-control"
                    >
                      <option value="" disable>
                        Seleccionar...
                      </option>
                      <option value="1">Full</option>
                      <option value="4">Fast</option>
                      <option value="7">Medium</option>
                      <option value="8">Kid Full</option>
                      <option value="11">Kid Fast</option>
                    </select>
                  </div>
                </div>
                <div
                  className="form-group buttons-container"
                  style={{ width: "100%" }}
                >
                  <button
                    id="btnGuardar"
                    className="btn btn-primary btn-submit"
                    type="button"
                    style={{ width: "100%" }}
                  >
                    Iniciar Solicitud
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
    }
  }
}
