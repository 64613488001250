import React, {useState} from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment'

import SCIcon from './smile_creator_icons'
import apiClient from '../../../utils/backend';
import { PlayCircle } from 'react-feather';

function PaymentsCard({external_id, doctor, patient, sc_type, created_at, end_time, treatment_name, maxilla, video_restrict, payment_status,status, id, start, index, discount_value, cost_value, finalcost_value}) {

  const [discount, setDiscount] = useState(discount_value)
  const [cost, setCost] = useState(cost_value)
  const [totalCost, setTotalCost] = useState(null)
  const [restrict, setRestrict] = useState(video_restrict === 1 ? true : false)

  function startPayment() {
    if(discount !== null /*&& discount > 0*/ && cost !== null){

      let finalCost;

      if(discount == 0) {
        console.log("sin descuento")
       finalCost = cost
      } else {
        console.log("con descuento")
       finalCost = cost - (cost * discount) / 100
      }

      start(id, index, restrict, discount, cost, finalCost)
    }
  }

  return (
    <div className="row" style={{paddingLeft: '15px'}}>
        <div className="col d-flex flex-row justify-content-center">
          <p className="mb-0">{patient}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <p className="mb-0">{discount_value}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <p className="mb-0">{cost_value}</p>
        </div>
        <div className="col d-flex flex-row justify-content-center">
          <p className="mb-0">{finalcost_value}</p>
        </div>
      <div className="col d-flex flex-row justify-content-center">

      </div>
    </div>
  )
}

export default PaymentsCard