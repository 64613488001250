import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import { HelpCircle, AlertCircle } from 'react-feather'
import './index.css'

export const TreatmentAdminStepOne = ({ state, setState }) => {
  const [patients, setPatients] = useState([])

  const maxillaOptions = [
    { label: 'Superior', value: 1 },
    { label: 'Inferior', value: 2 },
    { label: 'Ambos', value: 3 },
  ]

  const genderOptions = [
    { label: 'Masculino', value: 'm' },
    { label: 'Femenino', value: 'f' },
    { label: 'Otro', value: 'o' },
  ]

  const selectStyle = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: 'none',
      borderBottom: '2px solid #0027a8!important',
    }),
  }

  const handleChangeTreatment = (id) => {
    setState({ ...state, treatment_type: id })
  }

  const showMaxillaAlert = () => {
    if (!state.selected_patient || !state.maxilla) return
    if (state.selected_patient.maxilarNumber !== state.maxilla.value)
      return (
        <div className="col-12 d-flex justify-content-end">
          <div className="alert alert-danger d-flex p-2">
            <span>
              <AlertCircle size={15} />
            </span>
            <p className="fs-6 ms-1">
              Recuerde que si cambia el
              <br /> maxilar a tratar, esta indicando
              <br /> que el paciente modifica en
              <br /> esta etapa el maxilar que trato
              <br /> en su etapa anterior.
            </p>
          </div>
        </div>
      )
  }

  useEffect(() => {
    if (!state.odontologo) return
    let array = []
    let treatType = null
    state.odontologo.patients.map((patient) => {
      treatType = patient.last_treatment.type.id
      if (treatType === 1 || treatType === 2 || treatType === 6) {
        array.push({
          label: patient.name + ' ' + patient.surname,
          value: patient.id,
          name: patient.name,
          surname: patient.surname,
          genero: patient.gender,
          maxilarString: patient.last_treatment.maxilla,
          maxilarNumber: patient.last_treatment.maxilla_number,
          treatmentType: patient.last_treatment.type.id,
          treatmentStage: patient.last_treatment.order,
          patientImage: patient.last_treatment?.frontal_sonriendo,
          id: patient.id,
        })
      }
    })
    setPatients(array)
  }, [state.odontologo])

  return (
    <>
      <div style={{ height: '100%' }}>
        <div className="container mt-6">
          <div className="row mt-4">
            <div className="col-12 mx-auto">
              <form className="row mb-4 col-6 mx-auto">
                <div className="row">
                  <div className="col-8">
                    <label className="fs-5 mb-2" htmlFor="odontologo">
                      Odontologo
                    </label>
                    <Select
                      value={state.odontologo}
                      styles={selectStyle}
                      options={state.od_list}
                      placeholder="Seleccionar dentista.."
                      onChange={(e) => {
                        setState({
                          ...state,
                          odontologo: e,
                          dentist_id: e.value,
                          selected_patient: null,
                          nombre: '',
                          apellido: '',
                          external_id: '',
                          genero: '',
                          maxilla: null,
                          etapa: 1,
                          treatment_type: 1,
                        })
                      }}
                    />
                    <span style={{ color: 'red' }}>
                      {state.errors['dentist_id']}
                    </span>
                  </div>
                  <div className="col-4 d-flex align-items-center">
                    <input
                      checked={state.is_new_patient ? false : true}
                      className="checkbox_input mt-3 rounded"
                      type="checkbox"
                      id="new_patient"
                      onClick={(e) => {
                        setState({
                          ...state,
                          is_new_patient: !state.is_new_patient,
                          nombre: '',
                          apellido: '',
                          external_id: '',
                          genero: '',
                          maxilla: null,
                          maxilarString: '',
                          selected_patient: null,
                          gender_select: null,
                          treatment_type: 1,
                          etapa: 1,
                        })
                      }}
                    />
                    <label className="mt-3 ms-2" htmlFor="new_patient">
                      Usar paciente existente
                    </label>
                  </div>
                </div>
              </form>
            </div>
            {!state.is_new_patient ? (
              <div className="col-12 mx-auto">
                <form className="row col-6 mx-auto mb-5">
                  <div className="col-12 mb-2">
                    <h4>Paciente existente</h4>
                  </div>
                  <div className="col-4">
                    <p>Id Externo</p>
                    <input
                      type="text"
                      className="form-control"
                      value={state.external_id}
                      onChange={(e) =>
                        setState({ ...state, external_id: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-8">
                    <p>Nombre y Apellido</p>
                    <Select
                      styles={selectStyle}
                      options={patients}
                      placeholder="Seleccionar Paciente"
                      value={state.selected_patient}
                      onChange={(e) => {
                        setState({
                          ...state,
                          selected_patient: e,
                          nombre: e.name,
                          apellido: e.surname,
                          genero: e.genero,
                          etapa: e.treatmentStage + 1,
                          treatment_type: e.treatmentType,
                          maxilla: {
                            label: e.maxilarString,
                            value: e.maxilarNumber,
                          },
                          maxilar: Number(e.maxilarNumber),
                        })
                      }}
                    />
                  </div>
                </form>
                <form className="row col-6 mx-auto">
                  <div className="col-12 mb-2 d-flex">
                    <h4 className="me-2">Tratamiento</h4>
                    <div data-tip data-for="treatment_tooltip">
                      <HelpCircle size={22} className="mt-1" />
                    </div>
                    {/* LOS TOOLTIP SOLO DEBEN MOSTRARSE COMO ODONTOLOGO */}
                    <ReactTooltip id="treatment_tooltip">
                      <div className="col-6 d-flex flex-row">
                        <p className="fs-3 fw-bold">?</p>
                        <p className="mt-2 ms-1">
                          Ayuda <span className="fw-bold">KeepSmiling</span>
                        </p>
                      </div>
                      <div>
                        <p>Tipo de Tratamiento</p>
                        <p>
                          Si tiene dudas del tipo de tratamiento que requiere su
                          paciente,
                          <br /> entonces debe, primero, enviar una solicitud de
                          Viabilidad de
                          <br /> tratamiento. En la cual, el equipo de
                          KeepSmiling evaluara la
                          <br /> documentacion de su paciente y le aconsejara
                          que tipo de
                          <br />
                          tratamiento corresponde al caso de su paciente.
                        </p>
                      </div>
                    </ReactTooltip>
                  </div>
                  {state.treatment_type === 1 && (
                    <div className="col-6 mt-2">
                      <div
                        className={
                          'btn w-100 my-2 rounded-pill scanButton btn-outline-primary active'
                        }
                      >
                        KeepSmiling Full
                      </div>
                    </div>
                  )}
                  {state.treatment_type === 2 && (
                    <div className="col-6 mt-2">
                      <div
                        className={
                          'btn w-100 my-2 rounded-pill scanButton btn-outline-warning active'
                        }
                      >
                        KeepSmiling Kids
                      </div>
                    </div>
                  )}
                  {state.treatment_type === 3 && (
                    <div className="col-6 mt-2">
                      <div
                        className={
                          'btn w-100 my-2 rounded-pill scanButton btn-outline-secondary active'
                        }
                      >
                        KeepSmiling Fast
                      </div>
                    </div>
                  )}
                  {state.treatment_type === 4 && (
                    <div className="col-6 mt-2">
                      <div
                        className={
                          'btn w-100 my-2 rounded-pill scanButton btn-outline-danger active'
                        }
                      >
                        KeepSmiling Kids Fast
                      </div>
                    </div>
                  )}
                  {state.treatment_type === 5 && (
                    <div className="col-6 mt-2">
                      <div
                        className={
                          'btn w-100 my-2 rounded-pill scanButton btn-outline-info active'
                        }
                      >
                        KeepSmiling Medium
                      </div>
                    </div>
                  )}
                  {state.treatment_type === 6 && (
                    <div className="col-6 mt-2">
                      <div
                        className={
                          'btn w-100 my-2 rounded-pill scanButton btn-outline-yellow active'
                        }
                      >
                        KeepSmiling Teen
                      </div>
                    </div>
                  )}
                  <div className="col-6">
                    <form className="d-flex col-12">
                      <div className="col-3">
                        <label htmlFor="etapa">Etapa</label>
                        <select
                          value={state.etapa}
                          disabled
                          name="etapa"
                          id="etapa"
                          className="form-control"
                        >
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                      <div className="col-8 ms-3">
                        <label htmlFor="maxilar">Maxilar</label>
                        <Select
                          styles={selectStyle}
                          options={maxillaOptions}
                          isSearchable
                          value={state.maxilla}
                          placeholder="Seleccionar Maxilar.."
                          onChange={(e) =>
                            setState({
                              ...state,
                              maxilla: e,
                              maxilar_string: e.label,
                              maxilar: Number(e.value),
                            })
                          }
                        />
                      </div>
                    </form>
                    {showMaxillaAlert()}
                  </div>
                </form>
              </div>
            ) : (
              <div className="col-12 mx-auto">
                <form className="row col-6 mx-auto">
                  <div className="col-12 d-flex flex-column mt-3">
                    <label className="fs-5 mt-2 mb-2" htmlFor="paciente">
                      Paciente
                    </label>
                    <label htmlFor="external_id">Id Externo</label>
                    <div className="col-3">
                      <input
                        value={state.external_id}
                        type="text"
                        className="form-control"
                        id="external_id"
                        onChange={(e) =>
                          setState({ ...state, external_id: e.target.value })
                        }
                      />
                      <span style={{ color: 'red' }}>
                        {state.errors['external_id']}
                      </span>
                    </div>
                  </div>
                  <div className="col-6">
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      value={state.nombre}
                      type="text"
                      className="form-control"
                      id="nombre"
                      onChange={(e) =>
                        setState({ ...state, nombre: e.target.value })
                      }
                    />
                    <span style={{ color: 'red' }}>{state.errors['name']}</span>
                  </div>
                  <div className="col-6">
                    <label htmlFor="apellido">Apellido</label>
                    <input
                      value={state.apellido}
                      type="text"
                      className="form-control"
                      id="apellido"
                      onChange={(e) =>
                        setState({ ...state, apellido: e.target.value })
                      }
                    />
                    <span style={{ color: 'red' }}>
                      {state.errors['surname']}
                    </span>
                  </div>
                </form>
                <div className="row mb-4 col-6 mx-auto">
                  <div className="col-6">
                    <label>Género</label>
                    <Select
                      styles={selectStyle}
                      options={genderOptions}
                      placeholder="Seleccionar Género..."
                      value={state.gender_select}
                      onChange={(e) => {
                        setState({
                          ...state,
                          gender_select: e,
                          genero: e.value,
                        })
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <div className="d-flex">
                      <label htmlFor="maxilar" className="me-1">
                        Maxilar
                      </label>
                      <div data-tip data-for="maxilar_tooltip">
                        <HelpCircle size={18} />
                      </div>
                    </div>
                    <ReactTooltip id="maxilar_tooltip">
                      <div className="col-6 d-flex flex-row">
                        <p className="fs-3 fw-bold">?</p>
                        <p className="mt-2 ms-1">
                          Ayuda <span className="fw-bold">KeepSmiling</span>
                        </p>
                      </div>
                      <div>
                        <p>Maxilar</p>
                        <p>
                          Debe indicar el maxilar a tratar. Superior, inferior o
                          ambos. Si
                          <br /> tiene dudas sobre la viabilidad del tratamiento
                          sobre un
                          <br /> maxilar, debe enviar antes una consulta de
                          Viabilidad. En la
                          <br /> cual, el equipo KeepSmiling evaluara la
                          documentacion de
                          <br /> su paciente y le podrá aconsejar.
                        </p>
                      </div>
                    </ReactTooltip>
                    <Select
                      styles={selectStyle}
                      options={maxillaOptions}
                      isSearchable
                      value={state.maxilla}
                      placeholder={'Seleccionar Maxilar..'}
                      onChange={(e) =>
                        setState({
                          ...state,
                          maxilla: e,
                          maxilar_string: e.label,
                          maxilar: Number(e.value),
                        })
                      }
                    />
                    <span style={{ color: 'red' }}>
                      {state.errors['gender']}
                    </span>
                  </div>
                </div>
                <form className="row col-6 mx-auto">
                  <div className="col-12 mb-2">
                    <div className="d-flex flex-row">
                      <h4 className="me-2">Tratamiento</h4>
                      <div data-tip data-for="treatment_tooltip">
                        <HelpCircle size={22} className="mt-1" />
                      </div>
                      {/* LOS TOOLTIP SOLO DEBEN MOSTRARSE COMO ODONTOLOGO */}
                      <ReactTooltip id="treatment_tooltip">
                        <div className="col-6 d-flex flex-row">
                          <p className="fs-3 fw-bold">?</p>
                          <p className="mt-2 ms-1">
                            Ayuda <span className="fw-bold">KeepSmiling</span>
                          </p>
                        </div>
                        <div>
                          <p>Tipo de Tratamiento</p>
                          <p>
                            Si tiene dudas del tipo de tratamiento que requiere
                            su paciente,
                            <br /> entonces debe, primero, enviar una solicitud
                            de Viabilidad de
                            <br /> tratamiento. En la cual, el equipo de
                            KeepSmiling evaluara la
                            <br /> documentacion de su paciente y le aconsejara
                            que tipo de
                            <br />
                            tratamiento corresponde al caso de su paciente.
                          </p>
                        </div>
                      </ReactTooltip>
                    </div>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        handleChangeTreatment(1)
                      }}
                      className={` btn w-100 my-2 rounded-pill btn-outline-primary ${
                        state.treatment_type === 1 && 'active'
                      }`}
                    >
                      KeepSmiling Full
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        handleChangeTreatment(2)
                      }}
                      className={` btn w-100 my-2 rounded-pill btn-outline-warning ${
                        state.treatment_type === 2 && 'active'
                      }`}
                    >
                      KeepSmiling Kids
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        handleChangeTreatment(3)
                      }}
                      className={` btn w-100 my-2 rounded-pill btn-outline-secondary ${
                        state.treatment_type === 3 && 'active'
                      }`}
                    >
                      KeepSmiling Fast
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        handleChangeTreatment(4)
                      }}
                      className={` btn w-100 my-2 rounded-pill btn-outline-danger ${
                        state.treatment_type === 4 && 'active'
                      }`}
                    >
                      KeepSmiling Kids Fast
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={(e) => {
                        e.preventDefault()
                        handleChangeTreatment(5)
                      }}
                      className={` btn w-100 my-2 rounded-pill btn-outline-info ${
                        state.treatment_type === 5 && 'active'
                      }`}
                    >
                      KeepSmiling Medium
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      onClick={(e) => {
                        e.preventDefault()

                        handleChangeTreatment(6)
                      }}
                      className={` btn w-100 my-2 rounded-pill btn-outline-yellow ${
                        state.treatment_type === 6 && 'active'
                      }`}
                    >
                      KeepSmiling Teen
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
