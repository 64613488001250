import React from 'react'
import {useHistory} from 'react-router-dom'

function PatientCard(props) {
    const history = useHistory()

    return (
        <div className="row">
            <div className="col-3 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.patient}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.treatment}</p>
            </div>
            <div className="col-1 d-flex flex-row justify-content-center">
            <p className="mb-0" style={{lineHeight: '38px'}}>{props.phase}</p>
            </div>
            <div className="col-4 d-flex flex-row justify-content-center">
            <p className="mb-0 text-danger" style={{lineHeight: '38px'}}>{props.pendings}</p>
            </div>
            <div className="col-2 d-flex flex-row justify-content-center px-2">
                {props.pendings.length > 0 && <button className="btn btn-outline-primary w-100 rounded-pill" onClick={() => history.push(`/od/documentacion/cargar/${props.id}`)} >Completar</button>}
            </div>
            <div className="col-1 d-flex flex-row justify-content-center px-2">
                <button className="btn btn-outline-primary w-100 rounded-pill" onClick={() => history.push(`/od/pacientes/${props.patient_id}/historiaclinica`)} >Ver</button>
            </div>
        </div>
      )
}

export default PatientCard