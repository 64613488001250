import React from 'react'
import './styles.css'

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export default function PaymentProcess({payment_status}) {
  return (
    <div style={{width: '100%', height: 'auto'}}>
        <CircularProgressbar
          value={payment_status}
          text={`${payment_status}%`}
          strokeWidth={15}
          styles={buildStyles({
            pathColor:
              payment_status < 50
                ? "#e74c3c"
                : payment_status >= 50 && payment_status < 100
                ? "#f1c40f"
                : "#2ecc71",
            textColor:
              payment_status === 0 ? "#d6d6d6" : payment_status < 50
                ? "#e74c3c"
                : payment_status >= 50 && payment_status < 100
                ? "#f1c40f"
                : "#2ecc71",
            textSize: 26,
            
          })}
        />
        </div>
  )
}
