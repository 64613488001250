import React from 'react';
import { ChevronLeft } from 'react-feather';

export const ResetPassword = (props) => {
    const {
        setView,
        error,
        handleInputChange,
        forgotEmail,
        handleRecoverPassword
    } = props;

        return (
            <>
                <div className="w-100 mb-5">
                    <a href="#" className="mx-5 mb-2" onClick={() => setView('login')}><small className="text-muted cursor-pointer"><ChevronLeft size={14} /> Volver</small></a>
                    <p className="h1 fw-bold text-primary mx-5">Recuperar contraseña</p>
                </div>
                {/* {error.error ? <div className="alert alert-danger text-center" role="alert">{error.message}</div> : null} */}
                <form action="" onSubmit={(e) => handleRecoverPassword(e)}>
                    <div className="mb-3 mx-5">
                    <label htmlFor="exampleFormControlInput1" className="form-label">Email o usuario</label>
                    <input type="email" name="email" onChange={(e) => handleInputChange(e)} className="form-control" id="exampleFormControlInput1" placeholder="ejemplo@keepsmiling.com.ar" value={forgotEmail} />
    
                    </div>
                <div className="w-100 d-flex justify-content-center mt-5">
                    <button className="btn btn-primary rounded-pill px-5 w-50" type="submit" >Enviar</button>
                </div>
                </form>
            </>
        )
}
