import React from 'react'

export const Borrar = ({value, color}) => {
  return <button className="btn rounded-pill" style={{minWidth: '60px', maxWidth: '100px', width: '100%', fontSize: '.7rem',backgroundColor: color, color: '#fff', textAlign: 'center'}}>{value}</button>
}

export const Tomar = ({value, color}) => {
  return <button className="btn rounded-pill" style={{minWidth: '60px', maxWidth: '100px', width: '100%', fontSize: '.7rem',backgroundColor: color, color: '#fff', textAlign: 'center'}}>{value}</button>
}

export const OK = ({value, color}) => {
  return <button className="btn rounded-pill" style={{minWidth: '60px', maxWidth: '100px', width: '100%', fontSize: '.7rem',backgroundColor: color, color: '#fff', textAlign: 'center'}}>{value}</button>
}

export const Cargar = ({value, color}) => {
  return <button className="btn rounded-pill" style={{minWidth: '60px', maxWidth: '100px', width: '100%', fontSize: '.7rem',backgroundColor: color, color: '#fff', textAlign: 'center'}}>{value}</button>
}

export const Finalizar = ({value, color}) => {
  return <button className="btn rounded-pill" style={{minWidth: '60px', maxWidth: '100px', width: '100%', fontSize: '.7rem',backgroundColor: color, color: '#fff', textAlign: 'center'}}>{value}</button>
}