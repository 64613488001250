import React from "react";
import moment from "moment";
import StepCounter from "./step_counter";

export default function NotificationCard({ data, user }) {
  return (
    <div
      className="container-fluid py-4 mt-3 border border-1 shadow"
      style={{ borderRadius: "20px", overflow: "hidden" }}
    >
      <div className="row m-0 w-100">
        <div
          className="col-6 border-end border-3"
          style={{ paddingRight: "30px" }}
        >
          <div className="row d-flex align-items-center">
            <div className="col-2 px-2">
              <div
                className="rounded-circle border border-2, mx-auto"
                style={{ width: "50px", height: "50px" }}
              ></div>
            </div>
            <div className="col-7 d-flex flex-column">
              <small>{user}</small>
              <p className="fw-bold">{data.notification.title}</p>
            </div>
            <div className="col-3">
              <p className="text-primary text-end mb-0 mt-2">
                {moment(data.created_at).format("DD/MM/YYYY")}
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-2"></div>
            <div className="col-10">
              <p className="text-muted lh-normal">
                {data.notification.description}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-end">
              <a
                href={`/od/pacientes/${data.treatment.patient.id}/historiaclinica`}
                className="btn btn-outline-primary px-4 rounded-pill"
              >
                Ver historia clinica
              </a>
            </div>
          </div>
        </div>
        <div className="col-6">
          <p className="fw-bold fs-5 mx-3 mt-2">Estado del tratamiento:</p>
          <StepCounter step={data.notification.id} />
        </div>
      </div>
    </div>
  );
}
