import React from 'react'
import { connect } from 'react-redux'

import Full from '../../../assets/images/full.jpeg'
import Fast from '../../../assets/images/fast.jpeg'
import Medium from '../../../assets/images/medium.jpeg'
import KidsFast from '../../../assets/images/kids-fast.jpeg'
import KidsFull from '../../../assets/images/kids-full.jpeg'
import Teens from '../../../assets/images/teens.jpeg'
import '../styles/index.css'
import moment from 'moment'

function TreatmentsCard(props) {
  const maxilla = ['Inferior', 'Superior', 'Ambos']

  function getTreatmentFlag() {
    const components = {
      Full: Full,
      Fast: Fast,
      Medium: Medium,
      'Kids Fast': KidsFast,
      'Kids Full': KidsFull,
      Teens: Teens,
    }

    return components[props.treatment_name]
  }

  if (props.filter === 'all') {
    return (
      <div style={{ paddingLeft: '15px', position: 'relative' }}>
        <img
          src={getTreatmentFlag()}
          alt="Treatment flag"
          style={{
            width: '50px',
            position: 'absolute',
            top: '-10px',
            right: 0,
          }}
        />
        <div>
          <p className="tc-small">Doctor:</p>
        </div>
        <div>
          <p className="fw-bold">{props.doctor}</p>
        </div>
        <div className="row mt-3">
          <div className="col-3 d-flex flex-column">
            <small className="tc-small">Paciente:</small>
            <p className="tc-p">{props.patient}</p>
          </div>
          <div className="col-3 d-flex flex-column">
            <small className="tc-small">Maxilar:</small>
            <p className="tc-p">{maxilla[props.maxillary]}</p>
          </div>
          <div className="col-3 d-flex flex-column">
            <small className="tc-small">Fecha de solicitud:</small>
            <p className="tc-p">
              {moment(props.request_time).format('DD-MM-YYYY')}
            </p>
          </div>
          <div className="col-3 d-flex flex-column">
            <small className="tc-small">
              {props.type === 'yeso' ? 'Fecha retiro:' : 'Fecha escaneo:'}
            </small>
            <p className="tc-p">03/01/2021</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={{ paddingLeft: '15px', position: 'relative' }}>
      <img
        src={getTreatmentFlag()}
        alt="Treatment flag"
        style={{ width: '50px', position: 'absolute', top: '-10px', right: 0 }}
      />
      <div className="row">
        <p className="mb-1 fs-6 fw-bold">
          {props.treatment_name} - {props.treatment_phase}° Etapa
        </p>
      </div>
      <div className="row ">
        <small className="tc-small">ID Externo: {props.external_id}</small>
      </div>
      <div className="row mt-3">
        <div className="col-3 d-flex flex-column">
          <small className="tc-small">Doctor:</small>
          <p className="tc-p">{props.doctor}</p>
        </div>
        <div className="col-3 d-flex flex-column">
          <small className="tc-small">Paciente:</small>
          <p className="tc-p">{props.patient}</p>
        </div>
        <div className="col-2 d-flex flex-column">
          <small className="tc-small">Fecha de solicitud:</small>
          <p className="tc-p">
            {moment(props.request_time).format('DD-MM-YYYY')}
          </p>
        </div>
        <div className="col-2 d-flex flex-column">
          <small className="tc-small">
            {props.type === 'intraoral'
              ? 'Fecha 1er escaneo:'
              : 'Fecha 1er retiro'}
          </small>
          <p className="tc-p">03/01/2021</p>
        </div>
        <div className="col-2 d-flex flex-column">
          <small className="tc-small">
            {props.type === 'intraoral'
              ? 'Fecha 2do escaneo:'
              : 'Fecha 2do retiro'}
            :
          </small>
          <p className="tc-p">03/01/2021</p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    login_token: state.userReducer.login_token,
  }
}

export default connect(mapStateToProps)(TreatmentsCard)
