export let type_dispatch={ step:'', field:'' }

export const mapStateToProps = (state) => {
    return state
}
export const mapDispatchToProps = (dispatch) => {
    return {
        updateState: (payload) => {
            dispatch({ type: type_dispatch.step, field:type_dispatch.field, payload })
        },
        updateErrors: (payload) => {
            dispatch({ type: 'errors_form_online', payload })
        },
        clearState: (payload) => {
            dispatch({ type: 'clear_form_online', payload })
        },
        setState: (payload) => {
            dispatch({ type: 'set_form_online', payload })
        },
        enable: (payload) => {
            dispatch({ type: 'enable', payload })
        },
    }
}