import React, { useEffect, useState } from 'react'
import { Modal, ModalFooter, ModalBody } from 'reactstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { MapPin } from 'react-feather'

import directionimg from '../../../../assets/images/direcciones.png'

const DirectionModal = (props) => {
  const { handleAddDir, handleEditDir, edit, direction, index } = props

  const [loading, setLoading] = useState(false)
  const [opened, setOpened] = useState(false)

  const [data, setData] = useState({
    type: { value: null, required: true },
    alias: { value: null, required: true },
    street_address: { value: null, required: true },
    street_number: { value: null, required: true },
    floor: { value: null, required: false },
    apartment: { value: null, required: false },
    location: { value: null, required: true },
    zip_code: { value: null, required: false },
    province: { value: null, required: true },
    city: { value: null, required: true },
    phone_number: { value: null, required: false },
    service_hours: { value: null, required: false },
    secretary_name: { value: null, required: false },
    observation: { value: null, required: false },
    prefix: { value: 54, required: false },
    sufix: { value: 15, required: false },
    area_code: { value: null, required: false },
    show_web: { value: null, required: false },
    is_edit: { value: edit ? true : false },
  })

  const [error, setError] = useState({})

  useEffect(() => {
    if (edit) {
      const keys = Object.keys(direction)
      const values = Object.values(direction)

      let newDirection = { ...data }

      keys.forEach((key, i) => {
        let item = newDirection[key]

        if (item !== undefined) {
          item.value = values[i]
        }
      })

      setData(newDirection)
    }
  }, [edit, direction])

  function handleChange(e) {
    if (e.target.name === 'show_web') {
      setData({
        ...data,
        show_web: {
          required: false,
          value: e.target.checked,
        },
      })
    } else {
      if (e.target.id === 'zip_code') {
        setData({
          ...data,
          zip_code: {
            required: false,
            value: e.target.value,
          },
        })
      }
      let input = data[e.target.id]
      input.value = e.target.value
      setData({ ...data, [e.target.id]: input })
    }
  }

  const handleValidation = () => {
    const keys = Object.keys(data)
    const values = Object.values(data)
    const errors = {}
    values.map((value, index) => {
      if (value.required) {
        if (value.value === null || value.value === undefined || !value.value) {
          errors[keys[index]] = true
        } else {
          errors[keys[index]] = false
        }
      }
    })
    setError(errors)
    const validation = Object.values(errors)
    const hasError = validation.includes(true)
    if (hasError) {
      return toast.error(
        '🤔 No se pudo agregar la direccion, complete los datos por favor',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      )
    } else {
      let dirData = {}

      keys.map((key, index) => {
        dirData[key] = values[index].value
      })


      if (edit) {
        handleEditDir(dirData, index, direction.id)
      } else {
        handleAddDir(dirData)
      }
      setError({})
      setOpened(false)
      clearForm()
    }
  }

  const clearForm = () => {
    setData({
      type: { value: null, required: true },
      alias: { value: null, required: true },
      street_address: { value: null, required: true },
      street_number: { value: null, required: true },
      floor: { value: null, required: false },
      apartment: { value: null, required: false },
      location: { value: null, required: true },
      zip_code: { value: null, required: false },
      province: { value: null, required: true },
      city: { value: null, required: true },
      phone_number: { value: null, required: false },
      service_hours: { value: null, required: false },
      secretary_name: { value: null, required: false },
      observation: { value: null, required: false },
      prefix: { value: 54, required: false },
      sufix: { value: 15, required: false },
      area_code: { value: null, required: false },
      is_edit: { value: edit ? true : false },
    })
  }

  let directionTypes = [
    { label: 'Consultorio', value: 'Consultorio' },
    { label: 'Casa', value: 'Casa' },
    { label: 'Otros', value: 'Otro' },
  ]

  let directionType = directionTypes.find(
    (item) => item.value === data.type.value
  )

  return (
    <div className="h-100">
      {edit && (
        <button
          onClick={() => setOpened(true)}
          className="btn bg-none d-flex flex-column justify-content-center text-center align-items-middle"
        >
          <span className="fs-6 mt-2 text-center w-100 text-muted">
            Editar dirección
          </span>
        </button>
      )}

      {!edit && (
        <button
          onClick={() => setOpened(true)}
          className="w-100 h-100 btn bg-none d-flex flex-column justify-content-center text-center align-items-middle"
        >
          <MapPin size={36} color="#6c757d" className="mx-auto" />
          <span className="fs-6 mt-2 text-center w-100 text-muted">
            Agregar dirección
          </span>
        </button>
      )}
      {!loading && (
        <Modal isOpen={opened} toggle={() => setOpened(!opened)}>
          <ModalBody>
            <div className="container">
              <h4 className="mb-3 fw-bold text-primary">
                {edit ? 'Editar dirección' : 'Nueva dirección'}
              </h4>
              <div className="row">
                <div className="col-4">
                  <img src={directionimg} alt="" />
                </div>
                <div className="col-8">
                  <form className="row mb-4 mt-2">
                    <div className="col-12">
                      <label htmlFor="type">Tipo de dirección</label>
                      <Select
                        id="type"
                        onChange={(e) => {
                          let input = data.type
                          input.value = e.value
                          return setData({ ...data, type: input })
                        }}
                        options={directionTypes}
                        value={directionType}
                      />
                      {error.type && (
                        <p
                          style={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'light',
                          }}
                        >
                          Este campo es obligatorio.
                        </p>
                      )}
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="alias">Nombre de la dirección</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="alias"
                        onChange={(e) => handleChange(e)}
                        value={data.alias.value}
                      />
                      {error.alias && (
                        <p
                          style={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'light',
                          }}
                        >
                          Este campo es obligatorio.
                        </p>
                      )}
                    </div>
                  </form>
                </div>
                <div className="col-12">
                  <form className="row mb-4 mt-2">
                    <div className="col-4 mt-2">
                      <label htmlFor="street_address">Calle</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="street_address"
                        onChange={(e) => handleChange(e)}
                        value={data.street_address.value}
                      />
                      {error?.street_address && (
                        <p
                          style={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'light',
                          }}
                        >
                          Este campo es obligatorio.
                        </p>
                      )}
                    </div>
                    <div className="col-3 mt-2">
                      <label htmlFor="street_number">Numero</label>
                      <input
                        className="form-control mb-0"
                        type="number"
                        id="street_number"
                        onChange={(e) => handleChange(e)}
                        value={data.street_number.value}
                      />
                      {error?.street_number && (
                        <p
                          style={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'light',
                          }}
                        >
                          Este campo es obligatorio.
                        </p>
                      )}
                    </div>
                    <div className="col-2 mt-2">
                      <label htmlFor="floor">Piso</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="floor"
                        onChange={(e) => handleChange(e)}
                        value={data.floor.value}
                      />
                    </div>
                    <div className="col-3 mt-2">
                      <label htmlFor="apartment">Apartamento</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="apartment"
                        onChange={(e) => handleChange(e)}
                        value={data.apartment.value}
                      />
                    </div>
                    <div className="col-8 mt-2">
                      <label htmlFor="location">Localidad</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="location"
                        onChange={(e) => handleChange(e)}
                        value={data.location.value}
                      />
                      {error?.location && (
                        <p
                          style={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'light',
                          }}
                        >
                          Este campo es obligatorio.
                        </p>
                      )}
                    </div>
                    <div className="col-4 mt-2">
                      <label htmlFor="zip_code">CP</label>
                      <input
                        className="form-control mb-0"
                        type="number"
                        id="zip_code"
                        name="zip_code"
                        onChange={(e) => handleChange(e)}
                        value={data.zip_code.value}
                      />
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="province">Provincia</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="province"
                        onChange={(e) => handleChange(e)}
                        value={data.province.value}
                      />
                      {error?.province && (
                        <p
                          style={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'light',
                          }}
                        >
                          Este campo es obligatorio.
                        </p>
                      )}
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="city">Ciudad</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="city"
                        onChange={(e) => handleChange(e)}
                        value={data.city.value}
                      />
                      {error?.city && (
                        <p
                          style={{
                            fontSize: '12px',
                            color: 'red',
                            fontWeight: 'light',
                          }}
                        >
                          Este campo es obligatorio.
                        </p>
                      )}
                    </div>
                  </form>
                </div>
                <div className="col-12">
                  <form className="row mb-4 mt-2">
                    <div className="col-2 mt-2">
                      <label htmlFor="area_code">Cod. Area</label>
                      <input
                        className="form-control mb-0"
                        type="number"
                        id="area_code"
                        onChange={(e) => handleChange(e)}
                        value={data.area_code.value}
                      />
                    </div>
                    <div className="col-10 mt-2">
                      <label htmlFor="phone_number">Teléfono</label>
                      <input
                        className="form-control mb-0"
                        type="number"
                        id="phone_number"
                        onChange={(e) => handleChange(e)}
                        value={data.phone_number.value}
                      />
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="service_hours">Horario de Atención</label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="service_hours"
                        onChange={(e) => handleChange(e)}
                        value={data.service_hours.value}
                      />
                    </div>
                    <div className="col-6 mt-2">
                      <label htmlFor="secretary_name">
                        Nombre de la secretaría
                      </label>
                      <input
                        className="form-control mb-0"
                        type="text"
                        id="secretary_name"
                        onChange={(e) => handleChange(e)}
                        value={data.secretary_name.value}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <label htmlFor="observation">Observacion</label>
                      <textarea
                        className="form-control"
                        placeholder="Ej: El timbre no funciona bien"
                        id="observation"
                        onChange={(e) => handleChange(e)}
                        value={data.observation.value}
                      />
                    </div>
                  </form>
                </div>
                <div className="col-12 align-items-start">
                  <input
                    type="checkbox"
                    name="show_web"
                    id="show_web"
                    className="mx-2"
                    checked={data.show_web?.value}
                    onChange={(e) => handleChange(e)}
                  />
                  <label htmlFor="show_web">
                    Mostrar ésta dirección en la web
                  </label>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {!loading && (
              <div>
                <button
                  className="btn btn-primary px-5 rounded-pill text-white"
                  onClick={handleValidation}
                >
                  Guardar
                </button>
              </div>
            )}

            {loading && (
              <button class="btn btn-danger" type="button" disabled>
                <span
                  class="spinner-border spinner-border-sm text-white"
                  role="status"
                  aria-hidden="true"
                ></span>
              </button>
            )}
          </ModalFooter>
        </Modal>
      )}
    </div>
  )
}

export default DirectionModal
