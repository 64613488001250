import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import apiClient from '../../../utils/backend'
import { useHistory } from 'react-router'
import moment from 'moment'

import PatientData from '../../../components/patient_data'
import { connect } from 'react-redux';

const RevisionModal = (props) => {
  const {
    className,
    id,
    opened,
    handleModal
  } = props;


  const [contentLoading, setContentLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState()
  const [url, setUrl] = useState()
  const [error, setError] = useState()
  const history = useHistory()

  useEffect(() => {
    handleGetData()
  }, [])

  function handleGetData() {
    console.log(props)

     apiClient.get(`/api/helper/getClinicalHistory/${props.id}`, { withCredentials: true, headers: { Authorization: `Bearer ${props.userReducer.login_token}` } })
      .then(res => {
        console.log(res.data.data.photos_files)
        setData(res.data.data)
      })
      .then(res => setContentLoading(false))
      .catch(err => console.log(err))
  }

  function handleRegistry(){
    apiClient.post('/api/status/nextStatus', {treatment_id: id, url: url, process: 'Registro'})
    .then(res => res.status === 200 ? window.location.reload() : setError('Error intentando pasar de estado el tratamiento.'))
    .catch(err => console.log(err))
  }

  if(contentLoading){
    return (
      <div>
        <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div class="spinner-grow text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Modal isOpen={opened === className ? true : false} toggle={handleModal} className={className}>
          <ModalBody>
            <div className="container">
              <h5 className="fs-6">Registro</h5>
              <PatientData type={data.type} stage={data.stage} id={id} created_at={data.created_at} dentist={data.dentist} patient={data.patient} maxilla={data.maxilla} profile_image={data.photos_files.length > 0 && data.photos_files[0].path !== undefined ? data.photos_files[0].path : null}  />
              <h6 className="mb-3 mt-5 fw-bold text-primary">Cargar link de registro original</h6>
              <div className="row">
                <div className="col-12 d-flex flex-row">
                  <input style={{marginBottom:'auto', width:'70%'}} type="url" name="registro" id="registro" className="form-control w-90" onChange={(e) => setUrl(e.target.value)} />
                  <button className="btn btn-success text-white rounded-pill w-10" onClick={null}>Ok</button>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>

            {!loading &&
              <div>
                <button className="btn border btn-light text-dark mx-2 rounded-pill" onClick={handleModal}>Volver</button>
                <button className="btn btn-success text-white rounded-pill" onClick={handleRegistry}>Guardar</button>
              </div>
            }

            {loading &&
              <button class="btn btn-success" type="button" disabled>
                <span class="spinner-border spinner-border-sm text-white" role="status" aria-hidden="true"></span>
              </button>
            }

          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => state

export default connect(mapStateToProps)(RevisionModal)
