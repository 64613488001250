import React, { useState, useEffect, useRef } from 'react'
import RadioList from '../Utilities/RadioList'
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps, type_dispatch } from '../../propsStateConnect';
import { mantain_keep_options } from '../Utilities/OptionsRadios';
import { useErrorList } from '../Utilities/UseErrorsList';
function MolarRelationship(props){
    type_dispatch.step='InstructionsTratment'
    type_dispatch.field='MolarRelationship'
    const { colors, updateState, updateErrors }= props;
    const middle_state= props.formOnlineReducer[type_dispatch.step][type_dispatch.field];
    const molRelationshipRight= middle_state.right
    const molRelationshipLeft= middle_state.left

    const refObsRight=useRef(null)
    const refObsLeft=useRef(null)
    useEffect(()=>{
        if(molRelationshipRight===2){
            refObsRight.current.focus()
        }
    },[molRelationshipRight])
    
    useEffect(()=>{
        if(molRelationshipLeft===2){
            refObsLeft.current.focus()
        }
    },[molRelationshipLeft])

    const errors_list=props.formOnlineReducer.ErrorsList
    const error1=errors_list.find(err=>err.order===1)
    const error2=errors_list.find(err=>err.order===2)
    useErrorList(errors_list, updateErrors, [molRelationshipRight, molRelationshipLeft])

    return (
        <div>
            <div className="w-100 fw-bolder pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                Relación Molar
            </div>
            <div className="d-flex mt-3">
                <div className="col-6 ms-3">
                    <div className="w-90 fw-bolder small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                        Relación molar derecha
                    </div>
                    {error1 && 
                    <div className="alert-danger alert-link p-2 mt-1 me-2">
                        {error1.message}
                    </div>
                    }
                    <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors} radios={mantain_keep_options}
                    setter={(value)=>updateState({key:'right', value})}
                    stateField={molRelationshipRight} name='molRelationshipRight' />
                    {molRelationshipRight===2 &&
                    <div>
                        <div className="fw-bolder mt-3 small">
                            Puede detallar a continuación:
                        </div>
                        <div className="text-muted mt-2 small">
                            (Recuerde indicar la pieza a la que hace referencia el detalle)
                        </div>
                        <div className="mt-2 col-11">
                            <textarea
                            disabled={!props.formOnlineReducer.Enabled}
                            onChange={(e)=>updateState({key:'rightDescription', value:e.target.value})} 
                            value={middle_state.rightDescription}
                            ref={refObsRight} className="form-control" name="observacion" id="observacion" cols="30" rows="3"></textarea>
                        </div>
                    </div>
                    }
                </div>
                <div className="col-6">
                    <div className="w-100 fw-bolder small pb-1" style={{borderBottom:`1px solid ${colors.color}` }}>
                        Relación molar izquierda
                    </div>
                    {error2 && 
                    <div className="alert-danger alert-link p-2 mt-1">
                        {error2.message}
                    </div>
                    }
                    <RadioList enabled={props.formOnlineReducer.Enabled} colors={colors}radios={mantain_keep_options} 
                    setter={(value)=>updateState({key:'left', value})} 
                    stateField={molRelationshipLeft} name='setMolRelationshipLeft' />
                    {molRelationshipLeft===2 &&
                    <div>
                        <div className="fw-bolder mt-3 small">
                            Puede detallar a continuación:
                        </div>
                        <div className="text-muted mt-2 small">
                            (Recuerde indicar la pieza a la que hace referencia el detalle)
                        </div>
                        <div className="mt-2 col-11">
                            <textarea
                            disabled={!props.formOnlineReducer.Enabled}
                            onChange={(e)=>updateState({key:'leftDescription', value:e.target.value})} 
                            value={middle_state.leftDescription}
                            ref={refObsLeft} className="form-control" name="observacion" id="observacion" cols="30" rows="3"></textarea>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(MolarRelationship)