import React from 'react'
import moment from 'moment'

export default function PatientData(props) {
  console.log('patient_props ', props)
  return (
    <div>
      <div className="row">
        <h3 className="mb-1 mt-3 fs-5 text-primary fw-bold">{props.patient.full_name}</h3>
        <div className={`col-4 w-25 ${!props.profile_image ? ' border':''}`}>
          {/* <div style={{ width: '100%', height: '150px', border: '1px solid #444' }}></div> */}
          <img src={props.profile_image} alt="Profile" style={{ maxWidth: '150px', height: '150px', objectFit: 'cover' }} />
        </div>
        <div className="col-8">
          {props.type &&
          <p className="fs-5 text-primary">KeepSmiling {props.type}</p>
          }
          {props.stage &&
          <p className="fs-5 text-primary">{props.stage} Etapa</p>
          }
          <div className="row">
            <div className="col-12 d-flex">
              <div className="col-4">
                <small>Doctor:</small>
                <p className="fw-bold mt-1">{props.dentist.full_name}</p>
              </div>
              {props.birthdate &&
                <div className="col-4">
                  <small>Fecha de nacimiento:</small>
                  <p className="fw-bold mt-1">{moment(props.birthdate).format('DD/MM/YYYY')}</p>
                </div>
              }
               {props.movements &&
                <div className="col-4">
                  <small>Movimientos:</small>
                  <p className="fw-bold mt-1">{props.movements}</p>
                </div>
              }
            </div>
            <div className="col-12 d-flex flex-row mt-2">
              <div className="col-4">
                <small>Fecha de ingreso:</small>
                <p className="fw-bold mt-1">{moment(props.created_at).format('DD/MM/YYYY')}</p>
              </div>
              <div className="col-4">
                <small>Maxilar:</small>
                <p className="fw-bold mt-1">{props.maxilla}</p>
              </div>
              {props.buttons &&
              <div className="col-2">
                <small>Botones:</small>
                <p className="fw-bold mt-1">{props.buttons}</p>
              </div>
              }
              {props.stripping &&
              <div className="col-2">
                <small>Stripping:</small>
                <p className="fw-bold mt-1">{props.stripping}</p>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
