import React from 'react'

import '../styles/index.css'

export default function TreatmentsCard(props) {
  return (
    <div className={`w-100 shadow border border-1 rounded container-fluid vc-container mt-2 mb-2 py-3 type-${props.treatment_type}`}>
      <div className="row"><p className="mb-1 fs-5 fw-bold">{props.treatment_name}</p></div>
      <div className="row mt-2">
        <div className="col-2 d-flex flex-column">
          <small className="">Doctor:</small>
          <p className="">{props.doctor}</p>
        </div>
        <div className="col-2 d-flex flex-column">
          <small className="">Paciente:</small>
          <p className="">{props.patient}</p>
        </div>
        <div className="col-2 d-flex flex-column">
          <small className="">Fecha de consulta:</small>
          <p className="">{props.request_time}</p>
        </div>
        <div className="col-1 d-flex flex-column">
          <button className={props.fotos === 1 ? "btn btn-primary btn-sm disabled" : "btn btn-outline-primary btn-sm disabled" }>Fotos</button>
        </div>
        <div className="col-1 d-flex flex-column">
        <button className={props.estudios === 1 ? "btn btn-primary btn-sm disabled" : "btn btn-outline-primary btn-sm disabled" }>Estudios</button>
        </div>
        <div className="col-1 d-flex flex-column">
          <button className={props.plan === 1 ? "btn btn-primary btn-sm disabled" : "btn btn-outline-primary btn-sm disabled" }>Plan</button>
        </div>
        <div className="col-2">
          <textarea name="" id="" cols="30" rows="2" style={{maxHeight: '100%', maxWidth: '100%', borderBottom: '3px solid '}}>{props.comment}</textarea>
        </div>
        <div className="col-1">
          <button className="btn btn-outline-primary">Ver</button>
        </div>
      </div>
    </div>
  )
}
