import React, { useEffect, useState } from 'react'
import { MoreVertical, Upload } from 'react-feather'

// IMAGES STEP 2
import trescuartos_perfil from '../../../../assets/images/34deperfilsonriendo.png'
import anterioroclusion from '../../../../assets/images/anteriorenoclusion.png'
import anteriorinoclusion from '../../../../assets/images/anterioreninoclusion.png'
import bucalderecho from '../../../../assets/images/bucalderecho.png'
import bucalizquierdo from '../../../../assets/images/bucalizquierdo.png'
import frontalreposo from '../../../../assets/images/frontalenreposo.png'
import frontalsonriendo from '../../../../assets/images/frontalsonriendo.png'
import oclusalmaxilarinf from '../../../../assets/images/oclusaldelmaxilarinferior.png'
import oclusalmaxilarsup from '../../../../assets/images/oclusaldelmaxilarsuperior.png'
import perfilizqreposo from '../../../../assets/images/perfilizquierdoenreposo.png'

// IMAGES STEP 3
import radioperfil from '../../../../assets/images/telerradiografiadeperfil.png'
import ricketts from '../../../../assets/images/cefalogramadericketts.png'
import rxpanoramica from '../../../../assets/images/rxpanoramica.png'

import {
  ImageUploadContainer,
  ImageUploadLabel,
  ImageUploadReference,
} from './styled'
import Compressor from 'compressorjs'

// Import the editor styles
import 'pintura/pintura.css'

// Import the editor component from `react-pintura`
import {
  // Import plugins
  plugin_crop,
  plugin_finetune,
  plugin_annotate,
  plugin_resize,
  // Import languajes
  openDefaultEditor,
  setPlugins,
} from 'pintura'
import { Document, Page } from 'react-pdf'

const ImageInput = React.forwardRef(
  ({ name, type, src, handleImageSelect, id, loadImage }, ref) => {
    const [image, setImage] = useState(null)
    const [hasPDF, setHasPDF] = useState(false)

    async function handleImage(e) {
      e.preventDefault()

      if (e.target.files[0]?.type === 'application/pdf') {
        setImage(e.target.files[0])
        setHasPDF(true)
        handleImageSelect(name, e.target.files[0], type)
      } else {
        setHasPDF(false)
        let file = e.target.files[0]
        new Compressor(file, {
          quality: 0.1,
          success(result) {
            let reader = new FileReader()
            let file_compressed = result

            reader.onloadend = () => {
              setImage(reader.result)
              handleImageSelect(name, reader.result, type)
            }

            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      }
    }

    const [inputValue, setInputValue] = useState()

    function handleImageEditor(e) {
      setPlugins(plugin_crop, plugin_finetune, plugin_annotate, plugin_resize)
      const editor = openDefaultEditor({
        src: image,
      })

      editor.on('process', ({ dest }) => {
        // Here we use URL.createObjectURL to create a URL to the output image
        new Compressor(dest, {
          quality: 0.1,
          success(result) {
            let reader = new FileReader()
            let file_compressed = result

            reader.onloadend = () => {
              setImage(reader.result)
              handleImageSelect(name, reader.result, type)
            }

            reader.readAsDataURL(file_compressed)
          },
          error(err) {
            console.log(err.message)
          },
        })
      })
    }

    const imageSource = () => {
      if (image) return image
      if (src) return Components[src]
      return frontalreposo
    }

    const Components = {
      trescuartos_perfil: trescuartos_perfil,
      frontal_sonriendo: frontalsonriendo,
      frontal_reposo: frontalreposo,
      anterior_oclusion: anterioroclusion,
      anterior_inoclusion: anteriorinoclusion,
      bucal_izquierdo: bucalizquierdo,
      bucal_derecho: bucalderecho,
      oclusal_maxilar_sup: oclusalmaxilarsup,
      oclusal_maxilar_inf: oclusalmaxilarinf,
      perfil_izq_reposo: perfilizqreposo,
      telerradiografia: radioperfil,
      ricketts: ricketts,
      rxpanoramica: rxpanoramica,
    }

    useEffect(() => {
      if (loadImage) {
        if (loadImage?.type === 'application/pdf') setHasPDF(true)
        setImage(loadImage)
      }
    }, [loadImage])

    return (
      <div
        className="col-3 image-upload-container d-flex flex-column my-3 mx-auto"
        style={{
          maxHeight: '200px',
          width: '200px',
          position: 'relative',
        }}
      >
        <div className="d-flex flex-row  m-0">
          <ImageUploadContainer>
            <input
              value={inputValue}
              type="file"
              accept=".png, .jpg, .jpeg, .pdf"
              onChange={handleImage}
              id={name}
              className={type}
            />
            <Upload />
          </ImageUploadContainer>
          <div
            style={{
              width: '100px',
              height: '100px',
              position: 'relative',
            }}
          >
            {hasPDF ? (
              <div
                style={{
                  height: '100px',
                  width: '100px',
                  overflow: 'hidden',
                }}
              >
                <Document file={image}>
                  <Page height={125} scale={1} pageNumber={1} />
                </Document>
              </div>
            ) : (
              <ImageUploadReference
                src={imageSource()}
                alt="Imagen"
                ref={ref}
              />
            )}
            {image && !hasPDF ? (
              <div class="btn-group dropend">
                <button
                  type="button"
                  class="btn dropdown p-0 position-absolute"
                  style={{
                    top: '15px',
                    right: '5px',
                    background: 'rgba(0,0,0,0.4)',
                    borderRadius: 6,
                  }}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <MoreVertical size={24} color="#fff" />
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button
                      className="btn bg-none text-muted d-flex justify-content-center text-center align-items-middle"
                      onClick={() => {
                        handleImageEditor()
                      }}
                    >
                      Editar
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        setImage(null)
                        handleImageSelect(name, null, type)
                        setInputValue(null)
                      }}
                      className="btn bg-none text-muted d-flex flex-column justify-content-center text-center align-items-middle"
                    >
                      Eliminar
                    </button>
                  </li>
                </ul>
              </div>
            ) : (
              hasPDF && (
                <div class="btn-group dropend">
                  <button
                    type="button"
                    class="btn dropdown p-0 position-absolute"
                    style={{
                      top: '-45px',
                      right: '-90px',
                      background: 'rgba(0,0,0,0.4)',
                      borderRadius: 6,
                    }}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <MoreVertical size={24} color="#fff" />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button
                        onClick={() => {
                          setImage(null)
                          handleImageSelect(name, null, type)
                          setHasPDF(false)
                          setInputValue(null)
                        }}
                        className="btn bg-none text-muted d-flex flex-column justify-content-center text-center align-items-middle"
                      >
                        Eliminar
                      </button>
                    </li>
                  </ul>
                </div>
              )
            )}
          </div>
        </div>
        <ImageUploadLabel>{id}</ImageUploadLabel>
      </div>
    )
  }
)

export default ImageInput
