import React from 'react'

export default function SCIcons({type}) {
    return (
        <div>
            {type === 'Beginner' && <div className="sc-beginner" />}
            {type === 'Silver' && <div className="sc-silver" />}
            {type === 'Gold' && <div className="sc-gold" />}
            {type === 'Platinum' && <div className="sc-platinum" />}
            {type === 'Black' && <div className="sc-black" />}
        </div>
    )
}
